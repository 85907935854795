import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import HoldemGameSheet from "../../../components/web/HoldemGameSheet";
import {
  checkAlreadyJoinRingRoom,
  getRingGameList, getUserTicketInfo,
  joinRingRoom,
  RingGameItemInterface,
  UserTicketInfoInterface
} from "../../../api/game";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../../recoil/app";
import {LOCAL_STORAGE_ACCESS_KEY} from "../../../utils/network";
import {getKoreanNumber} from "../../../utils/common";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import useUserInfo from "../../../hooks/useUserInfo";
import { enqueueSnackbar } from "notistack";

const RingGameListWrapper = styled.div<{ scrollLock: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
overscroll-behavior: none;
  z-index: 10;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${p => p.scrollLock ? `
      overflow-y: hidden;
  ` : `
     overflow-y: scroll;
  `}
  @media ${MEDIA_DESKTOP} {
    position: static;
    top: unset;
    left: unset;
    transform: unset;
    overflow-y: unset;
    max-width: 1200px;
    max-height: unset;
    padding: 0;
    height: auto;
  }
  > .header {
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    z-index: 11;
    background: white;
    gap: 8px;
    @media ${MEDIA_DESKTOP}{
      display: none;
    }

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    > .title {
      color: ${p => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
      color: var(--Black-200, #B7B7B7);
      text-align: right;
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.26px;
    }
  }

  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    >.wrapper {
      width: 100%;
      @media ${MEDIA_DESKTOP} {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 42px;
        padding: 0px 70px 80px;
      }
      > .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
        padding: 20px 16px 56px;
        @media ${MEDIA_DESKTOP} {
          width: auto;
          gap: 20px;
          padding: 0;
        }
        >.game-info-help {
          display: none;
          @media ${MEDIA_DESKTOP} {
            display: flex;
            cursor: pointer;
          }
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 2px;
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          >img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
`
const MyPointWrapper = styled.div`
  display: none;
  width: 338px;
  padding: 16px 17px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;
  border: 1px solid var(--Black-100, #F0F0F0);
  @media ${MEDIA_DESKTOP} {
    display: flex;
  }
  >.title {
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
  }
  >.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    >.button {
      cursor: pointer;
      width: 80px;
      height: 36px;
      border-radius: 8px;
      background: var(--Purple-100, #F0EAFF);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--Purple-300, #6436E7);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.28px;
    }
    >.info {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 4px;
      >.title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      >.value{
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`
const GameInfoItemWrapper = styled.div`
  width: 100%;
  padding: 16px;
  @media ${MEDIA_DESKTOP} {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
    width: fit-content;
  }
  >.pc-wrapper {
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: flex;
    }
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    >.game-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      
      >.info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 8px;
        padding: 8px;
        border-radius: 8px;
        background: var(--Black-100, #F0F0F0);
        >.item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;
          color: var(--Purple-300, #6436E7);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }
      >.button {
        cursor: pointer;
        width: 148px;
        height: 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        background: var(--Purple-300, #6436E7);
        color: #FFF;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    >.info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      >.item {
        width: 230px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        >.sub-title {
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.28px;
        }
        >.title {
          margin-top: 8px;
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.4px;
        }
        >.desc {
          margin-top: 4px;
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }
    }
  }
  >.mobile-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.20);
    padding: 16px;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
    > .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;

      > .row-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 6px;

        > .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 6px;

          > .title {
            color: var(--Black-300, #808080);
            font-family: Pretendard;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.26px;
          }

          > .value {
            color: var(--Black-400, #444);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.32px;
          }
        }
      }

      > .info-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        > span {
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.26px;
        }

        > img {
          width: 16px;
          height: 16px;
        }
      }

    }

    > .button {
      cursor: pointer;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      background: var(--Purple-300, #6436E7);
      height: 30px;
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .button:active {
      background: #502bb5;
    }

  }

`
const MainBanner = styled.div`
  width: 100%;
  height: 188px;
  position: relative;
  @media ${MEDIA_DESKTOP} {
    height: 240px;
  }

  > .background {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > .title {
    position: absolute;
    bottom: 24px;
    color: #FFF;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.50);
    font-family: 'yg-jalnan';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
    left: 50%;
    transform: translateX(-50%);
    @media ${MEDIA_DESKTOP} {
      color: #FFF;
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.50);
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.8px;
      top: 50%;
      bottom: unset;
      transform: translate(-50%, -50%);
    }
  }
`

interface RingGameList {
  onClose?: () => void;
}

const RingGameListPage = ({onClose}: RingGameList) => {
  const {user} = useUserInfo();

  const setLoading = useSetRecoilState(loadingState);
  const accessToken = (localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY) ?? '').replace("Bearer ", "");
  const history = useHistory();
  const [userTicketInfo, setUserTicketInfo] = useState<UserTicketInfoInterface | null>(null)
  const [showGameInfo, setShowGameInfo] = useState(false)
    const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      if (history.length > 1){
        history.goBack();
      }else{
        history.push('/')
      }
    }
  }
  const [gameList, setGameList] = useState<RingGameItemInterface[]>([]);
  useEffect(()=> {
    if(accessToken){
      setLoading(true);
      checkAlreadyJoinRingRoom({
        token: accessToken
      }).then((res) => {
        if(res.data.result === 1){
          if(res.data.link){
            window.location.href = res.data.link;
          }else{
            setLoading(true);
            getRingGameList({
              token: accessToken
            }).then((res) => {
              setGameList(res.data.list.list);
            }).finally(()=>{
              setLoading(false);
            })
          }
        }else{
          enqueueSnackbar('정보 로드에 실패했습니다.', {variant: 'error'})
        }
      }).finally(()=>{
        setLoading(false);
      })

    }else{
      history.push('/login', {redirect: window.location.href})
    }
  }, [accessToken])

  const handleJoinGame = (gameId: number) => {
    setLoading(true)
    joinRingRoom({
      token: accessToken,
      groupId: gameId
    }).then((res) => {
      if(res.data.result === 1){
        window.location.href = res.data.link;
      }else{
        enqueueSnackbar('게임 참여에 실패했습니다.', {variant: 'error'})
      }
    }).finally(()=>{
      setLoading(false)
    })
  }
  useEffect(() => {
    if (user) {
      getUserTicketInfo({
        userId: user.id
      }).then(res => {
        if (Object.keys(res.data).length === 0) {
          setUserTicketInfo(null)
          return
        } else {
          setUserTicketInfo(res.data)
        }
      }).catch((e: any) => {

      })
    }
  }, [user?.id]);

  return <>
    <HoldemGameSheet showSheet={showGameInfo} setShowSheet={setShowGameInfo}/>
    <RingGameListWrapper scrollLock={false}>
      <div className="header">
        <div className="close" onClick={handleClose}>
          <img src="/image-web/Icon/Back.svg" alt="close"/>
        </div>
        <div className="title">홀덤게임 리스트</div>
        <div className='button' onClick={() => setShowGameInfo(true)}>홀덤게임 정보</div>
      </div>
      <div className='inner'>
        <MainBanner>
          <img src='/image-web/ringGame/background.png' className='background'/>
          <div className='title'>Holdem Game</div>
        </MainBanner>
        <div className='wrapper'>
          <div className='list'>
            <div className='game-info-help'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13.5 8.5C13.5 11.5376 11.0376 14 8 14C4.96243 14 2.5 11.5376 2.5 8.5C2.5 5.46243 4.96243 3 8 3C11.0376 3 13.5 5.46243 13.5 8.5ZM8 5.5C7.72386 5.5 7.5 5.72386 7.5 6C7.5 6.27614 7.72386 6.5 8 6.5H8.01C8.28614 6.5 8.51 6.27614 8.51 6C8.51 5.72386 8.28614 5.5 8.01 5.5H8ZM8.5 8.5C8.5 8.22386 8.27614 8 8 8C7.72386 8 7.5 8.22386 7.5 8.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5Z"
                      fill="#808080"/>
              </svg>
              <span>홀덤 게임 정보</span>
            </div>
            {
              gameList.map((item, index) => {
                return <GameInfoItemWrapper key={index}>
                  <div className='mobile-wrapper'>
                    <div className='info'>
                      <div className='row-list'>
                        <div className='row'>
                          <div className='title'>SB / BB</div>
                          <div
                            className='value'>{getKoreanNumber(item.data.blind[0])} / {getKoreanNumber(item.data.blind[1])}</div>
                          <div className='title'>(앤티 : {getKoreanNumber(item.data.ante)})</div>
                        </div>
                        <div className='row'>
                          <div className='title'>최소 바이인</div>
                          <div className='value'>{getKoreanNumber(item.data.minBuyin)}</div>
                        </div>
                      </div>
                      <div className='info-row'>
                        <img src='/image-web/ringGame/UI/List/Icon/Room/small.svg'/>
                        <span>테이블</span>
                        <span>{item.tableCount.toLocaleString()}개</span>
                        <span>·</span>
                        <img src='/image-web/ringGame/UI/List/Icon/Person/small.svg'/>
                        <span>플레이 인원</span>
                        <span>{Number(item.totalPlayerCount).toLocaleString()}명</span>
                      </div>
                    </div>
                    <div className='button' onClick={() => {
                      handleJoinGame(item.groupId)
                    }}>
                      참여하기
                    </div>
                  </div>
                  <div className='pc-wrapper'>
                    <div className='info'>
                      <div className='item'>
                        <div className='sub-title'>SB / BB</div>
                        <div
                          className='title'>{getKoreanNumber(item.data.blind[0])} / {getKoreanNumber(item.data.blind[1])}</div>
                        <div className='desc'>(앤티 : {getKoreanNumber(item.data.ante)})</div>
                      </div>
                      <div className='item'>
                        <div className='sub-title'>최소 바이인</div>
                        <div className='title'>{getKoreanNumber(item.data.minBuyin)}</div>
                      </div>
                    </div>
                    <div className='game-info'>
                      <div className='info'>
                        <div className='item'>
                          <img src='/image-web/Icon/Room/small.svg'/>
                          <span>테이블</span>
                          <span>{item.tableCount.toLocaleString()}개</span>
                        </div>
                        <div className='item'>
                          <img src='/image-web/Icon/Person/small.svg'/>
                          <span>플레이 인원</span>
                          <span>{Number(item.totalPlayerCount).toLocaleString()}명</span>
                        </div>
                      </div>
                      <div className='button' onClick={() => handleJoinGame(item.groupId)}>참여하기</div>
                    </div>
                  </div>
                </GameInfoItemWrapper>
              })
            }
          </div>
          <MyPointWrapper>
            <div className='title'>내 포인트</div>
            <div className='row'>
              <div className='info'>
                <div className='title'>보유 GP</div>
                <div className='value'>{(userTicketInfo ? userTicketInfo.user.point.toLocaleString() : '-')}</div>
              </div>
              <div className='button' onClick={() => {}}>
                충전하기
              </div>
            </div>
          </MyPointWrapper>
        </div>
      </div>
    </RingGameListWrapper>
  </>
}

export default RingGameListPage;
