import styled from "styled-components";

export const PageWrapper = styled.div`
  background-color: black;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  color: white;
  padding-bottom: 150px;
`;

export const BackButton = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1001;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const Header = styled.h2`
  text-align: center;
  padding: 20px 0;
  margin: 0;
  background-color: #1a1a1a;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

export const PlayersContainer = styled.div`
  max-height: calc(100vh - 350px);
  overflow-y: auto;
  padding: 0 15px;
  margin-top: 200px;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background-color: black;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const CardBox = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 3 / 4;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? "#444" : "#222")};
  background-size: cover;
  pointer-events: ${({ selected }) => (selected ? "none" : "auto")};
  opacity: ${({ selected }) => (selected ? 0.5 : 1)};
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const CommunityBoard = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #1a1a1a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow-x: auto;
`;

export const CommunityCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: max-content;
`;

export const FlopCards = styled.div`
  display: flex;
  gap: 5px;
`;

export const TurnRiverCards = styled.div`
  display: flex;
  gap: 5px;
  margin-left: 15px;
`;

export const CardGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardItem = styled.div<{ isFocus: boolean }>`
  width: 60px;
  height: 84px;
  border: 1px solid ${({ isFocus }) => (isFocus ? "#FFD700" : "#444")};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #222;
  flex-shrink: 0;

  > img {
    max-width: 100%;
    max-height: 100%;
  }
  > .plus {
    font-size: 30px;
  }
`;

export const CardWrapper = styled.div`
  background-color: #1a1a1a;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  gap: 2px;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 10px rgba(255, 255, 255, 0.1);
  z-index: 1000;
  overflow-y: auto;
  max-height: 50vh;

  @media (max-width: 768px) {
    grid-template-columns: repeat(8, 1fr);
    padding: 5px;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
  padding: 15px;
  background-color: #1a1a1a;
  border-radius: 10px;
  color: white;
`;

export const PlayerInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const PlayerHand = styled.div`
  display: flex;
  gap: 5px;
`;

export const WinRateInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const WinRate = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #4caf50;
  text-align: center;
`;

export const DrawRate = styled.div`
  font-size: 18px;
  color: #ffa500;
  text-align: center;
`;

export const RankBox = styled.div`
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 10px;
  font-size: 14px;
`;

export const RankItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RankName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RankValue = styled.span`
  text-align: right;
  min-width: 50px;
`;

export const Button = styled.button`
  color: white;
  background-color: #333;
  border: none;
  padding: 10px 15px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #444;
  }
`;
