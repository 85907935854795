import styled from "styled-components";
import { MEDIA_DESKTOP } from "../../hooks/useScreenOrientation";
import { useState } from "react";
import Notice from "../../pages/Web/MyPage/Notice";
import FAQ from "../../pages/Web/MyPage/FAQ";
import Agreement, { TermsType } from "../../pages/Web/Agreement";
import { openNewWindow } from "../../utils/common";

const FooterWrapper = styled.div`
  width: 100%;
  background: ${(p) => p.theme.color.black100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .inner {
    width: 100%;
    padding: 40px 16px 80px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 64px;
    @media ${MEDIA_DESKTOP} {
      max-width: 1200px;
      padding: 80px 70px;
    }

    > .nav-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      > .nav-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex-grow: 1;
        gap: 16px;

        > .item {
          cursor: pointer;
          color: ${(p) => p.theme.color.black400};
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }

      > .icon-list {
        width: 120px;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > .height20 {
          height: 20px;
          object-fit: contain;
        }

        > .height16 {
          height: 16px;
          object-fit: contain;
        }
      }
    }

    > .info-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      > .inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        @media ${MEDIA_DESKTOP} {
          flex-direction: row;
          gap: 10px;
        }

        > .logo {
          width: 37px;
          object-fit: contain;
        }

        > .description {
          margin-top: 10px;
          color: ${(p) => p.theme.color.black200};
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          letter-spacing: -0.24px;
          @media ${MEDIA_DESKTOP} {
            margin-top: 0;
            color: var(--Black-300, #808080);
            font-family: Pretendard;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 138.462% */
            letter-spacing: -0.26px;
          }
        }

        > .description.mobile {
          display: block;
          @media ${MEDIA_DESKTOP} {
            display: none;
          }
        }

        > .description.pc {
          display: none;
          @media ${MEDIA_DESKTOP} {
            display: block;
          }
        }
      }

      > .rule-row {
        margin-top: 12px;
        display: grid;
        color: ${(p) => p.theme.color.black200};
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.26px;
        grid-template-columns: repeat(3, auto);
        padding: 8px 0;
        @media ${MEDIA_DESKTOP} {
          display: flex;
          flex-direction: row;
        }

        > .item {
          cursor: pointer;
          padding: 0 4px;
          border-right: 1px solid ${(p) => p.theme.color.black200};
          @media ${MEDIA_DESKTOP} {
            color: var(--Black-300, #808080);
            font-family: Pretendard;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.26px;
          }

          &:last-child {
            border-right: none;
            padding-right: 0;
          }
        }
      }
    }
  }
`;
const GameCertificationWrapper = styled.div`
  > .pc {
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      margin-bottom: 20px;
      .item {
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.26px;
      }
      > .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        > .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
        }
      }
      > .image-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        > .image {
          width: 60px;
          height: 69px;
        }
      }
    }
  }
  > .mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 12px;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
    > .image-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      > .image {
        width: 60px;
        height: 69px;
      }
    }
    .item {
      color: var(--Black-200, #b7b7b7);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.24px;
    }
    > .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      gap: 10px;
    }
  }
`;

const Footer = () => {
  const [showMenu, setShowMenu] = useState<"notice" | "faq" | null>(null);
  const [termsType, setTermsType] = useState<TermsType>(null);

  return (
    <>
      {showMenu === "notice" && <Notice onClose={() => setShowMenu(null)} />}
      {showMenu === "faq" && <FAQ onClose={() => setShowMenu(null)} />}
      {termsType !== null && (
        <Agreement type={termsType} onClose={() => setTermsType(null)} />
      )}
      <FooterWrapper>
        <div className="inner">
          <div className="nav-wrapper">
            <div className="nav-list">
              <div
                className="item"
                onClick={() => {
                  openNewWindow("https://pf.kakao.com/_BxbmbG");
                }}
              >
                고객센터 & 문의하기
              </div>
              <div className="item" onClick={() => setShowMenu("notice")}>
                공지사항
              </div>
              <div className="item" onClick={() => setShowMenu("faq")}>
                자주 묻는 질문
              </div>
            </div>
            <div className="icon-list">
              <img
                className="height20"
                src="/image-web/footer/kakao.png"
                alt="kakao"
                onClick={() => {
                  openNewWindow("https://open.kakao.com/o/gCoBPh4f");
                }}
              />
              <img
                className="height20"
                src="/image-web/Logo/telegram_solid.svg"
                alt="telegram"
                onClick={() => {
                  openNewWindow("https://t.me/RunnerRunner0");
                }}
              />
              <img
                className="height16"
                src="/image-web/footer/youtube.png"
                alt="youtube"
                onClick={() => {
                  openNewWindow(
                    "https://youtube.com/@_runner_2?si=zygcb6Be3jT7WaAj"
                  );
                }}
              />
              <img
                className="height20"
                src="/image-web/footer/facebook.png"
                alt="facebook"
                onClick={() => {
                  openNewWindow(
                    "https://www.facebook.com/share/xeWJdn1u2zDHft2r/?mibextid=LQQJ4d"
                  );
                }}
              />
              <img
                className="height20"
                src="/image-web/footer/instagram.png"
                alt="instagram"
                onClick={() => {
                  openNewWindow(
                    "https://www.instagram.com/app.runner.official?igsh=MWxlb3dnYnNzeWpldg=="
                  );
                }}
              />
            </div>
          </div>
          <div className="info-wrapper">
            <GameCertificationWrapper>
              <div className="pc">
                <div className="image-row">
                  <img className="image" src="/image-web/game_mark1.png" />
                  <img className="image" src="/image-web/game_mark2.png" />
                </div>
                <div className="col">
                  <div className="item">게임물관리위원회 인증</div>
                  <div className="row">
                    <div className="item">
                      게임물명: 러너러너
                      <br />
                      이용등급: 청소년이용불가
                    </div>
                    <div className="item">
                      제작년월일: 2023.11.02
                      <br />
                      상호: 주식회사 러너소프트
                    </div>
                    <div className="item">
                      등급분류번호: CC-OM_240314-002
                      <br />
                      신고번호: 제2023-024호
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile">
                <div className="image-row">
                  <img className="image" src="/image-web/game_mark1.png" />
                  <img className="image" src="/image-web/game_mark2.png" />
                </div>
                <div className="item">게임물관리위원회 인증</div>
                <div className="row">
                  <div className="item">
                    게임물명: 러너러너
                    <br />
                    이용등급: 청소년이용불가
                    <br />
                    제작년월일: 2023.11.02
                  </div>
                  <div className="item">
                    상호: 주식회사 러너소프트
                    <br />
                    등급분류번호: CC-OM-240314-002
                    <br />
                    신고번호: 제2023-024호
                  </div>
                </div>
                <div className="row">
                  <div className="item">
                    게임물명: 러너텍사스홀덤
                    <br />
                    이용등급: 청소년이용불가
                    <br />
                    제작년월일: 2024.07.01
                  </div>
                  <div className="item">
                    상호: 주식회사 러너소프트
                    <br />
                    등급분류번호: CC-OM-240912-004
                    <br />
                    신고번호: 제2023-024호
                  </div>
                </div>
              </div>
            </GameCertificationWrapper>
            <div className="inner">
              <img
                className="logo"
                src="/image-web/footer/Runnerrunner_Full.png"
              />
              <div className="description mobile">
                주식회사 러너소프트
                <br />
                회사주소 : 서울특별시 강남구 삼성로133길 15, 2층(청담동,
                근정빌딩 2층)
                <br />
                전화번호 : 02-517-0365 사업자등록번호 : 816-86-02969
                <br />
                통신판매자 등록번호 : 2023-서울강남-06319
              </div>
              <div className="description pc">
                주식회사 러너소프트 | 회사주소 : 서울특별시 강남구 삼성로133길
                15, 2층(청담동, 근정빌딩 2층)
                <br />
                전화번호 : 02-517-0365 | 사업자등록번호 : 816-86-02969 |
                통신판매자 등록번호 : 2023-서울강남-06319
              </div>
            </div>
            <div className="rule-row">
              <div className="item" onClick={() => setTermsType("service")}>
                서비스 이용약관
              </div>
              <div
                className="item"
                style={{ color: "black" }}
                onClick={() => setTermsType("privacy")}
              >
                <b>개인정보처리방침</b>
              </div>
              <div className="item" onClick={() => setTermsType("location")}>
                위치정보 이용약관
              </div>
              <div className="item" onClick={() => setTermsType("teenager")}>
                청소년보호방침
              </div>
              <div
                className="item"
                onClick={() =>
                  window.open(
                    "http://gucc.or.kr/active/report_illegality_guide.asp",
                    "_blank"
                  )
                }
              >
                불법환전신고
              </div>
            </div>
          </div>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
