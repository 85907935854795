import styled from "styled-components";
import { useState } from "react";
import useQueryParams from "../../../hooks/useQueryParams";
import { ReviewDataResponse } from "../../../api/types";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import moment from "moment";
import ImageViewer from "../../../components/ImageViewer";
import PcReviewWrite from "./PcReviewWrite";
import StarDisplay from "../../../components/StarDisplay";
import { useRecoilValue } from "recoil";
import { userState } from "../../../recoil/auth";
import { enqueueSnackbar } from "notistack";

const HoldemItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 20px;
  gap: 10px;
  cursor: pointer;
  @media ${MEDIA_DESKTOP} {
    border-radius: 12px;
    border: 1px solid var(--Black-100, #f0f0f0);
    background: #fff;
  }

  > .info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;

    > .thumbnail {
      width: 40px;
      height: 40px;
      background: gray;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;
      flex-grow: 1;
      > .title-top {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        > .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.32px;
          gap: 4px;

          > img {
            width: 16px;
            height: 16px;
            object-fit: contain;
          }
        }
        > .edit {
          font-size: 14px;
          color: gray;
        }
      }

      > .title-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        > .createdAt {
          margin-left: 7px;
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.24px;
        }
      }
    }
  }

  > .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    > .content {
      width: 100%;
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      white-space: pre-wrap;
    }

    > .images {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 8px;
      background: #e5e5e5;
    }
  }

  > .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    > .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;

      > img {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }
  }
`;

const ReviewBox = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  > .title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > .button {
      cursor: pointer;
      color: var(--Black-200, #b7b7b7);
      text-align: right;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
    }
    > .title-box {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.36px;
      }
      > .count {
        color: var(--Black-200, #b7b7b7);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 6px;
        margin-bottom: 2px;
      }
    }
  }

  > .list {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }

  > .show-more {
    margin-top: 20px;
    border: 1px solid var(--Purple-300, #6436e7);
    background: var(--Purple-100, #f0eaff);
    color: var(--Purple-300, #6436e7);
    border-radius: 8px;
    cursor: pointer;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
  }
`;

const ImageGrid = styled.div<{
  length: number;
}>`
  width: 100%;
  > .grid-box {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    > img {
      object-fit: cover;
      aspect-ratio: 1;
      border-radius: 8px;
      background: #e5e5e5;
      width: 100%;
    }
    > .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      fontsize: 14px;
    }
  }

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
`;
interface ReviewProps {
  reviewData: ReviewDataResponse[];
  pubId: number;
  todayReviewPhotoAdd: boolean;
  setRefresh: any;
}
const PCReview = ({
  reviewData,
  pubId,
  todayReviewPhotoAdd,
  setRefresh,
}: ReviewProps) => {
  const [visibleCount, setVisibleCount] = useState(5);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
  const [imageList, setImageList] = useState<string[]>([]);
  const [showWrite, setShowWrite] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editReviewData, setEditReviewData] =
    useState<ReviewDataResponse | null>(null);
  const query = useQueryParams();
  const user = useRecoilValue(userState);
  const showMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };

  return (
    <>
      {showWrite && (
        <PcReviewWrite
          mode={"write"}
          onClose={() => setShowWrite(false)}
          rate={5}
          pubId={pubId}
          setRefresh={setRefresh}
        />
      )}
      {showEdit && (
        <PcReviewWrite
          setRefresh={setRefresh}
          mode={"edit"}
          onClose={() => setShowEdit(false)}
          rate={editReviewData ? editReviewData.score : 5}
          reviewData={editReviewData ? editReviewData : undefined}
          pubId={pubId}
        />
      )}
      <ReviewBox>
        {selectedImageIndex !== -1 && (
          <ImageViewer
            images={imageList || []}
            defaultIndex={selectedImageIndex}
            onClose={() => setSelectedImageIndex(-1)}
          />
        )}
        <div className="title-row">
          <div className="title-box">
            <div className="title">리뷰</div>
            <div className="count">({reviewData.length})</div>
          </div>

          {user &&
            !reviewData.some((review) => review.userName === user.nickname) && (
              <span
                className="button"
                onClick={() => {
                  setShowWrite(true);
                }}
              >
                리뷰 작성
              </span>
            )}
        </div>
        <div className="list">
          {reviewData.slice(0, visibleCount).map((item, idx) => {
            return (
              <HoldemItem key={idx}>
                <div className="info-wrapper">
                  <div className="thumbnail">
                    {item.userProfile ? (
                      <img src={item.userProfile} />
                    ) : (
                      <img src="https://dfesoodpx4jgd.cloudfront.net/user/default_profile.png" />
                    )}
                  </div>
                  <div className="info">
                    <div className="title-top">
                      <div className="title">{item.userName}</div>
                      {item.userName === user?.nickname && (
                        <div
                          className="edit"
                          onClick={() => {
                            setShowEdit(true);
                            setEditReviewData(item);
                          }}
                        >
                          편집
                        </div>
                      )}
                    </div>
                    <div className="title-bottom">
                      <StarDisplay rating={item.score} />
                      <div className="createdAt">
                        {moment(item.createdAt).fromNow()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-wrapper">
                  <div className="content">{item.message}</div>
                  <ImageGrid length={item.imageList.length}>
                    {item.imageList.slice(0, 5).map((url, i) => (
                      <div className="grid-box" key={url} style={{}}>
                        <img
                          className="images"
                          src={url}
                          onClick={() => {
                            setSelectedImageIndex(i);
                            setImageList(item.imageList);
                          }}
                        />
                        {i === 4 && (
                          <div
                            onClick={() => {
                              setSelectedImageIndex(4);
                              setImageList(item.imageList);
                            }}
                            className="overlay"
                          >
                            더보기 ..
                          </div>
                        )}
                      </div>
                    ))}
                  </ImageGrid>
                </div>
              </HoldemItem>
            );
          })}
        </div>
        {visibleCount < reviewData.length && (
          <div className="show-more" onClick={showMoreItems}>
            더보기
          </div>
        )}
      </ReviewBox>
    </>
  );
};

export default PCReview;
