import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Job,
  jobList,
  RecruitType,
  UserJob,
  userJobList,
} from "../../../api/job";
import { enqueueSnackbar } from "notistack";
import useGeoLocation from "../../../hooks/useGeoLocation";
import { getDistanceKm } from "../../../utils/common";
import moment from "moment/moment";
import {
  getGenderLabel,
  getRecruitStateLabel,
  getRecruitTypeLabel,
  RecruitTypeLabels,
} from "../../../utils/constants";
import { ContentItemWrapper } from "./index";
import RecruitDetail from "../RecruitDetail";
import RegisterRecruit from "./RegisterRecruit";
import useUserInfo from "../../../hooks/useUserInfo";
import RegisterUserRecruit from "./RegisterUserRecruit";

const PCRecruitWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 100%;
  > .top-wrapper {
    flex-shrink: 0;
    width: 100%;
    max-width: 1060px;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    > .button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 11px 20px;
      height: 36px;
      border-radius: 12px;
      background: var(--Purple-300, #6436e7);
      color: #fff;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
    }
    > .filter-wrapper {
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      > .item-wrapper {
        position: relative;
        > .filter-box {
          position: absolute;
          top: 40px;
          padding: 20px;
          border-radius: 7px;
          border: 1px solid var(--Black-100, #f0f0f0);
          background: #fff;
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
          z-index: 100;
          left: 0;
        }

        > .filter-box.list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: max-content;
          gap: 20px;

          > .item {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;
            color: var(--Black-400, #444);
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.28px;
          }
        }

        > .filter-box.grid-3 {
          width: max-content;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;

          > .item {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;

            > span {
              width: 80px;
              color: var(--Black-400, #444);
              font-family: Pretendard;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -0.28px;
            }
          }

          > .item.selected {
            color: #fff;
            border-radius: 4px;
            background: var(--Purple-200, #8359f7);
            border: none;
          }
        }

        > .filter-box.grid {
          width: max-content;
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: 8px;

          > .item {
            cursor: pointer;
            color: var(--Black-400, #444);
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.28px;
            padding: 4px 12px;
            border-radius: 4px;
            border: 1px solid var(--Black-200, #b7b7b7);
            background: #fff;
          }

          > .item.selected {
            color: #fff;
            border-radius: 4px;
            background: var(--Purple-200, #8359f7);
            border: none;
          }
        }

        > .item {
          cursor: pointer;
          width: fit-content;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 4px;
          padding: 9px 12px;
          border-radius: 8px;
          border: 1px solid var(--Black-100, #f0f0f0);
          background: #fff;
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        > .item.selected {
          border-radius: 8px;
          border: 1px solid var(--Purple-300, #6436e7);
          background: var(--Purple-100, #f0eaff);
        }
      }
    }
  }

  > .horizontal-line {
    width: 100%;
    height: 1px;
    flex-shrink: 0;
    background: var(--Black-200, #b7b7b7);
  }

  > .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    max-width: 1060px;
    max-height: calc(100% - 61px);

    > .list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 20px;
      height: 100%;
      flex-shrink: 0;
      width: 368px;
      border-right: 1px solid var(--Black-200, #b7b7b7);

      > .result-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px 43px 8px 0;

        > .result {
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        > .sort-wrapper {
          position: relative;

          > .sort-list {
            width: 116px;
            padding: 20px;
            position: absolute;
            border-radius: 7px;
            border: 1px solid var(--Black-100, #f0f0f0);
            background: #fff;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;
            z-index: 2;
            top: 42px;
            right: 0;

            > .item {
              cursor: pointer;
              color: var(--Black-400, #444);
              font-family: Pretendard;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: -0.28px;
            }

            > .item.selected {
              color: var(--Purple-300, #6436e7);
            }
          }

          > .button {
            border-radius: 18px;
            border: 1px solid var(--Black-200, #b7b7b7);
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 2px;
            padding: 9px 8px 9px 12px;
            color: var(--Black-500, #202020);
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }

      > .inner {
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        padding-bottom: 20px;
        padding-right: 30px;

        > .content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 12px;
        }
      }

      > .inner::-webkit-scrollbar {
        width: 14px;
        display: unset;
      }

      > .inner::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 7px;
        background: var(--Black-100, #f0f0f0);
        background-clip: padding-box;
      }

      > .inner::-webkit-scrollbar-track {
        background: transparent;
      }
    }

    > .detail {
      height: 100%;
      flex-grow: 1;
      padding-left: 30px;

      > .empty-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        gap: 12px;

        > img {
          margin-top: 10px;
          width: 180px;
          object-fit: contain;
        }

        > .empty-text {
          width: 100%;
          color: var(--Black-300, #808080);
          text-align: center;
          font-family: Pretendard;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
`;
const PCRecruit = () => {
  const history = useHistory();
  const params = useParams<{
    id?: string;
    type?: string;
  }>();
  const { user } = useUserInfo();
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedPrice, setSelectedPrice] = useState("전체");
  const [selectedDistance, setSelectedDistance] = useState(-1);
  const [priceValue, setPriceValue] = useState<{
    min: number;
    max: number;
    selectedMin: number;
    selectedMax: number;
  }>({
    min: 1234,
    max: 67890,
    selectedMin: 1234,
    selectedMax: 67890,
  });
  const [locationValue, setLocationValue] = useState<{
    min: number;
    max: number;
    selectedMin: number;
    selectedMax: number;
  }>({
    min: 0,
    max: 98,
    selectedMin: 0,
    selectedMax: 98,
  });
  const [showSortList, setShowSortList] = useState(false);
  const [showFilter, setShowFilter] = useState<
    "area" | "status" | "pay" | "distance" | null
  >(null);
  const [showRegisterRecruit, setShowRegisterRecruit] = useState(false);
  const [showRegisterUserRecruit, setShowRegisterUserRecruit] = useState(false);
  const [showRegisterEdit, setShowRegisterEdit] = useState(-1);
  const [showRegisterUserEdit, setShowRegisterUserEdit] = useState(-1);
  const [selectedSort, setSelectedSort] = useState("최신순");
  const [recruitType, setSelectedRecruitType] = useState<RecruitType>("");
  const [list, setList] = useState<Job[]>([]);
  const [userList, setUserList] = useState<UserJob[]>([]);
  const [selectedRecruitId, setSelectedRecruitId] = useState(-1);
  const [selectedUserRecruitId, setSelectedUserRecruitId] = useState(-1);
  const { latitude, longitude } = useGeoLocation();
  const updateList = useCallback(() => {
    jobList({
      recruitType: recruitType ? [recruitType] : undefined,
      lat: latitude,
      lon: longitude,
    })
      .then((list) => {
        setList(list);
      })
      .catch((e: any) => {
        enqueueSnackbar("채용 목록을 가져올 수 없습니다:" + e.message, {
          variant: "error",
        });
      });
    userJobList({
      recruitType: recruitType ? [recruitType] : undefined,
      lat: latitude,
      lon: longitude,
    })
      .then((list) => {
        setUserList(list);
      })
      .catch((e: any) => {
        enqueueSnackbar("채용 목록을 가져올 수 없습니다:" + e.message, {
          variant: "error",
        });
      });
  }, [recruitType]);
  const sortedList = useMemo(() => {
    if (list.length === 0) {
      return [];
    }

    return list.sort((a, b) => {
      if (selectedSort === "oldest") {
        return a.id - b.id;
      } else if (selectedSort === "nearest") {
        const distanceA = Number(getDistanceKm(latitude, longitude, 0, 0));
        const distanceB = Number(getDistanceKm(latitude, longitude, 0, 0));
        return distanceA - distanceB;
      } else if (selectedSort === "highNet") {
        return b.hourlyPay - a.hourlyPay;
      }

      return b.id - a.id;
    });
  }, [list, recruitType, selectedSort, latitude, longitude]);

  useEffect(() => {
    updateList();
  }, [updateList]);
  useEffect(() => {
    if (params.id) {
      if (params.type === "pub") {
        setSelectedRecruitId(Number(params.id));
        setSelectedUserRecruitId(-1);
      } else {
        setSelectedRecruitId(-1);
        setSelectedUserRecruitId(Number(params.id));
      }
    }
  }, [params.id]);
  useEffect(() => {}, [selectedRecruitId]);

  return (
    <>
      {showRegisterRecruit && (
        <RegisterRecruit
          mode="write"
          update={updateList}
          onClose={() => setShowRegisterRecruit(false)}
        />
      )}
      {showRegisterEdit > -1 && (
        <RegisterRecruit
          mode="edit"
          recruitId={showRegisterEdit}
          update={updateList}
          onClose={() => setShowRegisterEdit(-1)}
        />
      )}
      {showRegisterUserRecruit && (
        <RegisterUserRecruit
          mode="write"
          update={updateList}
          onClose={() => setShowRegisterUserRecruit(false)}
        />
      )}
      {showRegisterUserEdit > -1 && (
        <RegisterUserRecruit
          mode="edit"
          recruitId={showRegisterUserEdit}
          update={updateList}
          onClose={() => setShowRegisterUserEdit(-1)}
        />
      )}
      <PCRecruitWrapper>
        <div className="top-wrapper">
          <div className="filter-wrapper">
            <div className="item-wrapper">
              <div
                className={
                  "item " + (selectedLocations.length > 0 ? "selected" : "")
                }
                onClick={() => {
                  setShowFilter(showFilter === "area" ? null : "area");
                }}
              >
                {selectedLocations.length > 0
                  ? selectedLocations.join(", ")
                  : "지역"}
                {""} <img src="/image-web/Icon/Arrow%20down.svg" />
              </div>
              {showFilter === "area" && (
                <div className="filter-box grid">
                  {[
                    "전체",
                    "서울",
                    "경기",
                    "인천",
                    "대전",
                    "대구",
                    "부산",
                    "울산",
                    "광주",
                    "강원",
                    "충북",
                    "충남",
                    "경북",
                    "경남",
                    "전북",
                    "전남",
                    "제주",
                  ].map((v, i) => {
                    const isSelected = selectedLocations.includes(v);

                    return (
                      <div
                        key={i}
                        className={"item " + (isSelected ? " selected" : "")}
                        onClick={() => {
                          if (isSelected) {
                            setSelectedLocations(
                              selectedLocations.filter(
                                (location) => location !== v
                              )
                            );
                          } else {
                            setSelectedLocations([...selectedLocations, v]);
                          }
                        }}
                      >
                        {v}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="item-wrapper">
              <div
                className={"item " + (recruitType !== "" ? "selected" : "")}
                onClick={() => {
                  setShowFilter(showFilter === "status" ? null : "status");
                }}
              >
                {recruitType !== ""
                  ? RecruitTypeLabels.find((x) => x.type === recruitType)?.label
                  : "직무"}{" "}
                <img src="/image-web/Icon/Arrow%20down.svg" />
              </div>
              {showFilter === "status" && (
                <div className="filter-box list">
                  {RecruitTypeLabels.map(({ type, label }, i) => {
                    const isSelected = recruitType === type;
                    return (
                      <div
                        className="item"
                        key={i}
                        onClick={() => setSelectedRecruitType(type)}
                      >
                        {isSelected ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <rect
                              width="20"
                              height="20"
                              rx="3"
                              fill="#8359F7"
                            />
                            <path
                              d="M6 10L8.96296 12.5L13.6667 7.5"
                              stroke="white"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="19"
                              height="19"
                              rx="2.5"
                              fill="white"
                              stroke="#B7B7B7"
                            />
                          </svg>
                        )}
                        <span>{label}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="item-wrapper">
              <div
                className={
                  "item " + (selectedPrice !== "전체" ? "selected" : "")
                }
                onClick={() => {
                  setShowFilter(showFilter === "pay" ? null : "pay");
                }}
              >
                {selectedPrice !== "전체" ? selectedPrice : "시급"}{" "}
                <img src="/image-web/Icon/Arrow%20down.svg" />
              </div>
              {showFilter === "pay" && (
                <div className="filter-box list">
                  {[
                    "전체",
                    "10,000원 ~ 15,000원",
                    "15,000원 ~ 20,000원",
                    "20,000원 ~ 30,000원",
                    "30,000원 ~",
                  ].map((v, i) => {
                    const isSelected =
                      selectedPrice === v ||
                      (selectedPrice === "" && v === "전체");
                    return (
                      <div
                        className="item"
                        key={i}
                        onClick={() => {
                          setSelectedPrice(v);
                          if (v === "전체") {
                            setPriceValue({
                              ...priceValue,
                              selectedMin: priceValue.min,
                              selectedMax: priceValue.max,
                            });
                          } else if (v === "10,000원 ~ 15,000원") {
                            setPriceValue({
                              ...priceValue,
                              selectedMin: 10000,
                              selectedMax: 15000,
                            });
                          } else if (v === "15,000원 ~ 20,000원") {
                            setPriceValue({
                              ...priceValue,
                              selectedMin: 15000,
                              selectedMax: 20000,
                            });
                          } else if (v === "20,000원 ~ 30,000원") {
                            setPriceValue({
                              ...priceValue,
                              selectedMin: 20000,
                              selectedMax: 30000,
                            });
                          } else if (v === "30,000원 ~") {
                            setPriceValue({
                              ...priceValue,
                              selectedMin: 30000,
                              selectedMax: priceValue.max,
                            });
                          }
                        }}
                      >
                        {isSelected ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <circle
                              cx="10"
                              cy="10"
                              r="9.5"
                              fill="white"
                              stroke="#8359F7"
                            />
                            <circle cx="10" cy="10" r="6" fill="#8359F7" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <circle
                              cx="10"
                              cy="10"
                              r="9.5"
                              fill="white"
                              stroke="#B7B7B7"
                            />
                          </svg>
                        )}
                        <span>{v}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="item-wrapper">
              <div
                className={
                  "item " + (selectedDistance !== -1 ? "selected" : "")
                }
                onClick={() => {
                  setShowFilter(showFilter === "distance" ? null : "distance");
                }}
              >
                {selectedDistance !== -1
                  ? `${selectedDistance}km 이내`
                  : "거리"}{" "}
                <img src="/image-web/Icon/Arrow%20down.svg" />
              </div>
              {showFilter === "distance" && (
                <div className="filter-box grid-3">
                  {[-1, 1, 2, 5, 10, 20, 50].map((v, i) => {
                    const isSelected = selectedDistance === v;
                    return (
                      <div
                        className="item"
                        key={i}
                        onClick={() => {
                          setSelectedDistance(v);
                          if (v === -1) {
                            setLocationValue({
                              ...locationValue,
                              selectedMin: locationValue.min,
                              selectedMax: locationValue.max,
                            });
                          } else {
                            setLocationValue({
                              ...locationValue,
                              selectedMin: locationValue.min,
                              selectedMax: v,
                            });
                          }
                        }}
                      >
                        {isSelected ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <circle
                              cx="10"
                              cy="10"
                              r="9.5"
                              fill="white"
                              stroke="#8359F7"
                            />
                            <circle cx="10" cy="10" r="6" fill="#8359F7" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <circle
                              cx="10"
                              cy="10"
                              r="9.5"
                              fill="white"
                              stroke="#B7B7B7"
                            />
                          </svg>
                        )}
                        <span>{v === -1 ? "전체" : `${v}km 이내`}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {user && (
            <div
              className="button"
              onClick={() => {
                if (user?.role === "ROLE_SELLER") setShowRegisterRecruit(true);
                else setShowRegisterUserRecruit(true);
              }}
            >
              채용 등록
            </div>
          )}
        </div>
        <div className="horizontal-line" />
        <div className="content">
          <div className="list">
            <div className="result-wrapper">
              <div className="result">{sortedList.length}개의 검색결과</div>
              <div className="sort-wrapper">
                {showSortList && (
                  <div className="sort-list">
                    {["최신순", "오래된 순", "가까운 순", "시급 높은 순"].map(
                      (x) => {
                        return (
                          <div
                            key={x}
                            className={
                              "item " + (selectedSort === x ? "selected" : "")
                            }
                            onClick={() => setSelectedSort(x)}
                          >
                            {x}
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                <div
                  className="button"
                  onClick={() => {
                    setShowSortList(!showSortList);
                  }}
                >
                  {selectedSort === "" ? "정렬" : selectedSort}
                  <img src="/image-web/Icon/Arrow%20down.svg" />
                </div>
              </div>
            </div>
            <div className="inner">
              <div className="content">
                {sortedList.map((item, index) => {
                  return (
                    <ContentItemWrapper
                      selected={item.id === selectedRecruitId}
                      key={item.id}
                      onClick={() => {
                        history.push(`/recruit/detail/${item.id}/pub`);
                      }}
                    >
                      {moment().isAfter(item.endDate) && (
                        <div className="dim" />
                      )}
                      <div className="top-row">
                        <div className="pub-badge">
                          {Math.random() > 0.5 ? (
                            <img src="/image-web/recruit/UI/List/Hire/Icon/star.png" />
                          ) : (
                            <img src="/image-web/recruit/UI/List/Hire/Icon/check.png" />
                          )}
                        </div>
                        <div className="badge">
                          {getRecruitStateLabel(item.state)}
                        </div>
                        <div className="badge">
                          {getRecruitTypeLabel(
                            item.recruitType,
                            item.recruitTypeEtc
                          )}
                        </div>
                        {item.owner && (
                          <div
                            className="edit"
                            onClick={(e) => {
                              setShowRegisterEdit(item.id);
                              e.stopPropagation();
                            }}
                          >
                            수정
                          </div>
                        )}
                      </div>
                      <div className="info-wrapper">
                        <div className="title">{item.cafeName}</div>
                        <div className="info">{item.place}</div>
                        <div className="info">
                          성별: {getGenderLabel(item.gender)}
                        </div>
                        <div className="money">
                          시급: {item.hourlyPay.toLocaleString()}원
                        </div>
                      </div>
                    </ContentItemWrapper>
                  );
                })}
                {userList.map((item, index) => {
                  return (
                    <ContentItemWrapper
                      selected={item.id === selectedUserRecruitId}
                      key={item.id}
                      onClick={() => {
                        history.push(`/recruit/detail/${item.id}/user`);
                      }}
                    >
                      {moment().isAfter(item.endDate) && (
                        <div className="dim" />
                      )}
                      <div className="top-row">
                        <div className="badge">
                          {getRecruitStateLabel(item.state)}
                        </div>
                        <div className="badge">
                          {getRecruitTypeLabel(
                            item.recruitType,
                            item.recruitTypeEtc
                          )}
                        </div>
                        {item.owner && (
                          <div
                            className="edit"
                            onClick={(e) => {
                              setShowRegisterUserEdit(item.id);
                              e.stopPropagation();
                            }}
                          >
                            수정
                          </div>
                        )}
                      </div>
                      <div className="info-wrapper">
                        <div className="title">{item.cafeName}</div>
                        <div className="info">{item.place}</div>
                        <div className="info">
                          성별: {getGenderLabel(item.gender)}
                        </div>
                        <div className="money">
                          시급: {item.hourlyPay.toLocaleString()}원
                        </div>
                      </div>
                    </ContentItemWrapper>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="detail">
            {selectedRecruitId !== -1 || selectedUserRecruitId !== -1 ? (
              <RecruitDetail />
            ) : (
              <div className="empty-wrapper">
                <img src="/image-web/None.png" />
                <div className="empty-text">선택된 채용 정보가 없습니다.</div>
              </div>
            )}
          </div>
        </div>
      </PCRecruitWrapper>
    </>
  );
};

export default PCRecruit;
