import styled from "styled-components";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import { useHistory } from "react-router-dom";
import { openNewWindow } from "../../../utils/common";
import { useEffect, useState } from "react";
import { IOpenChat, getOpenChatList } from "../../../api/community";

const EventWrapper = styled.div<{
  scrollLock: boolean;
}>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  height: 100vh;
  max-height: 100vh;
  z-index: 10;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media ${MEDIA_DESKTOP} {
    position: static;
    top: unset;
    left: unset;
    height: unset;
    transform: unset;
    overflow-y: unset;
    max-width: 1060px;
    max-height: unset;
    padding: 40px 0;
  }

  > .header {
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    z-index: 11;
    background: white;
    gap: 8px;
    @media ${MEDIA_DESKTOP} {
      position: static;
      bottom: unset;
      right: unset;
      left: unset;
      top: unset;
      transform: unset;
      height: unset;
      padding: 0;
    }

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      @media ${MEDIA_DESKTOP} {
        display: none;
      }
    }

    > .title {
      color: ${(p) => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  > .banner {
    margin-top: 5px;
    width: 100%;
    @media ${MEDIA_DESKTOP} {
      margin-top: 20px;
    }
    > img {
      width: 100%;
    }
  }
`;

const ChatWrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > .menus {
    padding: 0 8px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 16px;
    justify-items: center;
    justify-content: center;
    @media ${MEDIA_DESKTOP} {
      margin-top: 10px;
      grid-template-columns: repeat(5, 1fr);
    }
    > .item {
      width: 74px;
      height: 72px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: background-color 0.2s ease-in-out;
      @media ${MEDIA_DESKTOP} {
        width: 144px;
        height: 144px;
      }
      &:active {
        background-color: #f0f0f0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }

      > .icon {
        width: 70px;
        height: 70px;
        object-fit: contain;
        @media ${MEDIA_DESKTOP} {
          width: 140px;
          height: 140px;
        }
      }

      > .title {
        color: ${(p) => p.theme.color.black400};
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;

const OpenChatList = () => {
  const history = useHistory();
  const [openChatList, setOpenChatList] = useState<IOpenChat[]>([]);
  const getOpenChats = async () => {
    const result = await getOpenChatList();
    setOpenChatList(result);
  };
  useEffect(() => {
    getOpenChats();
  }, []);
  return (
    <>
      <EventWrapper scrollLock={false}>
        <div className="header">
          <div
            className="close"
            onClick={() => {
              history.push("/");
            }}
          >
            <img
              src="/image-web/Icon/Back.svg"
              id="오픈톡 뒤로가기"
              alt="close"
            />
          </div>
          <div className="title">지역별 오픈채팅</div>
        </div>
        <div className="banner" id="오픈톡 배너">
          <img src="/image-web/openChat/open_chat_banner.png" />
        </div>
        <ChatWrapper>
          <div className="menus">
            {openChatList.map((item, index) => (
              <div
                className="item"
                key={index}
                onClick={() => {
                  openNewWindow(item.url);
                }}
                id={item.title}
              >
                <img
                  src={item.image_url}
                  alt="icon"
                  className="icon"
                  style={{ pointerEvents: "none" }}
                />
              </div>
            ))}
          </div>
        </ChatWrapper>
      </EventWrapper>
    </>
  );
};

export default OpenChatList;
