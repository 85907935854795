import Picker from 'react-mobile-picker'
import {useEffect, useMemo, useState} from "react";
import moment from "moment";

interface DateTimeWheelProps {
  type: 'date' | 'time',
  onChange: (value: string) => void,
}

type DateKeyType = 'year' | 'month' | 'day'
type TimeKeyType = 'hour' | 'minute' | 'meridiem'


const DateWheel = ({onChange}: { onChange: (value: string) => void }) => {
  const current = moment().format('YYYY-MM-DD')
  const [yearArray, setYearArray] = useState(Array.from({length: 10}, (_, i) => (moment().year() - 5 + i).toString()))
  const [monthArray, setMonthArray] = useState(Array.from({length: 12}, (_, i) => (i + 1).toString().padStart(2, '0')))
  const [dayArray, setDayArray] = useState(Array.from({length: 31}, (_, i) => (i + 1).toString().padStart(2, '0'))
  )

  const [pickerValue, setPickerValue] = useState({
    year: current.split('-')[0],
    month: current.split('-')[1],
    day: current.split('-')[2]
  })

  useEffect(() => {
    //선택된 월에 따라 일수가 달라짐
    const year = parseInt(pickerValue.year)
    const month = parseInt(pickerValue.month)
    const days = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()
    setDayArray(Array.from({length: days}, (_, i) => (i + 1).toString().padStart(2, '0')))
    onChange(`${pickerValue.year}.${pickerValue.month}.${pickerValue.day}`)
  }, [pickerValue])

  return useMemo(() => {
    return <Picker
      style={{
        width: '100%'
      }}
      height={180}
      wheelMode={'normal'}
      value={pickerValue}
      onChange={setPickerValue}
    >
      <Picker.Column name={'year'}>
        {
          yearArray.map((value) => {
            return <Picker.Item key={value} value={value}>{value}</Picker.Item>
          })
        }
      </Picker.Column>
      <Picker.Column name={'month'}>
        {
          monthArray.map((value) => {
            return <Picker.Item key={value} value={value}>{value}</Picker.Item>
          })
        }
      </Picker.Column>
      <Picker.Column name={'day'}>
        {
          dayArray.map((value) => {
            return <Picker.Item key={value} value={value}>{value}</Picker.Item>
          })
        }
      </Picker.Column>
    </Picker>
  }, [pickerValue, dayArray])

}
const TimeWheel = ({onChange}: { onChange: (value: string) => void }) => {
  const current = moment().format('hh mm A')
  const [hourArray, setHourArray] = useState(Array.from({length: 12}, (_, i) => (i).toString().padStart(2, '0'))
  )
  const [minuteArray, setMinuteArray] = useState(Array.from({length: 60}, (_, i) => (i).toString().padStart(2, '0'))
  )
  const [meridiemArray, setMeridiemArray] = useState(['오전', '오후'])

  const [pickerValue, setPickerValue] = useState({
    hour: current.split(' ')[0],
    minute: current.split(' ')[1],
    meridiem: current.split(' ')[2]
  })

  useEffect(() => {
    onChange(`${pickerValue.meridiem} ${pickerValue.hour}:${pickerValue.minute}`)
  }, [pickerValue])

  return useMemo(() => {
    return <Picker
      style={{
        width: '100%'
      }}
      height={180}
      wheelMode={'normal'}
      value={pickerValue}
      onChange={setPickerValue}
    >
      <Picker.Column name={'hour'}>
        {
          hourArray.map((value) => {
            return <Picker.Item key={value} value={value}>{value}</Picker.Item>
          })
        }
      </Picker.Column>
      <Picker.Column name={'minute'}>
        {
          minuteArray.map((value) => {
            return <Picker.Item key={value} value={value}>{value}</Picker.Item>
          })
        }
      </Picker.Column>
      <Picker.Column name={'meridiem'}>
        {
          meridiemArray.map((value) => {
            return <Picker.Item key={value} value={value}>{value}</Picker.Item>
          })
        }
      </Picker.Column>
    </Picker>
  }, [pickerValue])
}

const DateTimeWheel = ({
                         type,
                         onChange
                       }: DateTimeWheelProps) => {
  return useMemo(() => {
    return type === 'date' ? <DateWheel onChange={onChange}/> : <TimeWheel onChange={onChange}/>
  }, [type])
}

export default DateTimeWheel;
