import styled from "styled-components";

const AdminButton = styled.div`
  cursor: pointer;
  padding: 6px 16px;
  color: var(--Black-500, #202020);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid var(--Black-200, #B7B7B7);
`

export default AdminButton