import styled from "styled-components";
import {useCallback, useContext, useEffect, useState} from "react";
import useDialog from "../../../hooks/useDialog";
import {enqueueSnackbar, useSnackbar} from "notistack";
import {cafeAddMenu, cafeUpdateMenu} from "../../../api/cafe";
import {StoreContext} from "./index";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../../recoil/app";
import PhotoUploadPopup from "../../../components/common/PhotoUploadPopup";
import {FoodMenuItem} from "../../../api/types";
import {urlToFile} from "../../../utils/common";
import Dim from "../../../components/Dim";
import useScreenOrientation, { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";

const WriteWrapper = styled.div<{
  scrollLock: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
overscroll-behavior: none;
  z-index: 12;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media ${MEDIA_DESKTOP} {
    width: 680px;
    max-width: 680px;
    padding: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 112;
    height: auto;
    border-radius: 20px;
    overflow: hidden;
  }

  ${p => p.scrollLock ? `
      overflow-y: hidden;
  ` : `
  
  `}
  >.pc-header {
    width: 100%;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid var(--Black-100, #F0F0F0);
    @media ${MEDIA_DESKTOP} {
      display: flex;
    }
    >.title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }
    >.close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }
  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
  }

  > .header {
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;
    background: white;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    > .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${p => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
      color: var(--Purple-300, #6436E7);
      text-align: right;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .floating-button-wrapper {
    background: white;
    position: fixed;
    bottom: 0;
    padding: 30px 24px 48px;
    width: 100%;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);

    > .button {
      cursor: pointer;
      width: 100%;
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.165px;
      border-radius: 8px;
      text-align: center;
      padding: 15px 0;
      background: var(--Purple-300, #6436E7);
    }
    > .button:active {
      background: #502bb5;
    }
  }
`

const Banner = styled.div`
  width: 100%;
  padding: 16px;
  color: var(--Black-500, #202020);
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
  background: var(--Black-100, #F0F0F0);
`
const DeleteButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  background: rgba(217, 24, 24, 0.10);
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #D91818;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  @media ${MEDIA_DESKTOP} {
    width: 160px;
  }
`
const WriteButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  background: var(--Purple-300, #6436E7);
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  @media ${MEDIA_DESKTOP} {
    width: 160px;
  }
`
const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  margin: 20px 0;
  gap: 16px;
  background: white;

  @media ${MEDIA_DESKTOP} {
    flex-direction: row;
    align-items: flex-start;
  }
  &:last-child {
    padding-bottom: 48px;
  }
  @media ${MEDIA_DESKTOP} {
    &:last-child {
      border-top: 1px solid var(--Black-100, #F0F0F0); 
      padding: 20px;
      margin: 0;
      justify-content: flex-end;
    } 
  }

  > .title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    @media ${MEDIA_DESKTOP} {
      width: 156px;
      height: 47px;
      align-items: center;
    }

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
  }

  textarea {
    width: 100%;
    resize: none;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid var(--Black-100, #F0F0F0);
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }

  > input {
    width: 100%;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid var(--Black-100, #F0F0F0);
    background: white;
    outline: none;
    color: var(--Black-500, #202020);
    text-align: left;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  > .photo-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    > .item {
      cursor: pointer;
      position: relative;
      width: 80px;
      height: 80px;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #F0F0F0;

      > .plus {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
      }

      > .photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > .remove {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 100%;
        padding: 4px 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 0px 0px 4px 4px;
        background: rgba(0, 0, 0, 0.50);
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`

interface WritePageProps {
  onClose: () => void;
  mode: 'write' | 'edit';
  menu?: FoodMenuItem;
}

const WritePage = ({
                     mode,
                     menu,
                     onClose
                   }: WritePageProps) => {

  const {openDialog} = useDialog();
  const orientation = useScreenOrientation()
  const setLoading = useSetRecoilState(loadingState);

  const [showPhotoUploadPopup, setShowPhotoUploadPopup] = useState(-1)
  const [imageFiles, setImageFiles] = useState<File[]>([]);

  const {
    data,
    update
  } = useContext(StoreContext);

  useEffect(() => {
    if (menu && menu.id) {
      const menuNameInp = (document.getElementById("menuName") as HTMLInputElement)
      const menuInfoInp = (document.getElementById("menuInfo") as HTMLInputElement)
      const priceInp = (document.getElementById("price") as HTMLInputElement)

      menuNameInp.value = menu.itemName;
      menuInfoInp.value = menu.description;
      priceInp.value = menu.price + '';

      if (menu.imageUrl) {
        urlToFile(menu.imageUrl, 'menuImage.png').then((file) => {
          setImageFiles([file]);
        }).catch((e) => {
          console.error('image load err', e);
        });
      }
    }
  }, [menu]);

  const handleWrite = () => {
    openDialog({
      text: '메뉴를 등록하시겠습니까?<br/>',
      confirm: true,
      confirmText: '등록하기',
      type: 'web',
      onConfirm: () => {
        //API 연동
        const menuName = (document.getElementById("menuName") as HTMLInputElement).value
        const menuInfo = (document.getElementById("menuInfo") as HTMLInputElement).value
        const price = (document.getElementById("price") as HTMLInputElement).value

        setLoading(true);
        cafeAddMenu(data.id, imageFiles[0], {
          menuName,
          menuInfo,
          price: Number(price)
        }).then(() => {
          update();
          enqueueSnackbar('메뉴가 등록되었습니다.', {variant: 'success'})
          onClose();
        }).catch((e: any) => {
          enqueueSnackbar(e.message, {variant: 'error'});
        }).finally(() => {
          setLoading(false);
        })
      }
    })
  }
  const handleClickFileUpload = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.multiple = true;
    fileInput.click();
    fileInput.onchange = (e) => {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        const fileList = Array.from(files);
        const newUploadImageList = [...imageFiles, ...fileList];
        setImageFiles(newUploadImageList);
      }
    }
  }
  const handleClickUploadImage = () => {
    if(orientation === 'landscape'){
      handleClickFileUpload()
    }else{
      setShowPhotoUploadPopup(new Date().getTime())
    }
  }
  const handleEdit = () => {
    if (!menu?.id) {
      return;
    }

    openDialog({
      text: '메뉴를 수정하시겠습니까?<br/>',
      confirm: true,
      confirmText: '수정하기',
      type: 'web',
      onConfirm: () => {
        const menuName = (document.getElementById("menuName") as HTMLInputElement).value
        const menuInfo = (document.getElementById("menuInfo") as HTMLInputElement).value
        const price = (document.getElementById("price") as HTMLInputElement).value

        setLoading(true);
        cafeUpdateMenu(data.id, menu.id, imageFiles[0], {
          menuName,
          menuInfo,
          price: Number(price)
        }).then(() => {
          update();
          enqueueSnackbar('메뉴가 수정되었습니다.', {variant: 'success'})
          onClose();
        }).catch((e: any) => {
          enqueueSnackbar(e.message, {variant: 'error'});
        }).finally(() => {
          setLoading(false);
        })
      }
    })
  }

  const handleDelete = () => {
    openDialog({
      text: '해당 메뉴를 삭제하시겠습니까?',
      confirm: true,
      confirmText: '삭제하기',
      type: 'web',
      confirmColor: '#D91818',
      onConfirm: () => {
        update();
        enqueueSnackbar('메뉴가 삭제되었습니다.', {variant: 'success'})
        onClose();
      }
    })
  }

  const handleSelectedPhoto = useCallback((file: File) => {
    setImageFiles(p => [...p, file])
    setShowPhotoUploadPopup(-1);
  }, []);

  return <>
    <PhotoUploadPopup showPhotoPopup={showPhotoUploadPopup} onSelectedPhoto={handleSelectedPhoto}
                      onClose={() => setShowPhotoUploadPopup(-1)}/>
    <WriteWrapper scrollLock={false}>
      <div className='pc-header'>
        <div className='title'>{mode === 'edit' ? '메뉴 수정' : '메뉴 등록'}</div>
        <div className='close' onClick={onClose}>
          <img src='/image-web/Holdem%20Now/Icon/Close.svg'/>
        </div>
      </div>
      <div className="header">
        <div className="close" onClick={onClose}>
          <img src="/image-web/Icon/Back.svg" alt="close"/>
        </div>
        <div className="title">{mode === 'edit' ? '메뉴 수정' : '메뉴 등록'}</div>
      </div>
      <div className='inner'>
        <Box>
          <div className="title-row">
            <div className="title">메뉴 이름</div>
          </div>
          <input type="text" id="menuName" placeholder="메뉴 이름을 입력해주세요"/>
        </Box>
        <Box>
          <div className="title-row">
            <div className="title">메뉴 가격</div>
          </div>
          <input type="number" id="price" placeholder="가격을 입력해주세요"/>
        </Box>
        <Box>
          <div className="title-row">
            <div className="title">메뉴 설명</div>
          </div>
          <textarea className="content" id="menuInfo" placeholder="메뉴 설명을 입력해주세요" onInput={
            (e) => {
              const target = e.target as HTMLTextAreaElement;
              target.style.height = 'auto';
              target.style.height = target.scrollHeight + 'px';
            }
          }/>
        </Box>
        <Box>
          <div className='title-row'>
            <div className='title'>사진 등록</div>
          </div>
          <div className='photo-row'>
            {
              imageFiles.length === 0 && (
                <div className='item' onClick={handleClickUploadImage}>
                  <img className='plus' src='/image-web/Plus.svg' alt='plus'/>
                </div>
              )
            }
            {
              imageFiles.map((file, index) => {
                return <div className='item' key={index}>
                  <img src={URL.createObjectURL(file)} alt='photo' className='photo'/>
                  <div className='remove' onClick={() => {
                    openDialog({
                      text: '사진을 삭제하시겠습니까?',
                      confirm: true,
                      confirmText: '삭제하기',
                      type: 'web',
                      onConfirm: () => {
                        setImageFiles(p => {
                          const temp = [...p];
                          temp.splice(index, 1);
                          return temp;
                        })
                      }
                    })
                  }}>삭제
                  </div>
                </div>
              })
            }
          </div>
        </Box>
        {
          (orientation === 'portrait' && mode === 'edit') && <Box>
            <DeleteButton onClick={handleDelete}>
              삭제하기
            </DeleteButton>
          </Box>
        }
        {
          mode === 'write' && <Box>
            <WriteButton onClick={handleWrite}>
              등록하기
            </WriteButton>
          </Box>
        }
        {
          mode === 'edit' && <Box>
            {
              orientation === 'landscape' && <DeleteButton onClick={handleDelete}>
                삭제하기
              </DeleteButton>
            }
            <WriteButton onClick={handleEdit}>
              수정하기
            </WriteButton>
          </Box>
        }
      </div>
    </WriteWrapper>
    <Dim/>
  </>
}

export default WritePage;
