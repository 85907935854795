import styled from "styled-components";
import { InfoBoxWrapper } from "../../../components/common/InfoBoxWrapper";
import React, { RefObject, useContext, useEffect, useState } from "react";
import { StoreContext } from "./index";
import useDialog from "../../../hooks/useDialog";
import { cafeEventDelete } from "../../../api/cafe";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { EventData } from "./Admin/EditEventInfo";
import AdminButton from "./Admin/AdminButton";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import { getKoreanNumber } from "../../../utils/common";

const DetailInfoBox = styled(InfoBoxWrapper)`
  > .price-row {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .title {
      flex-shrink: 0;
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
    }

    > .price {
      flex-shrink: 0;
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }

  > .info-box {
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.28px;
    }

    > .title-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
      }

      > .button {
        cursor: pointer;
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.26px;
      }
    }

    > .box-wrapper-outer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      background: var(--Black-100, #f0f0f0);
      gap: 4px;
      border-radius: 8px;
      padding: 16px;

      > .box-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 18px;
        border-radius: 8px;
        word-break: break-word;

        > .content {
          flex-grow: 1;
          color: var(--Black-500, #202020);
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 18.2px */
          letter-spacing: -0.26px;
          transition: max-height 0.3s;
        }

        > .button {
          cursor: pointer;
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          object-fit: contain;
          transition: transform 0.3s;
          transform: rotate(180deg);
        }
      }

      > .box-wrapper.fold {
        > .content {
          max-height: 65px;
          overflow: hidden;
        }

        > .button {
          transform: rotate(0);
        }
      }

      > .admin-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }
`;

interface InfoBoxProps {
  boxRef: RefObject<HTMLDivElement>;
  openEventRegister: () => void;
  openEventEdit: (eventId: EventData) => void;
  openDetailEdit: () => void;
}

const DetailInfo = ({
  boxRef,
  openEventRegister,
  openDetailEdit,
  openEventEdit,
}: InfoBoxProps) => {
  const { openDialog } = useDialog();
  const { data, update } = useContext(StoreContext);
  const [isFold, setIsFold] = useState(true);
  const [infoUseRef, setInfoUseRef] = useState<HTMLDivElement | null>(null);
  const [showFoldButton, setShowFoldButton] = useState(false);

  const setLoading = useSetRecoilState(loadingState);

  // 매장 이용 안내 폴드 버튼
  useEffect(() => {
    if (infoUseRef) {
      new ResizeObserver(() => {
        const scrollHeight = infoUseRef.scrollHeight;
        const offsetHeight = infoUseRef.offsetHeight;
        setShowFoldButton(!isFold || scrollHeight > offsetHeight);
      }).observe(infoUseRef);
    }
  }, [infoUseRef, isFold]);

  const handleRegisterEvent = () => {
    //if(!data.myCafe) return;
    if (data.events.length >= 5) {
      openDialog({
        title: "이벤트 등록 제한",
        text:
          "이벤트는 최대 5개 등록 가능합니다.<br/>" +
          "신규 이벤트 등록 시<br/>" +
          "기존 이벤트 삭제 후 진행해주세요.",
        confirmText: "확인",
        type: "web",
        onConfirm: () => {},
      });
      return;
    }
    openEventRegister();
  };
  const handleDeleteEvent = (eventId: number) => {
    openDialog({
      text: "이벤트 정보를<br/> 삭제하시겠습니까?",
      confirmText: "삭제하기",
      confirm: true,
      cancelText: "취소",
      confirmColor: "#D91818",
      type: "web",
      onConfirm: () => {
        setLoading(true);

        cafeEventDelete({ id: eventId })
          .then(() => {
            update();
            enqueueSnackbar("이벤트 정보가 삭제되었습니다.", {
              variant: "success",
            });
          })
          .catch((e) => {
            enqueueSnackbar(e.message, { variant: "error" });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  return (
    <DetailInfoBox ref={boxRef}>
      <div className="title-row">
        <div className="title">상세정보</div>
        {data.myCafe && (
          <AdminButton onClick={openDetailEdit}>수정</AdminButton>
        )}
      </div>
      <div className="price-row">
        <div className="title">프라이즈</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="150"
          height="1"
          viewBox="0 0 150 1"
          fill="none"
        >
          <path
            d="M1 0.5H149"
            stroke="#B7B7B7"
            strokeLinecap="round"
            strokeDasharray="0.1 4"
          />
        </svg>
        <div className="price">
          {data.prize || 50}% ~ {data.prizeMax ? data.prizeMax + "%" : ""}
        </div>
      </div>
      <div className="price-row">
        <div className="title">블라인드 업 타임</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="150"
          height="1"
          viewBox="0 0 150 1"
          fill="none"
        >
          <path
            d="M1 0.5H149"
            stroke="#B7B7B7"
            strokeLinecap="round"
            strokeDasharray="0.1 4"
          />
        </svg>
        <div className="price">
          {!data.blindUp && !data.blindUpMax
            ? "정보없음"
            : data.blindUp === data.blindUpMax
            ? `${data.blindUp}분`
            : `${data.blindUp}분 ~ ${data.blindUpMax}분`}
        </div>
      </div>
      <div className="price-row">
        <div className="title">바이인</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="150"
          height="1"
          viewBox="0 0 150 1"
          fill="none"
        >
          <path
            d="M1 0.5H149"
            stroke="#B7B7B7"
            strokeLinecap="round"
            strokeDasharray="0.1 4"
          />
        </svg>
        <div className="price">
          {getKoreanNumber(data.buyIn ?? 0)}{" "}
          {data.buyInMax && "~ " + getKoreanNumber(data.buyInMax ?? 0)}
        </div>
      </div>
      <div className="info-box">
        <div className="title">매장 이용 안내</div>
        <div className="box-wrapper-outer">
          <div className={"box-wrapper " + (isFold ? "fold" : "")}>
            <div
              ref={setInfoUseRef}
              className="content"
              dangerouslySetInnerHTML={{
                __html: data.informationUse.replace(/\n/g, "<br/>"),
              }}
            />
            {showFoldButton && (
              <img
                src="/image-web/store/Arrow%20down.svg"
                className="button"
                onClick={() => setIsFold(!isFold)}
              />
            )}
          </div>
        </div>
      </div>
      {(data.myCafe || data.events.length > 0) && (
        <div className="info-box">
          <div className="title-row">
            <div className="title">이벤트 정보</div>
            {data.myCafe && (
              <div className="button" onClick={handleRegisterEvent}>
                등록하기
              </div>
            )}
          </div>
          {data.events.map((item, i) => (
            <div className="box-wrapper-outer" key={i}>
              <div className="box-wrapper">
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
              {data.myCafe && (
                <div className="admin-row">
                  <AdminButton
                    onClick={() => {
                      openEventEdit({
                        id: item.id,
                        title: item.title,
                        description: item.description,
                        closeAt: item.closeAt,
                        startAt: item.startAt,
                      });
                    }}
                  >
                    수정
                  </AdminButton>
                  <AdminButton onClick={() => handleDeleteEvent(item.id)}>
                    삭제
                  </AdminButton>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <div className="info-box">
        <div className="title">업체 정보</div>
        <div className="box-wrapper-outer">
          <div className="box-wrapper">
            <div className="content">
              상호: {data.cafeName}
              <br />
              주소: {data.newAddress} {data.detailAddress}
              <br />
              전화번호: {data.vcn}
              <br />
              사업자번호: {data.employerId || "-"}
            </div>
          </div>
        </div>
      </div>
    </DetailInfoBox>
  );
};
export default DetailInfo;
