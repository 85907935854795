import styled from 'styled-components';
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import PhotoDnd from "../Photo/PhotoDnd";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../../../hooks/useScreenOrientation";
import {StoreContext} from "../index";
import {PhotoData} from "../PhotoInfo";
import PhotoUploadPopup from "../../../../components/common/PhotoUploadPopup";
import {useSnackbar} from "notistack";
import {cafeDeleteCafeImage, cafeRegistCafeImage, setCafeImageOrder} from "../../../../api/cafe";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../../../recoil/app";

const Dim = styled.div`
  @media ${MEDIA_DESKTOP} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background: #000;
    z-index: 110;
  }
`
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #000;
  display: flex;
  flex-direction: column;
  @media ${MEDIA_DESKTOP} {
    width: 900px;
    height: 674px;
    border-radius: 20px;
    background: var(--Black-100, #F0F0F0);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .title {
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: block;
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }
  }

  @media ${MEDIA_DESKTOP} {
    width: 100%;
    height: 64px;
    background: #FFF;
    padding: 0 20px;
  }

  .close {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 20px;

    > img {
      width: 24px;
      height: 24px;
      line-height: 1;
    }
  }

  > .toggle-mode {
    display: flex;
    width: 54px;
    height: 36px;
    padding: 9px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Purple-300, #6436E7);
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
    margin-left: 12px;
    margin-right: 20px;
  }
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  padding: 16px;
  
  > .button {
    display: flex;
    height: 40px;
    padding: 11px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--Black-100, #F0F0F0);
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
  }
`;

const Grid = styled.div`
  margin-top: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
`;


interface EditPubPhotoProps {
  onClose: () => void
}

function EditPubPhoto({
                        onClose,
                      }: EditPubPhotoProps) {
  const {
    data,
    update
  } = useContext(StoreContext);
  const orientation = useScreenOrientation();
  const {enqueueSnackbar} = useSnackbar();
  const setLoading = useSetRecoilState(loadingState);

  const [showPhotoUploadPopup, setShowPhotoUploadPopup] = useState(-1)
  const [orderedImages, setOrderedImages] = useState<PhotoData[]>([]);

  useEffect(() => {
    setOrderedImages(data.images.map(({id, imageUrl}, i) => ({
      id: id,
      orderIndex: i,
      photoUrl: imageUrl,
      deletable: true,
    })));
  }, [data]);

  const handleUpload = useCallback((photo: File) => {
    if (!data) {
      return;
    }

    setLoading(true);
    cafeRegistCafeImage(data.id, photo).then(() => {
      enqueueSnackbar("사진이 등록되었습니다.", {variant: 'success'});
      update();
      setShowPhotoUploadPopup(-1);
    }).catch((e: any) => {
      enqueueSnackbar(e.message, {variant: 'error'});
    }).finally(() => {
      setLoading(false);
    })
  }, [data]);

  const handleConfirm = useCallback(() => {
    if (!data) {
      return;
    }

    setLoading(true);
    setCafeImageOrder(data.id, {
      cafeImageOrder: orderedImages.map(x => ({id: x.id}))
    }).then(() => {
      update();
      enqueueSnackbar("변경사항이 저장되었습니다.", {variant: 'success'});
      onClose();
    }).catch((e: any) => {
      enqueueSnackbar(e.message, {variant: 'error'});
    }).finally(() => {
      setLoading(false);
    })
  }, [orderedImages]);

  const handleDelete = useCallback((itemId: number) => {
    if (!data) {
      return;
    }

    setLoading(true);
    cafeDeleteCafeImage({cafeId: data.id, photoId: itemId}).then(() => {
      update();
      enqueueSnackbar("변경사항이 저장되었습니다.", {variant: 'success'});
      onClose();
    }).catch((e: any) => {
      enqueueSnackbar(e.message, {variant: 'error'});
    }).finally(() => {
      setLoading(false);
    })
  }, []);

  return <>
    <Wrapper>
      <Header>
        <div className="close" onClick={onClose}>
          {
            orientation === 'landscape' ?
              <img src="/image-web/Icon/Close_gray.svg" alt="close"/> :
              <img src="/image-web/Icon/Back_white.svg" alt="close"/>
          }
        </div>
        <div className="button toggle-mode" onClick={handleConfirm}>완료</div>
      </Header>
      <Content>
        <div className="button" onClick={() => setShowPhotoUploadPopup(Date.now())}>+ 사진 업로드</div>
        <Grid>
          <PhotoDnd
            images={orderedImages}
            enabled
            onOrderChange={setOrderedImages}
            onClickDelete={handleDelete}
          />
        </Grid>
      </Content>
      <PhotoUploadPopup showPhotoPopup={showPhotoUploadPopup} onSelectedPhoto={handleUpload}
                        onClose={() => setShowPhotoUploadPopup(-1)}/>
    </Wrapper>
    <Dim/>
  </>
}

export default EditPubPhoto
