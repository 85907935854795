import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameMonth,
  isSameDay,
  parse,
  getYear,
  getMonth,
  getDay,
} from "date-fns";
import {
  getDayMajorTournamentList,
  getMajorTournamentList,
  TournamentScheduleItemInterface,
} from "../../../api/tournament";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import TournamentItem from "./TournamentItem";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;
const CalendarWrapper = styled.div`
  width: 100%;
  padding 16px;
  margin: 0 auto;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div<{ flex?: number }>`
  width: 100%;
  flex: ${(props) => props.flex || 1};
  text-align: center;

  color: #000;
  padding: 13px;
  font-family: "Prestige Elite Std";
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

const Cell = styled.div<{ selected?: boolean; disabled?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  > .background {
    width: 37px;
    height: 37px;
    flex-shrink: 0;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    color: ${(props) =>
      props.disabled ? "#7A7A7A" : props.selected ? "#FFFFFF" : "#000000"};

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    font-family: Pretendard;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 145.455% */
    background-color: ${(props) =>
      props.disabled ? "" : props.selected ? "#6436E7" : "#F0F0F0"};
  }
`;

const Number = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RedDot = styled.div`
  width: 3px;
  height: 3px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
`;

const Selectors = styled.div`
  display: flex;
  gap: 12px;

  .select-wrapper {
    position: relative;
    display: inline-block;
    width: fit-content;
  }

  select {
    padding: 8px 22px 8px 12px;
    border-radius: 15px;
    border: 1px solid var(--Black-200, #b7b7b7);
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    appearance: none;
    background-color: white;
    width: 100%;
    cursor: pointer;
  }

  .select-wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    width: 14px;
    height: 14px;
    background-image: url("/image-web/Icon/down-arrow.svg");
    background-size: cover;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

const TournamentWrapper = styled.div`
  margin-top: 10px;
  padding: 0 16px 30px;
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > .title {
      color: #000;

      font-family: Pretendard;
      font-size: 17.181px;
      font-style: normal;
      font-weight: 600;
      line-height: 25.771px;
    }

    > .date {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
      line-height: 25.771px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 3px;
      > .count {
        font-weight: 700;
      }
    }
  }

  > .body {
    margin-top: 16.5px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

const MTournamentSchedule = () => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [listData, setListData] = useState<TournamentScheduleItemInterface[]>(
    []
  );

  const [selectedListData, setSelectedListData] = useState<
    TournamentScheduleItemInterface[]
  >([]);

  const setLoading = useSetRecoilState(loadingState);

  useEffect(() => {
    const selectedYear = getYear(currentMonth);
    const selectedMonth = getMonth(currentMonth) + 1;
    setLoading(true);
    getMajorTournamentList({
      year: selectedYear,
      month: selectedMonth,
    })
      .then((res) => {
        setListData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentMonth]);

  useEffect(() => {
    const selectedYear = getYear(selectedDate);
    const selectedMonth = getMonth(selectedDate) + 1;
    const selectedDay = selectedDate.getDate();
    setLoading(true);
    getDayMajorTournamentList({
      year: selectedYear,
      month: selectedMonth,
      day: selectedDay,
    })
      .then((res) => {
        setSelectedListData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedDate]);

  const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
  const months = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];

  const renderHeader = () => {
    const year = format(currentMonth, "yyyy");
    const month = getMonth(currentMonth);

    return (
      <Header>
        <Column onClick={prevMonth} flex={0}>
          &lt;
        </Column>
        <Column>
          <span>{`${year}년 ${months[month]}`}</span>
        </Column>
        <Column onClick={nextMonth} flex={0}>
          &gt;
        </Column>
      </Header>
    );
  };

  const renderDays = () => {
    return (
      <Row>
        {daysOfWeek.map((day, index) => (
          <Column key={index}>{day}</Column>
        ))}
      </Row>
    );
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];

    let days: JSX.Element[] = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;

        days.push(
          <Cell
            key={day.toString()}
            selected={isSameDay(day, selectedDate!)}
            disabled={!isSameMonth(day, monthStart)}
            onClick={() => onDateClick(cloneDay)}
          >
            <div className="background">
              <Number>{formattedDate}</Number>
              {renderDot(day)}
            </div>
          </Cell>
        );
        day = addDays(day, 1);
      }
      rows.push(<Row key={day.toString()}>{days}</Row>);
      days = [];
    }
    return <div>{rows}</div>;
  };

  const renderDot = (day: Date) => {
    const isImportant = listData.some((item) => {
      const start = new Date(item.startAt);
      const end = new Date(item.endAt);

      const startOfDay = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate()
      );
      const endOfDay = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate()
      );
      const dayToCompare = new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate()
      );

      return dayToCompare >= startOfDay && dayToCompare <= endOfDay;
    });

    return isImportant ? <RedDot /> : null;
  };

  const onDateClick = (day: Date) => {
    setSelectedDate(day);
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const changeYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = parseInt(event.target.value, 10);
    setCurrentMonth(
      parse(`${newYear}-${getMonth(currentMonth) + 1}`, "yyyy-MM", new Date())
    );
  };

  const changeMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newMonth = parseInt(event.target.value, 10);
    setCurrentMonth(
      parse(`${getYear(currentMonth)}-${newMonth}`, "yyyy-MM", new Date())
    );
  };

  const renderSelectors = () => {
    const years = [];
    for (let i = getYear(new Date()) - 10; i <= getYear(new Date()) + 10; i++) {
      years.push(i);
    }

    return (
      <Selectors>
        <div className="select-wrapper">
          <select value={getYear(currentMonth)} onChange={changeYear}>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}년
              </option>
            ))}
          </select>
        </div>
        <div className="select-wrapper">
          <select value={getMonth(currentMonth) + 1} onChange={changeMonth}>
            {months.map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
        </div>
      </Selectors>
    );
  };

  return (
    <Wrapper>
      <CalendarWrapper>
        {renderSelectors()}
        {renderDays()}
        {renderCells()}
      </CalendarWrapper>
      <TournamentWrapper>
        <div className="header">
          <div className="title">토너먼트 정보</div>
          <div className="date">
            {`${getYear(selectedDate)}년 ${
              getMonth(selectedDate) + 1
            }월 ${selectedDate.getDate()}일 `}
            <div className="count">{`${selectedListData.length}개`}</div> 진행중
          </div>
        </div>
        <div className="body">
          {selectedListData.map((item) => {
            return <TournamentItem data={item} />;
          })}
        </div>
      </TournamentWrapper>
    </Wrapper>
  );
};

export default MTournamentSchedule;
