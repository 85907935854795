import styled from "styled-components";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import useGeoLocation from "../../hooks/useGeoLocation";
import {
  getDistanceKm,
  getKoreanNumber,
  getOpStatusText,
  getOpTimeText,
} from "../../utils/common";
import {
  GameTypeLabels,
  getGameTypeString,
  getPubSortLabel,
  PubSortTypeLabels,
  PubTypeLabels,
} from "../../utils/constants";
import { debounce } from "lodash";
import { renderToString } from "react-dom/server";
import StoreMarker from "../../components/StoreMarker";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { geoCoordState } from "../../recoil/geo";
import { Cafe } from "../../api/types";
import usePubSearchFilter from "../../hooks/usePubSearchFilter";
import useQueryParams from "../../hooks/useQueryParams";
import { PubSearchParams, searchCafe } from "../../api/cafe";
import useQuickButtons from "../../hooks/useQuickButtons";
import { loadingState } from "../../recoil/app";
import { navigationTargetState } from "../../recoil/store";
import { likeItems } from "../../api/like";
import useUserInfo from "../../hooks/useUserInfo";

const PCSearchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 100%;

  > .filter-wrapper {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
    max-width: 1200px;
    padding: 12px 0;
    background: #fff;

    > .item-wrapper {
      position: relative;

      > .filter-box {
        position: absolute;
        top: 40px;
        padding: 20px;
        border-radius: 7px;
        border: 1px solid var(--Black-100, #f0f0f0);
        background: #fff;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
        z-index: 100;
        left: 0;
      }

      > .filter-box.list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: max-content;
        gap: 20px;

        > .item {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }

      > .filter-box.grid-3 {
        width: max-content;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        > .item {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;

          > span {
            width: 80px;
            color: var(--Black-400, #444);
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.28px;
          }
        }

        > .item.selected {
          color: #fff;
          border-radius: 4px;
          background: var(--Purple-200, #8359f7);
          border: none;
        }
      }

      > .filter-box.row {
        width: max-content;
        display: flex;
        flex-direction: row;
        gap: 8px;

        > .item {
          cursor: pointer;
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.28px;
          padding: 4px 12px;
          border-radius: 4px;
          border: 1px solid var(--Black-200, #b7b7b7);
          background: #fff;
        }

        > .item.selected {
          color: #fff;
          border-radius: 4px;
          background: var(--Purple-200, #8359f7);
          border: none;
        }
      }

      > .item {
        cursor: pointer;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 9px 12px;
        border-radius: 8px;
        border: 1px solid var(--Black-100, #f0f0f0);
        background: #fff;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      > .item.selected {
        border-radius: 8px;
        border: 1px solid var(--Purple-300, #6436e7);
        background: var(--Purple-100, #f0eaff);
      }
    }
  }

  > .horizontal-line {
    width: 100%;
    height: 1px;
    flex-shrink: 0;
    background: var(--Black-200, #b7b7b7);
  }

  > .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    max-width: unset;
    max-height: calc(100% - 61px);

    > .list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-top: 20px;
      margin-left: 30px;
      height: 100%;
      flex-shrink: 0;
      width: 422px;
      border-right: 1px solid var(--Black-200, #b7b7b7);

      > .result-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 8px 43px 8px 0;

        > .result {
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        > .sort-wrapper {
          position: relative;

          > .sort-list {
            width: 160px;
            padding: 20px;
            position: absolute;
            border-radius: 7px;
            border: 1px solid var(--Black-100, #f0f0f0);
            background: #fff;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;
            z-index: 2;
            top: 42px;
            right: 0;

            > .item {
              cursor: pointer;
              color: var(--Black-400, #444);
              font-family: Pretendard;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: -0.28px;
            }

            > .item.selected {
              color: var(--Purple-300, #6436e7);
            }
          }

          > .button {
            border-radius: 18px;
            border: 1px solid var(--Black-200, #b7b7b7);
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 2px;
            padding: 9px 8px 9px 12px;
            color: var(--Black-500, #202020);
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }

      > .inner {
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        padding-bottom: 20px;
        padding-right: 30px;

        > .content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 8px;
        }
      }

      > .inner::-webkit-scrollbar {
        width: 14px;
        display: unset;
      }

      > .inner::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 7px;
        background: var(--Black-100, #f0f0f0);
        background-clip: padding-box;
      }

      > .inner::-webkit-scrollbar-track {
        background: transparent;
      }
    }

    > #map {
      flex-grow: 1;
      width: 100%;
      height: 100%;
    }
  }
`;
const StoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;

  > .top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    > .thumbnail {
      background: gray;
      border-radius: 8px;
      overflow: hidden;
      width: 110px;
      height: 110px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      > .tag-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        > .tag {
          padding: 4px 6px;
          color: ${(p) => p.theme.color.purple300};
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border-radius: 16.667px;
          border: 1px solid ${(p) => p.theme.color.purple100};
        }
      }

      > .name {
        margin-top: 8px;
        color: ${(p) => p.theme.color.black400};
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.32px;
      }

      > .address {
        margin-top: 6px;
        color: ${(p) => p.theme.color.black300};
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.26px;
      }

      > .info-row {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        > .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 2px;

          > .icon {
            width: 16px;
            height: 16px;
          }

          > .text {
            color: ${(p) => p.theme.color.black400};
            font-family: Pretendard;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.26px;
          }
        }
      }
    }
  }

  > .bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .status-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 2px;

      > span {
        color: ${(p) => p.theme.color.black300};
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
      }

      > span.bold {
        color: ${(p) => p.theme.color.black400};
      }
    }

    > .button-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;

      > img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
`;
const NaverMaps = window.naver.maps;
const PCFavorite = () => {
  const setLoading = useSetRecoilState(loadingState);
  const setNavigationTarget = useSetRecoilState(navigationTargetState);
  const query = useQueryParams();
  const { user } = useUserInfo(true);
  const { updateLocation } = useGeoLocation();
  const { openPhoneCall, toggleLike, shareLink } = useQuickButtons();
  const history = useHistory();
  const location = useLocation<{
    mode?: "query" | "area" | "premium";
  }>();
  const mapRef = useRef<any>();
  const myLocMarkerRef = useRef<any>();
  const params = useParams<{
    id?: string;
  }>();
  const [selectedBuyInPrice, setSelectedBuyInPrice] = useState("전체");
  const [selectedBlindType, setSelectedBlindType] = useState("전체");
  const [selectedPrizeType, setSelectedPrizeType] = useState("전체");
  const [showSortList, setShowSortList] = useState(false);
  const [showFilter, setShowFilter] = useState<
    "pubType" | "gameType" | "buyin" | "blind" | "prize" | null
  >(null);
  //////// MAP START
  const [mapCenter, setMapCenter] = useState<any>();
  const { latitude, longitude, accuracy } = useRecoilValue(geoCoordState);
  const {
    filter,
    setFilter,
    togglePubType,
    toggleGameType,
    setSortType,
    isSelectedGameType,
    isSelectedPubType,
    isSelectedSortType,
  } = usePubSearchFilter();
  const [storeList, setStoreList] = useState<Cafe[]>([]);
  const [markerList, setMarkerList] = useState<any>([]);
  // 네이버 맵 초기화 및 기본 위치 표시
  useEffect(() => {
    let center;

    const qLat = query.get("lat");
    const qLng = query.get("lng");
    if (qLat && qLng) {
      center = new NaverMaps.LatLng(qLat, qLng);
    } else {
      center = new NaverMaps.LatLng(latitude, longitude);
    }

    const mapDiv = document.getElementById("map") as HTMLDivElement;
    const naverMap = new NaverMaps.Map(mapDiv, {
      tileSpare: 3,
      center: center,
    });

    const qZoom = query.get("z");
    if (qZoom) {
      naverMap.setZoom(qZoom);
    }

    new NaverMaps.Event.addListener(
      naverMap,
      "bounds_changed",
      debounce(() => {
        setMapCenter(naverMap.getCenter());
      }, 300)
    );
    //new NaverMaps.Event.addListener(naverMap, "click", () => setSelectedStore(null));
    setMapCenter(center);

    mapRef.current = naverMap;
    // @ts-ignore
    window.map = naverMap;
  }, []);

  // 내 위치 이동
  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    if (myLocMarkerRef.current) {
      myLocMarkerRef.current.setMap(null);
    }

    if (accuracy !== -1) {
      const markerContent = `
        <div class="marker" style="width: 50px; height: 50px; filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2))"> 
         <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
             <circle cx="9" cy="9" r="9" fill="white"/>
             <circle cx="9" cy="9" r="5" fill="#6436E7"/>
         </svg>
        </div>
      `;
      myLocMarkerRef.current = new NaverMaps.Marker({
        position: new NaverMaps.LatLng(latitude, longitude),
        map: mapRef.current,
        icon: {
          content: markerContent,
          size: new NaverMaps.Size(22, 35),
          anchor: new NaverMaps.Point(11, 35),
        },
      });
    }
  }, [latitude, longitude, accuracy]);
  const handleLikeItem = useCallback(
    async (id: number, isList: boolean) => {
      setLoading(true);
      const liked = await toggleLike(id, "CAFE");
      if (typeof liked === "boolean") {
        if (isList) {
          const idx = storeList.findIndex((item) => item.id === id);
          if (idx !== -1) {
            const newList = storeList.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  like: liked,
                };
              } else {
                return item;
              }
            });

            setStoreList(newList);
          }
        } else {
          /*setSelectedStore((v) => {
          if (v) {
            return {
              ...v,
              like: liked
            };
          }
          return v;
        })*/
        }
      }
      setLoading(false);
    },
    [storeList, toggleLike]
  );

  const handleSearch = useCallback(
    async (params: PubSearchParams): Promise<Cafe[]> => {
      const noPubTypes =
        params.pubTypes?.length === 1 && params.pubTypes[0] === "";
      const noSortType = !Boolean(params.sort);

      return await searchCafe({
        ...params,
        lat: params.lat || 35,
        lon: params.lon || 127,
        pubTypes: !noPubTypes ? params.pubTypes : undefined,
        sort: !noSortType ? params.sort : undefined,
      });
    },
    []
  );

  // 마커 그리기
  useEffect(() => {
    //마커를 그리기 전, 기존 마커를 모두 지워준다.
    markerList.forEach((marker: any) => {
      marker.setMap(null);
    });

    const markers = storeList.map((store) => {
      if (!mapRef.current) return;

      //const isSelected = selectedStore?.id === store.id || hoverStoreId === store.id;
      const isSelected = false;
      const marker = new NaverMaps.Marker({
        position: new NaverMaps.LatLng(store.lat, store.lon),
        map: mapRef.current,
        title: store.cafeName,
        icon: {
          content: renderToString(
            <StoreMarker
              selected={isSelected}
              pubType={store.pubType}
              buyInFrom={store.buyIn}
              buyInTo={store.buyInMax}
              name={store.cafeName}
            />
          ),
          anchor: new NaverMaps.Point(0, 30),
        },
        zIndex: isSelected ? 1 : undefined,
      });
      new NaverMaps.Event.addListener(marker, "click", function (e: any) {
        if (!mapRef.current) return;
        //setSelectedStore(store)
        /*mapRef.current.setCenter(new NaverMaps.LatLng(store.lat, store.lon));
        mapRef.current.setZoom(17);*/
      });

      new NaverMaps.Event.addListener(marker, "mouseover", function (e: any) {
        //setHoverStoreId(store.id)
      });

      new NaverMaps.Event.addListener(marker, "mouseout", function (e: any) {
        //setHoverStoreId(-1);
      });

      return marker;
    });
    setMarkerList(markers);
    //}, [storeList, selectedStore, hoverStoreId]);
  }, [storeList]);
  // 지도 이동 시 주변 카페 조회
  useEffect(() => {
    if (!user?.id) {
      return;
    }
    if (!mapCenter || !mapRef.current) {
      return;
    }

    const { y: lat, x: lng } = mapCenter;

    query.set("lat", lat.toFixed(8));
    query.set("lng", lng.toFixed(8));
    query.set("z", mapRef.current.getZoom());

    history.replace({
      search: query.toString(),
      state: location.state,
    });

    const mapBounds = mapRef.current.getBounds();
    const km = Number(
      getDistanceKm(
        mapBounds._min.y,
        mapBounds._min.x,
        mapBounds._max.y,
        mapBounds._max.x
      )
    );

    const searchFilter = { ...filter };
    if (searchFilter.buyInFrom) searchFilter.buyInFrom *= 10000;
    if (searchFilter.buyInTo) searchFilter.buyInTo *= 10000;

    likeItems({
      userId: user.id,
      itemType: "CAFE",
      ...searchFilter,
      lat: latitude,
      lon: longitude,
    })
      .then((list) => {
        setStoreList(list);
      })
      .catch((e) => {});
  }, [mapCenter, filter]);

  // 내 위치 업데이트
  const updateMyLocation = useCallback(() => {
    updateLocation();
    if (mapRef.current) {
      mapRef.current.setCenter(new NaverMaps.LatLng(latitude, longitude));
    }
  }, [latitude, longitude]);

  const updateList = useCallback(() => {
    if (!user?.id) {
      return;
    }

    const searchFilter = { ...filter };
    if (searchFilter.buyInFrom) searchFilter.buyInFrom *= 10000;
    if (searchFilter.buyInTo) searchFilter.buyInTo *= 10000;

    setLoading(true);
    likeItems({
      userId: user.id,
      itemType: "CAFE",
      ...searchFilter,
      lat: latitude,
      lon: longitude,
    })
      .then((list) => {
        setStoreList(list);
      })
      .catch((e: any) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [filter, user, latitude, longitude]);

  useEffect(() => {
    updateList();
  }, [updateList]);

  const handleOutsideClick = useCallback((e: any) => {
    if (e.target.closest(".filter-wrapper")) {
      return;
    }
    setShowFilter(null);
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <PCSearchWrapper>
      <div className="filter-wrapper">
        <div className="item-wrapper">
          <div
            className={"item " + (!isSelectedPubType("") ? "selected" : "")}
            onClick={() => {
              setShowFilter(showFilter === "pubType" ? null : "pubType");
            }}
          >
            {filter.pubTypes && filter.pubTypes.length > 0
              ? filter.pubTypes
                  .map((x) => {
                    return PubTypeLabels.find((v) => v.type === x)?.label || x;
                  })
                  .join(",")
              : "홀덤펍 종류"}{" "}
            <img src="/image-web/Icon/Arrow%20down.svg" />
          </div>
          {showFilter === "pubType" && (
            <div className="filter-box row">
              {PubTypeLabels.map((v, i) => {
                return (
                  <div
                    key={i}
                    className={
                      "item " + (isSelectedPubType(v.type) ? "selected" : "")
                    }
                    onClick={() => {
                      setShowFilter(null);
                      togglePubType(v.type);
                    }}
                  >
                    {v.label}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="item-wrapper">
          <div
            className={"item " + (!isSelectedGameType("") ? "selected" : "")}
            onClick={() => {
              setShowFilter(showFilter === "gameType" ? null : "gameType");
            }}
          >
            {filter.gameTypes && filter.gameTypes.length > 0
              ? filter.gameTypes
                  .map((x) => {
                    return GameTypeLabels.find((v) => v.type === x)?.label || x;
                  })
                  .join(",")
              : "게임종류"}{" "}
            <img src="/image-web/Icon/Arrow%20down.svg" />
          </div>
          {showFilter === "gameType" && (
            <div className="filter-box row">
              {GameTypeLabels.map((v, i) => {
                return (
                  <div
                    key={i}
                    className={
                      "item " + (isSelectedGameType(v.type) ? "selected" : "")
                    }
                    onClick={() => {
                      setShowFilter(null);
                      toggleGameType(v.type);
                    }}
                  >
                    {v.label}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="item-wrapper">
          <div
            className={
              "item " + (selectedBuyInPrice !== "전체" ? "selected" : "")
            }
            onClick={() => {
              setShowFilter(showFilter === "buyin" ? null : "buyin");
            }}
          >
            {selectedBuyInPrice !== "전체" ? selectedBuyInPrice : "바이인"}{" "}
            <img src="/image-web/Icon/Arrow%20down.svg" />
          </div>
          {showFilter === "buyin" && (
            <div className="filter-box grid-3">
              {[
                "전체",
                "2만 이상",
                "5만 이상",
                "10만 이상",
                "20만 이상",
                "50만 이상",
              ].map((v, i) => {
                const isSelected =
                  selectedBuyInPrice === v ||
                  (selectedBuyInPrice === "" && v === "전체");
                return (
                  <div
                    className="item"
                    key={i}
                    onClick={() => {
                      setSelectedBuyInPrice(v);
                      setShowFilter(null);
                      if (v === "전체") {
                        setFilter({
                          ...filter,
                          buyInFrom: 0,
                          buyInTo: 100,
                        });
                      } else if (v === "2만 이상") {
                        setFilter({
                          ...filter,
                          buyInFrom: 2,
                          buyInTo: 100,
                        });
                      } else if (v === "5만 이상") {
                        setFilter({
                          ...filter,
                          buyInFrom: 5,
                          buyInTo: 100,
                        });
                      } else if (v === "10만 이상") {
                        setFilter({
                          ...filter,
                          buyInFrom: 10,
                          buyInTo: 100,
                        });
                      } else if (v === "20만 이상") {
                        setFilter({
                          ...filter,
                          buyInFrom: 20,
                          buyInTo: 100,
                        });
                      } else if (v === "50만 이상") {
                        setFilter({
                          ...filter,
                          buyInFrom: 50,
                          buyInTo: 100,
                        });
                      }
                    }}
                  >
                    {isSelected ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9.5"
                          fill="white"
                          stroke="#8359F7"
                        />
                        <circle cx="10" cy="10" r="6" fill="#8359F7" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9.5"
                          fill="white"
                          stroke="#B7B7B7"
                        />
                      </svg>
                    )}
                    <span>{v}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="item-wrapper">
          <div
            className={
              "item " + (selectedBlindType !== "전체" ? "selected" : "")
            }
            onClick={() => {
              setShowFilter(showFilter === "blind" ? null : "blind");
            }}
          >
            {selectedBlindType !== "전체" ? selectedBlindType : "블라인드 시간"}{" "}
            <img src="/image-web/Icon/Arrow%20down.svg" />
          </div>
          {showFilter === "blind" && (
            <div className="filter-box grid-3">
              {[
                "전체",
                "15분 이상",
                "20분 이상",
                "30분 이상",
                "45분 이상",
                "60분 이상",
              ].map((v, i) => {
                const isSelected =
                  selectedBlindType === v ||
                  (selectedBlindType === "" && v === "전체");
                return (
                  <div
                    className="item"
                    key={i}
                    onClick={() => {
                      setSelectedBlindType(v);
                      setShowFilter(null);
                      if (v === "전체") {
                        setFilter({
                          ...filter,
                          blindUpTo: 100,
                          blindUpFrom: 0,
                        });
                      } else if (v === "15분 이상") {
                        setFilter({
                          ...filter,
                          blindUpTo: 100,
                          blindUpFrom: 15,
                        });
                      } else if (v === "20분 이상") {
                        setFilter({
                          ...filter,
                          blindUpTo: 100,
                          blindUpFrom: 20,
                        });
                      } else if (v === "30분 이상") {
                        setFilter({
                          ...filter,
                          blindUpTo: 100,
                          blindUpFrom: 30,
                        });
                      } else if (v === "45분 이상") {
                        setFilter({
                          ...filter,
                          blindUpTo: 100,
                          blindUpFrom: 45,
                        });
                      } else if (v === "60분 이상") {
                        setFilter({
                          ...filter,
                          blindUpTo: 100,
                          blindUpFrom: 60,
                        });
                      }
                    }}
                  >
                    {isSelected ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9.5"
                          fill="white"
                          stroke="#8359F7"
                        />
                        <circle cx="10" cy="10" r="6" fill="#8359F7" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="9.5"
                          fill="white"
                          stroke="#B7B7B7"
                        />
                      </svg>
                    )}
                    <span>{v}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="item-wrapper">
          <div
            className={
              "item " + (selectedPrizeType !== "전체" ? "selected" : "")
            }
            onClick={() => {
              setShowFilter(showFilter === "prize" ? null : "prize");
            }}
          >
            {selectedPrizeType !== "전체" ? selectedPrizeType : "프라이즈"}{" "}
            <img src="/image-web/Icon/Arrow%20down.svg" />
          </div>
          {showFilter === "prize" && (
            <div className="filter-box grid-3">
              {["전체", "60% 이상", "70% 이상", "80% 이상", "90% 이상"].map(
                (v, i) => {
                  const isSelected =
                    selectedPrizeType === v ||
                    (selectedPrizeType === "" && v === "전체");
                  return (
                    <div
                      className="item"
                      key={i}
                      onClick={() => {
                        setSelectedPrizeType(v);
                        setShowFilter(null);
                        if (v === "전체") {
                          setFilter({
                            ...filter,
                            prizeFrom: 0,
                            prizeTo: 1000,
                          });
                        } else if (v === "60% 이상") {
                          setFilter({
                            ...filter,
                            prizeFrom: 60,
                            prizeTo: 1000,
                          });
                        } else if (v === "70% 이상") {
                          setFilter({
                            ...filter,
                            prizeFrom: 70,
                            prizeTo: 1000,
                          });
                        } else if (v === "80% 이상") {
                          setFilter({
                            ...filter,
                            prizeFrom: 80,
                            prizeTo: 1000,
                          });
                        } else if (v === "90% 이상") {
                          setFilter({
                            ...filter,
                            prizeFrom: 90,
                            prizeTo: 1000,
                          });
                        }
                      }}
                    >
                      {isSelected ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="9.5"
                            fill="white"
                            stroke="#8359F7"
                          />
                          <circle cx="10" cy="10" r="6" fill="#8359F7" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="9.5"
                            fill="white"
                            stroke="#B7B7B7"
                          />
                        </svg>
                      )}
                      <span>{v}</span>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
      </div>
      <div className="horizontal-line" />
      <div className="content">
        <div className="list">
          <div className="result-wrapper">
            <div className="result">{storeList.length}개의 검색결과</div>
            <div className="sort-wrapper">
              {showSortList && (
                <div className="sort-list">
                  {PubSortTypeLabels.map((x) => {
                    return (
                      <div
                        key={x.label}
                        className={
                          "item " +
                          (isSelectedSortType(x.type) ? "selected" : "")
                        }
                        onClick={() => setSortType(x.type)}
                      >
                        {x.label}
                      </div>
                    );
                  })}
                </div>
              )}
              <div
                className="button"
                onClick={() => {
                  setShowSortList(!showSortList);
                }}
              >
                {getPubSortLabel(filter.sort)}
                <img src="/image-web/Icon/Arrow%20down.svg" />
              </div>
            </div>
          </div>
          <div className="inner">
            <div className="content">
              {storeList.map((item, index) => {
                return (
                  <StoreInfoWrapper
                    key={index}
                    onClick={() => history.push(`/store/${item.id}`)}
                  >
                    <div className="top">
                      <div className="thumbnail">
                        {item.images[0] && (
                          <img src={item.images[0].imageUrl} />
                        )}
                      </div>
                      <div className="info">
                        <div className="tag-row">
                          <span className="tag">
                            {getGameTypeString(item.gameTypes)}
                          </span>
                          {item.buyIn && (
                            <span className="tag">
                              바이인 : {getKoreanNumber(item.buyIn)} ~{" "}
                              {item.buyInMax
                                ? getKoreanNumber(item.buyInMax)
                                : ""}
                            </span>
                          )}
                        </div>
                        <div className="name">{item.cafeName}</div>
                        <div className="address">
                          {item.newAddress} {item.detailAddress}
                        </div>
                        <div className="info-row">
                          <div className="item">
                            <img
                              className="icon"
                              src="/image-web/Icon/Heart/small.svg"
                            />
                            <span className="text">{item.likeCount}</span>
                          </div>
                          <div className="item">
                            <img
                              className="icon"
                              src="/image-web/Icon/Map%20pin/small.svg"
                            />
                            <span className="text">
                              {getDistanceKm(
                                latitude,
                                longitude,
                                item.lat,
                                item.lon
                              )}{" "}
                              km
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom">
                      <div className="status-row">
                        {item.operatingDays && item.operatingStartTime && (
                          <span className="bold">
                            {getOpStatusText(
                              item.operatingDays,
                              item.operatingStartTime,
                              item.operatingEndTime
                            )}
                          </span>
                        )}
                        <span>
                          {getOpTimeText(
                            item.operatingDays,
                            item.operatingStartTime,
                            item.operatingEndTime
                          )}
                        </span>
                      </div>
                      <div
                        className="button-row"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <img
                          src="/image-web/Icon/Phone.svg"
                          onClick={() =>
                            openPhoneCall(
                              item.cafeName,
                              item.vcn ? item.vcn : item.phoneNumber
                            )
                          }
                        />
                        <img
                          src={`/image-web/Icon/${
                            item.like ? "Heart_on" : "Heart"
                          }.svg`}
                          onClick={() => handleLikeItem(item.id, true)}
                        />
                        <img
                          src="/image-web/Icon/Navigation.svg"
                          onClick={() => setNavigationTarget(item)}
                        />
                        <img
                          src="/image-web/Icon/Share.svg"
                          onClick={() => shareLink()}
                        />
                      </div>
                    </div>
                  </StoreInfoWrapper>
                );
              })}
            </div>
          </div>
        </div>
        <div id="map" />
      </div>
    </PCSearchWrapper>
  );
};

export default PCFavorite;
