import styled from "styled-components";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import React, { useState } from "react";
import useUserInfo from "../../../hooks/useUserInfo";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { sellerAddMessage } from "../../../api/seller";
import { NowAddMessage } from "../../../api/cafe";
import useDialog from "../../../hooks/useDialog";
import useGeoLocation from "../../../hooks/useGeoLocation";
import { Cafe } from "../../../api/types";
import { LOCAL_STORAGE_ACCESS_KEY } from "../../../utils/network";
import { useHistory } from "react-router-dom";

const PCWriteBoxWrapper = styled.div`
  width: 100%;
  padding: 24px 20px 20px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  @media ${MEDIA_DESKTOP} {
    display: flex;
    border-radius: 12px;
    border: 1px solid var(--Black-100, #f0f0f0);
    background: #fff;
  }

  > .title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    > .count {
      color: var(--Black-200, #b7b7b7);
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 18.2px */
    }
  }

  > .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    > textarea {
      min-height: 94px;
      width: 100%;
      padding: 10px 12px;
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      border-radius: 4px;
      border: 1px solid var(--Black-100, #f0f0f0);
      resize: none;

      &::placeholder {
        color: var(--Black-200, #b7b7b7);
      }

      outline: none;
    }

    > .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .photo-upload {
        cursor: pointer;
      }

      > .write-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;

        > .desc {
          color: var(--Black-300, #808080);
          text-align: right;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 16.8px */
          letter-spacing: -0.24px;
        }

        > .button {
          cursor: pointer;
          transition: all 0.3s;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 12px 20px;
          height: 40px;
          border-radius: 4px;
          opacity: 0.5;
          background: var(--Purple-300, #6436e7);
          color: #fff;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        > .button:active {
          background: #502bb5;
        }

        > .button.active {
          opacity: 1;
        }
      }
    }
  }
`;
const ImageGrid = styled.div<{ length: number }>`
  width: 100%;

  > div {
    width: 100%;
    position: relative;

    > img {
      object-fit: cover;
      aspect-ratio: 1;
      border-radius: 8px;
      background: #e5e5e5;
      width: 100%;
    }
    > .delete-button {
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 8px;
      border-radius: 15px;
      background: rgba(32, 32, 32, 0.5);
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  ${(p) =>
    p.length > 1
      ? `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  `
      : ``}
  ${(p) =>
    p.length === 3
      ? `
    > img:nth-child(1) {
      grid-column: 1 / -1;
      aspect-ratio: 2 / 1;
    }
  `
      : ``}
`;
interface PCWriteBoxProps {
  onUpdate: () => void;
}
const PCWriteBox = ({ onUpdate }: PCWriteBoxProps) => {
  const { openDialog } = useDialog();
  const { user } = useUserInfo();
  const setLoading = useSetRecoilState(loadingState);
  const history = useHistory();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY);

  const [uploadImageList, setUploadImageList] = useState<File[]>([]);
  const [cafe, setCafe] = useState<Cafe | null>(null);
  const { latitude, longitude } = useGeoLocation();

  const handleClickFileUpload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.multiple = true;
    fileInput.click();
    fileInput.onchange = async (e) => {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        const fileList = Array.from(files);
        try {
          const resizedFiles = await Promise.all(fileList.map(resizeImage));
          const newUploadImageList = [...uploadImageList, ...resizedFiles];
          setUploadImageList(newUploadImageList);
        } catch (error) {
          console.error("Error resizing images:", error);
        }
      }
    };
  };

  const resizeImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();
      reader.onload = (e) => {
        img.src = e.target?.result as string;
      };
      reader.onerror = (error) => reject(error);

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          return reject(new Error("Canvas context not available"));
        }

        const maxWidth = 1280;
        let { width, height } = img;

        if (width > maxWidth) {
          height = (height * maxWidth) / width;
          width = maxWidth;
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            resolve(resizedFile);
          } else {
            reject(new Error("Image resizing failed"));
          }
        }, file.type);
      };

      reader.readAsDataURL(file);
    });
  };
  const handleClickWriteButton = () => {
    if (!accessToken) {
      enqueueSnackbar("로그인이 필요한 서비스입니다.", { variant: "error" });
      history.push("/login");
      return;
    }
    setLoading(true);

    const content = document.getElementById(
      "write-area"
    ) as HTMLTextAreaElement;
    let promise;
    if (cafe?.id) {
      promise = sellerAddMessage(
        {
          nowMessage: content.value,
          cafeId: cafe.id,
        },
        uploadImageList
      );
    } else {
      promise = NowAddMessage(
        {
          nowMessage: content.value,
          latitude: latitude,
          longitude: longitude,
        },
        uploadImageList
      );
    }

    promise
      .then()
      .then((res) => {
        enqueueSnackbar("홀덤 NOW가 등록되었습니다.", {
          variant: "success",
        });
        //사진 및 텍스트 모두 초기화
        setUploadImageList([]);
        content.value = "";
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
        onUpdate();
      });
  };
  return (
    <PCWriteBoxWrapper>
      <div className="title-row">
        <div className="title">홀덤 NOW 글쓰기</div>
        <div className="count" id="text-count">
          0/1024
        </div>
      </div>
      <div className="info">
        <textarea
          id="write-area"
          placeholder="내용을 입력해주세요."
          onInput={(e) => {
            const target = e.target as HTMLTextAreaElement;
            target.style.height = "1px";
            target.style.height = target.scrollHeight + "px";
          }}
          onChange={(e) => {
            const target = e.target as HTMLTextAreaElement;
            const count = target.value.length;
            const textCount = document.getElementById("text-count");
            if (textCount) {
              textCount.innerText = `${count}/1024`;
              if (count > 1024) {
                target.value = target.value.slice(0, 1024);
                textCount.innerText = `1024/1024`;
              }
            }
            if (count > 0) {
              document.getElementById("write-button")?.classList.add("active");
            } else {
              document
                .getElementById("write-button")
                ?.classList.remove("active");
            }
          }}
        />
        <ImageGrid length={uploadImageList.length}>
          {uploadImageList.map((file, index) => {
            return (
              <div key={index}>
                <img
                  className="delete-button"
                  src="/image-web/Holdem Now/Delete.svg"
                  alt="delete"
                  onClick={() => {
                    const newUploadImageList = [...uploadImageList];
                    newUploadImageList.splice(index, 1);
                    setUploadImageList(newUploadImageList);
                  }}
                />
                <img src={URL.createObjectURL(file)} alt="upload" />
              </div>
            );
          })}
        </ImageGrid>
        <div className="row">
          <div className="photo-upload" onClick={handleClickFileUpload}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 15L17.914 11.914C17.5389 11.5391 17.0303 11.3284 16.5 11.3284C15.9697 11.3284 15.4611 11.5391 15.086 11.914L6 21M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z"
                stroke="#808080"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="write-wrapper">
            <div
              className="button"
              id="write-button"
              onClick={handleClickWriteButton}
            >
              등록하기
            </div>
          </div>
        </div>
      </div>
    </PCWriteBoxWrapper>
  );
};
export default PCWriteBox;
