import styled from "styled-components";
import {enqueueSnackbar, useSnackbar} from "notistack";
import useDialog from "../../../../hooks/useDialog";
import {useCallback, useContext, useEffect, useState} from "react";
import DateTimeWheel from "../../../../components/common/DateTimeWheel";
import moment from "moment";
import {cafeEventRegister, cafeEventUpdate} from "../../../../api/cafe";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../../../recoil/app";
import {StoreContext} from "../../StoreDetail";
import Dim from "../../../../components/Dim";

const EventInfoWrapper = styled.div<{
  scrollLock: boolean
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  width: 680px;
  height: 534px;
  z-index: 111;
  background: white;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${p => p.scrollLock ? `
      overflow-y: hidden;
  ` : `
  
  `}
  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    padding: 20px;
  }

  > .header {
    border-bottom: 1px solid var(--Black-100, #F0F0F0);
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    > .button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
      color: var(--Purple-300, #6436E7);
      text-align: right;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .floating-button-wrapper {
    border-top: 1px solid var(--Black-100, #F0F0F0);
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    > .button {
      cursor: pointer;
      width: 160px;
      height: 48px;
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.165px;
      border-radius: 8px;
      text-align: center;
      padding: 15px 0;
      background: var(--Purple-300, #6436E7);
    }
    > .button:active {
      background: #502bb5;
    }
  }
 `

const DeleteButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  background: rgba(217, 24, 24, 0.10);
  width: 160px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #D91818;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
`
const WriteButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  background: var(--Purple-300, #6436E7);
  width: 160px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
`
const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 16px;
  background: white;
  margin-bottom: 12px;

  > .title-row {
    width: 156px;
    height: 47px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.28px;
    }

    > .count {
      color: var(--Black-200, #B7B7B7);
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 18.2px */
    }
  }
  >.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    > .time-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 24px;

      > .title {
        color: var(--Black-500, #202020);
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      > .time-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;

        > .item {
          height: 36px;
          width: 94px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background: var(--Black-100, #F0F0F0);
          color: var(--Black-500, #202020);
          text-align: center;
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        > .item.selected {
          color: var(--Purple-300, #6436E7);
          text-align: center;
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
  

  > input {
    width: 100%;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid var(--Black-100, #F0F0F0);
    background: white;
    outline: none;
    color: var(--Black-500, #202020);
    text-align: left;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  > textarea {
    min-height: 100px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--Black-100, #F0F0F0);
    padding: 10px 12px;
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    outline: none;

    &::placeholder {
      color: var(--Black-200, #B7B7B7);
    }
  }

  > .photo-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    > .item {
      cursor: pointer;
      position: relative;
      width: 80px;
      height: 80px;
      border-radius: 4px;
      border: 1px solid #F0F0F0;

      > .plus {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
      }

      > .remove {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 100%;
        padding: 4px 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 0px 0px 4px 4px;
        background: rgba(0, 0, 0, 0.50);
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`

export interface EventData {
  title: string;
  description: string;
  startAt: string;
  closeAt: string;
  id: number;
}

interface WritePageProps {
  onClose: () => void;
  mode: 'write' | 'edit';
  item?: EventData;
}

const EditEventInfo = ({
                         mode,
                         onClose,
                         item
                       }: WritePageProps) => {

  const {data, update} = useContext(StoreContext);
  const {openDialog} = useDialog();
  const setLoading = useSetRecoilState(loadingState);
  const [eventDateTime, setEventDateTime] = useState<{
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
  }>({
    startDate: moment().format('YYYY.MM.DD'),
    startTime: moment().format('A hh:mm'),
    endDate: moment().format('YYYY.MM.DD'),
    endTime: moment().format('A hh:mm')
  })
  const [showDateTimePicker, setShowDateTimePicker] = useState<{
    type1: 'start' | 'end',
    type2: 'date' | 'time'
  } | null>(null)
  const [desc, setDesc] = useState('');

  useEffect(() => {
    if (item) {
      setDesc(item.description);

      setEventDateTime({
        startDate: moment(item.startAt).format('YYYY.MM.DD'),
        startTime: moment(item.startAt).format('A hh:mm'),
        endDate: moment(item.closeAt).format('YYYY.MM.DD'),
        endTime: moment(item.closeAt).format('A hh:mm')
      });
    }
  }, [item]);

  const handleWrite = () => {
    openDialog({
      text: '이벤트를<br/>등록하시겠습니까?',
      confirm: true,
      confirmText: '등록하기',
      type: 'web',
      onConfirm: () => {
        handleRegisterEvent();
      }
    })
  }
  const handleEdit = () => {
    openDialog({
      text: '해당 이벤트를<br/>수정하시겠습니까?',
      confirm: true,
      confirmText: '수정하기',
      type: 'web',
      onConfirm: () => {
        handleEditEvent();
      }
    })
  }

  const handleRegisterEvent = useCallback(() => {
    if (!data.id) {
      return;
    }

    setLoading(true);

    cafeEventRegister({
      title: desc, //TODO: title이 필요한지?
      description: desc,
      startAt: moment(eventDateTime.startDate + ' ' + eventDateTime.startTime, "YYYY.MM.DD a HH:mm").format('YYYY-MM-DD HH:mm:ss'),
      closeAt: moment(eventDateTime.endDate + ' ' + eventDateTime.endTime, "YYYY.MM.DD a HH:mm").format('YYYY-MM-DD HH:mm:ss'),
      cafeId: data.id
    }).then(res => {
      update();
      enqueueSnackbar('이벤트가 등록되었습니다.', {variant: 'success'})
      onClose();
    }).catch((e) => {
      enqueueSnackbar(e.response.data.message ?? e.message, {variant: 'error'});
    }).finally(() => {
      setLoading(false);
    });
  }, [desc, data, eventDateTime])

  const handleEditEvent = useCallback(() => {
    if (data?.id && item?.id) {
      setLoading(true);

      cafeEventUpdate({
        id: item.id,
        title: desc,
        description: desc,
        startAt: moment(eventDateTime.startDate + ' ' + eventDateTime.startTime, "YYYY.MM.DD a HH:mm").format('YYYY-MM-DD HH:mm:ss'),
        closeAt: moment(eventDateTime.endDate + ' ' + eventDateTime.endTime, "YYYY.MM.DD a HH:mm").format('YYYY-MM-DD HH:mm:ss'),
        cafeId: data.id
      }).then(res => {
        update();
        enqueueSnackbar('이벤트가 수정되었습니다.', {variant: 'success'})
        onClose();
      }).catch((e) => {
        enqueueSnackbar(e.message, {variant: 'error'});
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [desc, data, item, eventDateTime])

  return <>
    <EventInfoWrapper scrollLock={false}>
      <div className="header">
        <div className="title">{mode === 'edit' ? '이벤트 수정' : '이벤트 등록'}</div>
        <div className="close" onClick={onClose}>
          <img src="/image-web/Icon/Close_gray.svg" alt="close"/>
        </div>
      </div>
      <div className='inner'>
        <Box>
          <div className="title-row">
            <div className="title">이벤트 시간</div>
          </div>
          <div className='wrapper'>
            <div className='time-wrapper'>
              <div className='title'>시작</div>
              <div className='time-row'>
                <div className='item' onClick={() => {
                  if (showDateTimePicker !== null && showDateTimePicker.type1 === 'start' && showDateTimePicker.type2 === 'date') {
                    setShowDateTimePicker(null)
                  } else {
                    setShowDateTimePicker({
                      type1: 'start',
                      type2: 'date'
                    })
                  }
                }}>{eventDateTime.startDate}</div>
                <div className='item' onClick={() => {
                  if (showDateTimePicker !== null && showDateTimePicker.type1 === 'start' && showDateTimePicker.type2 === 'time') {
                    setShowDateTimePicker(null)
                  } else {
                    setShowDateTimePicker({
                      type1: 'start',
                      type2: 'time'
                    })
                  }
                }}>{eventDateTime.startTime}</div>
              </div>
            </div>
            {
              (showDateTimePicker !== null && showDateTimePicker.type1 === 'start' && showDateTimePicker.type2 === 'date') &&
              <DateTimeWheel type={'date'} onChange={(value) => {
                setEventDateTime({
                  ...eventDateTime,
                  startDate: value
                })
              }}/>
            }
            {
              (showDateTimePicker !== null && showDateTimePicker.type1 === 'start' && showDateTimePicker.type2 === 'time') &&
              <DateTimeWheel type={'time'} onChange={(value) => {
                setEventDateTime({
                  ...eventDateTime,
                  startTime: value
                })
              }}/>
            }
            <div className='time-wrapper'>
              <div className='title'>종료</div>
              <div className='time-row'>
                <div className='item' onClick={() => {
                  if (showDateTimePicker !== null && showDateTimePicker.type1 === 'end' && showDateTimePicker.type2 === 'date') {
                    setShowDateTimePicker(null)
                  } else {
                    setShowDateTimePicker({
                      type1: 'end',
                      type2: 'date'
                    })
                  }
                }}>{eventDateTime.endDate}</div>
                <div className='item' onClick={() => {
                  if (showDateTimePicker !== null && showDateTimePicker.type1 === 'end' && showDateTimePicker.type2 === 'time') {
                    setShowDateTimePicker(null)
                  } else {
                    setShowDateTimePicker({
                      type1: 'end',
                      type2: 'time'
                    })
                  }
                }}>{eventDateTime.endTime}</div>
              </div>
            </div>
            {
              (showDateTimePicker !== null && showDateTimePicker.type1 === 'end' && showDateTimePicker.type2 === 'date') &&
              <DateTimeWheel type={'date'} onChange={(value) => {
                setEventDateTime({
                  ...eventDateTime,
                  endDate: value
                })
              }}/>
            }
            {
              (showDateTimePicker !== null && showDateTimePicker.type1 === 'end' && showDateTimePicker.type2 === 'time') &&
              <DateTimeWheel type={'time'} onChange={(value) => {
                setEventDateTime({
                  ...eventDateTime,
                  endTime: value
                })
              }}/>
            }
          </div>

        </Box>
        <Box>
          <div className="title-row">
            <div className="title">이벤트 설명</div>
          </div>
          <textarea name="description" id="description" value={desc} placeholder="이벤트 설명을 입력해주세요."
                    onChange={(e) => setDesc(e.target.value)}
                    onInput={
                      (e) => {
                        const target = e.target as HTMLTextAreaElement;
                        target.style.height = '1px';
                        target.style.height = target.scrollHeight + 'px';
                      }
                    }/>
        </Box>

      </div>
      <div className="floating-button-wrapper">
        {
          mode === 'edit' && <DeleteButton onClick={handleEdit}>
            수정하기
          </DeleteButton>
        }
        {
          mode === 'write' && <WriteButton onClick={handleWrite}>
            등록하기
          </WriteButton>
        }
      </div>
    </EventInfoWrapper>
    <Dim/>
  </>
}

export default EditEventInfo;
