import styled from "styled-components";
import {getOpStatusText, getOpTimeText} from "../../../utils/common";
import React, {useContext, useState} from "react";
import {StoreContext} from "../StoreDetail";
import {getFacilityContent} from "../../../utils/constants";
import AdminButton from "../StoreDetail/Admin/AdminButton";
import EditStoreInfo from "./Admin/EditStoreInfo";

const StoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  >.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    >.info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;
      >.row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        > img {
          width: 16px;
          height: 16px;
        }
        > span {
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.32px;
        }
        >.bold {
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.32px;
        }
      }
    }
  }
  
  >.store-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    >.title {
      color: var(--Purple-400, #402295);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
    }
    >.row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 14px;
      >.item {
        width: 72px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        >img {
          width: 32px;
          height: 32px;
        }
        >span {
          color: var(--Black-400, #444);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
`
const StoreInfo = () => {
  const {data} = useContext(StoreContext);
  const [showEditStoreInfo,setShowEditStoreInfo] = useState(false);
  return <>
    {
      showEditStoreInfo && <EditStoreInfo cafeId={data.id} onClose={()=>setShowEditStoreInfo(false)}/>
    }
    <StoreInfoWrapper>
    <div className='wrapper'>
      <div className='info-wrapper'>
        <div className='row'>
          <img src='/image-web/store/Time/small.png'/>
          {
            data.operatingDays && data.operatingStartTime && <>
            <span className='bold'>
              {getOpStatusText(data.operatingDays, data.operatingStartTime, data.operatingEndTime)}
            </span>
              <span>·</span>
            </>
          }
          <span>
          {getOpTimeText(data.operatingDays, data.operatingStartTime, data.operatingEndTime)}
        </span>
        </div>
        <div className='row'>
          <img src='/image-web/store/Phone/small.png'/>
          <span>{data.vcn !== null ? data.vcn : "-"}</span>
        </div>
      </div>
      {
        data.myCafe && <AdminButton onClick={()=>setShowEditStoreInfo(true)}>수정</AdminButton>
      }
    </div>
    <div className='store-info'>
      <div className='title'>편의시설</div>
      <div className='row'>
        {
          data.facilities.map((item, index) => {
            const facility = getFacilityContent(item.type);
            if (!facility) {
              return null;
            }
            return <div className='item' key={index}>
              <img src={`/image-web/store/Detail/Property%201=${facility.img}.svg`}/>
              <span>{facility.title}</span>
            </div>
          })
        }
      </div>
    </div>
  </StoreInfoWrapper>
    </>
}

export default StoreInfo;
