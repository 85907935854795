import instance from "../utils/network";

interface NetworkResponse {
  code: number;
  message: string;
}
export function recommendationPost(
  messageId: number
): Promise<NetworkResponse> {
  return instance.post(`/user-message/recommendation?messageId=${messageId}`);
}

export function cancelrecommendationPost(
  messageId: number
): Promise<NetworkResponse> {
  return instance.post(
    `/user-message/cancelRecommendation?messageId=${messageId}`
  );
}
