import styled from "styled-components";
import { enqueueSnackbar, useSnackbar } from "notistack";
import useScreenOrientation from "../../../hooks/useScreenOrientation";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { certification } from "../../../utils/iamport";
import { debounce } from "lodash";
import { authPassword } from "../../../api/auth";
import useDialog from "../../../hooks/useDialog";

const PCFindPWWrapper = styled.div`
  width: 680px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 80px 0 100px;
  gap: 30px;

  > .title {
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  > .horizontal-line {
    width: 100%;
    height: 2px;
    background: #444;
  }

  > .horizontal-line-light {
    width: 100%;
    height: 1px;
    background: #b7b7b7;
  }

  > .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
  > .content {
    width: 312px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.28px;
    }
    > .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 312px;
      height: 40px;
      color: var(--Black-500, #202020);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 4px;
      background: var(--Black-100, #f0f0f0);
    }
    > .sub-title {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      > .retry {
        cursor: pointer;
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }

  > .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    > .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 312px;
      height: 40px;
      color: var(--Black-500, #202020);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    > .input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      > .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 156px;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
      }
      > input {
        width: 312px;
        padding: 15px 12px;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
        color: ${(p) => p.theme.color.black500};
        border-radius: 8px;
        border: 1px solid var(--Black-100, #f0f0f0);
        outline: none;

        &::placeholder {
          color: var(--Black-300, #808080);
        }
      }
    }
  }

  > .guide-line {
    width: 680px;
    height: 1px;
    background-color: var(--Black-200, #b7b7b7);
  }

  > .button {
    cursor: pointer;
    width: 312px;
    height: 56px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--Purple-300, #6436e7);
    color: #f0f0f0;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  > .button:active {
    background: #502bb5;
  }
  > .disable {
    background: #d3d3d3;
    color: #a0a0a0;
  }
`;
const SignUpButton = styled.div`
  cursor: pointer;
  width: 312px;
  height: 56px;
  color: #f0f0f0;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background: var(--Purple-300, #6436e7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FindPasswordButton = styled(SignUpButton)`
  color: var(--Purple-300, #6436e7);
  border: 1px solid var(--Purple-300, #6436e7);
  background: white;
`;

const InputWrapper = styled.div<{ success: boolean; show?: boolean }>`
  > input {
    width: 312px;
    padding: 15px 12px;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.28px;
    color: ${(p) => p.theme.color.black500};
    border-radius: 8px;
    border: 1px solid var(--Black-100, #f0f0f0);
    outline: none;

    &::placeholder {
      color: var(--Black-300, #808080);
    }
  }
  > .label {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    color: ${(p) => p.theme.color.purple200};
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    > svg {
      > circle {
        transition: all 0.3s;
        fill: ${(p) =>
          p.success ? p.theme.color.purple200 : p.theme.color.black200};
      }
    }
  }
  > input {
    width: 312px;
    padding: 15px 12px;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.28px;
    color: ${(p) => p.theme.color.black500};
    border-radius: 8px;
    border: 1px solid var(--Black-100, #f0f0f0);
    outline: none;

    &::placeholder {
      color: var(--Black-300, #808080);
    }
  }
`;

const PCFindId = () => {
  const [id, setId] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const orientation = useScreenOrientation();
  const setLoading = useSetRecoilState(loadingState);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const history = useHistory();
  const { openDialog } = useDialog();
  useEffect(() => {
    if (orientation === "portrait") {
      history.push("/");
    }
  }, [orientation]);
  const handleClickCertification = () => {
    certification(true)
      .then((res) => {
        if (res.textId) {
          setId(res.textId);
        } else {
          enqueueSnackbar("회원 정보를 찾을 수 없습니다", { variant: "error" });
        }
      })
      .catch((e) => {
        enqueueSnackbar("본인인증에 실패했습니다: " + e.message, {
          variant: "error",
        });
      });
  };

  const handleSetNewPassword = () => {
    if (id !== "" && rePassword !== "") {
      setLoading(true);
      authPassword({ textId: id, newPassword: password })
        .then(() => {
          setLoading(false);
          openDialog({
            title: "비밀번호 변경",
            type: "web",
            text: "비밀번호가 변경되었습니다.",
            onConfirm: () => {
              window.location.href = "/login";
            },
          });
        })
        .catch((e: any) => {
          setLoading(false);
          enqueueSnackbar("비밀번호를 변경할 수 없습니다: " + e.message, {
            variant: "error",
          });
        });
    }
  };
  return (
    <PCFindPWWrapper>
      <div className="title">비밀번호 재설정</div>
      <div className="horizontal-line" />
      {id === "" ? (
        <SignUpButton onClick={() => handleClickCertification()}>
          본인인증 하기
        </SignUpButton>
      ) : (
        <>
          <div className="content-wrapper">
            <div className="description">신규 비밀번호를 설정합니다.</div>
            <div className="input-wrapper">
              <div className="title">비밀번호</div>
              <InputWrapper success={password !== ""} show={true}>
                <input
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length < 8 || value.length > 15) {
                      debounce(() => setPassword(""), 100)();
                      return;
                    }
                    //영문, 숫자, 특수문자 조합 8~15자리
                    if (
                      !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+}{":;'?/>.<,])(?=.{8,15})/.test(
                        value
                      )
                    ) {
                      debounce(() => setPassword(""), 100)();
                      return;
                    }
                    debounce(() => setPassword(value), 100)();
                  }}
                />
                <div className="label">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <circle cx="6" cy="6" r="6" fill="#B7B7B7" />
                    <path
                      d="M4 6L5.77778 7.5L8 4.5"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>영문, 숫자, 특수문자 조합 8~15자리</span>
                </div>
              </InputWrapper>
            </div>
            <div className="input-wrapper">
              <div className="title">비밀번호확인</div>
              <input
                type="password"
                placeholder="비밀번호를 한번 더 입력해주세요"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value !== password) {
                    debounce(() => setRePassword(""), 100)();
                    return;
                  }
                  debounce(() => setRePassword(value), 100)();
                }}
              />
            </div>
          </div>
          <div className="guide-line" />
          <div
            className={rePassword !== "" ? "button" : "button disable"}
            onClick={handleSetNewPassword}
          >
            비밀번호 재설정
          </div>
        </>
      )}
    </PCFindPWWrapper>
  );
};

export default PCFindId;
