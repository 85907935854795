import styled from "styled-components";
import {InfoBoxWrapper} from "../../../components/common/InfoBoxWrapper";
import React, {MouseEvent, RefObject, useCallback, useContext, useEffect, useState} from "react";
import {StoreContext} from "./index";
import {cafeDeleteMenu, setMenuOrder} from "../../../api/cafe";
import {enqueueSnackbar} from "notistack";
import {FoodMenuItem} from "../../../api/types";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../../recoil/app";

const MenuInfoBox = styled(InfoBoxWrapper)`
  > .list {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }

  > .no-menu {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
  }
`
const AdminMenuItemWrapper = styled.div<{
  transform?: string
  transition?: string
  isDragging?: boolean
  overlay?: boolean
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  transform: ${p => p.transform || 'none'};
  transition: ${p => p.transition || 'none'};

  ${p => p.isDragging && `
    background: rgba(0, 0, 0, 0.05);
    border: 1px dashed rgba(0, 0, 0, 0.2);
    > * {
      opacity: 0;
    }
  `};

  ${p => p.overlay && `
      opacity: 0.7;
  `};

`

const MenuItemWrapper = styled.div<{
  transform?: string
  transition?: string
  isDragging?: boolean
  overlay?: boolean
}>`
  width: 100%;
  transform: ${p => p.transform || 'none'};
  transition: ${p => p.transition || 'none'};

  ${p => p.isDragging && `
    background: rgba(0, 0, 0, 0.05);
    border: 1px dashed rgba(0, 0, 0, 0.2);
    > * {
      opacity: 0;
    }
  `};

  ${p => p.overlay && `
      opacity: 0.7;
  `};
  
  > .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    
    > .thumbnail {
      width: 92px;
      height: 92px;
      border-radius: 4px;
      border: 1px solid var(--Black-100, #F0F0F0);
      background: url(<path-to-image>) lightgray 50% / cover no-repeat;
    }

    > .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
      }

      > .description {
        margin-top: 4px;
        color: var(--Black-500, #202020);
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 18.2px */
        letter-spacing: -0.26px;
      }

      > .price {
        margin-top: 8px;
        color: var(--Purple-300, #6436E7);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
      }
    }
  }


  > .admin-menu-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    > .button {
      padding: 6px 16px;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid var(--Black-200, #B7B7B7);
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`

interface MenuData {
  id: number,
  orderIndex: number,
  title: string,
  description: string,
  price: number,
  image: string | null,
}

interface MenuItemProps {
  data: MenuData,
  editable?: boolean
  overlay?: boolean
  onClickDelete?: (menuId: number) => void;
  onClickEdit?: (menuId: number) => void;
}

const MenuItem = (
  {
    data,
    editable,
    overlay,
    onClickDelete,
    onClickEdit
  }: MenuItemProps
) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({id: data.id});

  return <MenuItemWrapper
    ref={setNodeRef}
    transform={CSS.Transform.toString(transform)}
    transition={transition}
    isDragging={isDragging}
    overlay={overlay}
    {...attributes}
    {...listeners}
  >
    <div className="content">
      {
        data.image && (
          <img className='thumbnail' src={data.image}/>
        )
      }
      <div className='info'>
        <div className='title'>{data.title}</div>
        <div className='description'>{data.description}</div>
        <div className='price'>{data.price.toLocaleString()}원</div>
      </div>
    </div>
    {
      (overlay || editable) && (
        <div className='admin-menu-row'>
          <div className='button' onClick={() => onClickEdit && onClickEdit(data.id)}>수정</div>
          <div className='button' onClick={() => onClickDelete && onClickDelete(data.id)}>삭제</div>
        </div>
      )
    }
  </MenuItemWrapper>
}

interface InfoBoxRef {
  boxRef: RefObject<HTMLDivElement>,
  openWritePopup: (mode: 'write' | 'edit', menu?: FoodMenuItem) => void
}

const MenuInfo = (
  {
    boxRef,
    openWritePopup
  }: InfoBoxRef
) => {
  const {
    data,
    update
  } = useContext(StoreContext);

  const [menus, setMenus] = useState<MenuData[]>([])
  const [activeItem, setActiveItem] = useState<MenuData>();

  const setLoading = useSetRecoilState(loadingState);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 5
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 5
      }
    }),
  );

  useEffect(() => {
    setMenus(data.menuItems.map((item, i) => {
      return {
        id: item.id,
        orderIndex: i,
        title: item.itemName,
        description: item.description,
        price: item.price,
        image: item.imageUrl
      }
    }))
  }, [data]);


  const handleEditItem = useCallback((menuId: number) => {
    const menu = menus.find(x => x.id === menuId);
    if (menu) {
      openWritePopup('edit', {
        description: menu.description,
        id: menu.id,
        imageUrl: menu.image,
        itemName: menu.title,
        price: menu.price
      })
    }
  }, [menus]);

  const handleDeleteItem = useCallback((menuId: number) => {
    if (!data) {
      return;
    }

    if (confirm('삭제하시겠습니까?')) {
      setLoading(true);
      cafeDeleteMenu({
        cafeId: data.id,
        menuId: menuId
      }).then(() => {
        enqueueSnackbar("메뉴가 삭제되었습니다.", {variant: 'success'});
        update();
      }).catch((e) => {
        enqueueSnackbar(e.message, {variant: 'error'});
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [data]);


  const handleDragStart = useCallback((e: DragStartEvent) => {
    const item = menus.find(x => x.id === e.active.id);
    setActiveItem(item);
  }, [menus]);

  const handleDragEnd = useCallback((e: DragEndEvent) => {
    setActiveItem(undefined);

    const {
      active,
      over
    } = e;
    if (over && active.id !== over.id) {
      const oldIndex = menus.findIndex((image) => image.id == active.id);
      const newIndex = menus.findIndex((image) => image.id == over.id);
      const newMenus = arrayMove(menus, oldIndex, newIndex).map((item, i) => {
        return {
          ...item,
          orderIndex: i
        }
      });
      setMenus(newMenus)

      setLoading(true);
      setMenuOrder(data.id, {
        menuOrder: newMenus.map(x => ({id: x.id}))
      }).then(() => {
        enqueueSnackbar("메뉴 순서가 변경되었습니다.", {variant: 'success'})
      }).catch((e) => {
        enqueueSnackbar(e.message, {variant: 'error'});
      }).finally(() => {
        setLoading(false);
      })
    }
  }, [data, menus]);
  return <MenuInfoBox ref={boxRef}>
    <div className='title-row'>
      <div className='title'>메뉴</div>
      {
        data.myCafe && (
          <div className='button' onClick={() => {
            openWritePopup('write')
          }}>
            메뉴 등록
          </div>
        )
      }
    </div>
    {
      menus.length === 0 ? <div className="no-menu" style={{
        margin: '20px 0',
        textAlign: 'center',
        fontSize: '14px'

      }}>등록된 메뉴가 없습니다.</div> : <div className='list'>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext items={menus} disabled={!data.myCafe}>
            {
              menus.map((item, index) => (
                <MenuItem
                  key={index}
                  editable={data.myCafe}
                  data={item}
                  onClickDelete={handleDeleteItem}
                  onClickEdit={handleEditItem}
                />
              ))
            }
            <DragOverlay>
              {
                activeItem && (
                  <MenuItem data={activeItem} overlay/>
                )
              }
            </DragOverlay>
          </SortableContext>
        </DndContext>
      </div>
    }
  </MenuInfoBox>
}
export default MenuInfo;
