import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import HomeInfo from "./HomeInfo";
import DetailInfo from "./DetailInfo";
import MenuInfo from "./MenuInfo";
import LocationInfo from "./LocationInfo";
import ReportInfo from "./ReportInfo";
import {
  EMPTY_IMAGE,
  getDistanceKm,
  getKoreanNumber,
  shareURL,
} from "../../../utils/common";
import useDialog from "../../../hooks/useDialog";
import LocationSheet from "../../../components/web/LocationSheet";
import Write from "./Write";
import { cafeDeleteTournament, cafeDetail } from "../../../api/cafe";
import { enqueueSnackbar } from "notistack";
import {
  Cafe,
  FoodMenuItem,
  PubTournamentItem,
  ReviewDataResponse,
} from "../../../api/types";
import useGeoLocation from "../../../hooks/useGeoLocation";
import useQuickButtons from "../../../hooks/useQuickButtons";
import { getGameTypeString } from "../../../utils/constants";
import moment from "moment";
import EditTournamentInfo from "./Admin/EditTournamentInfo";
import EditEventInfo, { EventData } from "./Admin/EditEventInfo";
import EditStoreInfo from "./Admin/EditStoreInfo";
import EditDetailInfo from "./Admin/EditDetailInfo";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { navigationTargetState } from "../../../recoil/store";
import Loading from "../../../components/Loading";
import AdminButton from "./Admin/AdminButton";
import { userState } from "../../../recoil/auth";
import { debounce } from "lodash";
import { loadingState } from "../../../recoil/app";
import ImageViewer from "../../../components/ImageViewer";
import EditPubPhoto from "./Admin/EditPubPhoto";
import Review from "./Review";
import ReviewWrite from "./WriteReview";

const StoreDetailWrapper = styled.div<{
  scrollLock: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
  overscroll-behavior: none;
  z-index: 10;
  background: white;
  transition: all 0.5s ease-in-out;
  overscroll-behavior: none;
  > div {
    scroll-margin-top: 108px;
  }

  ${(p) =>
    p.scrollLock
      ? `
      overflow-y: hidden;
  `
      : `
    overflow-y: scroll;
  `}
  > .header {
    z-index: 101;
    transition: all 0.1s ease-in-out;
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 8px;

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    > .title {
      transition: all 0.1s ease-in-out;
      color: ${(p) => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      opacity: 0;
    }

    > .button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
      color: var(--Black-200, #b7b7b7);
      text-align: right;
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.26px;
    }
  }

  > .header.show {
    background: white;

    > .title {
      opacity: 1;
    }

    > .close {
      svg {
        path {
          stroke: var(--Black-400, #444);
        }
      }
    }
  }
`;
const InfoMenuWrapper = styled.div`
  position: sticky;
  left: 0;
  top: 48px;
  background: white;
  padding: 12px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  height: 60px;
  z-index: 101;
  justify-content: space-around;

  > .item {
    text-align: center;
    color: var(--Black-300, #808080);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  > .item.selected {
    color: var(--Black-400, #444);
  }
`;
const HorizontalBar = styled.div`
  width: 100%;
  height: 8px;
  background: var(--Black-100, #f0f0f0);
`;
const ThumbnailWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  height: 30%;

  > .main {
    height: 100%;
    width: calc(75% - 2px);
    background: gray;
    object-fit: cover;
    max-width: 100%;
  }

  > .sub-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    width: 25%;

    > .sub-wrapper {
      position: relative;
      width: 100%;
      flex: 1;
      overflow: hidden;

      .sub {
        width: 100%;
        height: 100%;
        background: gray;
        object-fit: cover;
      }

      .more {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.24px;
        pointer-events: none;

        > img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;
const StoreInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 16px 0;

  > .top {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .info-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      > .item {
        padding: 0 6px;
        height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--Purple-300, #6436e7);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 16.667px;
        border: 1px solid var(--Purple-100, #f0eaff);
      }
    }

    > .tag {
      padding: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2px;

      > img {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }

      color: var(--Purple-200, #8359f7);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 14px;
      border: 1.5px solid #6c36d6;
      background: var(--Purple-100, #f0eaff);
    }

    > .tag.affiliate {
      border: 1.5px solid #f2f7ff;
      color: #176ced;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: transparent;
    }

    > .tag.normal {
      border: 1.5px solid var(--Black-100, #f0f0f0);
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: transparent;
    }
  }

  > .title-wrapper {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    > .title-box {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      text-align: center;
      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.32px;
      }
      > .star {
        font-family: Pretendard;
        margin-left: 5px;
        color: gold;
        font-size: 12px;
        margin-bottom: 2px;
        font-style: normal;
      }
      > .review-count {
        font-family: Pretendard;
        margin-left: 1px;
        font-size: 12px;
        margin-bottom: 2px;
        font-style: normal;
      }
    }

    > .address {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }

  > .count-wrapper {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    > .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;

      > img {
        width: 16px;
        height: 16px;
      }
    }
  }

  > .bottom {
    margin-top: 20px;
    width: 100%;
    height: 1px;
    background: var(--Black-200, #b7b7b7);
  }
`;
const ExtraInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 16px 30px;

  > .menu-wrapper {
    width: 100%;
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    > .item {
      cursor: pointer;
      border-radius: 8px;
      border: 1px solid var(--Black-100, #f0f0f0);
      width: var(--Pro2, 72px);
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;

      > img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }

      > span {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
  }

  > .menu-wrapper.width60 {
    > .item {
      width: 60px;
    }
  }

  > .box {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    &:first-child {
      margin-top: 0;
    }

    > .title-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
      }

      > .button {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.26px;

        > img {
          cursor: pointer;
          object-fit: contain;
          width: 16px;
          height: 16px;
        }
      }
    }

    > .content-wrapper {
      padding: 16px 12px 16px 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 8px;
      flex-grow: 1;
      border-radius: 8px;
      background: var(--Black-100, #f0f0f0);

      > .content-col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;

        > .content {
          color: var(--Black-500, #202020);
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 18.2px */
          letter-spacing: -0.26px;
          word-break: break-word;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          white-space: pre-wrap;
        }

        > .admin-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;

          > .button {
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid var(--Black-200, #b7b7b7);
            padding: 6px 16px;
            color: var(--Black-500, #202020);
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        > .createdAt {
          color: var(--Black-200, #b7b7b7);
          text-align: right;
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.26px;
        }
      }

      > img {
        height: 99px;
        flex-shrink: 0;
        width: 70px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid #fff;
        background: url(<path-to-image>) lightgray 50% / contain no-repeat;
      }
    }
  }
`;

type InfoMenuType =
  | "home"
  | "detail"
  | "menu"
  | "location"
  | "photo"
  | "review";

export const StoreContext = createContext<{
  data: Cafe;
  update: () => void;
}>({
  data: {} as any,
  update: Function,
});

const StoreDetail = () => {
  const { openDialog } = useDialog();
  const history = useHistory();
  const params = useParams<{
    id: string;
  }>();
  //Admin Edit menu
  const [showRegisterTournament, setShowRegisterTournament] = useState(false);
  const [editTournamentItem, setEditTournamentItem] =
    useState<PubTournamentItem>();
  const [showRegisterEvent, setShowRegisterEvent] = useState(false);
  const [showEditEvent, setShowEditEvent] = useState<{
    cafeId: number;
    data: EventData;
  } | null>(null);
  const [showEditStoreInfo, setShowEditStoreInfo] = useState(false);
  const [showEditDetailInfo, setShowEditDetailInfo] = useState(false);
  const [showEditPubPhoto, setShowEditPubPhoto] = useState(false);
  const [innerRef, setInnerRef] = useState<Element | null>(null);
  const [showHeader, setShowHeader] = useState(false);
  const [infoMenu, setInfoMenu] = useState<InfoMenuType>("home");
  const [hasOpenChat, setHasOpenChat] = useState(true);
  const [showWrite, setShowWrite] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editReviewData, setEditReviewData] =
    useState<ReviewDataResponse | null>(null);
  const [rate, setRate] = useState(0);
  const [showRegisterMenu, setShowRegisterMenu] = useState<{
    show: boolean;
    mode: "edit" | "write";
    menu?: FoodMenuItem;
  }>({
    show: false,
    mode: "write",
  });
  const [data, setData] = useState<Cafe>();
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
  const menuListRef = Array.from({ length: 6 }).map(() =>
    useRef<HTMLDivElement>(null)
  );

  const user = useRecoilValue(userState);
  const setLoading = useSetRecoilState(loadingState);
  const setNavigationTarget = useSetRecoilState(navigationTargetState);

  const { latitude, longitude } = useGeoLocation(true);

  const handleUpdate = useCallback(() => {
    if (params.id) {
      const cafeId = Number(params.id);
      cafeDetail(cafeId)
        .then((res) => {
          res.reviewData.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          if (res.reviewData.length > 0) {
            const totalScore = res.reviewData.reduce(
              (sum, review) => sum + review.score,
              0
            );
            setRate(totalScore / res.reviewData.length);
          }

          setData(res);
          if (res.openChatURL) {
            setHasOpenChat(true);
          } else {
            setHasOpenChat(false);
          }
        })
        .catch((e: any) => {
          enqueueSnackbar("펍 정보를 가져올 수 없습니다: " + e.message, {
            variant: "error",
          });
          history.goBack();
        });
    } else {
      history.goBack();
    }
  }, [params.id, user?.id, showEdit, showWrite]);

  const handleClose = useCallback(() => {
    if (history.action === "PUSH") {
      history.goBack();
    } else {
      history.replace("/");
    }
  }, []);

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate]);

  useEffect(() => {
    if (innerRef) {
      const setMenu = debounce((index: number) => {
        const arr: InfoMenuType[] = [
          "home",
          "detail",
          "menu",
          "location",
          "photo",
          "review",
        ];
        setInfoMenu(arr[index]);
      }, 100);

      //스크롤이 3px 이상 넘어가면 setShowHeader(true)
      const handleScroll = () => {
        if (innerRef) {
          const scrollTop = innerRef.scrollTop;
          if (scrollTop > 3) {
            setShowHeader(true);
          } else {
            setShowHeader(false);
          }
          //스크롤에 따라 selected menu 변경
          menuListRef.forEach((_, index) => {
            if (menuListRef[index].current) {
              const observer = new IntersectionObserver(
                (entries) => {
                  if (
                    index === menuListRef.length - 1 &&
                    entries[0].intersectionRatio === 1
                  ) {
                    setMenu(index);
                  } else {
                    const top = entries[0].intersectionRect.top;
                    if (
                      entries[0].intersectionRatio > 0.8 &&
                      top >= 100 &&
                      top <= 200
                    ) {
                      setMenu(index);
                    }
                  }
                },
                { threshold: 1 }
              );
              observer.observe(menuListRef[index].current as Element);
            }
          });
        }
      };
      innerRef.addEventListener("scroll", handleScroll);
      return () => {
        if (innerRef) {
          innerRef.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [innerRef]);

  const { openPhoneCall, toggleLike } = useQuickButtons();

  const handleOpenCall = useCallback(() => {
    if (data?.cafeName && data?.vcn) {
      openPhoneCall(data.cafeName, data.vcn);
    }
    if (!data?.vcn) {
      enqueueSnackbar("전화번호가 없습니다.", { variant: "error" });
    }
  }, [data]);

  const handleDeleteTournament = (tournamentId: number) => {
    if (!data) {
      return;
    }

    setLoading(true);
    cafeDeleteTournament(data.id, tournamentId)
      .then(() => {
        handleUpdate();
        enqueueSnackbar("토너먼트가 삭제되었습니다", { variant: "success" });
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickMenu = (section: string) => {
    if (!menuListRef) return;

    let index = 0;
    switch (section) {
      case "home":
        index = 0;
        break;
      case "detail":
        index = 1;
        break;
      case "menu":
        index = 2;
        break;
      case "location":
        index = 3;
        break;
      case "review":
        index = 4;
        break;
    }

    if (menuListRef[index].current) {
      menuListRef[index].current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  if (!data) {
    return <Loading full />;
  }

  return (
    <StoreContext.Provider
      value={{
        data: data,
        update: handleUpdate,
      }}
    >
      {showWrite && (
        <ReviewWrite
          mode={"write"}
          onClose={() => setShowWrite(false)}
          rate={5}
          pubId={data.id}
        />
      )}
      {showEdit && (
        <ReviewWrite
          mode={"edit"}
          onClose={() => setShowEdit(false)}
          rate={editReviewData ? editReviewData.score : 5}
          reviewData={editReviewData ? editReviewData : undefined}
          pubId={data.id}
        />
      )}
      {showRegisterMenu.show && (
        <Write
          onClose={() =>
            setShowRegisterMenu({
              show: false,
              mode: "write",
            })
          }
          mode={showRegisterMenu.mode}
          menu={showRegisterMenu.menu}
        />
      )}
      {showRegisterTournament && (
        <EditTournamentInfo
          mode="write"
          onClose={() => {
            handleUpdate();
            setShowRegisterTournament(false);
          }}
        />
      )}
      {editTournamentItem && (
        <EditTournamentInfo
          mode="edit"
          tournament={editTournamentItem}
          onClose={() => {
            setEditTournamentItem(undefined);
            setShowRegisterTournament(false);
          }}
        />
      )}
      {showRegisterEvent && (
        <EditEventInfo
          mode={"write"}
          onClose={() => {
            handleUpdate();
            setShowRegisterEvent(false);
          }}
        />
      )}
      {showEditEvent && (
        <EditEventInfo
          mode={"edit"}
          item={showEditEvent.data}
          onClose={() => setShowEditEvent(null)}
        />
      )}
      {showEditStoreInfo && (
        <EditStoreInfo
          cafeId={data.id}
          onClose={() => setShowEditStoreInfo(false)}
        />
      )}
      {showEditDetailInfo && (
        <EditDetailInfo
          cafeId={data.id}
          onClose={() => setShowEditDetailInfo(false)}
        />
      )}
      {selectedImageIndex !== -1 && (
        <ImageViewer
          images={data?.images.map((x) => x.imageUrl) ?? []}
          defaultIndex={selectedImageIndex}
          onClose={() => setSelectedImageIndex(-1)}
          canEdit={data.myCafe}
          onClickEdit={() => setShowEditPubPhoto(true)}
        />
      )}
      {showEditPubPhoto && (
        <EditPubPhoto onClose={() => setShowEditPubPhoto(false)} />
      )}
      <LocationSheet />
      <StoreDetailWrapper ref={setInnerRef} scrollLock={false}>
        <div className={"header " + (showHeader ? "show" : "")}>
          <div className="close" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 19L5 12M5 12L12 5M5 12H19"
                stroke="white"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="title">{data.cafeName}</div>
        </div>
        <ThumbnailWrapper>
          <img
            className="main"
            src={
              data.images[0]
                ? data.images[0].imageUrl
                : "https://dfesoodpx4jgd.cloudfront.net/cafe/default.png"
            }
            onClick={() => setSelectedImageIndex(0)}
          />
          {data.images && data.images.length > 1 && (
            <div className="sub-list">
              {[1, 2, 3].map((i) => (
                <div className="sub-wrapper">
                  <img
                    className="sub"
                    src={data.images[i] ? data.images[i].imageUrl : EMPTY_IMAGE}
                    onClick={
                      data.images[i]
                        ? () => setSelectedImageIndex(i)
                        : undefined
                    }
                  />
                  {i === 3 && data.images.length > 4 && (
                    // TODO: 이미지 더 있음 표시
                    <div className="more">
                      <img src="/image-web/Icon/Photo.svg" />+
                      {data.images.length - 4}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </ThumbnailWrapper>
        <StoreInfoWrapper>
          <div className="top">
            <div className="info-row">
              <div className="item">{getGameTypeString(data.gameTypes)}</div>
              {data.buyIn && (
                <div className="item">
                  바이인 : {getKoreanNumber(data.buyIn ?? 0)} ~{" "}
                  {data.buyInMax ? getKoreanNumber(data.buyInMax ?? 0) : ""}
                </div>
              )}
            </div>
            {data.pubType === "PREMIUM" && (
              <div className="tag">
                <img src="/image-web/store/premium.png" />
                프리미엄
              </div>
            )}
            {data.pubType === "AFFILIATE" && (
              <div className="tag affiliate">
                <img src="/image-web/store/store_check.svg" />
                제휴
              </div>
            )}
            {data.pubType === "NORMAL" && (
              <div className="tag normal">일반</div>
            )}
          </div>
          <div className="title-wrapper">
            <div className="title-box">
              <div className="title">{data.cafeName}</div>
              <div className="star">★</div>
              <div className="review-count">{rate.toFixed(1)}</div>
            </div>
            <div className="address">
              {data.newAddress} {data.detailAddress}
            </div>
          </div>
          <div className="count-wrapper">
            <div className="item">
              <img src="/image-web/store/Icon/Heart/small.svg" />
              {data.likeCount.toLocaleString()}
            </div>
            <div className="item">
              <img src="/image-web/store/Icon/Map%20pin/small.svg" />
              {getDistanceKm(
                latitude,
                longitude,
                data.lat || 0,
                data.lon || 0
              ).toLocaleString()}
              km
            </div>
          </div>
          {data.holdemNowList.length + data.pubTournamentList.length > 0 && (
            <div className="bottom" />
          )}
        </StoreInfoWrapper>
        <ExtraInfoWrapper>
          {data.holdemNowList.length > 0 && (
            <div className="box">
              <div className="title-row">
                <div className="title">홀덤 NOW</div>
                <div
                  className="button"
                  onClick={() => history.push("/holdemNow?pubId=" + data.id)}
                >
                  <img src="/image-web/store/more.svg" />
                  더보기
                </div>
              </div>
              <div
                className="content-wrapper"
                onClick={() => {
                  history.push(
                    `/holdemNow/${data?.holdemNowList[0].messageType}/${data?.holdemNowList[0].id}`
                  );
                }}
              >
                <div className="content-col">
                  <div className="content">{data.holdemNowList[0].message}</div>
                  <div className="createdAt">
                    {moment(data.holdemNowList[0].createdAt).fromNow()}
                  </div>
                </div>
                {data.holdemNowList[0].imageList[0] && (
                  <img src={data.holdemNowList[0].imageList[0]} />
                )}
              </div>
            </div>
          )}
          {(data.myCafe || data.pubTournamentList.length > 0) && (
            <div className="box">
              <div className="title-row">
                <div className="title">진행중 토너먼트</div>
                {data.myCafe && (
                  <AdminButton
                    onClick={() => {
                      setShowRegisterTournament(true);
                    }}
                  >
                    등록
                  </AdminButton>
                )}
              </div>
              {data.pubTournamentList.map((item, i) => (
                <div className="content-wrapper" key={i}>
                  <div className="content-col">
                    <div className="content">{item.title}</div>
                    {data.myCafe && (
                      <div className="admin-row">
                        <div
                          className="button"
                          onClick={() => {
                            setShowRegisterTournament(true);
                            setEditTournamentItem(item);
                          }}
                        >
                          수정
                        </div>
                        <div
                          className="button"
                          onClick={() => {
                            openDialog({
                              title: "토너먼트 삭제",
                              text: "토너먼트를 삭제하시겠습니까?",
                              type: "web",
                              confirm: true,
                              onConfirm: () =>
                                handleDeleteTournament(item.tournamentId),
                            });
                          }}
                        >
                          삭제
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className={"menu-wrapper " + (hasOpenChat ? "width60" : "")}>
            <div className="item" onClick={handleOpenCall}>
              <img src="/image-web/store/Phone.svg" />
              <span>전화</span>
            </div>
            <div
              className="item"
              onClick={async () => {
                await toggleLike(data?.id!, "CAFE");
                handleUpdate();
              }}
            >
              <img
                src={`/image-web/store/${data.like ? "Heart_on" : "Heart"}.svg`}
              />
              <span>즐겨찾기</span>
            </div>
            {hasOpenChat && (
              <div
                className="item"
                onClick={() => {
                  if (data && data.pubType === "PREMIUM" && data.openChatURL) {
                    window.open(data.openChatURL, "_blank");
                  } else {
                    openDialog({
                      type: "web",
                      text: "프리미엄펍만 이용 가능한 서비스입니다.",
                    });
                  }
                }}
              >
                <img src="/image-web/store/Btn/Kakao.png" />
                <span>오픈채팅</span>
              </div>
            )}
            <div className="item" onClick={() => setNavigationTarget(data)}>
              <img src="/image-web/store/Navigation.svg" />
              <span>길안내</span>
            </div>
            <div
              className="item"
              onClick={() => shareURL(window.location.href)}
            >
              <img src="/image-web/store/Share.svg" />
              <span>공유</span>
            </div>
          </div>
        </ExtraInfoWrapper>
        <HorizontalBar />
        <InfoMenuWrapper>
          <div
            onClick={() => handleClickMenu("home")}
            className={"item " + (infoMenu === "home" ? "selected" : "")}
          >
            홈
          </div>
          <div
            onClick={() => handleClickMenu("detail")}
            className={"item " + (infoMenu === "detail" ? "selected" : "")}
          >
            상세정보
          </div>
          <div
            onClick={() => handleClickMenu("menu")}
            className={"item " + (infoMenu === "menu" ? "selected" : "")}
          >
            메뉴
          </div>
          <div
            onClick={() => handleClickMenu("location")}
            className={"item " + (infoMenu === "location" ? "selected" : "")}
          >
            위치
          </div>
          <div
            onClick={() => handleClickMenu("review")}
            className={"item " + (infoMenu === "review" ? "selected" : "")}
          >
            리뷰
          </div>
        </InfoMenuWrapper>
        <HomeInfo
          boxRef={menuListRef[0]}
          openStoreInfoEdit={() => setShowEditStoreInfo(true)}
        />
        <HorizontalBar />
        <DetailInfo
          boxRef={menuListRef[1]}
          openEventEdit={(eventData) => {
            setShowEditEvent({
              cafeId: data.id,
              data: eventData,
            });
          }}
          openEventRegister={() => {
            setShowRegisterEvent(true);
          }}
          openDetailEdit={() => {
            setShowEditDetailInfo(true);
          }}
        />
        <HorizontalBar />
        <MenuInfo
          boxRef={menuListRef[2]}
          openWritePopup={(mode: "write" | "edit", menu?: FoodMenuItem) => {
            setShowRegisterMenu({
              show: true,
              mode,
              menu,
            });
          }}
        />
        <HorizontalBar />
        <LocationInfo boxRef={menuListRef[3]} />
        <HorizontalBar />
        <Review
          boxRef={menuListRef[4]}
          setEditReviewData={setEditReviewData}
          setEditReview={setShowEdit}
          setCloseWrite={setShowWrite}
          reviewData={data.reviewData}
          pubId={data.id}
          todayReviewPhotoAdd={data.todayReviewPhotoAdd}
        />
        <HorizontalBar />
        <ReportInfo />
      </StoreDetailWrapper>
    </StoreContext.Provider>
  );
};
export default StoreDetail;
