import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useUserInfo from "../../hooks/useUserInfo";
import useDialog from "../../hooks/useDialog";
import { useSetRecoilState } from "recoil";
import { searchState } from "../../recoil/search";
import { openNewWindow } from "../../utils/common";

const PCHeaderWrapper = styled.div`
  position: fixed;
  padding: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 105;
  border-bottom: 1px solid #b7b7b7;

  > .inner {
    width: 100%;
    max-width: 1060px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    > .top {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .logo-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;

        > .search-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          cursor: pointer;
          padding: 11px 16px;
          border-radius: 12px;
          border: 1px solid var(--Black-100, #f0f0f0);
          background: var(--Black-100, #f0f0f0);
          transition: all 0.3s;
          width: 280px;

          > .search-icon {
            width: 16px;
            height: 16px;
          }

          > input {
            width: 100%;
            background: none;
            border: none;
            outline: none;
            color: var(--Black-500, #202020);
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.28px;

            &::placeholder {
              color: var(--Black-300, #808080);
              font-family: Pretendard;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.28px;
            }
          }
        }

        > .search-wrapper.focus {
          border-radius: 12px;
          border: 1px solid var(--Purple-300, #6436e7);
          background: #fff;
        }

        > .logo {
          cursor: pointer;
          width: 120px;
          height: 32px;
        }
      }

      > .login-button {
        cursor: pointer;
        border-radius: 12px;
        background: var(--Purple-300, #6436e7);
        color: #fff;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        padding: 11px 20px;
      }

      > .user-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;

        > .like-wrapper {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 4px;

          > .heart {
            width: 16px;
            height: 16px;
          }

          > .like {
            color: var(--Black-300, #808080);
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.28px;
          }
        }

        > .profile {
          cursor: pointer;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }

    > .menu-row {
      padding-left: 12px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 40px;

      > .item {
        position: relative;
        cursor: pointer;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        transition: all 0.3s;

        > .sub-menu {
          transition: all 0.3s;
          max-height: 0;
          overflow: hidden;
          width: max-content;
          z-index: 106;
          position: absolute;
          left: -16px;
          top: 35px;
          padding: 0 20px;
          border-radius: 7px;
          border: 1px solid transparent;
          background: #fff;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 20px;

          > .sub-item {
            cursor: pointer;
            color: var(--Black-400, #444);
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.28px;
            transition: all 0.3s;
          }

          > .sub-item.hover {
            color: var(--Black-400, #444);
          }

          > .sub-item.not-hover {
            color: #b7b7b7;
          }

          > .sub-item.none {
            color: #444 !important;
          }
        }
      }

      > .item.none {
        color: #444 !important;
      }

      > .item.hover {
        color: #444;

        > .sub-menu {
          max-height: 200px;
          padding: 20px;
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
          border: 1px solid var(--Black-100, #f0f0f0);
        }
      }

      > .item.not-hover {
        color: #b7b7b7;
      }
      > .item.selected {
        color: var(--Purple-300, #6436e7) !important;
      }
    }
  }
`;
const PCHeader = () => {
  const { openDialog } = useDialog();
  const history = useHistory();
  const { user } = useUserInfo();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [isHover, setIsHover] = useState("");
  const setSearch = useSetRecoilState(searchState);
  const [isSubHover, setIsSubHover] = useState("");
  const location = useLocation();
  const menus = [
    {
      name: "홀덤펍 찾기",
      link: ["/search"],
      onClick: () => {
        history.push("/search");
      },
    },
    {
      name: "홀덤 NOW",
      link: ["/holdemNow"],
      onClick: () => {
        history.push("/holdemNow");
      },
    },
    {
      name: "이벤트",
      link: ["/event"],
      onClick: () => {
        history.push("/event/list");
      },
    },
    {
      name: "토너먼트",
      link: ["/tournamentSchedule", "/tournament"],
      onClick: () => {
        history.push("/tournamentSchedule");
      },
    },
    {
      name: "홀덤 게임",
      link: ["/game/ring"],
      onClick: () => {
        openDialog({
          title: "홀덤게임",
          type: "web",
          text: "🚧홀덤 게임은 현재 준비 중입니다🚧<br><br>준비가 완료되는 대로 공지를 통해<br>알려드리겠습니다.",
          style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            maxWidth: "240px",
          },
          onConfirm: () => {
            //history.push('/game/ring')
          },
        });
        //history.push('/game/ring/list')
      },
    },
    {
      name: "랭킹",
      link: ["/ranking"],
      onClick: () => {
        history.push("/ranking");
      },
    },
    {
      name: "실시간 채용",
      link: ["/recruit"],
      onClick: () => {
        history.push("/recruit");
      },
    },
    {
      name: "홀덤 가이드",
      link: ["/holdem/guide"],
      onClick: () => {
        history.push("/holdem/guide");
      },
    },
    {
      name: "지역별 오픈채팅",
      link: ["/openChat"],
      onClick: () => {
        history.push("/openChat");
      },
    },
  ];
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Enter") {
          if (inputRef.current?.value) {
            setSearch(inputRef.current?.value);
            history.push("/search");
          }
        }
      });
      //focus detect
      inputRef.current.addEventListener("focus", () => {
        setIsInputFocus(true);
      });
      inputRef.current.addEventListener("blur", () => {
        setIsInputFocus(false);
      });
    }
  }, []);

  return (
    <PCHeaderWrapper>
      <div className="inner">
        <div className="top">
          <div className="logo-wrapper">
            <img
              className="logo"
              src="/image-web/Logo/Runnerrunner_Full2.png"
              alt="logo"
              onClick={() => {
                history.push("/");
              }}
            />
            <div className={"search-wrapper " + (isInputFocus ? "focus" : "")}>
              <img
                className="search-icon"
                src="/image-web/Icon/Search/small_gray.svg"
                alt="search-icon"
              />
              <input
                ref={inputRef}
                placeholder="원하는 홀덤펍을 검색해보세요"
              />
            </div>
          </div>
          {user ? (
            <div className="user-wrapper">
              <div
                className="like-wrapper"
                onClick={() => {
                  history.push("/like");
                }}
              >
                <img
                  src="/image-web/Icon/Heart/small-gray.svg"
                  alt="heart"
                  className="heart"
                />
                <div className="like">즐겨찾기</div>
              </div>
              <img
                src={user.profileImageUrl ?? "/image-web/default_profile.png"}
                className="profile"
                onClick={() => {
                  history.push("/mypage");
                }}
              />
            </div>
          ) : (
            <div
              className="login-button"
              onClick={() => {
                history.push("/login", { redirect: window.location.href });
              }}
            >
              로그인 및 회원가입
            </div>
          )}
        </div>
        <div className="menu-row">
          {menus.map((menu, index) => {
            const isSelected = menu.link.some((link) =>
              location.pathname.includes(link)
            );
            return (
              <div
                key={index}
                className={
                  "item " +
                  (isHover === menu.name ? "hover " : "not-hover ") +
                  (isHover === "" ? "none" : "") +
                  (isSelected ? " selected" : "")
                }
                onClick={menu.onClick}
                onMouseEnter={() => setIsHover(menu.name)}
                onMouseLeave={() => setIsHover("")}
              >
                {menu.name}
              </div>
            );
          })}
        </div>
      </div>
    </PCHeaderWrapper>
  );
};

export default PCHeader;
