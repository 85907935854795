import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../../../hooks/useScreenOrientation";

const HowtoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  > .inner {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background: var(--Black-100, #F0F0F0);
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 18.2px */
    letter-spacing: -0.26px;
    @media ${MEDIA_DESKTOP}{
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
      background: white;
      padding: 0;
      padding-bottom: 36px;
      border-bottom: 1px solid var(--Black-200, #B7B7B7);
      border-radius: 0;
    }

    li {
      padding-left: 1.28571429em;
      text-indent: -1.28571429em;
    }
  }
`


const GridWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`

const CardWrapper = styled.div`
  width: 100%;
  padding: 12px 12px 16px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.20);

  > .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  > .point {
    margin-top: 12px;
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
  }

  > .money {
    margin-top: 4px;
    color: var(--Black-300, #808080);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.26px;
  }
`

interface CardProps {
  point: string;
  money: number;
}


const HowTo = () => {
  return <HowtoWrapper>
    <div className='inner'>
      <li>가입 시 친구 닉네임을 추천하면 친구에게 100,000 게임 포인트와 리바인 1회를 선물합니다.</li>
      <li>중복 추천 및 보상은 불가능합니다.</li>
      <li>어뷰징 또는 불법적인 악용 시 제재를 받을 수 있습니다.</li>
      <li>인게임에서는 최대 300,000 게임포인트까지 사용가능합니다.</li>
    </div>
  </HowtoWrapper>
}

export default HowTo;
