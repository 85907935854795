import instance from "../utils/network";
import {
  AddCommentInterface,
  Cafe,
  CommentInterface,
  CommonResponse,
  EditCommentInterface,
  GameType,
  GetPointResponse,
  HoldemNowType,
  PubType,
} from "./types";

export interface PubSearchParams {
  q?: string;
  lat?: number;
  lon?: number;
  myLat?: number;
  myLon?: number;
  km?: number;
  pubTypes?: PubType[];
  gameTypes?: GameType[];
  buyInFrom?: number;
  buyInTo?: number;
  blindUpFrom?: number;
  blindUpTo?: number;
  prizeFrom?: number;
  prizeTo?: number;
  sort?: string;
}

export function searchCafe(data: PubSearchParams): Promise<Cafe[]> {
  return instance.post("/search/cafe", data);
}

export function searchAreaCafe(cityName: string): Promise<Cafe[]> {
  return instance.get("/cafe/cityCafeList", { params: { cityName } });
}

export function cafeAllMessageList(pubId?: number): Promise<HoldemNowType[]> {
  return instance.get("/cafe/allMessageList", { params: { pubId } });
}

export function cafeAllMessageListLogin(
  pubId?: number
): Promise<HoldemNowType[]> {
  return instance.get("/cafe/allMessageListLogin", { params: { pubId } });
}

export function cafeViewMessage(params: {
  messageId: number;
}): Promise<HoldemNowType> {
  return instance.get("/cafe/viewMessage", { params });
}

export function userViewMessage(params: {
  messageId: number;
}): Promise<HoldemNowType> {
  return instance.get("/user-message/viewMessage", { params });
}

export function cafeDetail(id: number): Promise<Cafe> {
  return instance.get("/cafe/" + id);
}

export function cafeSelectReview(data: {
  cafeId: number;
  review: number;
}): Promise<CommonResponse> {
  return instance.post("/cafe/selectReview", null, { params: data });
}

export function cafeReports(data: {
  cafeId: number;
  info: string;
}): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.post("/cafe/reports", formData);
}

// holdem now
export function GetUserPoint(): Promise<GetPointResponse> {
  return instance.get("/user-message/userPoint");
}

// user point charge (test)
export function TestAddUserPoint(): Promise<any> {
  return instance.get("/user-message/testCharge?points=50000");
}

export function NowAddMessage(
  data: {
    nowMessage: string;
    latitude: number;
    longitude: number;
  },
  imageFiles: File[]
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  for (let file of imageFiles) {
    formData.append("coverImages", file);
  }

  return instance.post("/user-message/addMessage", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function AddReview(
  data: { pubId: number; message: string; score: number },
  imageFiles: File[]
) {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  for (let file of imageFiles) {
    formData.append("imageList", file);
  }

  return instance.post("/cafe/review", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function UpdateReview(
  data: { pubId: number; message: string; score: number; id: number },
  imageFiles: File[]
) {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  for (let file of imageFiles) {
    formData.append("imageList", file);
  }

  return instance.put(`/cafe/review/${data.id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function RemoveReview(id: number): Promise<CommonResponse> {
  return instance.delete(`/cafe/review/${id}`);
}

export function NowUpdateMessage(
  data: {
    nowMessage: string;
    latitude: number;
    longitude: number;
    id: number;
  },
  imageFiles: File[]
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  for (let file of imageFiles) {
    formData.append("images", file);
  }

  return instance.put("/user-message/messageUpdate", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function NowDeleteUserMessage(params: {
  messageId: number;
}): Promise<CommonResponse> {
  return instance.delete("/user-message/messageDelete", { params });
}

export function GetCommentList(params: {
  messageId: number;
  messageType: string;
}): Promise<CommentInterface[]> {
  return instance.get("/runner-comment/commentList", { params });
}

export function DeleteComment(params: {
  commentId: number;
}): Promise<CommonResponse> {
  return instance.delete("/runner-comment/deleteComment", { params });
}

export function AddPubComment(
  data: AddCommentInterface
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.post("/runner-comment/addPubMessageComment", formData);
}

export function AddUserComment(
  data: AddCommentInterface
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.post("/runner-comment/addUserMessageComment", formData);
}

export function EditComment(
  data: EditCommentInterface
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.put("/runner-comment/updateComment", formData);
}

// admin api

export function cafeUpdateDetailInfo(
  cafeId: number,
  data: {
    operatingDays?: string[];
    operatingStartTime?: string;
    operatingEndTime?: string;
    gameTypes?: GameType[];
    facilities?: string[];
    blindUp?: number;
    buyIn?: number;
    prize?: number;
    prizeMax?: number;
    informationUse?: string;
  }
): Promise<Cafe> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.post("/cafe/updateDetailInfo", formData, {
    params: { cafeId: cafeId },
  });
}

interface FoodMenu {
  menuName: string;
  menuInfo: string;
  price: number;
}

// TODO: add data type
export function cafeAddMenu(
  cafeId: number,
  image: File | null,
  data: FoodMenu
): Promise<Cafe> {
  const formData = new FormData();
  image && formData.append("image", image);
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.put("/cafe/addMenu", formData, { params: { cafeId } });
}

// TODO: add data type
export function cafeUpdateMenu(
  cafeId: number,
  menuId: number,
  image: File | null,
  data: FoodMenu
): Promise<Cafe> {
  const formData = new FormData();
  image && formData.append("image", image);
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.post("/cafe/updateMenu", formData, {
    params: { cafeId, menuId },
  });
}

export function cafeDeleteMenu(params: {
  cafeId: number;
  menuId: number;
}): Promise<Cafe> {
  return instance.delete("/cafe/deleteMenu", { params });
}

export function setMenuOrder(
  pubId: number,
  data: {
    menuOrder: { id: number }[];
  }
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.put("/cafe/setMenuOrder", formData, { params: { pubId } });
}

export function cafeRegistCafeImage(
  cafeId: number,
  image: File
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append("image", image);

  return instance.put("/cafe/registCafeImage", formData, {
    params: { cafeId },
  });
}

export function cafeDeleteCafeImage(params: {
  cafeId: number;
  photoId: number;
}): Promise<CommonResponse> {
  return instance.delete("/cafe/deletCafeImage", { params });
}

export function setCafeImageOrder(
  pubId: number,
  data: {
    cafeImageOrder: { id: number }[];
  }
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.put("/cafe/setCafeImageOrder", formData, {
    params: { pubId },
  });
}

export function cafeRegistReviewPhoto(
  cafeId: number,
  image: File
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append("image", image);

  return instance.put("/cafe/registReviewPhoto", formData, {
    params: { cafeId },
  });
}

export function cafeDeleteReviewPhoto(params: {
  cafeId: number;
  photoId: number;
}): Promise<CommonResponse> {
  return instance.delete("/cafe/deleteReviewPhoto", { params });
}

export function setReviewImageOrder(
  pubId: number,
  data: {
    reviewImageOrder: { id: number }[];
  }
): Promise<CommonResponse> {
  const formData = new FormData();
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.put("/cafe/setReviewImageOrder", formData, {
    params: { pubId },
  });
}

interface EventRegisterParams {
  title: string;
  description: string;
  startAt: string;
  closeAt: string;
  cafeId: number;
}

export function cafeEventRegister(
  data: EventRegisterParams
): Promise<CommonResponse> {
  return instance.post("/cafe-event/register", data);
}

interface EventUpdateParams extends EventRegisterParams {
  id: number;
}

export function cafeEventUpdate(
  data: EventUpdateParams
): Promise<CommonResponse> {
  return instance.put("/cafe-event/update ", data);
}

export function cafeEventDelete(params: {
  id: number;
}): Promise<CommonResponse> {
  return instance.delete("/cafe-event/delete", { params });
}

interface CafeTournament {
  title: string;
  startAt: string;
  endAt: string;
}

interface CafeTournamentResponse extends CafeTournament {
  imageList: string[];
}

export function cafeRegisterTournament(
  cafeId: number,
  images: File[],
  data: CafeTournament
): Promise<CafeTournamentResponse> {
  const formData = new FormData();
  for (let image of images) {
    formData.append("images", image);
  }
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.post("/cafe/registerTournament", formData, {
    params: { cafeId },
  });
}

export function cafeModifyTournament(
  cafeId: number,
  tournamentId: number,
  images: File[],
  data: CafeTournament
): Promise<CafeTournamentResponse> {
  const formData = new FormData();
  for (let image of images) {
    formData.append("images", image);
  }
  formData.append(
    "data",
    new Blob([JSON.stringify(data)], { type: "application/json" })
  );

  return instance.put("/cafe/modifyTournament", formData, {
    params: { cafeId, tournamentId },
  });
}

export function cafeDeleteTournament(
  cafeId: number,
  tournamentId: number
): Promise<CommonResponse> {
  return instance.delete("/cafe/deleteTournament", {
    params: { cafeId, tournamentId },
  });
}
