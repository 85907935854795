import styled from "styled-components";
import { useCallback, useContext, useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { enqueueSnackbar } from "notistack";
import { Cafe, GameType } from "../../../../api/types";
import { GameTypeLabels } from "../../../../utils/constants";
import { cafeUpdateDetailInfo } from "../../../../api/cafe";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/app";
import { StoreContext } from "../index";

const EditStoreInfoWrapper = styled.div<{
  scrollLock: boolean;
}>`
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  height: 100svh;
  overscroll-behavior: none;
  z-index: 107;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${(p) =>
    p.scrollLock
      ? `
    overflow-y: hidden;
  `
      : `
    overflow-y: scroll;
  `}
  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;

    > .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 20px 16px 220px;
      gap: 40px;
    }

    > .content.search-result {
      padding: 20px 16px;
      flex-grow: 1;
    }
  }

  > .floating-button-wrapper {
    background: white;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    padding: 30px 24px 48px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    > .button {
      cursor: pointer;
      width: 100%;
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.165px;
      border-radius: 8px;
      text-align: center;
      padding: 15px 0;
      background: var(--Purple-300, #6436e7);
    }
    > .button:active {
      background: #502bb5;
    }
  }

  > .header {
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;
    background: white;

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    > .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${(p) => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
      color: var(--Purple-300, #6436e7);
      text-align: right;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;
const DefaultInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  gap: 12px;

  > .title {
    width: 100%;
    text-align: left;
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  > .sub-title {
    width: 100%;
    text-align: left;
    margin-top: 8px;
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
const RecentlySearchWordWrapper = styled(DefaultInfoWrapper)`
  > .list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    > .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;

      > .word {
        color: var(--Black-500, #202020);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      > img {
        cursor: pointer;
        width: 16px;
        height: 16px;
      }
    }
  }
`;
const RecruitStatusInfoWrapper = styled(DefaultInfoWrapper)`
  > .service-list {
    width: 100%;
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 12px;
    justify-items: center;
    justify-content: center;

    > .item {
      border-radius: 8px;
      border: 1px solid var(--Black-100, #f0f0f0);
      width: 72px;
      height: 72px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      img {
        width: 32px;
        height: 32px;
      }

      > span {
        color: var(--Black-500, #202020);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    > .item.selected {
      border: 1px solid var(--Purple-300, #6436e7);
      background: var(--Purple-100, #f0eaff);

      > span {
        color: var(--Purple-300, #6436e7);
      }
    }
  }

  > .box {
    padding: 8px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    > .item-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      > .item {
        cursor: pointer;
        padding: 9px 16px;
        color: var(--Black-500, #202020);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 8px;
        border: 1px solid var(--Black-300, #808080);
        background: white;
      }

      > .item.selected {
        background: var(--Purple-100, #f0eaff);
        color: var(--Purple-300, #6436e7);
        border: 1px solid var(--Purple-300, #6436e7);
      }

      > .item-mini {
        cursor: pointer;
        padding: 9px 12px;
        background: white;
        border-radius: 8px;
        border: 1px solid var(--Black-300, #808080);
        color: var(--Black-500, #202020);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      > .item-mini.selected {
        color: var(--Purple-300, #6436e7);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 8px;
        border: 1px solid var(--Purple-300, #6436e7);
        background: var(--Purple-100, #f0eaff);
      }
    }
  }
`;
const RangeInfoWrapper = styled(DefaultInfoWrapper)`
  > .box {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    > .price {
      width: 100%;
      color: var(--Black-200, #b7b7b7);
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    > .rc-slider-wrapper {
      width: calc(100% - 32px);
    }

    > .range-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .text {
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
`;

const convertNumberToTime = (n: number) => {
  const hour = Math.floor(n / 2);
  const min = n % 2 === 0 ? "00" : "30";
  return `${hour.toString().padStart(2, "0")}:${min
    .toString()
    .padStart(2, "0")}`;
};

interface EditStoreInfoProps {
  onClose: () => void;
  cafeId: number;
}

const EditStoreInfo = ({ onClose, cafeId }: EditStoreInfoProps) => {
  const { data, update } = useContext(StoreContext);

  let initGameTypes = "";
  if (
    data.gameTypes.includes("TOURNAMENT") &&
    data.gameTypes.includes("OTHER_GAME")
  ) {
    initGameTypes = "";
  } else if (data.gameTypes.includes("TOURNAMENT")) {
    initGameTypes = "TOURNAMENT";
  } else if (data.gameTypes.includes("OTHER_GAME")) {
    initGameTypes = "OTHER_GAME";
  }
  const [selectedGameType, setSelectedGameType] = useState<GameType>(
    initGameTypes as GameType
  );
  const [openWeekDays, setOpenWeekDays] = useState<string[]>(
    data.operatingDays ?? []
  );
  const [openTime, setOpenTime] = useState<{
    min: number;
    max: number;
    selectedMin: number;
    selectedMax: number;
  }>({
    min: 0,
    max: 48,
    selectedMin: 0,
    selectedMax: 48,
  });
  const [selectedServiceList, setSelectedServiceList] = useState<string[]>(
    data.facilities.map((x) => x.type)
  );

  const setLoading = useSetRecoilState(loadingState);

  const serviceList = [
    {
      img: "/image-web/store/Detail/Property%201=Wifi.svg",
      title: "와이파이",
      value: "WIFI",
    },
    {
      img: "/image-web/store/Detail/Property%201=Parking.svg",
      title: "주차가능",
      value: "PARKING",
    },
    {
      img: "/image-web/store/Detail/Property%201=Smoking.svg",
      title: "흡연실",
      value: "SMOKING",
    },
    {
      img: "/image-web/store/Detail/Property%201=Food.svg",
      title: "식사제공",
      value: "FOOD",
    },
    {
      img: "/image-web/store/Detail/Property%201=Valet.svg",
      title: "발렛파킹",
      value: "VALET",
    },
    {
      img: "/image-web/store/Detail/Property%201=Pickup.svg",
      title: "픽업 서비스",
      value: "PICK_UP",
    },
    {
      img: "/image-web/store/Detail/Property%201=Rest.svg",
      title: "휴게실",
      value: "REST_AREA",
    },
    {
      img: "/image-web/store/Detail/Property%201=Coffee.svg",
      title: "커피제공",
      value: "COFFEE",
    },
  ];

  const handleUpdateStoreInfo = useCallback(() => {
    /*let gameTypes: number[] = []
    if(selectedGameType === ''){
      gameTypes = [1,2]
    }else if(selectedGameType === 'TOURNAMENT'){
      gameTypes = [1]
    }else if(selectedGameType === 'OTHER_GAME'){
      gameTypes = [2]
    }*/
    let gameTypes: GameType[] = [];
    if (selectedGameType === "") {
      gameTypes = ["TOURNAMENT", "OTHER_GAME"];
    } else {
      gameTypes = [selectedGameType];
    }

    let operatingHours = "";
    if (openWeekDays.length > 0) {
      operatingHours = openWeekDays.map((day) => day + 1).join(",");
    }
    operatingHours += `| ${convertNumberToTime(
      openTime.selectedMin
    )}~${convertNumberToTime(openTime.selectedMax)}`;

    setLoading(true);

    cafeUpdateDetailInfo(cafeId, {
      operatingDays: openWeekDays,
      operatingStartTime: convertNumberToTime(openTime.selectedMin),
      operatingEndTime: convertNumberToTime(openTime.selectedMax),
      gameTypes: gameTypes,
      facilities: selectedServiceList,
    })
      .then((res) => {
        update();
        enqueueSnackbar("수정되었습니다.", { variant: "success" });
        onClose();
      })
      .catch((e: any) => {
        enqueueSnackbar(e.message, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedGameType, selectedServiceList, openWeekDays, openTime]);

  // 매장 운영 시간 표시
  useEffect(() => {
    if (data.operatingStartTime && data.operatingEndTime) {
      const convert = (str: string) => {
        const [h, m] = str.split(":");
        return Number(h) * 2 + Number(m) / 30;
      };

      const newSelected = {
        selectedMin: convert(data.operatingStartTime),
        selectedMax: convert(data.operatingEndTime),
      };
      setOpenTime((v) => ({
        ...v,
        ...newSelected,
      }));
    }
  }, [data.operatingStartTime, data.operatingEndTime]);

  return (
    <>
      <EditStoreInfoWrapper scrollLock={false}>
        <div className="header">
          <div className="close" onClick={onClose}>
            <img src="/image-web/Icon/Close_gray.svg" alt="close" />
          </div>
          <div className="title">매장 정보</div>
        </div>
        <div className="inner">
          <div className="content">
            <RecruitStatusInfoWrapper>
              <div className="title">운영 시간</div>
              <div className="box">
                <div className="item-row">
                  {["월", "화", "수", "목", "금", "토", "일"].map(
                    (label, i) => {
                      return (
                        <div
                          key={i}
                          className={
                            "item-mini " +
                            (openWeekDays.includes(label) ? "selected" : "")
                          }
                          onClick={() => {
                            if (openWeekDays.includes(label)) {
                              setOpenWeekDays(
                                openWeekDays.filter((day) => day !== label)
                              );
                            } else {
                              setOpenWeekDays([...openWeekDays, label]);
                            }
                          }}
                        >
                          {label}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </RecruitStatusInfoWrapper>
            <RangeInfoWrapper>
              <div className="title">오픈 시간</div>
              <div className="box">
                <div className="price">
                  {convertNumberToTime(openTime.selectedMin)}
                </div>
                <div className="rc-slider-wrapper">
                  <div
                    style={{
                      marginTop: 16,
                      marginBottom: 16,
                    }}
                  />
                  <Slider
                    min={openTime.min}
                    max={openTime.max}
                    value={openTime.selectedMin}
                    onChange={(value) => {
                      console.log("onChange Min", value);
                      setOpenTime({
                        ...openTime,
                        selectedMin: Array.isArray(value) ? value[0] : value,
                      });
                    }}
                    trackStyle={{
                      backgroundColor: "#6436E7",
                      height: 4,
                    }}
                    handleStyle={{
                      borderWidth: 1.5,
                      borderColor: "#6436E7",
                      height: 26,
                      width: 26,
                      marginLeft: 0,
                      marginTop: -12,
                      backgroundColor: "#FFFFFF",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
                      opacity: 1,
                    }}
                    activeDotStyle={{
                      boxShadow: "none",
                    }}
                  />
                </div>
                <div className="range-row">
                  <div className="text">
                    {convertNumberToTime(openTime.min)}
                  </div>
                  <div className="text">
                    {convertNumberToTime(openTime.max)}
                  </div>
                </div>
              </div>
              <div className="title">마감 시간</div>
              <div className="box">
                <div className="price">
                  {convertNumberToTime(openTime.selectedMax)}
                </div>
                <div className="rc-slider-wrapper">
                  <div
                    style={{
                      marginTop: 16,
                      marginBottom: 16,
                    }}
                  />
                  <Slider
                    min={openTime.min}
                    max={openTime.max}
                    value={openTime.selectedMax}
                    onChange={(value) => {
                      console.log("onChange Max", value);
                      setOpenTime({
                        ...openTime,
                        selectedMax: Array.isArray(value) ? value[0] : value,
                      });
                    }}
                    trackStyle={{
                      backgroundColor: "#6436E7",
                      height: 4,
                    }}
                    handleStyle={{
                      borderWidth: 1.5,
                      borderColor: "#6436E7",
                      height: 26,
                      width: 26,
                      marginLeft: 0,
                      marginTop: -12,
                      backgroundColor: "#FFFFFF",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
                      opacity: 1,
                    }}
                    activeDotStyle={{
                      boxShadow: "none",
                    }}
                  />
                </div>
                <div className="range-row">
                  <div className="text">
                    {convertNumberToTime(openTime.min)}
                  </div>
                  <div className="text">
                    {convertNumberToTime(openTime.max)}
                  </div>
                </div>
              </div>
            </RangeInfoWrapper>
            <RecruitStatusInfoWrapper>
              <div className="title">게임 종류</div>
              <div className="box">
                <div className="item-row">
                  {GameTypeLabels.map(({ type, label }, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          "item " +
                          (selectedGameType === type ? "selected" : "")
                        }
                        onClick={() => setSelectedGameType(type)}
                      >
                        {label}
                      </div>
                    );
                  })}
                </div>
              </div>
            </RecruitStatusInfoWrapper>
            <RecruitStatusInfoWrapper>
              <div className="title">서비스 항목</div>
              <div className="service-list">
                {serviceList.map((item, index) => {
                  return (
                    <div
                      className={
                        "item " +
                        (selectedServiceList.includes(item.value)
                          ? "selected"
                          : "")
                      }
                      key={index}
                      onClick={() => {
                        if (selectedServiceList.includes(item.value)) {
                          setSelectedServiceList(
                            selectedServiceList.filter(
                              (service) => service !== item.value
                            )
                          );
                        } else {
                          setSelectedServiceList([
                            ...selectedServiceList,
                            item.value,
                          ]);
                        }
                      }}
                    >
                      <img src={item.img} />
                      <span>{item.title}</span>
                    </div>
                  );
                })}
              </div>
            </RecruitStatusInfoWrapper>
          </div>
        </div>
        <div className="floating-button-wrapper">
          <div className="button" onClick={handleUpdateStoreInfo}>
            수정하기
          </div>
        </div>
      </EditStoreInfoWrapper>
    </>
  );
};

export default EditStoreInfo;
