import React, { useState } from "react";
import styled from "styled-components";
import { openNewWindow } from "../../../utils/common";
import { IYoutubeChannel } from "../../../api/youtube";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";

const YoutubeWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  background: white;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  @media ${MEDIA_DESKTOP} {
    gap: 20px;
  }
  > .title-row {
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.4px;
    }
  }

  .channel-list {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four items per row */
    gap: 16px;
    padding: 0 16px;
    width: 100%;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: #ccc;
    position: relative;
    margin: 20px 0;
    text-align: center;

    .more-button {
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      background: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 4px 8px;
      cursor: pointer;
    }
  }
`;

const CardItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  > .thumbnail {
    width: 100%;
    max-width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  > .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .title-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
  }
`;

const YoutubeChannelList = ({ list }: { list: IYoutubeChannel[] }) => {
  const [visibleCount, setVisibleCount] = useState(4);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleShowMore = () => {
    if (isExpanded) {
      setVisibleCount(4);
    } else {
      setVisibleCount(list.length);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <YoutubeWrapper>
      <div className="title-row">
        <div className="title">홀덤 유튜브 채널</div>
      </div>
      <div className="channel-list">
        {list.slice(0, visibleCount).map((item) => (
          <CardItemWrapper
            key={item.id}
            onClick={() => openNewWindow(item.url)}
          >
            <div className="thumbnail">
              <img src={item.profileUrl} alt={item.name} />
            </div>
            <div className="info">
              <div className="title-row">
                <div className="title">{item.name}</div>
              </div>
            </div>
          </CardItemWrapper>
        ))}
      </div>
      <div className="divider">
        <button className="more-button" onClick={handleShowMore}>
          {isExpanded ? "접기" : "더보기"}
        </button>
      </div>
    </YoutubeWrapper>
  );
};

export default YoutubeChannelList;
