import styled from "styled-components";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useDialog from "../../../hooks/useDialog";
import { CommentInterface, HoldemNowType } from "../../../api/types";
import { useHistory, useParams } from "react-router-dom";
import {
  AddPubComment,
  AddUserComment,
  cafeViewMessage,
  DeleteComment,
  EditComment,
  GetCommentList,
  userViewMessage,
} from "../../../api/cafe";
import Write from "../HoldemNow/Write";
import { timeAgo } from "../../../utils/common";
import { enqueueSnackbar, useSnackbar } from "notistack";
import Loading from "../../../components/Loading";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import useScreenOrientation, {
  MEDIA_DESKTOP,
} from "../../../hooks/useScreenOrientation";
import useUserInfo from "../../../hooks/useUserInfo";
import moment from "moment";
import ImageViewer from "../../../components/ImageViewer";
import { LOCAL_STORAGE_ACCESS_KEY } from "../../../utils/network";
import {
  cancelrecommendationPost,
  recommendationPost,
} from "../../../api/holdemNowLike";

const DetailWrapper = styled.div<{
  scrollLock: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
  overscroll-behavior: none;
  z-index: 10;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${(p) =>
    p.scrollLock
      ? `
      overflow-y: hidden;
  `
      : `
  
  `} @media ${MEDIA_DESKTOP} {
    right: 0;
    padding: 0;
    height: 100%;
  }

  > .floating-button-wrapper {
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    padding: 12px 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-top: 1px solid var(--Black-100, #f0f0f0);
    background: #fff;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15);
    @media ${MEDIA_DESKTOP} {
      display: none;
    }

    > textarea {
      outline: none;
      border: none;
      resize: none;
      flex-grow: 1;
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      height: 20px;
      max-height: 100px;

      &::placeholder {
        color: var(--Black-300, #808080);
      }
    }

    > .button {
      cursor: pointer;
      width: 52px;
      height: 32px;
      color: var(--Purple-300, #6436e7);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.28px;
      border-radius: 6px;
      background: var(--Purple-100, #f0eaff);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    > .button:active {
      background: #502bb5;
    }
    .button.disable {
      cursor: not-allowed;
      color: var(--Gray-300, #b7b7b7);
      background: var(--Gray-100, #e0e0e0); /* 비활성 버튼의 배경색 설정 */
    }
  }

  > .header {
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 8px;
    z-index: 11;
    background: white;
    @media ${MEDIA_DESKTOP} {
      position: static;
      transform: none;
      margin-bottom: 12px;
      justify-content: space-between;
      inset: unset;
    }

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    > .title {
      color: ${(p) => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media ${MEDIA_DESKTOP} {
        display: none;
      }
    }

    > .button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
      color: var(--Black-200, #b7b7b7);
      text-align: right;
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.26px;
      @media ${MEDIA_DESKTOP} {
        position: static;
        inset: unset;
        transform: none;
      }
    }
  }

  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      border-radius: 12px;
      border: 1px solid var(--Black-100, #f0f0f0);
      background: #fff;
    }
  }
`;

const HoldemItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 16px;
  gap: 10px;
  background: white;
  @media ${MEDIA_DESKTOP} {
    padding: 24px 20px;
  }

  > .info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    > .thumbnail {
      width: 40px;
      height: 40px;
      background: gray;
      border-radius: 4px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;

      > .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        gap: 4px;

        > img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }

      > .createdAt {
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
      }
    }
  }

  > .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    > .content {
      width: 100%;
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
      white-space: pre-wrap;
    }

    > .images {
      width: 100%;
      border-radius: 8px;
      background: #e5e5e5;
      object-fit: cover;
    }
  }

  > .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    > .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;

      > img {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }
  }
`;
const HorizontalBar = styled.div`
  width: 100%;
  height: 8px;
  background: var(--Black-100, #f0f0f0);
  @media ${MEDIA_DESKTOP} {
    height: 1px;
    width: calc(100% - 40px);
    margin: 0 20px;
  }
`;
const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  padding-bottom: 60px;
  background: white;
  @media ${MEDIA_DESKTOP} {
    padding-bottom: 0;
  }
`;
const CommentItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 20px 16px;
  border-bottom: 1px solid var(--Black-100, #f0f0f0);
  @media ${MEDIA_DESKTOP} {
    border-bottom: none;
    padding: 20px 20px;
  }

  &:last-child {
    border-bottom: none;
  }

  > .profile-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    > .profile {
      display: none;
      @media ${MEDIA_DESKTOP} {
        display: block;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;

      > .author-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: flex-start;
        gap: 4px;

        > .author {
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.28px;
        }

        > .my-comment {
          width: 40px;
          height: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.24px;
          border-radius: 5px;
          background: var(--Black-100, #f0f0f0);
        }
      }
    }
  }

  > .content {
    margin-top: 12px;
    width: 100%;
    text-align: left;
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;

    > .edited {
      color: var(--Black-200, #b7b7b7);
    }
  }

  .createdAt {
    width: 100%;
    margin-top: 12px;
    text-align: left;
    color: var(--Black-300, #808080);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
  }

  .createdAt.pc {
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: block;
      margin-top: 0;
    }
  }

  .createdAt.mobile {
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  > .fix-row {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;

    > .button {
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      width: 53px;
      height: 26px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid var(--Black-200, #b7b7b7);
    }
  }
`;

const EmptyWrapper = styled.div`
  padding: 60px 0 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  @media ${MEDIA_DESKTOP} {
    display: none;
  }

  > .empty-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;

    > img {
      width: 180px;
      object-fit: contain;
    }

    > .empty-text {
      width: 100%;
      color: var(--Black-300, #808080);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

const PCCommentWriteWrapper = styled.div`
  display: none;
  @media ${MEDIA_DESKTOP} {
    width: calc(100% - 40px);
    padding: 16px;
    margin: 20px;
    border-radius: 12px;
    background: var(--Black-100, #f0f0f0);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 8px;
    > .edit-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: 16px;
      width: 100%;

      > .profile {
        width: 42px;
        height: 42px;
        object-fit: cover;
        border-radius: 50%;
        flex-shrink: 0;
      }

      > textarea {
        margin-top: 10px;
        height: 20px;
        resize: none;
        background: transparent;
        border: none;
        outline: none;
        flex-grow: 1;
        color: var(--Black-500, #202020);
        text-align: justify;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.28px;

        &::placeholder {
          color: var(--Black-300, #808080);
        }
      }
    }

    > .button {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 32px;
      color: var(--Black-200, #b7b7b7);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
`;

interface DetailPageProps {
  onClose?: () => void;
}

const DetailPage = ({ onClose }: DetailPageProps) => {
  const orientation = useScreenOrientation();
  const { user } = useUserInfo();
  const { openDialog } = useDialog();
  const setLoading = useSetRecoilState(loadingState);
  const history = useHistory();
  const inputRefMobile = useRef<HTMLTextAreaElement | null>(null);
  const inputRefPC = useRef<HTMLTextAreaElement | null>(null);
  const [showEdit, setShowEdit] = useState(false);
  const param = useParams();
  const detailId = (param as any).id;
  const messageType = (param as any).messageType;
  const [commentList, setCommentList] = useState<CommentInterface[]>([]);
  const [data, setData] = useState<HoldemNowType | null>(null);
  const [refreshCommentList, setRefreshCommentList] = useState(
    new Date().getTime()
  );
  const [selectedEditCommentId, setSelectedEditCommentID] = useState<
    number | null
  >(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY);
  const [comment, setComment] = useState<string>("");
  const [commentCount, setCommentCount] = useState<number>(0);

  const [recommendation, setRecommendation] = useState(false);
  const [recommedationCount, setRecommendationCount] = useState(0);

  useEffect(() => {
    if (detailId && messageType) {
      setLoading(true);
      if (messageType === "PUB") {
        cafeViewMessage({ messageId: detailId })
          .then((res) => {
            setData(res);
            setRecommendation(res.recommended);
            setRecommendationCount(res.recommendationCount);
          })
          .catch((err) => {
            if (err.code === 9001) {
              enqueueSnackbar("해당 게시글이 존재하지 않습니다.", {
                variant: "error",
              });
              history.replace("/holdemNow");
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (messageType === "USER") {
        userViewMessage({ messageId: detailId })
          .then((res) => {
            setRecommendation(res.recommended);
            setRecommendationCount(res.recommendationCount);
            setData(res);
          })
          .catch((err) => {
            if (err.code === 9001) {
              enqueueSnackbar("해당 게시글이 존재하지 않습니다.", {
                variant: "error",
              });
              history.replace("/holdemNow");
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [detailId, messageType, showEdit]);
  useEffect(() => {
    if (!data) return;
    GetCommentList({
      messageId: detailId,
      messageType: data.messageType,
    }).then((res) => {
      setCommentList(res);
      setCommentCount(res.length);
    });
  }, [data, refreshCommentList]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      if (history.action === "PUSH") {
        history.goBack();
      } else {
        history.replace("/holdemNow");
      }
    }
  };
  const handleEditContent = () => {
    setShowEdit(true);
  };
  const handleAddComment = useCallback(() => {
    let inputRef: RefObject<HTMLTextAreaElement>;
    if (orientation === "landscape") {
      inputRef = inputRefPC;
    } else {
      inputRef = inputRefMobile;
    }
    if (inputRef && inputRef.current && inputRef.current.value === "") return;
    if (data) {
      if (selectedEditCommentId) {
        openDialog({
          text: "해당 댓글을 수정하시겠습니까?",
          confirm: true,
          confirmText: "수정하기",
          type: "web",
          onConfirm: () => {
            if (inputRef && inputRef.current) {
              setLoading(true);
              const message = inputRef.current.value;
              EditComment({
                message,
                commentId: selectedEditCommentId,
              })
                .then(() => {
                  enqueueSnackbar("댓글이 수정되었습니다.", {
                    variant: "success",
                  });
                  setComment("");
                  setRefreshCommentList(new Date().getTime());
                  setSelectedEditCommentID(null);

                  if (inputRef.current) {
                    inputRef.current.value = "";
                  }
                })
                .catch((e: any) => {
                  enqueueSnackbar(e.message, { variant: "error" });
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          },
        });
      } else {
        if (!accessToken) {
          enqueueSnackbar("로그인이 필요한 서비스입니다.", {
            variant: "error",
          });
          history.push("/login");
          return;
        }
        openDialog({
          text: "댓글을 등록하시겠습니까?",
          confirm: true,
          confirmText: "등록하기",
          type: "web",
          onConfirm: () => {
            if (inputRef && inputRef.current) {
              setLoading(true);
              const message = inputRef.current.value;
              console.log("등록하기", inputRef, message);
              const AddComment =
                messageType === "USER" ? AddUserComment : AddPubComment;
              AddComment({
                message,
                messageId: Number(detailId),
                latitude: 0,
                longitude: 0,
              })
                .then(() => {
                  enqueueSnackbar("댓글이 등록되었습니다.", {
                    variant: "success",
                  });
                  setRefreshCommentList(new Date().getTime());
                  setCommentCount(commentCount + 1);
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          },
        });
      }
    }
  }, [
    data,
    inputRefPC,
    inputRefMobile,
    selectedEditCommentId,
    orientation,
    setCommentCount,
  ]);
  const recommendPost = async () => {
    if (!accessToken) {
      history.push("/login");
      enqueueSnackbar("로그인이 필요한 서비스입니다.", {
        variant: "error",
      });
      return;
    }

    if (recommendation) {
      try {
        const result = await cancelrecommendationPost(detailId);
        if (result.code === 200) {
          setRecommendation(false);
          setRecommendationCount(recommedationCount - 1);
        }
      } catch (e) {}
    } else {
      try {
        const result = await recommendationPost(detailId);
        if (result.code === 200) {
          setRecommendation(true);
          setRecommendationCount(recommedationCount + 1);
        }
      } catch (e) {}
    }
  };

  return (
    <>
      {showEdit && (
        <Write
          mode={"edit"}
          messageType={messageType}
          messageId={detailId}
          onClose={() => setShowEdit(false)}
        />
      )}
      {selectedImageIndex !== -1 && (
        <ImageViewer
          images={data?.imageList || []}
          defaultIndex={selectedImageIndex}
          onClose={() => setSelectedImageIndex(-1)}
        />
      )}
      {data ? (
        <DetailWrapper scrollLock={showEdit}>
          <div className="header">
            <div className="close" onClick={handleClose}>
              <img src="/image-web/Icon/Back.svg" alt="close" />
            </div>
            <span className="title">홀덤 NOW</span>
            {data.myMessage && (
              <div className="button" onClick={handleEditContent}>
                글 수정
              </div>
            )}
          </div>
          <div className="inner">
            <HoldemItem>
              <div
                className="info-wrapper"
                onClick={() => {
                  if (data?.messageType === "PUB") {
                    history.push("/store/" + data?.pubId);
                  }
                }}
              >
                <div className="thumbnail">
                  {data.coverImageUrl ? (
                    <img src={data.coverImageUrl} />
                  ) : data.messageType === "PUB" ? (
                    <img src="https://dfesoodpx4jgd.cloudfront.net/cafe/holdem_default.png" />
                  ) : (
                    <img src="https://dfesoodpx4jgd.cloudfront.net/user/default_profile.png" />
                  )}
                </div>
                <div className="info">
                  <div className="title">
                    {data.pubName}
                    {data.messageType === "PUB" &&
                      data.pubType === "PREMIUM" && (
                        <img src="/image-web/Holdem%20Now/Icon/star.png" />
                      )}
                    {data.messageType === "PUB" &&
                      data.pubType === "AFFILIATE" && (
                        <img src="/image-web/store/store_check.svg" />
                      )}
                    {data.messageType === "PUB" &&
                      data.pubType === "NORMAL" && (
                        <img src="/image-web/store/store_normal.png" />
                      )}
                  </div>
                  <div className="createdAt">
                    {moment(data.createdAt).fromNow()}
                  </div>
                </div>
              </div>
              <div className="content-wrapper">
                {data.imageList.map((url, i) => (
                  <img
                    className="images"
                    src={url}
                    onClick={() => setSelectedImageIndex(i)}
                  />
                ))}
                <div className="content">{data.message}</div>
              </div>
              <div className="bottom">
                <div
                  className="item"
                  id="종아요"
                  onClick={() => {
                    recommendPost();
                  }}
                >
                  {recommendation ? (
                    <img src="/image-web/Icon/Heart/small.svg" alt="좋아요" />
                  ) : (
                    <img
                      src="/image-web/Icon/Heart/small-gray.svg"
                      alt="좋아요"
                    />
                  )}

                  <span>{recommedationCount}</span>
                </div>
                <div className="item">·</div>
                <div className="item">
                  <img src="/image-web/Holdem%20Now/Icon/view/small.svg" />
                  <span>{data.viewCount.toLocaleString()}</span>
                </div>
                <div className="item">·</div>
                <div className="item">
                  <img src="/image-web/Holdem%20Now/Icon/comment/small.svg" />
                  <span>{commentCount.toLocaleString()}</span>
                </div>
              </div>
            </HoldemItem>
            <HorizontalBar />
            {commentList.length === 0 ? (
              <EmptyWrapper>
                <div className="empty-wrapper">
                  <img src="/image-web/None.png" />
                  <div className="empty-text">
                    아직 댓글이 없습니다.
                    <br />첫 댓글을 남겨보세요!
                  </div>
                </div>
              </EmptyWrapper>
            ) : (
              <CommentWrapper>
                {commentList.map((commentItem, index) => {
                  const handleDelete = () => {
                    openDialog({
                      type: "web",
                      text: "해당 댓글을 삭제하시겠습니까?",
                      onConfirm: () => {
                        setLoading(true);
                        DeleteComment({ commentId: commentItem.id })
                          .then(() => {
                            enqueueSnackbar("댓글이 삭제되었습니다.", {
                              variant: "success",
                            });
                            GetCommentList({
                              messageId: detailId,
                              messageType: data.messageType,
                            }).then((res) => {
                              setCommentList(res);
                              setCommentCount(res.length);
                            });
                          })
                          .finally(() => {
                            setLoading(false);
                          });
                      },
                      confirm: true,
                      confirmColor: "#D91818",
                    });
                  };

                  const handleChange = () => {
                    let inputRef;
                    if (orientation === "landscape") {
                      inputRef = inputRefPC;
                    } else {
                      inputRef = inputRefMobile;
                    }
                    if (inputRef && inputRef.current) {
                      if (selectedEditCommentId === commentItem.id) {
                        enqueueSnackbar("댓글 등록 모드로 전환합니다.", {
                          variant: "info",
                        });
                        setSelectedEditCommentID(null);
                        setComment("");
                      } else {
                        enqueueSnackbar("댓글 수정 모드로 전환합니다.", {
                          variant: "info",
                        });
                        inputRef.current.focus();
                        setComment(commentItem.message);
                        setSelectedEditCommentID(commentItem.id);
                      }
                    }
                  };

                  return (
                    <CommentItemWrapper key={index}>
                      <div className="profile-wrapper">
                        <img
                          className="profile"
                          src={
                            commentItem?.profileImageUrl ||
                            "/image-web/default_profile.png"
                          }
                          alt="profile"
                        />
                        <div className="info">
                          <div className="author-row">
                            <span className="author">
                              {commentItem.nickName}
                            </span>
                            {commentItem.myComment && (
                              <div className="my-comment">내 댓글</div>
                            )}
                          </div>
                          <div className="createdAt pc">
                            {timeAgo(commentItem.createdAt)}
                          </div>
                        </div>
                      </div>
                      <div className="content">
                        {commentItem.message}{" "}
                        {commentItem.modified && (
                          <span className="edited">(edited)</span>
                        )}
                      </div>
                      <div className="createdAt mobile">
                        {timeAgo(commentItem.createdAt)}
                      </div>
                      {commentItem.myComment && !commentItem.exit && (
                        <div className="fix-row">
                          <div className="button" onClick={handleChange}>
                            {selectedEditCommentId !== null &&
                            selectedEditCommentId === commentItem.id
                              ? "취소"
                              : "수정"}
                          </div>
                          <div className="button" onClick={handleDelete}>
                            삭제
                          </div>
                        </div>
                      )}
                    </CommentItemWrapper>
                  );
                })}
              </CommentWrapper>
            )}
            <PCCommentWriteWrapper>
              <div className="edit-wrapper">
                <img
                  className="profile"
                  src={
                    user?.profileImageUrl || "/image-web/default_profile.png"
                  }
                  alt="profile"
                />
                <textarea
                  ref={inputRefPC}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="댓글을 남겨주세요"
                  onInput={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    target.style.height = "1px";
                    target.style.height = target.scrollHeight + "px";
                  }}
                />
              </div>
              <div className="button" onClick={handleAddComment}>
                {selectedEditCommentId ? "수정" : "등록"}
              </div>
            </PCCommentWriteWrapper>
          </div>
          <div className="floating-button-wrapper">
            <textarea
              ref={inputRefMobile}
              placeholder="댓글을 남겨주세요"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = "1px";
                target.style.height = target.scrollHeight + "px";
              }}
            />
            <div
              className={`button ${comment === "" ? "disable" : ""}`}
              onClick={handleAddComment}
            >
              {selectedEditCommentId ? "수정" : "등록"}
            </div>
          </div>
        </DetailWrapper>
      ) : (
        <Loading full />
      )}
    </>
  );
};

export default DetailPage;
