import instance from "../utils/network";
import {Cafe, HoldemNowType} from "./types";
import {RunnerEvent} from "./event";

export interface DashboardPopup {
  id: number;
  title: string;
  startAt: string;
  endAt: string;
  displayOrder: number;
  webImage: string;
  appImage: string;
  webUrl: string;
  appUrl: string
}

export interface DashboardCity {
  province: {
    id: number;
    code: number;
    name: string;
    centerLat: number;
    centerLon: number;
    minLat: number;
    minLon: number;
    maxLat: number;
    maxLon: number;
  },
  city: {
    id: number;
    code: number;
    name: string;
    provinceId: number,
    centerLat: number;
    centerLon: number;
    minLat: number;
    minLon: number;
    maxLat: number;
    maxLon: number;
  },
  street: {
    id: number;
    code: number;
    name: string;
    cityId: number;
    centerLat: number;
    centerLon: number;
  }
}

export interface DashboardDailyTournament {
  id: number;
  groupId: number;
  imageUrl: string;
  title: string;
  description: string;
  startAt: string;
  endAt: string | null;
  tournamentGroup: string;
  isOpen: boolean;
}


export interface DashboardMajorTournament {
  id: number;
  image: string;
  title: string;
  description: string | null;
  lat: null;
  lon: null;
  prize: number;
  prizeUnit: string;
  place: string;
  phone: string | null;
  event: any,
  webUrl: string;
  startAt: string;
  endAt: string;
}

export interface DashBoardYoutube {
  id: number;
  url: string;
  ord: number;
  thumbnailUrl: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  title: string;
}


export interface DashboardResponse {
  popup: DashboardPopup[],
  city: DashboardCity,
  recommend: Cafe[],
  premium: Cafe[],
  majorTournament: DashboardMajorTournament[]
  tournament: DashboardDailyTournament[],
  youtube: DashBoardYoutube[],
  roulette: number,
  holdemNow: HoldemNowType[] | null,
  message: HoldemNowType[] | null,
  around: Cafe[],
  event: Cafe[]
  runnerEventBanner: RunnerEvent[]
}

export function dashboard(
  params: {
    lat: number;
    lon: number;
  }
): Promise<DashboardResponse> {
  return instance.get('/dashboard', {params})
}