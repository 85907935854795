import styled from "styled-components";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import React, {MouseEvent, MouseEventHandler, useCallback} from "react";

const Wrapper = styled.div<{
  transform?: string
  transition?: string
  isDragging?: boolean
  overlay?: boolean
}>`
  width: 100%;
  height: fit-content;
  aspect-ratio: 162/120;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
  position: relative;
  overflow: hidden;
  transform: ${p => p.transform || 'none'};
  transition: ${p => p.transition || 'none'};

  ${p => p.isDragging && `
    background: rgba(0, 0, 0, 0.05);
    border: 1px dashed rgba(0, 0, 0, 0.2);
    > * {
      opacity: 0;
    }
  `};

  ${p => p.overlay && `
      opacity: 0.7;
  `};

  > .delete {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
  }
`;

interface PhotoItemProps {
  id: number,
  src: string,
  overlay?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
  onClickDelete?: MouseEventHandler<HTMLImageElement>
}

function PhotoItem(
  {
    id,
    src,
    overlay,
    onClick,
    onClickDelete,
  }: PhotoItemProps
) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({id: id});

  const handleClickDelete = useCallback((e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    onClickDelete && onClickDelete(e);
  }, [onClickDelete]);

  return (
    <Wrapper
      ref={setNodeRef}
      transform={CSS.Transform.toString(transform)}
      transition={transition}
      isDragging={isDragging}
      overlay={overlay}
      onClick={onClick}
      {...attributes}
      {...listeners}>
      <img src={src}/>
      {
        onClickDelete && (
          <img className='delete' src='/image-web/store/Minus.svg' onClick={handleClickDelete}/>
        )
      }
    </Wrapper>
  )
}

export default PhotoItem;