import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import useDialog from "../../../hooks/useDialog";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import useUserInfo from "../../../hooks/useUserInfo";
import { getUserTicketInfo, UserTicketInfoInterface } from "../../../api/game";

import { getFriendRecommendCount } from "../../../api/referral";
import { updateProfileImage } from "../../../api/auth";
import { enqueueSnackbar } from "notistack";
import {
  clearCredential,
  LOCAL_STORAGE_ACCESS_KEY,
} from "../../../utils/network";
import ModifyProfile from "./ModifyProfile";
import RPHistory from "./RPHistory";
import Notice from "./Notice";
import FAQ from "./FAQ";
import Referral from "./Referral";
import ManagePub from "../ManagePub";
import ManageRecruit from "../ManageRecruit";
import PCModifyPassword from "./PCModifyPassword";
import { GetUserPoint, TestAddUserPoint } from "../../../api/cafe";
import { copyClipboard } from "../../../utils/common";
import PremiumRegister from "./PremiumRegister";
import useQueryParams from "../../../hooks/useQueryParams";
import MarketNotice from "./MarketNotice";
import { openNewWindow } from "../../../utils/common";

const PCMyPageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 0;
  background: var(--Black-100, #f0f0f0);

  > .inner {
    width: 100%;
    max-width: 1060px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;

    > .mypage-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-items: flex-start;
      gap: 12px;

      > .profile-wrapper {
        width: 100%;
        padding: 20px;
        border-radius: 12px;
        background: #fff;

        > .profile-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;

          > .profile {
            width: 84px;
            height: 84px;
            border-radius: 50%;
            background: gray;
            position: relative;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }

            .edit {
              position: absolute;
              width: 24px;
              height: 24px;
              background-image: url(/image-web/Icon/Edit-Profile-Img.svg);
              bottom: 0;
              right: 0;
            }
          }

          > .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            > .tag-row {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              gap: 4px;

              > .tag {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 2px;
                border-radius: 2px;
                padding: 4px;
                border: 1px solid ${(p) => p.theme.color.purple100};

                > img {
                  width: 16px;
                }

                color: ${(p) => p.theme.color.purple300};
                text-align: right;
                font-family: Pretendard;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.24px;
              }
            }

            > .nickname {
              margin-top: 8px;
              color: ${(p) => p.theme.color.black400};
              font-family: Pretendard;
              font-size: 20px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              letter-spacing: -0.4px;
            }
          }
        }

        > .info-row {
          margin-top: 20px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 16px 8px;
          border-radius: 8px;
          border: 1px solid var(--Black-100, #f0f0f0);

          > .item {
            width: 72px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;

            > .title {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              color: var(--Black-400, #444);
              font-family: Pretendard;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              > img {
                cursor: pointer;
                width: 16px;
                height: 16px;
                object-fit: contain;
              }
            }

            > .value {
              color: var(--Black-400, #444);
              font-family: Pretendard;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }

      > .menu-wrapper {
        padding: 24px 20px;
        width: 100%;
        border-radius: 12px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
        z-index: 1;

        .menu-row {
          cursor: pointer;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          > .title {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            color: var(--Black-400, #444);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.32px;
            gap: 12px;

            > img {
              width: 24px;
              height: 24px;
            }
          }

          > .arrow {
            flex-shrink: 0;
            width: 24px;
            height: 24px;
          }
        }

        .menu-row.active {
          > .title {
            color: var(--Purple-300, #6436e7);
          }
        }

        > .menu-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 24px;

          > .title {
            color: var(--Black-300, #808080);
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        > .horizontal-line {
          width: 100%;
          height: 1px;
          background: var(--Black-100, #f0f0f0);
        }
      }
    }

    > .content {
      width: 672px;
      padding: 24px 20px 20px;
      border-radius: 12px;
      background: #fff;
    }
  }
`;
type MenuType =
  | "profile"
  | "password"
  | "point"
  | "notice"
  | "market-notice"
  | "faq"
  | "referral"
  | "manage-pub"
  | "manage-recruit"
  | null;
const PCMyPage = () => {
  const setLoading = useSetRecoilState(loadingState);
  const { openDialog } = useDialog();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState<MenuType>("profile");
  const { user, setUser, refreshUser } = useUserInfo(true);
  const [userTicketInfo, setUserTicketInfo] =
    useState<UserTicketInfoInterface | null>(null);
  const [isPremiumRegisterOpen, setIsPremiumRegisterOpen] = useState(false);

  const [friendRecommendCount, setFriendRecommendCount] = useState<number>(0);
  const [myPoint, setMyPoint] = useState(0);
  const query = useQueryParams();
  useEffect(() => {
    const menu = query.get("menu");
    if (menu) {
      setShowMenu(menu);
    }
  }, []);
  useEffect(() => {
    if (user) {
      getUserTicketInfo({
        userId: user.id,
      })
        .then((res) => {
          if (Object.keys(res.data).length === 0) {
            setUserTicketInfo(null);
            return;
          } else {
            setUserTicketInfo(res.data);
          }
        })
        .catch((e: any) => {});
      GetUserPoint().then((res) => {
        setMyPoint(res.points);
      });
      setLoading(true);
      getFriendRecommendCount()
        .then((res) => {
          setFriendRecommendCount(res.count);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user?.id]);

  // 프로필 이미지 수정
  const handleClickImage = useCallback(() => {
    if (!user) {
      return;
    }

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = () => {
      if (!input.files || !input.files[0]) {
        return;
      }

      const file = input.files[0];
      updateProfileImage(file, { id: user.id })
        .then(() => {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const dataUri = reader.result;
            if (typeof dataUri !== "string") {
              return;
            }

            await refreshUser();
            enqueueSnackbar("프로필 사진이 변경되었습니다.", {
              variant: "success",
            });
          };
          reader.readAsDataURL(file);
        })
        .catch((e: any) => {
          enqueueSnackbar("프로필 사진을 변경하지 못했습니다: " + e.message, {
            variant: "error",
          });
        })
        .finally(() => {
          input.remove();
        });
    };
    input.click();
  }, [user]);

  const handleLogout = useCallback(() => {
    clearCredential();
    setUser(undefined);
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_KEY);
    history.replace("/logout");
  }, []);

  return (
    <PCMyPageWrapper>
      <div className="inner">
        <div className="mypage-wrapper">
          <div className="profile-wrapper">
            <div className="profile-row">
              <div className="profile" onClick={handleClickImage}>
                {user?.profileImageUrl ? (
                  <img src={user.profileImageUrl} />
                ) : (
                  <img src="https://dfesoodpx4jgd.cloudfront.net/user/default_profile.png" />
                )}
                <div className="edit" />
              </div>
              <div className="info">
                <div className="tag-row">
                  {user?.validate && (
                    <div className="tag">
                      <img src="/image-web/Icon/Certified/small.svg" />
                      <span>본인 인증 완료</span>
                    </div>
                  )}
                  {user?.role === "ROLE_SELLER" && (
                    <div className="tag">
                      <img src="/image-web/Icon/manager/small.svg" />
                      <span>홀덤펍 관리자</span>
                    </div>
                  )}
                </div>
                <div className="nickname">{user?.nickname}</div>
              </div>
            </div>
            <div className="info-row">
              <div className="item">
                <div className="title">리바인권</div>
                <div className="value">
                  {userTicketInfo
                    ? userTicketInfo.user.rebuyinTicket.toLocaleString()
                    : "-"}
                </div>
              </div>
              <div className="item">
                <div className="title">게임포인트</div>
                <div className="value">
                  {userTicketInfo
                    ? userTicketInfo.user.point.toLocaleString()
                    : "-"}
                </div>
              </div>
              <div className="item">
                <div className="title">친구 추천 횟수</div>
                <div className="value">{friendRecommendCount}</div>
              </div>
            </div>
          </div>
          <div className="menu-wrapper">
            <div className="menu-list">
              <div
                className={
                  "menu-row " + (showMenu === "profile" ? "active" : "")
                }
                onClick={() => {
                  setShowMenu("profile");
                }}
              >
                <div className="title">
                  <img
                    className="icon"
                    src={
                      showMenu === "profile"
                        ? "/image-web/Icon/Profile_selected.svg"
                        : "/image-web/Icon/Profile.svg"
                    }
                  />
                  <span>프로필 수정</span>
                </div>
              </div>
              <div
                className={
                  "menu-row " + (showMenu === "password" ? "active" : "")
                }
                onClick={() => {
                  setShowMenu("password");
                }}
              >
                <div className="title">
                  <img
                    className="icon"
                    src={
                      showMenu === "password"
                        ? "/image-web/Icon/Password_selected.svg"
                        : "/image-web/Icon/Password.svg"
                    }
                  />
                  <span>비밀번호 변경</span>
                </div>
              </div>

              <div
                className={"menu-row " + (showMenu === "point" ? "active" : "")}
                onClick={() => {
                  copyClipboard(
                    window.location.protocol +
                      "//" +
                      window.location.host +
                      "/signup?referral=" +
                      user?.nickname
                  );
                }}
              >
                <div className="title">
                  <img
                    className="icon"
                    src="/image-web/Icon/Add_Friends_black.svg"
                  />
                  <span>초대 링크 복사</span>
                </div>
              </div>
              {(window.location.href.includes("test") ||
                window.location.href.includes("localhost")) && (
                <div
                  className={"menu-row"}
                  onClick={() => {
                    TestAddUserPoint().then((res) => {
                      alert(
                        "테스트 RP 5만원이 충전되었습니다. 새로고침합니다."
                      );
                      window.location.reload();
                    });
                  }}
                >
                  <div className="title">
                    <img
                      className="icon"
                      src={
                        showMenu === "point"
                          ? "/image-web/Icon/Point_selected.svg"
                          : "/image-web/Icon/Point.svg"
                      }
                    />
                    <span>5만 RP 충전(테스트)</span>
                  </div>
                </div>
              )}

              {user?.role === "ROLE_SELLER" && (
                <>
                  <div
                    className={
                      "menu-row " + (showMenu === "manage-pub" ? "active" : "")
                    }
                    onClick={() => {
                      setShowMenu("manage-pub");
                    }}
                  >
                    <div className="title">
                      <img
                        className="icon"
                        src={
                          showMenu === "manage-pub"
                            ? "/image-web/Icon/Manage_selected.svg"
                            : "/image-web/Icon/Manage.svg"
                        }
                      />
                      <span>내 홀덤펍 관리</span>
                    </div>
                  </div>
                  <div
                    className={
                      "menu-row " +
                      (showMenu === "manage-recruit" ? "active" : "")
                    }
                    onClick={() => {
                      setShowMenu("manage-recruit");
                    }}
                  >
                    <div className="title">
                      <img
                        className="icon"
                        src={
                          showMenu === "manage-recruit"
                            ? "/image-web/Icon/Manage%20hiring_selected.svg"
                            : "/image-web/Icon/Manage%20hiring.svg"
                        }
                      />
                      <span>내 채용 관리</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="horizontal-line" />
            <div className="menu-list">
              <div className="title">고객센터</div>
              <div
                className={
                  "menu-row " + (showMenu === "notice" ? "active" : "")
                }
                onClick={() => {
                  setShowMenu("notice");
                }}
              >
                <div className="title">
                  <img
                    className="icon"
                    src={
                      showMenu === "notice"
                        ? "/image-web/mypage/Megaphone_selected.svg"
                        : "/image-web/mypage/Megaphone.svg"
                    }
                  />
                  <span>공지사항</span>
                </div>
              </div>
              <div
                className={
                  "menu-row " + (showMenu === "market-notice" ? "active" : "")
                }
                onClick={() => {
                  setShowMenu("market-notice");
                }}
              >
                <div className="title">
                  <img
                    className="icon"
                    src={
                      showMenu === "market-notice"
                        ? "/image-web/Icon/market.svg"
                        : "/image-web/Icon/market-gray.svg"
                    }
                  />
                  <span>장터 공지사항</span>
                </div>
              </div>
              <div
                className={"menu-row " + (showMenu === "faq" ? "active" : "")}
                onClick={() => {
                  setShowMenu("faq");
                }}
              >
                <div className="title">
                  <img
                    className="icon"
                    src={
                      showMenu === "faq"
                        ? "/image-web/mypage/Question_selected.svg"
                        : "/image-web/mypage/Question.svg"
                    }
                  />
                  <span>자주 묻는 질문</span>
                </div>
              </div>
              <div
                className={
                  "menu-row " + (showMenu === "referral" ? "active" : "")
                }
                onClick={() => {
                  setShowMenu("referral");
                }}
              >
                <div className="title">
                  <img
                    className="icon"
                    src={
                      showMenu === "referral"
                        ? "/image-web/mypage/Add%20Friends_selected.svg"
                        : "/image-web/mypage/Add%20Friends.svg"
                    }
                  />
                  <span>친구 추천</span>
                </div>
              </div>
              <div
                className="menu-row"
                onClick={() => {
                  openNewWindow("https://pf.kakao.com/_BxbmbG");
                }}
              >
                <div className="title">
                  <img className="icon" src="/image-web/mypage/kakao.svg" />
                  <span>러너러너 오픈채팅</span>
                </div>
              </div>
              <div
                className="menu-row"
                onClick={() => {
                  setIsPremiumRegisterOpen(true);
                }}
              >
                <div className="title">
                  <img className="icon" src="/image-web/store/premium.png" />
                  <span>프리미엄 홀덤펍 등록 문의</span>
                </div>
              </div>
              <div
                className="menu-row"
                onClick={() => {
                  openNewWindow("https://t.me/RunnerRunner0");
                }}
              >
                <div className="title">
                  <img className="icon" src="/image-web/mypage/Telegram.svg" />
                  <span>텔레그램 상담</span>
                </div>
              </div>
            </div>
            <div className="horizontal-line" />
            <div
              className="menu-row"
              onClick={() => {
                openDialog({
                  type: "web",
                  text: "로그아웃을<br/>진행하시겠습니까?",
                  onConfirm: handleLogout,
                  confirmText: "로그아웃",
                  confirm: true,
                  confirmColor: "#D91818",
                });
              }}
            >
              <div className="title">
                <span>로그아웃</span>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          {showMenu === "profile" && (
            <ModifyProfile onClose={() => setShowMenu(null)} />
          )}
          {showMenu === "password" && <PCModifyPassword />}
          {showMenu === "point" && (
            <RPHistory onClose={() => setShowMenu(null)} />
          )}
          {showMenu === "notice" && (
            <Notice onClose={() => setShowMenu(null)} />
          )}
          {showMenu === "market-notice" && (
            <MarketNotice onClose={() => setShowMenu(null)} />
          )}
          {showMenu === "faq" && <FAQ onClose={() => setShowMenu(null)} />}
          {showMenu === "referral" && (
            <Referral onClose={() => setShowMenu(null)} />
          )}
          {showMenu === "manage-pub" && (
            <ManagePub onClose={() => setShowMenu(null)} />
          )}
          {showMenu === "manage-recruit" && (
            <ManageRecruit onClose={() => setShowMenu(null)} />
          )}
        </div>
      </div>
      {isPremiumRegisterOpen && (
        <PremiumRegister onClose={() => setIsPremiumRegisterOpen(false)} />
      )}
    </PCMyPageWrapper>
  );
};

export default PCMyPage;
