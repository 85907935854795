import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import PhotoItem from "./PhotoItem";
import { useCallback, useMemo, useState } from "react";
import { PhotoData } from "../PhotoInfo";
import useDialog from "../../../../hooks/useDialog";

export interface PhotoDndProps {
  images: PhotoData[];
  enabled?: boolean;
  limit?: number;
  onOrderChange: (newImages: PhotoData[]) => void;
  onClickItem?: (index: number) => void;
  onClickDelete?: (index: number) => void;
}

function PhotoDnd({
  images,
  limit,
  enabled,
  onOrderChange,
  onClickItem,
  onClickDelete,
}: PhotoDndProps) {
  const { openDialog } = useDialog();
  const [activeItem, setActiveItem] = useState<PhotoData>();
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 5,
      },
    })
  );

  const limitedImages = useMemo(() => {
    return limit ? images.slice(0, limit) : images;
  }, [limit, images]);

  const handleDragStart = useCallback(
    (e: DragStartEvent) => {
      const item = images.find((x) => x.id === e.active.id);
      setActiveItem(item);
    },
    [images]
  );

  const handleDragEnd = useCallback(
    (e: DragEndEvent) => {
      setActiveItem(undefined);

      const { active, over } = e;
      if (over && active.id !== over.id) {
        const oldIndex = images.findIndex((image) => image.id == active.id);
        const newIndex = images.findIndex((image) => image.id == over.id);
        const newImages = arrayMove(images, oldIndex, newIndex).map(
          (item, i) => {
            return {
              ...item,
              orderIndex: i,
            };
          }
        );
        onOrderChange(newImages);
      }
    },
    [images]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext items={limitedImages} disabled={!enabled}>
        {limitedImages.map((data, i) => (
          <PhotoItem
            key={i}
            id={data.id}
            src={data.photoUrl}
            onClick={() => onClickItem && onClickItem(i)}
            onClickDelete={
              onClickDelete && data.deletable
                ? () => {
                    openDialog({
                      title: "이미지 삭제",
                      text: "이미지를 삭제하시겠습니까?",
                      confirm: true,
                      type: "web",
                      confirmText: "예",
                      cancelText: "아니오",
                      onConfirm: () => {
                        onClickDelete(data.id);
                      },
                    });
                  }
                : undefined
            }
          />
        ))}
        <DragOverlay>
          {activeItem && (
            <PhotoItem id={activeItem.id} src={activeItem.photoUrl} overlay />
          )}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
}

export default PhotoDnd;
