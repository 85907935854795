import { useOddsCalc } from "../../../hooks/useOddsCalc";
import { CARD_INFO } from "../../../dataset";
import {
  PageWrapper,
  Header,
  PlayersContainer,
  ButtonContainer,
  CardBox,
  CommunityBoard,
  CommunityCards,
  FlopCards,
  TurnRiverCards,
  CardGroup,
  CardItem,
  CardWrapper,
  Overlay,
  PlayerWrapper,
  PlayerInfo,
  PlayerHand,
  WinRateInfo,
  WinRate,
  DrawRate,
  RankBox,
  RankItem,
  RankName,
  RankValue,
  Button,
  BackButton,
} from "./styles";
interface CardProps {
  card: string;
  index: number;
  onClick: (index: number) => void;
  selected: boolean;
  src: string; // Add src prop for the image
}

const Card: React.FC<CardProps> = ({ card, index, onClick, selected, src }) => {
  return (
    <CardBox onClick={() => onClick(index)} selected={selected}>
      <img src={src} />
    </CardBox>
  );
};

const OddsCalc = () => {
  const {
    winningRates,
    flop,
    turn,
    river,
    isCardSelect,
    selectMode,
    selectedCards,
    handleAddPlayer,
    handleCardClick,
    selectCardForPlayer,
    selectCardForFlop,
    selectCardForTurn,
    selectCardForRiver,
    clear,
    handleOverlayClick,
    handleClose,
  } = useOddsCalc();

  return (
    <PageWrapper>
      <BackButton onClick={handleClose}>
        <img src="/image-web/Icon/Back.svg" alt="close" />
      </BackButton>
      <Header>확률 계산기</Header>
      <CommunityBoard>
        <CommunityCards>
          <CardGroup>
            <FlopCards>
              {flop.map((hand, handIndex) => (
                <CardItem
                  isFocus={
                    selectMode?.type === "flop" &&
                    selectMode.index === handIndex
                  }
                  key={handIndex}
                  onClick={() => selectCardForFlop(handIndex)}
                >
                  {hand !== -1 ? (
                    <img src={`/image-web/card/${hand}.png`} alt="flop card" />
                  ) : (
                    <div className="plus">+</div>
                  )}
                </CardItem>
              ))}
            </FlopCards>
          </CardGroup>
          <TurnRiverCards>
            <CardGroup>
              <CardItem
                isFocus={selectMode?.type === "turn" && selectMode.index === 0}
                onClick={() => selectCardForTurn()}
              >
                {turn !== null ? (
                  <img src={`/image-web/card/${turn}.png`} alt="turn card" />
                ) : (
                  <div className="plus">+</div>
                )}
              </CardItem>
            </CardGroup>
            <CardGroup>
              <CardItem
                isFocus={selectMode?.type === "river" && selectMode.index === 0}
                onClick={() => selectCardForRiver()}
              >
                {river !== null ? (
                  <img src={`/image-web/card/${river}.png`} alt="river card" />
                ) : (
                  <div className="plus">+</div>
                )}
              </CardItem>
            </CardGroup>
          </TurnRiverCards>
        </CommunityCards>
      </CommunityBoard>
      <PlayersContainer>
        {winningRates.map((player, playerIndex) => (
          <PlayerWrapper key={player.userIndex}>
            <PlayerInfo>
              <PlayerHand>
                {player.hands.map((hand, handIndex) => (
                  <CardItem
                    isFocus={
                      selectMode?.player === playerIndex &&
                      selectMode.index === handIndex
                    }
                    key={handIndex}
                    onClick={() => selectCardForPlayer(playerIndex, handIndex)}
                  >
                    {hand !== -1 ? (
                      <img
                        src={`/image-web/card/${hand}.png`}
                        alt={`player card ${handIndex}`}
                      />
                    ) : (
                      <div className="plus">+</div>
                    )}
                  </CardItem>
                ))}
              </PlayerHand>
              <WinRateInfo>
                <WinRate>
                  {player.win
                    ? Number(player.win.replace("%", "")).toFixed(1) + "%"
                    : "0.0%"}
                </WinRate>
                <DrawRate>
                  {player.draw
                    ? Number(player.draw.replace("%", "")).toFixed(1) + "%"
                    : "0.0%"}
                </DrawRate>
              </WinRateInfo>
            </PlayerInfo>

            <RankBox>
              <RankItem>
                <RankName>로얄 플러시</RankName>
                <RankValue>{player.royalFlush || "0%"}</RankValue>
              </RankItem>
              <RankItem>
                <RankName>스트레이트 플러시</RankName>
                <RankValue>{player.straightFlush || "0%"}</RankValue>
              </RankItem>
              <RankItem>
                <RankName>포카드</RankName>
                <RankValue>{player.quads || "0%"}</RankValue>
              </RankItem>
              <RankItem>
                <RankName>풀하우스</RankName>
                <RankValue>{player.fullHouse || "0%"}</RankValue>
              </RankItem>
              <RankItem>
                <RankName>플러시</RankName>
                <RankValue>{player.flush || "0%"}</RankValue>
              </RankItem>
              <RankItem>
                <RankName>스트레이트</RankName>
                <RankValue>{player.straight || "0%"}</RankValue>
              </RankItem>
              <RankItem>
                <RankName>트리플</RankName>
                <RankValue>{player.treeOfAKind || "0%"}</RankValue>
              </RankItem>
              <RankItem>
                <RankName>투 페어</RankName>
                <RankValue>{player.twoPairs || "0%"}</RankValue>
              </RankItem>
              <RankItem>
                <RankName>원 페어</RankName>
                <RankValue>{player.onePair || "0%"}</RankValue>
              </RankItem>
              <RankItem>
                <RankName>하이 카드</RankName>
                <RankValue>{player.highCards || "0%"}</RankValue>
              </RankItem>
            </RankBox>
          </PlayerWrapper>
        ))}
      </PlayersContainer>
      <ButtonContainer>
        {winningRates.length < 9 && (
          <Button onClick={() => handleAddPlayer()}>플레이어 추가</Button>
        )}
        <Button onClick={() => clear()}>초기화</Button>
      </ButtonContainer>
      {isCardSelect && (
        <>
          <Overlay onClick={handleOverlayClick} />
          <CardWrapper>
            {CARD_INFO.map((card, index) => (
              <Card
                key={index}
                card={card}
                index={index}
                onClick={handleCardClick}
                selected={selectedCards.includes(index)}
                src={`/image-web/card/${index}.png`}
              />
            ))}
          </CardWrapper>
        </>
      )}
    </PageWrapper>
  );
};

export default OddsCalc;
