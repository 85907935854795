import styled from "styled-components";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";

const AppBannerWrapper = styled.div`
  margin-top: 64px;
  margin-bottom: 114px;
  width: 100%;

  > .banner {
    width: 100%;
    aspect-ratio: 360/112;
    position: relative;
    @media ${MEDIA_DESKTOP} {
      aspect-ratio: 1060/200;
      border-radius: 8px;
    }

    > .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      aspect-ratio: 360/112;
      @media ${MEDIA_DESKTOP} {
        aspect-ratio: 1060/200;
        border-radius: 8px;
      }
      object-fit: cover;
      z-index: 0;
    }

    > .content {
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 2;

      > .button-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;

        > .button {
          cursor: pointer;
          width: 40px;
          height: 32px;
          border-radius: 5px;
          border: 1px solid #ececec;
          background: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          > img {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
        }
      }

      > .typo-col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        @media ${MEDIA_DESKTOP} {
          gap: 4px;
        }
        > .title {
          color: #eddefc;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.45);
          font-family: "yg-jalnan";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 140% */
          letter-spacing: 0.4px;
          @media ${MEDIA_DESKTOP} {
            color: #eddefc;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px; /* 128.571% */
            letter-spacing: -0.56px;
          }
        }

        > .desc {
          color: var(--Purple-100, #f0eaff);
          text-align: center;
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.32px;
          @media ${MEDIA_DESKTOP} {
            color: var(--Purple-100, #f0eaff);
            font-family: Pretendard;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.36px;
          }
        }
      }
    }
  }
`;

const AppBanner = () => {
  return (
    <AppBannerWrapper>
      {/* 6월 17일 앱 배너 임시 비활성화, 이후 유튜브 영상으로 대체 예정 */}
      {/* <div
        className="banner"
        onClick={() =>
          window.open("https://www.youtube.com/watch?v=7tgzrJqynLg")
        }
      >
        <img
          className="background"
          src="/image-web/home/background-banner.png"
          alt="banner"
        />
      </div> */}
    </AppBannerWrapper>
  );
};
export default AppBanner;
