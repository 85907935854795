import styled from "styled-components";
import React, {useCallback, useEffect, useState} from "react";
import Home from "./Home";
import Search from "./Search";
import Index from "./MyPage";
import useQueryParams from "../../hooks/useQueryParams";
import {useHistory} from "react-router-dom";
import HoldemNow from "./HoldemNow";
import Favorite from "./Favorite";
import useScreenOrientation from "../../hooks/useScreenOrientation";
import PCMyPage from "./MyPage/PCMyPage";
import PCSearch from "./Search/PCSearch";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 84px;
`
const BottomNavigationWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  height: 84px;
  background: white;
  bottom: 0;
  padding: 16px 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 3;

  > .item {
    position: relative;
    cursor: pointer;
    height: 32px;

    > span {
      width: max-content;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      color: var(--Black-300, #808080);
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    > img {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }

    > img.home-selected {
      padding: 2px;
    }

    > .background {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      background: ${p => p.theme.color.purple100};
      border-radius: 50%;
      z-index: -1;
    }
  }

  > .item.selected {
    > span {
      color: var(--Purple-300, #6436E7);
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`

type MenuType = 'home' | 'like' | 'search' | 'holdemNow' | 'mypage'
const Main = () => {
  const orientation = useScreenOrientation();
  const history = useHistory();
  const path = history.location.pathname;
  const [selectedMenu, setSelectedMenu] = useState<MenuType>();

  useEffect(() => {
    if (path === '/') {
      history.push('/home')
    } else {
      const tab = path.split('/')[1] as MenuType;
      setSelectedMenu(tab ? tab : 'home');
    }
  }, [path]);

  const onClickMenu = (menu: MenuType) => {
    if (selectedMenu === menu) {
      return;
    }

    const state: {[key: string]: any} = {};
    if (menu === 'search') {
      state.mode = 'query';
    }

    history.push(`/${menu}`, state);
  }

  return orientation === 'landscape' ? <>
    {
      selectedMenu === 'home' && <Home/>
    }
    {
      selectedMenu === 'mypage' && <PCMyPage/>
    }
    {
      selectedMenu === 'holdemNow' && <HoldemNow/>
    }
    {
      selectedMenu === 'search' && <PCSearch/>
    }
  </> : <MainWrapper>
    {
      selectedMenu === 'home' && <Home/>
    }
    {
      selectedMenu === 'search' && <Search/>
    }
    {
      selectedMenu === 'mypage' && <Index/>
    }
    {
      selectedMenu === 'holdemNow' && <HoldemNow/>
    }
    {
      selectedMenu === 'like' && <Favorite/>
    }
    <BottomNavigationWrapper>
      <div className={'item ' + (selectedMenu === 'home' ? 'selected' : '')}>
        <img
          className={selectedMenu === 'home' ? 'home-selected' : ''}
          src={selectedMenu === 'home' ? '/image-web/bottom-nav/Runnerrunner (Img).png' : '/image-web/bottom-nav/Case=Home, Status=Default.png'}
          alt='홈' onClick={() => onClickMenu('home')}/>
        {
          selectedMenu === 'home' && <div className='background'/>
        }
        <span>홈</span>
      </div>
      <div className={'item ' + (selectedMenu === 'like' ? 'selected' : '')}>
        <img
          src={selectedMenu === 'like' ? '/image-web/bottom-nav/Case=Like, Status=Selected.png' : '/image-web/bottom-nav/Case=Like, Status=Default.png'}
          alt='즐겨찾기' onClick={() => onClickMenu('like')}/>
        {
          selectedMenu === 'like' && <div className='background'/>
        }
        <span>즐겨찾기</span>
      </div>
      <div className={'item ' + (selectedMenu === 'search' ? 'selected' : '')}>
        <img
          src={selectedMenu === 'search' ? '/image-web/bottom-nav/Case=Search, Status=Selected.png' : '/image-web/bottom-nav/Case=Search, Status=Default.png'}
          alt='검색' onClick={() => onClickMenu('search')}/>
        {
          selectedMenu === 'search' && <div className='background'/>
        }
        <span>검색</span>
      </div>
      <div className={'item ' + (selectedMenu === 'holdemNow' ? 'selected' : '')}>
        <img
          src={selectedMenu === 'holdemNow' ? '/image-web/bottom-nav/chat_selected.svg' : '/image-web/bottom-nav/chat.svg'}
          alt='홀덤NOW' onClick={() => onClickMenu('holdemNow')}/>
        {
          selectedMenu === 'holdemNow' && <div className='background'/>
        }
        <span>홀덤NOW</span>
      </div>
      <div className={'item ' + (selectedMenu === 'mypage' ? 'selected' : '')}>
        <img
          src={selectedMenu === 'mypage' ? '/image-web/bottom-nav/Case=Profile, Status=Selected.png' : '/image-web/bottom-nav/Case=Profile, Status=Default.png'}
          alt='마이페이지' onClick={() => onClickMenu('mypage')}/>
        {
          selectedMenu === 'mypage' && <div className='background'/>
        }
        <span>마이페이지</span>
      </div>
    </BottomNavigationWrapper>
  </MainWrapper>
}
export default Main;
