import styled from "styled-components";
import React, { useContext, useState } from "react";
import { StoreContext } from "../StoreDetail";
import { getKoreanNumber } from "../../../utils/common";
import AdminButton from "../StoreDetail/Admin/AdminButton";
import EditDetailInfo from "./Admin/EditDetailInfo";
import EditEventInfo, { EventData } from "./Admin/EditEventInfo";

const DetailInfoWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;

  > .title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.36px;
    }
  }

  .sub-title {
    color: var(--Purple-400, #402295);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
  }

  .sub-title-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > span {
      color: var(--Purple-400, #402295);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
    }
    > .register {
      cursor: pointer;
      color: var(--Black-200, #b7b7b7);
      text-align: right;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }

  > .info-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;

    > .prize-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .value {
        color: var(--Purple-300, #6436e7);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
      }
    }

    > .box {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;

      > .content {
        max-width: 100%;
        color: var(--Black-500, #202020);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: -0.32px;
        word-break: break-all;
      }

      > .event-row {
        width: 100%;
        overflow-x: scroll;

        > .inner {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 16px;

          > .item {
            padding: 16px;
            border-radius: 8px;
            background: var(--Black-100, #f0f0f0);
            color: var(--Black-500, #202020);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: -0.32px;
          }

          > .empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 16px;
            border-radius: 8px;
            background: var(--Black-100, #f0f0f0);
            width: 100%;
            color: var(--Black-500, #202020);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: -0.32px;
          }
        }
      }
    }
  }
`;
const DetailInfo = () => {
  const [showRegisterEventInfo, setShowRegisterEventInfo] = useState(false);
  const [showEditEventInfo, setShowEditEventInfo] = useState<EventData | null>(
    null
  );
  const [showEditDetailInfo, setShowEditDetailInfo] = useState(false);
  const { data } = useContext(StoreContext);

  return (
    <>
      {showEditDetailInfo && (
        <EditDetailInfo
          cafeId={data.id}
          onClose={() => setShowEditDetailInfo(false)}
        />
      )}
      {showRegisterEventInfo && (
        <EditEventInfo
          mode={"write"}
          onClose={() => setShowRegisterEventInfo(false)}
        />
      )}
      {showEditEventInfo && (
        <EditEventInfo
          mode={"edit"}
          onClose={() => setShowEditEventInfo(null)}
          item={showEditEventInfo}
        />
      )}
      <DetailInfoWrapper>
        <div className="title-row">
          <div className="title">상세정보</div>
          {data.myCafe && (
            <AdminButton onClick={() => setShowEditDetailInfo(true)}>
              수정
            </AdminButton>
          )}
        </div>
        <div className="info-list">
          <div className="prize-row">
            <div className="sub-title">프라이즈</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="486"
              height="1"
              viewBox="0 0 486 1"
              fill="none"
            >
              <path
                d="M1 0.5H485"
                stroke="#B7B7B7"
                strokeLinecap="round"
                strokeDasharray="0.1 4"
              />
            </svg>
            <div className="value">
              {data.prize || 50}% ~ {data.prizeMax ? data.prizeMax + "%" : ""}
            </div>
          </div>
          <div className="prize-row">
            <div className="sub-title">블라인드 업 타임</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="486"
              height="1"
              viewBox="0 0 486 1"
              fill="none"
            >
              <path
                d="M1 0.5H485"
                stroke="#B7B7B7"
                strokeLinecap="round"
                strokeDasharray="0.1 4"
              />
            </svg>
            <div className="value">
              {!data.blindUp && !data.blindUpMax
                ? "정보없음"
                : data.blindUp === data.blindUpMax
                ? `${data.blindUp}분`
                : `${data.blindUp}분 ~ ${data.blindUpMax}분`}
            </div>
          </div>
          <div className="prize-row">
            <div className="sub-title">바이인</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="486"
              height="1"
              viewBox="0 0 486 1"
              fill="none"
            >
              <path
                d="M1 0.5H485"
                stroke="#B7B7B7"
                strokeLinecap="round"
                strokeDasharray="0.1 4"
              />
            </svg>
            <div className="value">
              {getKoreanNumber(data.buyIn ?? 0)}
              {data.buyInMax && `~ ${getKoreanNumber(data.buyInMax)}`}
            </div>
          </div>
          <div className="box">
            <div className="sub-title">매장 이용 안내</div>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: data.informationUse.replace(/\n/g, "<br/>"),
              }}
            />
          </div>
          <div className="box">
            <div className="sub-title-wrapper">
              <span>이벤트 정보</span>
              {data.myCafe && (
                <div
                  className="register"
                  onClick={() => setShowRegisterEventInfo(true)}
                >
                  이벤트 등록
                </div>
              )}
            </div>
            <div className="event-row">
              <div className="inner">
                {data.events.length === 0 && (
                  <div className="empty">진행중인 이벤트가 없습니다.</div>
                )}
                {data.events.map((item, index) => {
                  return (
                    <div
                      className="item"
                      key={index}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="box">
            <div className="sub-title">업체 정보</div>
            <div className="content">
              상호: {data.cafeName}
              <br />
              주소: {data.newAddress} {data.detailAddress}
              <br />
              전화번호: {data.vcn}
              <br />
              사업자번호: {data.employerId || "-"}
            </div>
          </div>
        </div>
      </DetailInfoWrapper>
    </>
  );
};
export default DetailInfo;
