import styled from "styled-components";
import {enqueueSnackbar, useSnackbar} from "notistack";
import useScreenOrientation from "../../../hooks/useScreenOrientation";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../../recoil/app";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {certification} from "../../../utils/iamport";

const PCFindIdWrapper = styled.div`
  width: 680px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 80px 0 100px;
  gap: 30px;

  > .title {
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  > .horizontal-line {
    width: 100%;
    height: 2px;
    background: #444;
  }

  > .horizontal-line-light {
    width: 100%;
    height: 1px;
    background: #B7B7B7;
  }

  > .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
  >.content {
    width: 312px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    >.title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.28px;
    }
    >.description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 312px;
      height: 40px;
      color: var(--Black-500, #202020);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-radius: 4px;
      background: var(--Black-100, #F0F0F0);
    }
    >.sub-title {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      >.retry {
        cursor: pointer;
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }
`
const SignUpButton = styled.div`
  cursor: pointer;
  width: 312px;
  height: 56px;
  color: #F0F0F0;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background: var(--Purple-300, #6436E7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FindPasswordButton = styled(SignUpButton)`
  color: var(--Purple-300, #6436E7);
  border: 1px solid var(--Purple-300, #6436E7);
  background: white;
`

const PCFindId = () => {
  const [id, setId] = useState<string>('');

  const orientation = useScreenOrientation();
  const setLoading = useSetRecoilState(loadingState);
  const history = useHistory();
  useEffect(() => {
    if (orientation === 'portrait') {
      history.push('/')
    }
  }, [orientation]);
  const handleClickCertification = () => {
    certification(true).then((res) => {
      if (res.textId) {
        setId(res.textId)
      } else {
        enqueueSnackbar("회원 정보를 찾을 수 없습니다", {variant: "error"});
      }
    }).catch((e) => {
      enqueueSnackbar("본인인증에 실패했습니다: " + e.message, {variant: "error"});
    });
  }
  return <PCFindIdWrapper>
    <div className='title'>아이디 찾기</div>
    <div className='horizontal-line'/>
    {
      id === '' ? <SignUpButton onClick={() => handleClickCertification()}>
        본인인증 하기
      </SignUpButton> : <>
        <div className='content'>
          <div className='title'>
            아이디는 다음과 같습니다.
          </div>
          <div className='description'>
            {id}
          </div>
          <div className='sub-title'>
            찾으시려는 아이디가 아닌가요 ? <span className='retry' onClick={()=>setId('')}>다시 찾기</span>
          </div>
        </div>
        <div className='button-wrapper'>
          <SignUpButton onClick={()=>{
            history.push('/login')
          }}>로그인하러 가기</SignUpButton>
          <FindPasswordButton onClick={()=>{
            history.push('/find-pw')
          }}>비밀번호 찾기</FindPasswordButton>
        </div>
      </>
    }
  </PCFindIdWrapper>
}

export default PCFindId;
