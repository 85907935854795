import styled from "styled-components";
import {useEffect, useState} from "react";
import UsedHistory from "./UsedHistory";
import Howto from "./Howto";
import Receive from "./Receive";
import {getUserTicketInfo, UserTicketInfoInterface} from "../../../../api/game";
import useUserInfo from "../../../../hooks/useUserInfo";

import {getFriendRecommendList, getFriendRecommendUseList} from "../../../../api/referral";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../../../recoil/app";
import {MEDIA_DESKTOP} from "../../../../hooks/useScreenOrientation";

const ReferralWrapper = styled.div<{ scrollLock: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
overscroll-behavior: none;
  z-index: 10;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${p => p.scrollLock ? `
      overflow-y: hidden;
  ` : `
  
  `}
  @media ${MEDIA_DESKTOP}{
  position: static;
  max-width: unset;
  top: unset;
  left: unset;
  padding-top: 0;
  height: unset;
}
  > .header {
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;
    background: white;
    @media ${MEDIA_DESKTOP}{
      position: static;
      bottom: unset;
      right: unset;
      left: unset;
      top: unset;
      transform: unset;
      height: unset;
      padding: 0;
      max-width: unset;
      padding-bottom: 20px;
      border-bottom: 2px solid var(--Black-200, #B7B7B7);
      margin-bottom: 20px;
    }

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      @media ${MEDIA_DESKTOP}{
        display: none;
      }
    }

    > .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${p => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media ${MEDIA_DESKTOP}{
        bottom: unset;
        right: unset;
        left: unset;
        top: unset;
        position: static;
        transform: unset;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
`
const MyRPPCWrapper = styled.div`
  width: 100%;
  display: none;
  @media ${MEDIA_DESKTOP}{
    display: flex;
  }
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  border-bottom: 2px solid var(--Black-200, #B7B7B7);
  padding-bottom: 20px;
  margin-bottom: 20px;
  >.rp-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    background: var(--Black-100, #F0F0F0);
    gap: 16px;
    >.line {
      width: 1px;
      height: 40px;
      background: var(--Black-200, #B7B7B7);
    }
    >.rp-box {
      width: 160px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;
      padding: 16px 20px;
      >.title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      >.value {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`
const MyRPWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px 0;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  gap: 8px;
  @media ${MEDIA_DESKTOP}{
    display: none;
  }
  > .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 160px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid var(--Black-200, #B7B7B7);
    padding: 0px 5px 0px 12px;

    > .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding-bottom: 1px;

      > .vertical-line {
        width: 1px;
        height: 10px;
        background: #F0F0F0;
      }

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      > .point {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }


    > .button {

    }
  }
`
const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px 30px;
  height: calc(100% - 113px);
  @media ${MEDIA_DESKTOP}{
    padding: 16px 0;
  }
`
const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-shrink: 0;
  @media ${MEDIA_DESKTOP}{
    margin-top: 0;
    border-radius: 8px;
    justify-content: space-between;
    overflow: hidden;
    gap: 0;
    margin-bottom: 16px;
  }

  > .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 35px;
    color: var(--Black-300, #808080);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-bottom: 2px solid transparent;
    transition: all 0.3s;
    @media ${MEDIA_DESKTOP}{
      flex: 1;
      width: unset;
      padding: 9px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      &:nth-child(1){
        border-radius: 8px 0px 0px 8px;
        border-top: 1px solid var(--Black-200, #B7B7B7);
        border-bottom: 1px solid var(--Black-200, #B7B7B7);
        border-left: 1px solid var(--Black-200, #B7B7B7);
      }
      &:nth-child(2){
        border-top: 1px solid var(--Black-200, #B7B7B7);
        border-bottom: 1px solid var(--Black-200, #B7B7B7);
        border-left: 1px solid var(--Black-200, #B7B7B7);
        border-right: 1px solid var(--Black-200, #B7B7B7);
      }
      &:nth-child(3){
        border-radius: 0px 8px 8px 0px;
        border-top: 1px solid var(--Black-200, #B7B7B7);
        border-bottom: 1px solid var(--Black-200, #B7B7B7);
        border-right: 1px solid var(--Black-200, #B7B7B7);
      }
    }
  }

  > .item.selected {
    border-bottom: 2px solid var(--Black-400, #444);
    color: var(--Black-400, #444);
    @media ${MEDIA_DESKTOP}{
      color: white;
      background: var(--Purple-300, #6436E7);
      &:nth-child(1){
        border-radius: 8px 0px 0px 8px;
        border-top: 1px solid #6436E7;
        border-bottom: 1px solid #6436E7;
        border-left: 1px solid #6436E7;
      }
      &:nth-child(2){
        border-top: 1px solid #6436E7;
        border-bottom: 1px solid #6436E7;
        border-left: 1px solid #6436E7;
        border-right: 1px solid #6436E7;
      }
      &:nth-child(3){
        border-radius: 0px 8px 8px 0px;
        border-top: 1px solid #6436E7;
        border-bottom: 1px solid #6436E7;
        border-right: 1px solid #6436E7;
      }
    }
  }
`


interface RPHistoryProps {
  onClose: () => void;
}

const RPHistory = ({onClose}: RPHistoryProps) => {
  const setLoading = useSetRecoilState(loadingState);
  type MenuType = 'howto' | 'receive' | 'used';
  const {user, setUser, refreshUser} = useUserInfo(true)
  const [selectedMenu, setSelectedMenu] = useState<MenuType>('howto');
  const [userTicketInfo, setUserTicketInfo] = useState<UserTicketInfoInterface | null>(null)

  const [receiveList, setReceiveList] = useState<any[]>([])
  const [usedList, setUsedList] = useState<any[]>([])

  useEffect(() => {
    if (user) {
      setLoading(true)
      getUserTicketInfo({
        userId: user.id
      }).then(res => {
        setUserTicketInfo(res.data)
      }).catch(e => {

      }).finally(()=>{
        setLoading(false)
      })
    }
  }, [user?.id]);


  useEffect(()=>{
    if(user) {
      if (selectedMenu === 'receive') {
        setLoading(true)
        getFriendRecommendList({
          userId: user.id
        }).then(res => {
          setReceiveList(res)
        }).finally(()=>{
          setLoading(false)
        })
      } else if(selectedMenu === 'used') {
        setLoading(true)
        getFriendRecommendUseList({
          userId: user.id
        }).then(res => {
          setUsedList(res)
        }).finally(()=>{
          setLoading(false)
        })
      }
    }
  }, [selectedMenu, user?.id])

  return <>
    <ReferralWrapper scrollLock={true}>
      <div className="header">
        <div className="close" onClick={onClose}>
          <img src="/image-web/Icon/Back.svg" alt="close"/>
        </div>
        <div className="title">친구 추천</div>
      </div>
      <MyRPPCWrapper>
        <div className='rp-wrapper'>
          <div className='rp-box'>
            <div className='title'>리바인권</div>
            <div className='value'>{(userTicketInfo ? userTicketInfo.user.rebuyinTicket.toLocaleString() : '')}</div>
          </div>
          <div className='line'/>
          <div className='rp-box'>
            <div className='title'>게임포인트</div>
            <div className='value'>{(userTicketInfo ? userTicketInfo.user.point.toLocaleString() : '')}</div>
          </div>
        </div>
      </MyRPPCWrapper>
      <MyRPWrapper>
        <div className='inner'>
          <div className='wrapper'>
            <div className='title'>리바인권</div>
            <div className='vertical-line'/>
            <div className='point'>{(userTicketInfo ? userTicketInfo.user.rebuyinTicket.toLocaleString() : '')}</div>
          </div>
        </div>
        <div className='inner'>
          <div className='wrapper'>
            <div className='title'>게임포인트</div>
            <div className='vertical-line'/>
            <div className='point'>{(userTicketInfo ? userTicketInfo.user.point.toLocaleString() : '')}</div>
          </div>
        </div>
      </MyRPWrapper>
      <MenuWrapper>
        <div className={'item ' + (selectedMenu === 'howto' ? 'selected' : '')}
             onClick={() => setSelectedMenu('howto')}>
          보상 사용 방법
        </div>
        <div className={'item ' + (selectedMenu === 'receive' ? 'selected' : '')}
             onClick={() => setSelectedMenu('receive')}>
          받은 내역
        </div>
        <div className={'item ' + (selectedMenu === 'used' ? 'selected' : '')}
             onClick={() => setSelectedMenu('used')}>
          사용 내역
        </div>
      </MenuWrapper>
      <ContentWrapper>
        {
          selectedMenu === 'howto' && <Howto/>
        }
        {
          selectedMenu === 'receive' && <Receive/>
        }
        {
          selectedMenu === 'used' && <UsedHistory/>
        }
      </ContentWrapper>
    </ReferralWrapper>
  </>
}

export default RPHistory;
