import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Login from "./Login";
import { Redirect, Route, Switch } from "react-router-dom";
import SnsLogin from "./SnsLogin";
import Main from "./Main";
import StoreDetail from "./StoreDetail";
import EventPage from "./Event";
import EventDetail from "./EventDetail";
import EventTournamentDetail from "./EventTournamentDetail";
import Recruit from "./Recruit";
import TournamentSchedule from "./TournamentSchedule";
import RecruitDetail from "./RecruitDetail";
import TournamentDetail from "./TournamentDetail";
import HoldemGuide from "./HoldemGuide";
import Ranking from "./Ranking";
import RingGameList from "./RingGameList";
import HoldemNowDetail from "./HoldemNowDetail";
import useScreenOrientation, {
  MEDIA_DESKTOP,
} from "../../hooks/useScreenOrientation";
import PCHeader from "../../components/web/PCHeader";
import PCSignUp from "./PCSignUp";
import PCFindId from "./FindId/PCFindId";
import PCFindPW from "./FindPW/PCFindPW";
import PCRecruit from "./Recruit/PCRecruit";
import PCStoreDetail from "./PCStoreDetail";
import PCFavorite from "./PCFavorite";
import Youtube from "./Youtube";
import OpenChatList from "./OpenChat";
import OddsCalc from "./OddsCalc";
import SecondHandMarket from "./SecondHandMarket";
import SecondHandMarketDetail from "./SecondHandMarketDetail";
import PCSecondHandMarket from "./SecondHandMarket/pc_index";
import PCSecondHandMarketDetail from "./SecondHandMarketDetail/pc_index";
import News from "./News";
import NewsDetail from "./NewsDetail";
import NewsAndSchedule from "./NewsAndSchedule";

const WebWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  > .inner {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 500px;
    @media ${MEDIA_DESKTOP} {
      padding-top: 126px;
      max-width: unset;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
`;
const Web = () => {
  const orientation = useScreenOrientation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // Init iamport
    if (window.IMP) {
      window.IMP.init(process.env.REACT_APP_IAMPORT_USER_CODE!);
    }

    setInitialized(true);
  }, []);

  if (!initialized) {
    return null;
  }

  return (
    <WebWrapper>
      {orientation === "landscape" && <PCHeader />}
      <div className="inner">
        <Switch>
          <Route path={"/"} exact>
            <Main />
          </Route>
          <Route path={"/home"} exact>
            <Main />
          </Route>

          <Route path={"/search"} exact>
            <Main />
          </Route>
          <Route path={"/mypage"} exact>
            <Main />
          </Route>
          <Route path={"/login"} exact>
            <Login />
          </Route>

          <Route path={"/find-id"} exact>
            <PCFindId />
          </Route>
          <Route path={"/find-pw"} exact>
            <PCFindPW />
          </Route>
          <Route path={"/snslogin/:type"} exact>
            <SnsLogin />
          </Route>
          <Route path={"/logout"} exact>
            <Redirect to="/" />
          </Route>
          <Route path={"/event/list"} exact>
            <EventPage />
          </Route>
          <Route path={"/event/detail/:id"} exact>
            <EventDetail />
          </Route>
          <Route path={"/event/tournament/detail/:groupId"} exact>
            <EventTournamentDetail />
          </Route>
          <Route path={"/tournamentSchedule"} exact>
            <TournamentSchedule />
          </Route>
          <Route path={"/openChat"} exact>
            <OpenChatList />
          </Route>
          <Route path={"/tournament/detail/:type/:id"} exact>
            <TournamentDetail />
          </Route>
          <Route path={"/holdem/guide"} exact>
            <HoldemGuide />
          </Route>
          <Route path={"/ranking"} exact>
            <Ranking />
          </Route>
          <Route path={"/youtube"} exact>
            <Youtube />
          </Route>
          <Route path={"/game/ring/list"} exact>
            <RingGameList />
          </Route>
          <Route path={"/holdemNow"} exact>
            <Main />
          </Route>

          {orientation === "landscape" ? (
            <>
              <Route path={"/signup"} exact>
                <PCSignUp />
              </Route>
              <Route path={"/like"} exact>
                <PCFavorite />
              </Route>
              <Route path={"/recruit"} exact>
                <PCRecruit />
              </Route>
              <Route path={"/recruit/detail/:id/:type"} exact>
                <PCRecruit />
              </Route>
              <Route path={"/holdemNow/:messageType/:id"} exact>
                <Main />
              </Route>
              <Route path={`/store/:id`} exact>
                <PCStoreDetail />
              </Route>
              <Route path={"/second-hand-market"} exact>
                <PCSecondHandMarket />
              </Route>
              <Route path={"/second-hand-market/detail/:id"} exact>
                <PCSecondHandMarketDetail />
              </Route>
              <Route path={"/news"} exact>
                <NewsAndSchedule />
              </Route>
              <Route path={"/news-detail/:id"} exact>
                <NewsDetail />
              </Route>
            </>
          ) : (
            <>
              <Route path={"/calc"} exact>
                <OddsCalc />
              </Route>
              <Route path={"/signup"} exact>
                <Login />
              </Route>
              <Route path={"/like"} exact>
                <Main />
              </Route>
              <Route path={"/recruit"} exact>
                <Recruit />
              </Route>
              <Route path={"/recruit/detail/:id/:type"} exact>
                <RecruitDetail />
              </Route>
              <Route path={"/holdemNow/:messageType/:id"} exact>
                <HoldemNowDetail />
              </Route>
              <Route path={`/store/:id`} exact>
                <StoreDetail />
              </Route>
              <Route path={`/mobile/store/:id`} exact>
                <StoreDetail />
              </Route>
              <Route path={"/second-hand-market"} exact>
                <SecondHandMarket />
              </Route>
              <Route path={"/second-hand-market/detail/:id"} exact>
                <SecondHandMarketDetail />
              </Route>
              <Route path={"/news"} exact>
                <NewsAndSchedule />
              </Route>
              <Route path={"/news-detail/:id"} exact>
                <NewsDetail />
              </Route>
            </>
          )}
        </Switch>
      </div>
    </WebWrapper>
  );
};

export default Web;
