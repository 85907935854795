import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import Agreement, { TermsType } from "../Agreement";
import { authDetail, authExit, authUpdate } from "../../../api/auth";
import { enqueueSnackbar } from "notistack";
import { certification } from "../../../utils/iamport";
import useUserInfo from "../../../hooks/useUserInfo";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import {
  clearCredential,
  LOCAL_STORAGE_ACCESS_KEY,
} from "../../../utils/network";
import { useHistory } from "react-router-dom";
import useDialog from "../../../hooks/useDialog";
import Adjust from "@adjustcom/adjust-web-sdk";
import useNativeApp, { isApp } from "../../../hooks/useNativeApp";

const ModifyProfileWrapper = styled.div<{
  scrollLock: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
  overscroll-behavior: none;
  z-index: 10;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${(p) =>
    p.scrollLock
      ? `
      overflow-y: hidden;
  `
      : `
  
  `}
  @media ${MEDIA_DESKTOP} {
    position: static;
    max-width: unset;
    top: unset;
    left: unset;
    padding-top: 0;
    height: unset;
  }
  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      margin-top: 20px;
      border-top: 2px solid var(--Black-200, #808080);
    }
  }

  > .floating-button-wrapper {
    background: white;
    position: fixed;
    bottom: 0;
    padding: 30px 24px 48px;
    width: 100%;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    @media ${MEDIA_DESKTOP} {
      position: static;
      max-width: unset;
      top: unset;
      left: unset;
      bottom: unset;
      padding: 0;
      transform: unset;
    }
    > .button {
      cursor: pointer;
      width: 100%;
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.165px;
      border-radius: 8px;
      text-align: center;
      padding: 15px 0;
      background: var(--Purple-300, #6436e7);
    }
    > .button:active {
      background: #502bb5;
    }
  }

  > .header {
    top: 0;
    position: fixed;
    width: 100%;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;
    background: white;
    @media ${MEDIA_DESKTOP} {
      position: static;
      bottom: unset;
      right: unset;
      left: unset;
      top: unset;
      transform: unset;
      height: unset;
      padding: 0;
    }
    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      @media ${MEDIA_DESKTOP} {
        display: none;
      }
    }

    > .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${(p) => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media ${MEDIA_DESKTOP} {
        bottom: unset;
        right: unset;
        left: unset;
        top: unset;
        position: static;
        transform: unset;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
`;
const HorizontalBar = styled.div`
  width: 100%;
  height: 8px;
  background: ${(p) => p.theme.color.black100};
  @media ${MEDIA_DESKTOP} {
    background: ${(p) => p.theme.color.black300};
    height: 2px;
  }
`;
const DefaultInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 16px 30px;
  background: white;
  @media ${MEDIA_DESKTOP} {
    padding: 20px 0;
  }

  > .title {
    width: 100%;
    text-align: left;
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 28px */
    @media ${MEDIA_DESKTOP} {
      font-size: 18px;
    }
  }

  > .sub-title {
    width: 100%;
    text-align: left;
    margin-top: 8px;
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
const TextBox = styled.div`
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--Black-200, #b7b7b7);
  background: var(--Black-100, #f0f0f0);
  color: var(--Black-300, #808080);
  text-align: left;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-grow: 1;
`;
const BasicInfoWrapper = styled(DefaultInfoWrapper)`
  > .box {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    @media ${MEDIA_DESKTOP} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }

    > .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
      @media ${MEDIA_DESKTOP} {
        gap: 0;
      }
      > .title {
        width: 80px;
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left;
        flex-shrink: 0;
      }
    }
  }
`;
const VerificationWrapper = styled(DefaultInfoWrapper)`
  > .verified-box {
    border-radius: 8px;
    background: var(--Purple-100, #f0eaff);
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 6px;
    padding: 12px 0;

    > .title {
      padding: 4px 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
      color: var(--Purple-300, #6436e7);
      text-align: right;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;

      > img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }

    > .description {
      color: var(--Black-300, #808080);
      text-align: right;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
    }
  }

  > .no-box {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 12px;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
    }

    > .description {
      color: var(--Black-300, #808080);
      text-align: right;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
    }

    > .button {
      margin-top: 8px;
      gap: 4px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 14px 16px;
      color: var(--Black-400, #444);
      text-align: right;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
      border-radius: 24px;
      border: 1px solid var(--Black-300, #808080);

      > img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }
  }
`;
const AdditionalInfoWrapper = styled(DefaultInfoWrapper)`
  padding-bottom: 140px;
  @media ${MEDIA_DESKTOP} {
    padding-bottom: 0;
  }
  > .input-box {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 30px;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
    }

    > input {
      width: 100%;
      margin-top: 20px;
      padding: 10px 12px;
      border-radius: 4px;
      border: 1px solid var(--Black-200, #b7b7b7);
      background: var(--Black-100, #f0f0f0);
      outline: none;
      color: var(--Black-300, #808080);
      text-align: left;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    > .description {
      margin-top: 12px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.24px;
      text-align: left;
    }

    > .list {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;

      > .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 12px;

        > .checkbox {
          width: 20px;
          height: 20px;
          flex-shrink: 0;

          > circle {
            transition: all 0.3s;
            fill: ${(p) => p.theme.color.black200};
          }
        }

        > .checkbox.checked {
          > circle {
            fill: ${(p) => p.theme.color.purple300};
          }
        }

        > .checkbox.disabled {
          cursor: not-allowed;

          > path {
            stroke: ${(p) => p.theme.color.black200};
          }

          > circle {
            fill: ${(p) => p.theme.color.black300};
          }
        }

        > .text {
          flex-grow: 1;
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.28px;
        }

        > .button {
          padding-top: 2px;
          cursor: pointer;
          flex-shrink: 0;
          color: var(--Black-200, #b7b7b7);
          text-align: right;
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.26px;
        }

        > .quit {
          border: #d91818 solid 1px;
          color: #d91818;
          font-size: 15px;
          font-weight: 600;
          border-radius: 8px;
          padding: 5px 10px;
        }
      }
    }

    > .radio-row {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 30px;

      > .item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;

        > .text {
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.28px;
        }

        .border {
          transition: all 0.3s;
          stroke: ${(p) => p.theme.color.black200};
        }

        .inner {
          transition: all 0.3s;
          fill: transparent;
        }
      }

      > .item.selected {
        .border {
          stroke: ${(p) => p.theme.color.purple300};
        }

        .inner {
          fill: ${(p) => p.theme.color.purple300};
        }
      }
    }
  }

  > .input-box.top {
    margin-top: 20px;
  }
`;

interface ModifyProfileProps {
  onClose: () => void;
}

interface ModifyProfileRequest {
  username?: string;
  phoneNumber?: string;
  gender?: string;
  birthYear?: string;
  birthMonth?: string;
  birthDate?: string;
  expireType?: string;
  isAgree1?: boolean;
  accountInfo?: string;
  isValidate?: boolean;
  existed?: boolean;
}

const ModifyProfile = ({ onClose }: ModifyProfileProps) => {
  const { user, refreshUser } = useUserInfo(true);
  const [termsType, setTermsType] = useState<TermsType>(null);
  const agreementList = [
    "(필수) 이용약관 동의",
    "(필수) 개인정보 처리방침 약관 동의",
    "(필수) 위치정보 약관 동의",
    "(필수) 청소년 보호 약관 동의",
    "(선택) 마케팅에 관한 개인정보 수집 및 이용 동의",
  ];
  const [checkedAgreement, setCheckedAgreement] = useState([
    true,
    true,
    true,
    true,
    false,
  ]);
  const [expireType, setExpireType] = useState("ONE_YEAR");
  const [pendingUser, setPendingUser] = useState<ModifyProfileRequest>();
  const [accountBank, setAccountBank] = useState("");
  const [accountNum, setAccountNum] = useState("");
  const { setUser } = useUserInfo(true);
  const history = useHistory();
  const { openDialog } = useDialog();
  const { sendMessageToNative } = useNativeApp();
  const setLoading = useSetRecoilState(loadingState);

  const isCertified = useMemo(() => {
    return Boolean(user?.validate || pendingUser?.isValidate);
  }, [user, pendingUser]);

  useEffect(() => {
    if (user?.expireType) {
      setExpireType(user.expireType);
    }
    if (user?.accountBank) {
      setAccountBank(user.accountBank);
    }
    if (user?.accountInfo) {
      setAccountNum(user.accountInfo);
    }
    if (user?.agree1) {
      setCheckedAgreement((v) => {
        const newVal = [...v];
        newVal[4] = user.agree1;
        return newVal;
      });
    }
  }, [user]);

  const handleClickCert = useCallback(() => {
    if (!user?.id) {
      return;
    }

    setLoading(true);
    certification()
      .then((res) => {
        return {
          username: res.username,
          phoneNumber: res.phoneNumber,
          birthYear: res.birth[0],
          birthMonth: res.birth[1],
          birthDate: res.birth[2],
          gender: res.gender,
          existed: res.existed,
          isValidate: true,
        } as ModifyProfileRequest;
      })
      .then(async (data: ModifyProfileRequest) => {
        if (data.existed) {
          enqueueSnackbar("이미 가입된 번호입니다.", { variant: "error" });
        } else {
          await authUpdate(data);
          await refreshUser();
          enqueueSnackbar("본인인증이 완료되었습니다.", { variant: "success" });
        }
      })
      .catch((e: any) => {
        enqueueSnackbar("본인인증에 실패했습니다: " + e.message, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = useCallback(() => {
    const agreements = [...checkedAgreement].reverse();
    const data: any = {
      isAgree1: agreements[0],
      expireType: expireType,
    };

    data.accountBank = accountBank || null;
    data.accountInfo = accountNum || null;

    setLoading(true);
    authUpdate(data)
      .then(async () => {
        await refreshUser();
        enqueueSnackbar("정보를 업데이트했습니다.", { variant: "success" });
      })
      .catch((e: any) => {
        enqueueSnackbar(e.message, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [checkedAgreement, expireType, accountBank, accountNum]);

  //탈퇴
  const handleQuit = useCallback(() => {
    if (!user?.id) {
      return;
    }

    authExit({ userId: user.id })
      .then(() => {
        if (process.env.REACT_APP_ENV === "production") {
          if (!isApp) {
            Adjust.trackEvent({
              eventToken: "8o6svr",
              callbackParams: [
                { key: "user_id", value: "" + user.id },
                {
                  key: "event_value",
                  value: `Web_Exit_${user.nickname}`,
                },
              ],
            });
          } else {
            sendMessageToNative("adjustEvent", "", {
              eventToken: "8o6svr",
              eventName: "Register_remove",
              data: user.id!,
              callbackParams: {
                user_id: "" + user?.id,
                event_value: `Exit_${user.nickname}`,
              },
            });
          }
        }
        clearCredential();
        setUser(undefined);
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_KEY);
        history.replace("/logout");
        enqueueSnackbar("회원탈퇴가 완료되었습니다.", { variant: "success" });
      })
      .catch((e: any) => {
        enqueueSnackbar(e.message, { variant: "error" });
      });
  }, [user]);

  return (
    <>
      {termsType !== null && (
        <Agreement type={termsType} onClose={() => setTermsType(null)} />
      )}
      <ModifyProfileWrapper scrollLock={termsType !== null}>
        <div className="header">
          <div className="close" onClick={onClose}>
            <img src="/image-web/Icon/Back.svg" alt="close" />
          </div>
          <div className="title">프로필 수정</div>
        </div>
        <div className="inner">
          <BasicInfoWrapper>
            <div className="title">기본 정보</div>
            <div className="sub-title">기본 정보는 수정이 불가합니다.</div>
            <div className="box">
              {user?.validate && (
                <>
                  <div className="item">
                    <div className="title">이름</div>
                    <TextBox>{user?.username}</TextBox>
                  </div>
                  <div className="item">
                    <div className="title">생년월일</div>
                    <TextBox>{user?.birthday}</TextBox>
                  </div>
                  <div className="item">
                    <div className="title">성별</div>
                    <TextBox>{user?.gender === "MALE" ? "남" : "여"}</TextBox>
                  </div>
                </>
              )}
              <div className="item">
                <div className="title">닉네임</div>
                <TextBox>{user?.nickname}</TextBox>
              </div>
            </div>
          </BasicInfoWrapper>
          <HorizontalBar />
          <VerificationWrapper>
            <div className="title">본인 인증 정보</div>
            {isCertified && (
              <div className="verified-box">
                <div className="title">
                  <img src="/image-web/Icon/Certified/small.svg" />
                  본인 인증 완료
                </div>
                <div className="description">
                  인증 전화번호 :{" "}
                  {user?.phoneNumber || pendingUser?.phoneNumber}
                </div>
              </div>
            )}
            {!isCertified && (
              <div className="no-box">
                <div className="title">등록된 본인 인증 정보가 없습니다.</div>
                <div className="description">
                  본인 인증 후 계좌 정보 입력이 가능합니다.
                </div>
                <div className="button" onClick={handleClickCert}>
                  <img src="/image-web/customerMenu/small_black.svg" />
                  <span>본인 인증하기</span>
                </div>
              </div>
            )}
          </VerificationWrapper>
          <HorizontalBar />
          <AdditionalInfoWrapper>
            <div className="title">추가 정보</div>
            <div className="input-box">
              <div className="title">정보 보유 기간</div>
              <div className="radio-row">
                <div
                  className={
                    "item " + (expireType === "ONE_YEAR" ? "selected" : "")
                  }
                  onClick={() => setExpireType("ONE_YEAR")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      className="border"
                      cx="10"
                      cy="10"
                      r="9.5"
                      fill="white"
                    />
                    <circle className="inner" cx="10" cy="10" r="6" />
                  </svg>
                  <div className="text">1년</div>
                </div>
                <div
                  className={
                    "item " + (expireType === "THREE_YEAR" ? "selected" : "")
                  }
                  onClick={() => setExpireType("THREE_YEAR")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      className="border"
                      cx="10"
                      cy="10"
                      r="9.5"
                      fill="white"
                    />
                    <circle className="inner" cx="10" cy="10" r="6" />
                  </svg>
                  <div className="text">3년</div>
                </div>
                <div
                  className={
                    "item " + (expireType === "EXIT" ? "selected" : "")
                  }
                  onClick={() => setExpireType("EXIT")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      className="border"
                      cx="10"
                      cy="10"
                      r="9.5"
                      fill="white"
                    />
                    <circle className="inner" cx="10" cy="10" r="6" />
                  </svg>
                  <div className="text">회원 탈퇴 시</div>
                </div>
              </div>
            </div>
            <div className="input-box">
              <div className="title">약관 동의 내역</div>
              <div className="list">
                {agreementList.map((item, index) => {
                  if (item.includes("필수")) {
                    return null;
                  }
                  const handleClickAgreement = () => {
                    const newCheckedAgreement = [...checkedAgreement];
                    newCheckedAgreement[index] = !newCheckedAgreement[index];
                    setCheckedAgreement(newCheckedAgreement);
                  };
                  return (
                    <div className="row" key={index}>
                      <svg
                        onClick={handleClickAgreement}
                        className={
                          "checkbox " +
                          (checkedAgreement[index] ? "checked" : "") +
                          (item.includes("필수") ? " disabled" : " ")
                        }
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <circle cx="10" cy="10" r="10" fill="#8359F7" />
                        <path
                          d="M6 10L8.96296 12.5L13.6667 7.5"
                          stroke="white"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div onClick={handleClickAgreement} className="text">
                        {item}
                      </div>
                      <div
                        className="button"
                        onClick={() => setTermsType("marketing")}
                      >
                        보기
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="input-box">
              <div className="title">회원탈퇴</div>
              <div className="list">
                <div
                  className="row"
                  onClick={() => {
                    openDialog({
                      type: "web",
                      text:
                        "회원 탈퇴를 진행하시겠습니까?<br/>" +
                        "탈퇴 후 데이터 복구는 불가합니다.",
                      onConfirm: handleQuit,
                      confirmText: "탈퇴하기",
                      confirm: true,
                      confirmColor: "#D91818",
                    });
                  }}
                >
                  <div className="quit">탈퇴하기</div>
                </div>
              </div>
            </div>
          </AdditionalInfoWrapper>
        </div>
        <div className="floating-button-wrapper">
          <div className="button" onClick={handleSubmit}>
            프로필 수정
          </div>
        </div>
      </ModifyProfileWrapper>
    </>
  );
};

export default ModifyProfile;
