import Sheet from "react-modal-sheet";
import styled from "styled-components";

const InfoModal = styled.div`
  width: 100vw;
  max-width: 480px;
  max-height: 80vh;
  display: flex;
  padding: 30px 24px 60px 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 12px 12px 0px 0px;
  background: #FFF;

  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;

    > .title {
      color: ${p => p.theme.color.black500};
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
    }

    > .content {

      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .description {
        margin-top: 12px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;

        > div {
          width: 100%;
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 19.6px */
          text-align: left;
        }

        > div.bold {
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

      }

      > .prize-wrapper {
        margin-top: 32px;
        padding: 20px 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-radius: 12px;
        border: 1px solid var(--Black-100, #F0F0F0);
        background: #FFF;
        gap: 20px;

        > .row {
          width: 100%;
          gap: 4px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;

          &.caption {
            color: var(--Black-300, #808080);
            font-size: 14px;
            font-weight: 600;
            margin-top: -8px;
          }

          > .title {
            flex-shrink: 0;
            color: var(--Black-300, #808080);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; /* 22.4px */
          }

          > .value {
            flex-grow: 1;
            color: var(--Black-400, #444);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 22.4px */
          }
        }
      }
    }

  }

  > .button-row {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;

    > .button {
      flex: 1;
      height: 48px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: ${p => p.theme.color.purple300};
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.165px;
    }

    > .button:active {
      background: #502bb5;
    }

    > .button.cancel {
      color: ${p => p.theme.color.purple300};
      background: none;
    }
  }
`

interface HoldemGameSheetProps {
  showSheet: boolean;
  setShowSheet: (show: boolean) => void;

}

const HoldemGameSheet = (
  {
    showSheet,
    setShowSheet
  }: HoldemGameSheetProps
) => {
  return <Sheet
    isOpen={showSheet}
    onClose={() => setShowSheet(false)}
    disableDrag={true}
    detent={'content-height'}
    style={{
      width: '100%',
      maxWidth: 500,
      left: '50%',
      transform: 'translateX(-50%)'
    }}
  >
    <Sheet.Container style={{
      maxWidth: 500,
    }}>
      <Sheet.Content>
        <InfoModal>
          <div className='inner'>
            <div className='title'>홀덤게임 정보</div>
            <div className='content'>
              <div className='description'>
                <div>홀덤게임이란 러너러너의 게임 포인트를 사용해서 플레이할 수 있는 게임입니다. 아래의 세부 룰을 참고해주세요.</div>
              </div>
              <div className='prize-wrapper'>
                <div className='row'>
                  <div className='title'>
                    방당 플레이 인원
                  </div>
                  <div className='value'>
                    8명
                  </div>
                </div>
                <div className='row caption'>
                  * 최소 세 명부터 플레이 가능합니다.
                </div>
                <div className='row'>
                  <div className='title'>
                    레이크
                  </div>
                  <div className='value'>
                    승자 결정 시 팟에 모인 전체 배팅 금액의 3%<br/>
                    (단, 무승부가 일어난 판의 경우 레이크를 떼지 않음)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='button-row'>
            <div className='button' onClick={() => setShowSheet(false)}>확인</div>
          </div>
        </InfoModal>
      </Sheet.Content>
    </Sheet.Container>
    <Sheet.Backdrop onTap={() => setShowSheet(false)}/>
  </Sheet>
}
export default HoldemGameSheet;
