import styled from "styled-components";
import {useState} from "react";
import useDialog from "../../../../hooks/useDialog";
import {authPassword} from "../../../../api/auth";
import useUserInfo from "../../../../hooks/useUserInfo";
import {certification} from "../../../../utils/iamport";

const PCModifyPasswordWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  gap: 20px;

  > .title {
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  > .line {
    width: 100%;
    height: 2px;
    background: var(--Black-300, #808080);
  }

  > .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;

    > .description {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    > .input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      > .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 156px;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
      }

      > input {
        width: 312px;
        padding: 15px 12px;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
        color: ${p => p.theme.color.black500};
        border-radius: 8px;
        border: 1px solid var(--Black-100, #F0F0F0);
        outline: none;

        &::placeholder {
          color: var(--Black-300, #808080);
        }
      }
    }
  }

  > .button {
    cursor: pointer;
    width: 100%;
    height: 56px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--Purple-300, #6436E7);
    color: #F0F0F0;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  > .button:active {
    background: #502bb5;
  }
`
type ModifyStep = 'PHONE' | 'AUTH' | 'PASSWORD'; // PHONE: 휴대폰 번호 입력, AUTH: 본인인증, PASSWORD: 비밀번호 변경
const PCModifyPassword = () => {
  const {user} = useUserInfo(true)
  const {openDialog} = useDialog();
  const [currentStep, setCurrentStep] = useState<ModifyStep>('PHONE');
  const [id, setId] = useState('');
  const handleNextStep = () => {
    if(user){
      if (currentStep === 'PHONE') {
        const phoneNumberInput = document.getElementById('phone-number') as HTMLInputElement;
        const phoneNumber = phoneNumberInput.value;
        if (!phoneNumber) {
          openDialog({
            type: 'web',
            text: '휴대폰 번호를 입력해주세요.'
          })
          return;
        }
        if(phoneNumber !== user.phoneNumber){
          openDialog({
            type: 'web',
            text: '휴대폰 번호가 일치하지 않습니다.'
          })
          return;
        }
        setCurrentStep('AUTH');
        //setCurrentStep('PASSWORD');
      } else if (currentStep === 'AUTH') {
        certification(true).then((res) => {
          if (res.textId) {
            setId(res.textId)
            setCurrentStep('PASSWORD');
          } else {
            openDialog({
              type: 'web',
              text: '본인인증에 실패했습니다.'
            })
          }
        }).catch((e) => {
          openDialog({
            type: 'web',
            text: '본인인증에 실패했습니다.'
          })
        });
      } else if (currentStep === 'PASSWORD') {
        const newPassword = (document.getElementById('new-password') as HTMLInputElement).value;
        const newPasswordCheck = (document.getElementById('new-password-check') as HTMLInputElement).value;
        if (!newPassword || !newPasswordCheck) {
          openDialog({
            type: 'web',
            text: '비밀번호를 입력해주세요.'
          })
          return;
        }
        if (newPassword !== newPasswordCheck) {
          openDialog({
            type: 'web',
            text: '비밀번호가 일치하지 않습니다.'
          })
          return;
        }

        authPassword({textId: id, newPassword: newPassword}).then(() => {
          openDialog({
            type: 'web',
            text: '비밀번호가 변경되었습니다.',
            onConfirm: () => {
              //비밀번호 변경 완료 후 처리
              setCurrentStep('PHONE')
            }
          })
        }).catch((e: any) => {
          openDialog({
            type: 'web',
            text: '비밀번호 변경에 실패했습니다.',
            onConfirm: () => {
              //비밀번호 변경 완료 후 처리
              setCurrentStep('PHONE')
            }
          })
        });
      }
    }
  }
  return <PCModifyPasswordWrapper>
    <div className='title'>비밀번호 변경</div>
    <div className='line'/>
    {
      currentStep === 'PHONE' && <>
        <div className='content-wrapper'>
          <div className='description'>본인인증을 진행한 휴대폰 번호를 입력해주세요</div>
          <div className='input-wrapper'>
            <div className='title'>휴대폰 번호</div>
            <input type='text' placeholder='휴대폰 번호를 입력해주세요' id='phone-number' onChange={(e) => {
              //숫자만 남긴다.
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }}/>
          </div>
        </div>
        <div className='button' onClick={handleNextStep}>본인인증</div>
      </>
    }
    {
      currentStep === 'AUTH' && <>
        <div className='button' onClick={handleNextStep}>PASS 인증</div>
      </>
    }
    {
      currentStep === 'PASSWORD' && <>
        <div className='content-wrapper'>
          <div className='description'>변경할 비밀번호를 입력해주세요</div>
          <div className='input-wrapper'>
            <div className='title'>비밀번호</div>
            <input type='password' placeholder='비밀번호를 입력해주세요' id='new-password'/>
          </div>
          <div className='input-wrapper'>
            <div className='title'>비밀번호확인</div>
            <input type='password' placeholder='비밀번호를 한번 더 입력해주세요' id='new-password-check'/>
          </div>
        </div>
        <div className='button' onClick={handleNextStep}>비밀번호 변경</div>
      </>
    }


  </PCModifyPasswordWrapper>
}
export default PCModifyPassword;
