import { PubType } from "../api/types";
import { getKoreanNumber } from "../utils/common";
import styled, { css } from "styled-components";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

interface WrapperProps {
  pubType?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #fff;
  padding: 4px 6px;
  transform: translateX(-50%);
  position: relative;
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.25));

  ${(props) =>
    props.pubType === "PREMIUM" &&
    css`
      padding: 8px 10px;
    `}

  &::before {
    position: absolute;
    width: 7px;
    height: 6px;
    left: 50%;
    bottom: 1px;
    transform: translate(-50%, 100%);
    content: " ";
    background-image: url(/image-web/map_marker_arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  > .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;

    > img {
      width: 12px;
      height: 12px;
      ${(props) =>
        props.pubType === "PREMIUM" &&
        css`
          width: 16px;
          height: 16px;
        `}
    }

    > span {
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 13px;
      font-weight: 700;
      ${(props) =>
        props.pubType === "PREMIUM" &&
        css`
          font-size: 14px;
        `}
    }
  }

  > .title {
    display: none;
    text-align: center;
    max-width: 82px;
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    white-space: nowrap;
  }

  &.marker-expanded {
    transform: translate(-50%, -12px);

    > .content {
      > span {
        color: var(--Black-300, #808080);
        font-size: 10px;
        font-weight: 600;
      }
    }

    > .title {
      display: block;
    }
  }
`;

function StoreMarker({
  selected,
  pubType,
  buyInFrom,
  buyInTo,
  name,
}: {
  selected?: boolean;
  pubType?: PubType;
  buyInFrom?: number | null;
  buyInTo?: number | null;
  name?: string;
}) {
  const pubTypeIcon = useMemo(() => {
    if (pubType === "PREMIUM") {
      return "/image-web/store/premium.png";
    } else if (pubType === "AFFILIATE") {
      return "/image-web/store/store_check.svg";
    } else if (pubType === "NORMAL") {
      return "/image-web/store/store_normal.png";
    }
  }, [pubType]);

  return (
    <Wrapper
      className={selected ? "marker-expanded" : "marker-collapsed"}
      pubType={pubType}
    >
      <div className="content">
        <img src={pubTypeIcon} />
        {buyInFrom && (
          <span>
            {getKoreanNumber(buyInFrom)}~
            {buyInTo ? getKoreanNumber(buyInTo) : ""}
          </span>
        )}
      </div>
      <div className="title">{name}</div>
    </Wrapper>
  );
}

export default StoreMarker;
