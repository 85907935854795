import styled from "styled-components";
import {useEffect, useState} from "react";
import Charge from "./Charge";
import UsedHistory from "./UsedHistory";
import {GetUserPoint} from "../../../../api/cafe";
import ChargeHistory from "./ChargeHistory";
import {MEDIA_DESKTOP} from "../../../../hooks/useScreenOrientation";
import {enqueueSnackbar, useSnackbar} from "notistack";
import useQueryParams from "../../../../hooks/useQueryParams";
import {useHistory, useLocation} from "react-router-dom";

const RPHistoryWrapper = styled.div<{ scrollLock: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
overscroll-behavior: none;
  z-index: 10;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${p => p.scrollLock ? `
      overflow-y: hidden;
  ` : `
  
  `}
  @media ${MEDIA_DESKTOP}{
    position: static;
    max-width: unset;
    top: unset;
    left: unset;
    padding-top: 0;
    height: unset;
  }
  > .header {
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;
    background: white;
    @media ${MEDIA_DESKTOP}{
      position: static;
      bottom: unset;
      right: unset;
      left: unset;
      top: unset;
      transform: unset;
      height: unset;
      padding: 0;
      max-width: unset;
      padding-bottom: 20px;
      border-bottom: 2px solid var(--Black-200, #B7B7B7);
      margin-bottom: 20px;
    }
    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
      @media ${MEDIA_DESKTOP}{
        display: none;
      }
    }

    > .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${p => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media ${MEDIA_DESKTOP}{
        bottom: unset;
        right: unset;
        left: unset;
        top: unset;
        position: static;
        transform: unset;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
`
const HistoryWrapper = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  @media ${MEDIA_DESKTOP}{
    display: flex;
    width: 100%;
  }
  >.title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    >.title {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;
    }
    >.title.selected {
      color: var(--Black-400, #444);
    }
  }
  >.content {
    width: 100%;
    
  }
`
const MyRPPCWrapper = styled.div`
  width: 100%;
  display: none;
  @media ${MEDIA_DESKTOP}{
    display: flex;
  }
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  border-bottom: 2px solid var(--Black-200, #B7B7B7);
  padding-bottom: 20px;
  margin-bottom: 20px;
  >.rp-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background: var(--Black-100, #F0F0F0);
    >.rp-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;
      padding: 16px 20px;
      >.title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      >.value {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    >.button {
      padding: 12px 20px;
      cursor: pointer;
      border-radius: 4px;
      background: var(--Black-500, #202020);
      color: #FFF;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-right: 16px;
    }
  }
  >.description {
    color: var(--Black-300, #808080);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
  }
`
const MyRPWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px 0;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  @media ${MEDIA_DESKTOP}{
    display: none;
  }
  > .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 220px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid var(--Black-200, #B7B7B7);
    padding: 0px 5px 0px 12px;

    > .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding-bottom: 1px;

      > .vertical-line {
        width: 1px;
        height: 10px;
        background: #F0F0F0;
      }

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      > .point {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }


    > .button {

    }
  }
`
const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px 30px;
  height: calc(100% - 113px);
  @media ${MEDIA_DESKTOP}{
    display: none;
  }
`
const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-shrink: 0;
  @media ${MEDIA_DESKTOP}{
    display: none;
  }
  > .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 35px;
    color: var(--Black-300, #808080);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-bottom: 2px solid transparent;
    transition: all 0.3s;
  }

  > .item.selected {
    border-bottom: 2px solid var(--Black-400, #444);
    color: var(--Black-400, #444);
  }
`


interface RPHistoryProps {
  onClose: () => void;
}

const RPHistory = ({onClose}: RPHistoryProps) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();
  const [selectedMenu, setSelectedMenu] = useState<'info' | 'charge' | 'history'>('info');
  const [myPoint, setMyPoint] = useState(0);

  useEffect(() => {
    GetUserPoint().then((res) => {
      setMyPoint(res.points)
    }).catch((e) => {
      enqueueSnackbar(e.message, {variant: 'error'});
    })
  }, []);


  useEffect(() => {
    const menu = query.get('section');
    if (menu) {
      setSelectedMenu(menu);
    }
  }, []);
  const handleSetMenu = (menu?: 'info' | 'charge' | 'history') => {
    if (menu) {
      setSelectedMenu(menu);
      query.set('section', menu);
      history.replace({search: query.toString(), state: location.state});
    } else {
      setSelectedMenu('info');
      history.replace({search: '', state: location.state});
    }
  }

  return <>
    <RPHistoryWrapper scrollLock={true}>
      <div className="header">
        <div className="close" onClick={onClose}>
          <img src="/image-web/Icon/Back.svg" alt="close"/>
        </div>
        <div className="title">RP 충전 및 사용 내역</div>
      </div>
      <MyRPPCWrapper>
        <div className='rp-wrapper'>
          <div className='rp-box'>
            <div className='title'>보유 RP</div>
            <div className='value'>{myPoint.toLocaleString()}</div>
          </div>
          <div className='button' onClick={() => {
            window.open('http://pf.kakao.com/_BxbmbG/chat', '_blank');
          }}>RP 충전</div>
        </div>
        <div className='description'>
          <li>충전은 러너러너 공식 카카오 채널을 통해서 진행됩니다.</li>
          <li>충전 후 환불이나 양도가 불가능합니다.</li>
          <li>부가세 포함 금액이며 세금 계산서 또는 현금영수증을 발행해드립니다.</li>
        </div>
      </MyRPPCWrapper>
      <MyRPWrapper>
        <div className='inner'>
          <div className='wrapper'>
            <div className='title'>보유 RP</div>
            <div className='vertical-line'/>
            <div className='point'>{myPoint.toLocaleString()}</div>
          </div>
        </div>
      </MyRPWrapper>
      <MenuWrapper>
        <div className={'item ' + (selectedMenu === 'info' ? 'selected' : '')}
             onClick={() => handleSetMenu('info')}>
          RP 충전
        </div>
        <div className={'item ' + (selectedMenu === 'charge' ? 'selected' : '')}
             onClick={() => handleSetMenu('charge')}>
          충전 내역
        </div>
        <div className={'item ' + (selectedMenu === 'history' ? 'selected' : '')}
             onClick={() => handleSetMenu('history')}>
          사용 내역
        </div>
      </MenuWrapper>
      <ContentWrapper>
        {
          selectedMenu === 'info' && <Charge/>
        }
        {
          selectedMenu === 'history' && <UsedHistory/>
        }
        {
          selectedMenu === 'charge' && <ChargeHistory/>
        }
      </ContentWrapper>
      <HistoryWrapper>
        <div className='title-wrapper'>
          <div className={'title '+(selectedMenu === 'history' ? 'selected' : '')} onClick={() => setSelectedMenu('history')}>사용 내역</div>
          <div className={'title '+(selectedMenu === 'charge' ? 'selected' : '')} onClick={() => setSelectedMenu('charge')}>충전 내역</div>
        </div>
        <div className='content'>
          {
            selectedMenu === 'history' && <UsedHistory/>
          }
          {
            selectedMenu === 'charge' && <ChargeHistory/>
          }
        </div>
      </HistoryWrapper>
    </RPHistoryWrapper>
  </>
}

export default RPHistory;
