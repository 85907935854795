import styled from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import Sheet from "react-modal-sheet";
import { enqueueSnackbar, useSnackbar } from "notistack";
import Write from "./Write";
import { useHistory, useParams } from "react-router-dom";
import { HoldemNowType } from "../../../api/types";
import {
  cafeAllMessageList,
  cafeAllMessageListLogin,
  GetUserPoint,
} from "../../../api/cafe";
import moment from "moment";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import useUserInfo from "../../../hooks/useUserInfo";
import HoldemNowDetail from "../HoldemNowDetail";
import useQueryParams from "../../../hooks/useQueryParams";
import PCWriteBox from "./PCWriteBox";
import { LOCAL_STORAGE_ACCESS_KEY } from "../../../utils/network";

const SearchWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: white;
  position: relative;
  @media ${MEDIA_DESKTOP} {
    padding-top: 50px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
  }

  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      max-width: 480px;
      max-height: 100%;
      position: relative;
    }

    > .pub {
      width: 100%;
      background: var(--Black-100, #f0f0f0);
      padding: 16px;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.26px;
      position: sticky;
      top: 0;
      left: 0;
    }

    > .list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      @media ${MEDIA_DESKTOP} {
        gap: 12px;
      }
    }
  }

  > .inner.lock {
    @media ${MEDIA_DESKTOP} {
      overflow: hidden;
    }
  }
`;

const Header = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  width: 100%;
  height: 48px;
  color: ${(p) => p.theme.color.black400};
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  gap: 8px;
  @media ${MEDIA_DESKTOP} {
    display: none;
  }

  > .close {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  > .title {
    flex: 1;
  }

  > .button {
    cursor: pointer;
    color: var(--Black-200, #b7b7b7);
    text-align: right;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.26px;
  }
`;

const HoldemItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  padding: 20px 16px;
  gap: 10px;
  cursor: pointer;
  @media ${MEDIA_DESKTOP} {
    border-radius: 12px;
    border: 1px solid var(--Black-100, #f0f0f0);
    background: #fff;
  }

  > .info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    > .thumbnail {
      width: 40px;
      height: 40px;
      background: gray;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;

      > .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        gap: 4px;

        > img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }

      > .createdAt {
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
      }
    }
  }

  > .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    > .content {
      width: 100%;
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
      display: -webkit-box;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      white-space: pre-wrap;
    }

    > .images {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 8px;
      background: #e5e5e5;
    }
  }

  > .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    > .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;

      > img {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }
  }
`;

const WriteModal = styled.div`
  width: 100vw;
  max-width: 500px;
  display: flex;
  padding: 30px 24px 60px 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 12px 12px 0px 0px;
  background: #fff;

  > .button-row {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;

    > .button {
      flex: 1;
      height: 48px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: ${(p) => p.theme.color.purple300};
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.165px;
    }

    > .button:active {
      background: #502bb5;
    }

    > .button.cancel {
      color: ${(p) => p.theme.color.purple300};
      background: none;
    }
  }

  > .close {
    cursor: pointer;
    position: absolute;
    object-fit: contain;
    width: 24px;
    height: 24px;
    top: 20px;
    right: 20px;
  }

  > .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    > .title {
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
    }

    > .description {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
    }
  }
`;

const MyRPWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px 0;
  background: #fff;

  > .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid var(--Black-100, #f0f0f0);
    padding: 0px 5px 0px 12px;

    > .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      padding-bottom: 1px;

      > .vertical-line {
        width: 1px;
        height: 10px;
        background: #f0f0f0;
      }

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      > .point {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    > .button {
      cursor: pointer;
      width: 38px;
      height: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--Purple-300, #6436e7);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 26px;
      background: var(--Purple-100, #f0eaff);
    }

    > .button:active {
      background: #502bb5;
    }
  }
`;

const PCMyInfoWrapper = styled.div`
  display: none;
  @media ${MEDIA_DESKTOP} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }

  > .profile-wrapper {
    width: 328px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .profile {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;

      > .profile {
        width: 48px;
        height: 48px;
        object-fit: contain;
        border-radius: 50%;
      }

      > .nickname {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -0.32px;
      }
    }

    > .button {
      padding: 8px 12px;
      border-radius: 15px;
      border: 1px solid var(--Black-200, #b7b7b7);
      cursor: pointer;
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .my-rp-wrapper {
    width: 328px;
    padding: 16px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--Black-100, #f0f0f0);

    > .wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        ont-weight: 500;
      }

      > .value {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    > .button {
      cursor: pointer;
      padding: 12px 20px;
      color: var(--Purple-300, #6436e7);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-radius: 4px;
      background: var(--Purple-100, #f0eaff);
    }

    > .button:active {
      background: #502bb5;
    }
  }
`;

const ImageGrid = styled.div<{
  length: number;
}>`
  width: 100%;

  > img {
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 8px;
    background: #e5e5e5;
    width: 100%;
  }

  ${(p) =>
    p.length > 1
      ? `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  `
      : ``}
  ${(p) =>
    p.length === 3
      ? `
    > img:nth-child(1) {
      grid-column: 1 / -1;
      aspect-ratio: 2 / 1;
    }
  `
      : ``}
`;

const HoldemNow = () => {
  const history = useHistory();
  const [showWriteSheet, setShowWriteSheet] = useState(false);
  const [showWrite, setShowWrite] = useState(false);
  const [list, setList] = useState<HoldemNowType[]>([]);
  const [myPoint, setMyPoint] = useState(0);
  const { user } = useUserInfo();
  const params = useParams<{
    id?: string;
    messageType?: string;
  }>();
  const query = useQueryParams();

  const detailId = params.id;
  const messageType = params.messageType;

  const pubId = useMemo<number | undefined>(() => {
    const id = query.get("pubId");
    if (id) {
      return Number(id);
    }
  }, []);

  const pubName = useMemo<string>(() => {
    return pubId && list.length > 0 ? list[0].pubName : "";
  }, [pubId, list]);
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY);

  useEffect(() => {
    getHoldemNowList();
  }, [user, showWrite, pubId]);
  const getHoldemNowList = () => {
    if (user) {
      cafeAllMessageListLogin(pubId).then((res) => {
        setList(res);
      });

      GetUserPoint().then((res) => {
        setMyPoint(res.points);
      });
    } else {
      cafeAllMessageList(pubId).then((res) => {
        setList(res);
      });
    }
  };

  const handleWrite = () => {
    if (!user) {
      history.push("/login", { redirect: window.location.href });

      enqueueSnackbar("로그인이 필요합니다.", { variant: "error" });

      return;
    }

    setShowWriteSheet(false);
    if (myPoint >= 0) {
      setShowWrite(true);
    } else {
      enqueueSnackbar("RP가 부족합니다. RP 충전 후 진행해주세요.", {
        variant: "error",
      });
    }
  };

  const handleItemClick = (id: number, messageType: string) => {
    history.push(`/holdemNow/${messageType}/${id}`);
  };

  const handleClickCharge = () => {
    if (!user) {
      history.push("/login", { redirect: window.location.href });
      enqueueSnackbar("로그인이 필요합니다.", { variant: "error" });
      return;
    } else {
      history.push("/mypage?menu=point&section=info");
    }
  };

  return (
    <SearchWrapper>
      {showWrite && (
        <Write mode={"write"} onClose={() => setShowWrite(false)} />
      )}
      {showWriteSheet && (
        <Sheet
          style={{
            width: "100%",
            maxWidth: 500,
            left: "50%",
            transform: "translateX(-50%)",
          }}
          isOpen={showWriteSheet}
          onClose={() => setShowWriteSheet(false)}
          disableDrag={true}
          detent={"content-height"}
        >
          <Sheet.Container
            style={{
              maxWidth: 500,
            }}
          >
            <Sheet.Content>
              <WriteModal>
                <img
                  className="close"
                  onClick={() => setShowWriteSheet(false)}
                  src="/image-web/Holdem%20Now/Icon/Close.svg"
                />
                <div className="info">
                  <div className="title">홀덤 NOW 작성</div>
                  <MyRPWrapper>
                    <div className="inner">
                      <div className="wrapper">
                        <div className="title">현재 보유 RP</div>
                        <div className="vertical-line" />
                        <div className="point">{myPoint.toLocaleString()}</div>
                      </div>
                      <div
                        className="button"
                        onClick={() => handleClickCharge()}
                      >
                        충전
                      </div>
                    </div>
                  </MyRPWrapper>
                </div>
                <div className="button-row">
                  <div className="button" onClick={() => handleWrite()}>
                    홀덤 NOW 글쓰기
                  </div>
                </div>
              </WriteModal>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={() => {}} />
        </Sheet>
      )}
      <Header>
        {pubId && (
          <div className="close" onClick={() => history.goBack()}>
            <img src="/image-web/Icon/Back.svg" alt="close" />
          </div>
        )}
        <span className="title">홀덤 NOW</span>
        {!pubId && (
          <span
            className="button"
            onClick={() => {
              const accessToken = localStorage.getItem(
                LOCAL_STORAGE_ACCESS_KEY
              );
              if (!accessToken) {
                history.push("/login", { redirect: window.location.href });
                enqueueSnackbar("로그인이 필요한 서비스 입니다.", {
                  variant: "error",
                });
              } else {
                handleWrite();
              }
            }}
          >
            글 작성
          </span>
        )}
      </Header>
      {accessToken && (
        <PCMyInfoWrapper>
          <div className="profile-wrapper">
            <div className="profile">
              <img
                className="profile"
                src={user?.profileImageUrl || "/image-web/default_profile.png"}
              />
              <div className="nickname">{user?.nickname}</div>
            </div>
            <div className="button">작성한 글</div>
          </div>
          <div className="my-rp-wrapper">
            <div className="wrapper">
              <div className="title">보유 RP</div>
              <div className="value">{myPoint.toLocaleString()}</div>
            </div>
            <div className="button">충전하기</div>
          </div>
        </PCMyInfoWrapper>
      )}
      <div className={"inner " + (detailId && messageType ? "lock" : "")}>
        {detailId && messageType && (
          <HoldemNowDetail
            onClose={() => {
              history.push("/holdemNow");
            }}
          />
        )}
        {pubName && <div className="pub">{pubName}의 홀덤 NOW</div>}
        <div className="list">
          <PCWriteBox
            onUpdate={() => {
              //PCWriteBox에서 글 작성 후 새로고침
              getHoldemNowList();
            }}
          />
          {list.map((item, idx) => {
            return (
              <HoldemItem
                onClick={() => {
                  handleItemClick(item.id, item.messageType);
                }}
              >
                <div
                  className="info-wrapper"
                  onClick={(event) => {
                    history.push("/store/" + item.pubId);
                    event.stopPropagation();
                  }}
                >
                  <div className="thumbnail">
                    {item.coverImageUrl ? (
                      <img src={item.coverImageUrl} />
                    ) : item.messageType === "PUB" ? (
                      <img src="https://dfesoodpx4jgd.cloudfront.net/cafe/holdem_default.png" />
                    ) : (
                      <img src="https://dfesoodpx4jgd.cloudfront.net/user/default_profile.png" />
                    )}
                  </div>
                  <div className="info">
                    <div className="title">
                      {item.pubName}
                      {item.messageType === "PUB" &&
                        item.pubType === "PREMIUM" && (
                          <img src="/image-web/Holdem%20Now/Icon/star.png" />
                        )}
                      {item.messageType === "PUB" &&
                        item.pubType === "AFFILIATE" && (
                          <img src="/image-web/store/store_check.svg" />
                        )}
                      {item.messageType === "PUB" &&
                        item.pubType === "NORMAL" && (
                          <img src="/image-web/store/store_normal.png" />
                        )}
                    </div>
                    <div className="createdAt">
                      {moment(item.createdAt).fromNow()}
                    </div>
                  </div>
                </div>
                <div className="content-wrapper">
                  <div className="content">{item.message}</div>
                  <ImageGrid length={item.imageList.length}>
                    {item.imageList.slice(0, 4).map((url) => (
                      <img className="images" src={url} />
                    ))}
                  </ImageGrid>
                </div>
                <div className="bottom">
                  <div className="item">
                    {item.recommended ? (
                      <img src="/image-web/Icon/Heart/small.svg" alt="좋아요" />
                    ) : (
                      <img
                        src="/image-web/Icon/Heart/small-gray.svg"
                        alt="좋아요"
                      />
                    )}

                    <span>{item.recommendationCount.toLocaleString()}</span>
                  </div>
                  <div className="item">·</div>
                  <div className="item">
                    <img src="/image-web/Holdem%20Now/Icon/view/small.svg" />
                    <span>{item.viewCount.toLocaleString()}</span>
                  </div>
                  <div className="item">·</div>
                  <div className="item">
                    <img src="/image-web/Holdem%20Now/Icon/comment/small.svg" />
                    <span>{item.commentCount.toLocaleString()}</span>
                  </div>
                </div>
              </HoldemItem>
            );
          })}
        </div>
      </div>
    </SearchWrapper>
  );
};
export default HoldemNow;
