import styled from "styled-components";
import {enqueueSnackbar, useSnackbar} from "notistack";
import useDialog from "../../../../hooks/useDialog";
import {useCallback, useContext, useEffect, useState} from "react";
import DateTimeWheel from "../../../../components/common/DateTimeWheel";
import moment from "moment";
import {cafeDeleteTournament, cafeModifyTournament, cafeRegisterTournament} from "../../../../api/cafe";
import {StoreContext} from "../index";
import {useSetRecoilState} from "recoil";
import {loadingState} from "../../../../recoil/app";
import PhotoUploadPopup from "../../../../components/common/PhotoUploadPopup";
import {PubTournamentItem} from "../../../../api/types";
import {urlToFile} from "../../../../utils/common";

const EditTournamentWrapper = styled.div<{
  scrollLock: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
overscroll-behavior: none;
  z-index: 12;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${p => p.scrollLock ? `
      overflow-y: hidden;
  ` : `
  
  `}
  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
  }

  > .header {
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;
    background: white;

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    > .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${p => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
      color: var(--Purple-300, #6436E7);
      text-align: right;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .floating-button-wrapper {
    background: white;
    position: fixed;
    bottom: 0;
    padding: 30px 24px 48px;
    width: 100%;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);

    > .button {
      cursor: pointer;
      width: 100%;
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.165px;
      border-radius: 8px;
      text-align: center;
      padding: 15px 0;
      background: var(--Purple-300, #6436E7);
    }
    > .button:active {
      background: #502bb5;
    }
  }
`

const DeleteButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  background: rgba(217, 24, 24, 0.10);
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #D91818;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
`
const WriteButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  background: var(--Purple-300, #6436E7);
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
`
const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  margin: 20px 0;
  gap: 16px;
  background: white;

  &:last-child {
    padding-bottom: 48px;
  }

  > .title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    > .count {
      color: var(--Black-200, #B7B7B7);
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 18.2px */
    }
  }

  > .time-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .title {
      color: var(--Black-500, #202020);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    > .time-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;

      > .item {
        height: 36px;
        width: 94px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: var(--Black-100, #F0F0F0);
        color: var(--Black-500, #202020);
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      > .item.selected {
        color: var(--Purple-300, #6436E7);
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  > input {
    width: 100%;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid var(--Black-100, #F0F0F0);
    background: white;
    outline: none;
    color: var(--Black-500, #202020);
    text-align: left;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  > .photo-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    > .item {
      cursor: pointer;
      position: relative;
      width: 80px;
      height: 80px;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #F0F0F0;

      > .plus {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
      }

      > .photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > .remove {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 100%;
        padding: 4px 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 0px 0px 4px 4px;
        background: rgba(0, 0, 0, 0.50);
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`

interface WritePageProps {
  onClose: () => void;
  mode: 'write' | 'edit'
  tournament?: PubTournamentItem;
}

const EditTournamentInfo = (
  {
    onClose,
    mode,
    tournament
  }: WritePageProps
) => {
  const {
    data,
    update
  } = useContext(StoreContext);
  const setLoading = useSetRecoilState(loadingState);

  const {openDialog} = useDialog();
  const [tournamentDateTime, setTournamentDateTime] = useState<{
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
  }>({
    startDate: moment().format('YYYY.MM.DD'),
    startTime: moment().format('A hh:mm'),
    endDate: moment().format('YYYY.MM.DD'),
    endTime: moment().format('A hh:mm')
  })
  const [showDateTimePicker, setShowDateTimePicker] = useState<{
    type1: 'start' | 'end',
    type2: 'date' | 'time'
  } | null>(null)
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (tournament) {
      setTitle(tournament.title);
      setTournamentDateTime({
        startDate: moment(tournament.startAt).format('YYYY.MM.DD'),
        startTime: moment(tournament.startAt).format('A hh:mm'),
        endDate: moment(tournament.endAt).format('YYYY.MM.DD'),
        endTime: moment(tournament.endAt).format('A hh:mm')
      });
    }
  }, [tournament]);

  const handleWrite = () => {
    openDialog({
      text: '토너먼트를 등록하시겠습니까?<br/>',
      confirm: true,
      confirmText: '등록하기',
      type: 'web',
      onConfirm: () => {
        if (!title) {
          return enqueueSnackbar('토너먼트 제목을 입력하세요', {variant: 'error'});
        }

        setLoading(true);

        cafeRegisterTournament(data.id, [], {
          title: title,
          startAt: moment(tournamentDateTime.startDate + ' ' + tournamentDateTime.startTime, "YYYY.MM.DD a HH:mm").format('YYYY-MM-DDTHH:mm:ss'),
          endAt: moment(tournamentDateTime.endDate + ' ' + tournamentDateTime.endTime, "YYYY.MM.DD a HH:mm").format('YYYY-MM-DDTHH:mm:ss'),
        }).then(() => {
          update();
          enqueueSnackbar('토너먼트가 등록되었습니다.', {variant: 'success'})
          onClose();
        }).catch((e: any) => {
          enqueueSnackbar(e.message, {variant: 'error'});
        }).finally(() => {
          setLoading(false);
        })
      }
    })
  }

  const handleEdit = () => {
    openDialog({
      text: '해당 토너먼트를 수정하시겠습니까?',
      confirm: true,
      confirmText: '수정하기',
      type: 'web',
      confirmColor: '#D91818',
      onConfirm: () => {
        if (!tournament?.tournamentId) {
          return;
        }

        if (!title) {
          return enqueueSnackbar('토너먼트 제목을 입력하세요', {variant: 'error'});
        }

        setLoading(true);

        cafeModifyTournament(data.id, tournament.tournamentId, [], {
          title: title,
          startAt: moment(tournamentDateTime.startDate + ' ' + tournamentDateTime.startTime, "YYYY.MM.DD a HH:mm").format('YYYY-MM-DDTHH:mm:ss'),
          endAt: moment(tournamentDateTime.endDate + ' ' + tournamentDateTime.endTime, "YYYY.MM.DD a HH:mm").format('YYYY-MM-DDTHH:mm:ss'),
        }).then(() => {
          update();
          enqueueSnackbar('토너먼트가 수정되었습니다.', {variant: 'success'});
          onClose();
        }).catch((e: any) => {
          enqueueSnackbar(e.message, {variant: 'error'});
        }).finally(() => {
          setLoading(false);
        });
      }
    })
  }

  const handleDelete = () => {
    openDialog({
      text: '해당 토너먼트를 삭제하시겠습니까?',
      confirm: true,
      confirmText: '삭제하기',
      type: 'web',
      confirmColor: '#D91818',
      onConfirm: () => {
        if (!tournament?.tournamentId) {
          return;
        }

        setLoading(true);

        cafeDeleteTournament(data.id, tournament.tournamentId).then(() => {
          update();
          enqueueSnackbar('토너먼트가 삭제되었습니다.', {variant: 'success'})
          onClose();
        }).catch((e) => {
          enqueueSnackbar(e.message, {variant: 'error'});
        }).finally(() => {
          setLoading(false);
        });
      }
    })
  }

  return <>
    <EditTournamentWrapper scrollLock={false}>
      <div className="header">
        <div className="close" onClick={onClose}>
          <img src="/image-web/Icon/Close_gray.svg" alt="close"/>
        </div>
        <div className="title">{mode === 'edit' ? '토너먼트 정보 수정' : '토너먼트 정보 등록'}</div>
      </div>
      <div className='inner'>
        <Box>
          <div className="title-row">
            <div className="title">토너먼트 제목</div>
          </div>
          <input type="text" value={title} placeholder="토너먼트 제목을 입력해주세요" onChange={(e) => setTitle(e.target.value)}/>
        </Box>
        <Box>
          <div className="title-row">
            <div className="title">토너먼트 진행 시간</div>
          </div>
          <div className='time-wrapper'>
            <div className='title'>시작</div>
            <div className='time-row'>
              <div className='item' onClick={() => {
                if (showDateTimePicker !== null && showDateTimePicker.type1 === 'start' && showDateTimePicker.type2 === 'date') {
                  setShowDateTimePicker(null)
                } else {
                  setShowDateTimePicker({
                    type1: 'start',
                    type2: 'date'
                  })
                }
              }}>{tournamentDateTime.startDate}</div>
              <div className='item' onClick={() => {
                if (showDateTimePicker !== null && showDateTimePicker.type1 === 'start' && showDateTimePicker.type2 === 'time') {
                  setShowDateTimePicker(null)
                } else {
                  setShowDateTimePicker({
                    type1: 'start',
                    type2: 'time'
                  })
                }
              }}>{tournamentDateTime.startTime}</div>
            </div>
          </div>
          {
            (showDateTimePicker !== null && showDateTimePicker.type1 === 'start' && showDateTimePicker.type2 === 'date') &&
            <DateTimeWheel type={'date'} onChange={(value) => {
              setTournamentDateTime({
                ...tournamentDateTime,
                startDate: value
              })
            }}/>
          }
          {
            (showDateTimePicker !== null && showDateTimePicker.type1 === 'start' && showDateTimePicker.type2 === 'time') &&
            <DateTimeWheel type={'time'} onChange={(value) => {
              setTournamentDateTime({
                ...tournamentDateTime,
                startTime: value
              })
            }}/>
          }
          <div className='time-wrapper'>
            <div className='title'>종료</div>
            <div className='time-row'>
              <div className='item' onClick={() => {
                if (showDateTimePicker !== null && showDateTimePicker.type1 === 'end' && showDateTimePicker.type2 === 'date') {
                  setShowDateTimePicker(null)
                } else {
                  setShowDateTimePicker({
                    type1: 'end',
                    type2: 'date'
                  })
                }
              }}>{tournamentDateTime.endDate}</div>
              <div className='item' onClick={() => {
                if (showDateTimePicker !== null && showDateTimePicker.type1 === 'end' && showDateTimePicker.type2 === 'time') {
                  setShowDateTimePicker(null)
                } else {
                  setShowDateTimePicker({
                    type1: 'end',
                    type2: 'time'
                  })
                }
              }}>{tournamentDateTime.endTime}</div>
            </div>
          </div>
          {
            (showDateTimePicker !== null && showDateTimePicker.type1 === 'end' && showDateTimePicker.type2 === 'date') &&
            <DateTimeWheel type={'date'} onChange={(value) => {
              setTournamentDateTime({
                ...tournamentDateTime,
                endDate: value
              })
            }}/>
          }
          {
            (showDateTimePicker !== null && showDateTimePicker.type1 === 'end' && showDateTimePicker.type2 === 'time') &&
            <DateTimeWheel type={'time'} onChange={(value) => {
              setTournamentDateTime({
                ...tournamentDateTime,
                endTime: value
              })
            }}/>
          }
        </Box>
        {
          mode === 'edit' && <Box>
            <DeleteButton onClick={handleDelete}>
              삭제하기
            </DeleteButton>
          </Box>
        }
        {
          mode === 'edit' &&
          <Box>
            <WriteButton onClick={handleEdit}>
              수정하기
            </WriteButton>
          </Box>
        }
        {
          mode === 'write' &&
          <Box>
            <WriteButton onClick={handleWrite}>
              등록하기
            </WriteButton>
          </Box>
        }
      </div>
    </EditTournamentWrapper>
  </>
}

export default EditTournamentInfo;
