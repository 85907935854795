import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";
import { Cafe } from "../../../api/types";
import { DashboardCity } from "../../../api/dashboard";
import { getDistanceKm } from "../../../utils/common";
import { useRecoilValue } from "recoil";
import { geoCoordState } from "../../../recoil/geo";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";

const RecommendPubWrapper = styled.div`
  width: 100%;
  margin-top: 64px;
  display: flex;
  background: white;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  @media ${MEDIA_DESKTOP} {
    gap: 20px;
  }
  > .title-row {
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .title {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 8px;
      justify-content: flex-start;

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -0.4px;
      }

      > .address {
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }

    > .more {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      cursor: pointer;

      > img {
        width: 16px;
        height: 16px;
      }

      > span {
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.26px;
      }
    }
  }

  .swiper {
    width: 100%;
    padding-left: 16px;
  }

  .swiper-slide {
    width: 264px;
  }
`;
const CardItemWrapper = styled.div`
  display: flex;
  width: 264px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;

  > .thumbnail {
    width: 264px;
    height: 165px;
    border-radius: 8px;
    position: relative;
    background: gray;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  > .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    > .title-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 4px;

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.32px;
      }

      > .count {
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }

    > .distance {
      margin-top: 7px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
`;
const PubList = ({ list, city }: { list: Cafe[]; city?: DashboardCity }) => {
  const history = useHistory();
  const { latitude, longitude } = useRecoilValue(geoCoordState);

  const address = useMemo(() => {
    if (!city || !city.city) {
      return "";
    }

    return `${city.province.name} ${city.city.name} ${
      city.street?.name || "알 수 없음"
    }`;
  }, [city]);

  if (list.length === 0) {
    return null;
  }

  return (
    <RecommendPubWrapper>
      <div className="title-row">
        <div className="title">
          <div className="title">내 주변 홀덤펍</div>
          <div className="address">{address}</div>
        </div>
        <div
          className="more"
          onClick={() => {
            history.push("/search");
          }}
        >
          <img src="/image-web/home/more.svg" alt="more" />
          <span>더보기</span>
        </div>
      </div>
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={false}
        spaceBetween={16}
        className="mySwiper"
      >
        {list.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <CardItemWrapper
                onClick={() => history.push(`/store/${item.id}`)}
              >
                <div className="thumbnail">
                  {item.images[0] ? (
                    <img src={item.images[0].imageUrl} />
                  ) : (
                    <img src="https://dfesoodpx4jgd.cloudfront.net/cafe/default.png" />
                  )}
                </div>
                <div className="info">
                  <div className="title-row">
                    <div className="title">{item.cafeName}</div>
                  </div>
                  <div className="distance">
                    현재 내 위치에서{" "}
                    {getDistanceKm(latitude, longitude, item.lat, item.lon)}km
                  </div>
                </div>
              </CardItemWrapper>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </RecommendPubWrapper>
  );
};

export default PubList;
