import React from "react";
import styled from "styled-components";
import { openNewWindow } from "../../../utils/common";
import { IYoutube } from "../../../api/youtube";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";

const YoutubeWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
`;

const Title = styled.div`
  padding: 0 16px;
  color: #444;
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: -0.4px;
  margin-bottom: 16px;
`;

const ChannelList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;

  @media ${MEDIA_DESKTOP} {
    gap: 16px;
  }
`;

const CardItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; // 16:9 비율 유지
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`;

const Thumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TextWrapper = styled.div`
  width: 100%;
  padding: 8px 0;
  height: 26px;
  display: flex;
  align-items: flex-start;
`;

const Text = styled.div`
  color: #444;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.24px;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;

  @media ${MEDIA_DESKTOP} {
    font-size: 14px;
    letter-spacing: -0.28px;
  }
`;

const YoutubeList = ({ list }: { list: IYoutube[] }) => {
  if (list.length === 0) {
    return null;
  }

  return (
    <YoutubeWrapper>
      <Title>홀덤 영상</Title>
      <ChannelList>
        {list.map((item) => (
          <CardItemWrapper
            key={item.id}
            onClick={() => openNewWindow(item.url)}
          >
            <ThumbnailWrapper>
              <Thumbnail src={item.thumbnailUrl} alt={item.title} />
            </ThumbnailWrapper>
            <TextWrapper>
              <Text title={item.title}>{item.title}</Text>
            </TextWrapper>
          </CardItemWrapper>
        ))}
      </ChannelList>
    </YoutubeWrapper>
  );
};

export default YoutubeList;
