import styled from "styled-components";
import { enqueueSnackbar, useSnackbar } from "notistack";
import useDialog from "../../../../hooks/useDialog";
import { useContext, useState } from "react";
import DateTimeWheel from "../../../../components/common/DateTimeWheel";
import moment from "moment";
import { cafeUpdateDetailInfo } from "../../../../api/cafe";
import { StoreContext } from "../index";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/app";
import Slider from "rc-slider";
import { getKoreanNumber } from "../../../../utils/common";

const DetailInfoWrapper = styled.div<{ scrollLock: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
  overscroll-behavior: none;
  z-index: 12;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${(p) =>
    p.scrollLock
      ? `
      overflow-y: hidden;
  `
      : `
  
  `}
  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
  }

  > .header {
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;
    background: white;

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    > .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${(p) => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
      color: var(--Purple-300, #6436e7);
      text-align: right;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .floating-button-wrapper {
    background: white;
    position: fixed;
    bottom: 0;
    padding: 30px 24px 48px;
    width: 100%;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);

    > .button {
      cursor: pointer;
      width: 100%;
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.165px;
      border-radius: 8px;
      text-align: center;
      padding: 15px 0;
      background: var(--Purple-300, #6436e7);
    }
    > .button:active {
      background: #502bb5;
    }
  }
`;

const DeleteButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  background: rgba(217, 24, 24, 0.1);
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #d91818;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
`;
const WriteButton = styled.div`
  cursor: pointer;
  border-radius: 8px;
  background: var(--Purple-300, #6436e7);
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
`;
const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  margin: 20px 0;
  gap: 16px;
  background: white;

  &:last-child {
    padding-bottom: 48px;
  }

  > .title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    > .count {
      color: var(--Black-200, #b7b7b7);
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 18.2px */
    }
  }
  > .box {
    padding: 8px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    > .price {
      width: 100%;
      color: var(--Black-400, #444);
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      > .disabled {
        color: var(--Black-200, #b7b7b7);
      }
    }

    > .rc-slider-wrapper {
      width: calc(100% - 32px);
    }

    > .range-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .text {
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  > .time-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .title {
      color: var(--Black-500, #202020);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    > .time-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;

      > .item {
        height: 36px;
        width: 94px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: var(--Black-100, #f0f0f0);
        color: var(--Black-500, #202020);
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      > .item.selected {
        color: var(--Purple-300, #6436e7);
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  > input {
    width: 100%;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid var(--Black-100, #f0f0f0);
    background: white;
    outline: none;
    color: var(--Black-500, #202020);
    text-align: left;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &::placeholder {
      color: var(--Black-200, #b7b7b7);
    }
  }

  > textarea {
    min-height: 100px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--Black-100, #f0f0f0);
    padding: 10px 12px;
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    outline: none;
    &::placeholder {
      color: var(--Black-200, #b7b7b7);
    }
  }
  > .photo-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    > .item {
      cursor: pointer;
      position: relative;
      width: 80px;
      height: 80px;
      border-radius: 4px;
      border: 1px solid #f0f0f0;

      > .plus {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
      }

      > .remove {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 100%;
        padding: 4px 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 0px 0px 4px 4px;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;
interface WritePageProps {
  cafeId: number;
  onClose: () => void;
}

const EditDetailInfo = ({ cafeId, onClose }: WritePageProps) => {
  const { openDialog } = useDialog();
  const { data: cafe, update } = useContext(StoreContext);
  const setLoading = useSetRecoilState(loadingState);

  const [prizeValue, setPrizeValue] = useState<{
    min: number;
    max: number;
    selectedMin: number;
    selectedMax: number;
  }>({
    min: 50,
    max: 100,
    selectedMin: cafe.prize ?? 50,
    selectedMax: cafe.prizeMax ?? 100,
  });
  const [blindValue, setBlindValue] = useState({
    min: 1,
    max: 30,
    selectedMin: cafe.blindUp ?? 0,
    selectedMax: cafe.blindUpMax ?? 0,
  });
  const [buyInValue, setBuyInValue] = useState<{
    min: number;
    max: number;
    selectedMin: number;
    selectedMax: number;
  }>({
    min: 1,
    max: 100,
    selectedMin: cafe.buyIn ?? 0,
    selectedMax: cafe.buyInMax ?? 0,
  });

  const handleSubmit = () => {
    const data: any = {};

    if (
      prizeValue.min !== prizeValue.selectedMin ||
      prizeValue.max !== prizeValue.selectedMax
    ) {
      data.prize = prizeValue.selectedMin;
      data.prizeMax = prizeValue.selectedMax;
    }

    if (blindValue.selectedMin !== cafe.blindUp) {
      data.blindUp = blindValue.selectedMin;
    }
    if (blindValue.selectedMax !== cafe.blindUpMax) {
      data.blindUpMax = blindValue.selectedMax;
    }
    if (buyInValue.selectedMin !== cafe.buyIn) {
      data.buyIn = buyInValue.selectedMin;
    }
    if (buyInValue.selectedMax !== cafe.buyInMax) {
      data.buyInMax = buyInValue.selectedMax;
    }
    const informationUse = document.getElementById(
      "description"
    ) as HTMLInputElement | null;
    if (informationUse?.value) {
      data.informationUse = informationUse.value;
    }

    setLoading(true);

    cafeUpdateDetailInfo(cafeId, data)
      .then(() => {
        update();
        enqueueSnackbar("정보가 수정되었습니다.", { variant: "success" });
        onClose();
      })
      .catch((e: any) => {
        enqueueSnackbar(e.message, { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <DetailInfoWrapper scrollLock={false}>
        <div className="header">
          <div className="close" onClick={onClose}>
            <img src="/image-web/Icon/Close_gray.svg" alt="close" />
          </div>
          <div className="title">상세 정보</div>
        </div>
        <div className="inner">
          <Box>
            <div className="title-row">
              <div className="title">프라이즈</div>
            </div>
            <div className="box">
              <div className="price">
                {prizeValue.min === prizeValue.selectedMin &&
                prizeValue.max === prizeValue.selectedMax ? (
                  <div className="disabled">상관없음</div>
                ) : (
                  <>
                    {prizeValue.selectedMin.toLocaleString()}%~
                    {prizeValue.selectedMax.toLocaleString()}%
                  </>
                )}
              </div>
              <div className="rc-slider-wrapper">
                <div
                  style={{
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                />
                <Slider
                  min={prizeValue.min}
                  max={prizeValue.max}
                  range={true}
                  allowCross={false}
                  defaultValue={[
                    prizeValue.selectedMin,
                    prizeValue.selectedMax,
                  ]}
                  value={[prizeValue.selectedMin, prizeValue.selectedMax]}
                  onChange={(value) => {
                    console.log("onChange", value);
                    if (Array.isArray(value)) {
                      setPrizeValue({
                        ...prizeValue,
                        selectedMin: value[0],
                        selectedMax: value[1],
                      });
                    }
                  }}
                  trackStyle={{
                    backgroundColor: "#6436E7",
                    height: 4,
                  }}
                  handleStyle={{
                    borderWidth: 1.5,
                    borderColor: "#6436E7",
                    height: 26,
                    width: 26,
                    marginLeft: 0,
                    marginTop: -12,
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
                    opacity: 1,
                  }}
                  activeDotStyle={{
                    boxShadow: "none",
                  }}
                />
              </div>
              <div className="range-row">
                <div className="text">{prizeValue.min.toLocaleString()}%</div>
                <div className="text">
                  {((prizeValue.min + prizeValue.max) / 2).toLocaleString()}%
                </div>
                <div className="text">{prizeValue.max.toLocaleString()}%</div>
              </div>
            </div>
          </Box>
          <Box>
            <div className="title-row">
              <div className="title">블라인드 업 타임</div>
            </div>
            <div className="box">
              <div className="price">
                {blindValue.selectedMin +
                  "분 ~ " +
                  blindValue.selectedMax +
                  "분"}
              </div>
              <div className="rc-slider-wrapper">
                <div
                  style={{
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                />
                <Slider
                  min={1}
                  max={30}
                  range={true}
                  allowCross={false}
                  defaultValue={[
                    blindValue.selectedMin,
                    blindValue.selectedMax,
                  ]}
                  value={[blindValue.selectedMin, blindValue.selectedMax]}
                  onChange={(value) => {
                    if (Array.isArray(value)) {
                      setBlindValue({
                        ...blindValue,
                        selectedMin: value[0],
                        selectedMax: value[1],
                      });
                    }
                  }}
                  trackStyle={{
                    backgroundColor: "#6436E7",
                    height: 4,
                  }}
                  handleStyle={{
                    borderWidth: 1.5,
                    borderColor: "#6436E7",
                    height: 26,
                    width: 26,
                    marginLeft: 0,
                    marginTop: -12,
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
                    opacity: 1,
                  }}
                  activeDotStyle={{
                    boxShadow: "none",
                  }}
                />
              </div>
              <div className="range-row">
                <div className="text">정보없음</div>
                <div className="text">15분</div>
                <div className="text">30분</div>
              </div>
            </div>
          </Box>
          <Box>
            <div className="title-row">
              <div className="title">바이인</div>
            </div>
            <div className="box">
              <div className="price">
                {getKoreanNumber(buyInValue.selectedMin)} ~{" "}
                {getKoreanNumber(buyInValue.selectedMax)}
              </div>
              <div className="rc-slider-wrapper">
                <div
                  style={{
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                />
                <Slider
                  min={10000}
                  max={1000000}
                  range={true}
                  allowCross={false}
                  defaultValue={[
                    buyInValue.selectedMin,
                    buyInValue.selectedMax,
                  ]}
                  value={[buyInValue.selectedMin, buyInValue.selectedMax]}
                  onChange={(value) => {
                    if (Array.isArray(value)) {
                      setBuyInValue({
                        ...buyInValue,
                        selectedMin: value[0],
                        selectedMax: value[1],
                      });
                    }
                  }}
                  trackStyle={{
                    backgroundColor: "#6436E7",
                    height: 4,
                  }}
                  handleStyle={{
                    borderWidth: 1.5,
                    borderColor: "#6436E7",
                    height: 26,
                    width: 26,
                    marginLeft: 0,
                    marginTop: -12,
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
                    opacity: 1,
                  }}
                  activeDotStyle={{
                    boxShadow: "none",
                  }}
                />
              </div>
              <div className="range-row">
                <div className="text">1만</div>
                <div className="text">50만</div>
                <div className="text">100만</div>
              </div>
            </div>
          </Box>
          <Box>
            <div className="title-row">
              <div className="title">매장 이용 안내</div>
            </div>
            <textarea
              name="description"
              id="description"
              placeholder="안내할 내용을 입력해주세요."
              defaultValue={cafe?.informationUse}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = "1px";
                target.style.height = target.scrollHeight + "px";
              }}
            />
          </Box>
        </div>
        <div className="floating-button-wrapper">
          <div className="button" onClick={handleSubmit}>
            수정하기
          </div>
        </div>
      </DetailInfoWrapper>
    </>
  );
};

export default EditDetailInfo;
