import styled from "styled-components";

const ChargeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  > .inner {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background: var(--Black-100, #F0F0F0);
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 18.2px */
    letter-spacing: -0.26px;
    li {
      padding-left: 1.28571429em;
      text-indent: -1.28571429em;
    }
  }
`


const GridWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`

const CardWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 12px 12px 16px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.20);
  >.icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  >.point {
    margin-top: 12px;
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
  }
  >.money {
    margin-top: 4px;
    color: var(--Black-300, #808080);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.26px;
  }
`

interface CardProps {
  point: string;
  money: number;
}

const Card = ({point, money}: CardProps) => {
  return <CardWrapper onClick={()=>{
    window.open('http://pf.kakao.com/_BxbmbG/chat', '_blank');
  }}>
    <img className='icon' src='/image-web/Point.svg'/>
    <div className='point'>
      {point} RP 충전
    </div>
    <div className='money'>
      ({money.toLocaleString()}원)
    </div>
  </CardWrapper>
}

const Charge = () => {
  return <ChargeWrapper>
    <div className='inner'>
      <li>충전은 러너러너 공식 카카오 채널을 통해서 진행됩니다.</li>
      <li>충전 후 환불이나 양도가 불가능합니다.</li>
      <li>부가세 포함 금액이며 세금 계산서 또는 현금영수증을 발행해드립니다.</li>
    </div>
    <GridWrapper>
      <Card point={'36만'} money={50000}/>
      <Card point={'78만'} money={100000}/>
      <Card point={'126만'} money={150000}/>
    </GridWrapper>
  </ChargeWrapper>
}

export default Charge;
