import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import ModifyProfile from "./ModifyProfile";
import ModifyPassword from "./ModifyPassword";
import RPHistory from "./RPHistory";
import { useHistory, useLocation } from "react-router-dom";
import useDialog from "../../../hooks/useDialog";
import Notice from "./Notice";
import FAQ from "./FAQ";
import Referral from "./Referral";
import {
  clearCredential,
  hasCredential,
  LOCAL_STORAGE_ACCESS_KEY,
} from "../../../utils/network";
import { updateProfileImage } from "../../../api/auth";
import { enqueueSnackbar } from "notistack";
import useUserInfo from "../../../hooks/useUserInfo";
import { getUserTicketInfo, UserTicketInfoInterface } from "../../../api/game";

import ManagePub from "../ManagePub";
import ManageRecruit from "../ManageRecruit";
import { getFriendRecommendCount } from "../../../api/referral";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import { GetUserPoint, TestAddUserPoint } from "../../../api/cafe";
import useQueryParams from "../../../hooks/useQueryParams";
import RegisterPreminumPubInfo from "../Search/RegisterPreminumPubInfo";
import { copyClipboard, openNewWindow } from "../../../utils/common";
import MarketNotice from "./MarketNotice";
import useNativeApp, { isApp } from "../../../hooks/useNativeApp";
import { pushRegister } from "../../../api/push";

const MyPageWrapper = styled.div`
  width: 100%;
  height: calc(100svh - 84px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: white;
  position: relative;

  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    overscroll-behavior: none;
  }
`;
const HorizontalBar = styled.div`
  width: 100%;
  height: 8px;
  background: ${(p) => p.theme.color.black100};
`;

const MyProfileWrapper = styled.div<{
  scrollLock: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 30px;
  ${(p) =>
    p.scrollLock
      ? `
    overflow-y: hidden;
  `
      : ``};

  > .profile-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    > .profile {
      width: 84px;
      height: 84px;
      border-radius: 50%;
      background: gray;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .edit {
        position: absolute;
        width: 24px;
        height: 24px;
        background-image: url(/image-web/Icon/Edit-Profile-Img.svg);
        bottom: 0;
        right: 0;
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      > .tag-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        > .tag {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 2px;
          border-radius: 2px;
          padding: 4px;
          border: 1px solid ${(p) => p.theme.color.purple100};

          > img {
            width: 16px;
          }

          color: ${(p) => p.theme.color.purple300};
          text-align: right;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
        }
      }

      > .nickname {
        margin-top: 8px;
        color: ${(p) => p.theme.color.black400};
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -0.4px;
      }
    }
  }

  > .info-row {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    > .item {
      width: 78px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      > .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        > img {
          cursor: pointer;
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }

      > .value {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;
const MenuWrapper = styled.div`
  padding: 20px 16px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;

  > .row {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > .title {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
      gap: 12px;

      > img {
        width: 24px;
        height: 24px;
      }
    }

    > .arrow {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
    }
  }
`;
const CustomerServiceWrapper = styled.div`
  padding: 20px 16px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > .title {
    width: 100%;
    text-align: left;
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
  }

  > .grid-wrapper {
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 12px;

    > .item {
      padding: 9.5px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      > .icon-wrapper {
        width: 36px;
        height: 36px;
        border-radius: 20px;
        background: var(--Black-100, #f0f0f0);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > .icon {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
      }

      > .title {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
type MenuType =
  | "profile"
  | "password"
  | "point"
  | "notice"
  | "market-notice"
  | "faq"
  | "referral"
  | "manage-pub"
  | "manage-recruit"
  | null;
const Index = () => {
  const [showRegisterPremium, setShowRegisterPremium] = useState(false);
  const setLoading = useSetRecoilState(loadingState);
  const { openDialog } = useDialog();
  const history = useHistory();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState<MenuType>(null);
  const { user, setUser, refreshUser } = useUserInfo(true);
  const [userTicketInfo, setUserTicketInfo] =
    useState<UserTicketInfoInterface | null>(null);

  const [friendRecommendCount, setFriendRecommendCount] = useState<number>(0);
  const [myPoint, setMyPoint] = useState(0);

  const query = useQueryParams();
  const { sendMessageToNative } = useNativeApp();

  useEffect(() => {
    const menu = query.get("menu");
    if (menu) {
      setShowMenu(menu);
    }
  }, []);

  useEffect(() => {
    if (user) {
      getUserTicketInfo({
        userId: user.id,
      })
        .then((res) => {
          if (Object.keys(res.data).length === 0) {
            setUserTicketInfo(null);
            return;
          } else {
            setUserTicketInfo(res.data);
          }
        })
        .catch((e: any) => {});
      GetUserPoint().then((res) => {
        setMyPoint(res.points);
      });
      setLoading(true);
      getFriendRecommendCount()
        .then((res) => {
          setFriendRecommendCount(res.count);
        })
        .finally(() => {
          setLoading(false);
        });

      if (isApp) {
        sendMessageToNative("getPushInfo", "setPushInfo", {}).then(
          async (res: any) => {
            if (!res) {
              return;
            }
            res.userId = user.id;
            pushRegister(res);
          }
        );
      }
    }
  }, [user?.id]);

  // 프로필 이미지 수정
  const handleClickImage = useCallback(() => {
    if (!user) {
      return;
    }

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = () => {
      if (!input.files || !input.files[0]) {
        return;
      }

      const file = input.files[0];
      updateProfileImage(file, { id: user.id })
        .then(() => {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const dataUri = reader.result;
            if (typeof dataUri !== "string") {
              return;
            }

            await refreshUser();
            enqueueSnackbar("프로필 사진이 변경되었습니다.", {
              variant: "success",
            });
          };
          reader.readAsDataURL(file);
        })
        .catch((e: any) => {
          enqueueSnackbar("프로필 사진을 변경하지 못했습니다: " + e.message, {
            variant: "error",
          });
        })
        .finally(() => {
          input.remove();
        });
    };
    input.click();
  }, [user]);

  const handleLogout = useCallback(() => {
    clearCredential();
    setUser(undefined);
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_KEY);
    history.replace("/logout");
  }, []);

  const handleSetMenu = (menu?: MenuType) => {
    if (menu) {
      setShowMenu(menu);
      query.set("menu", menu);
      history.replace({ search: query.toString(), state: location.state });
    } else {
      setShowMenu(null);
      history.replace({ search: "", state: location.state });
    }
  };

  return (
    <MyPageWrapper>
      {showMenu === "profile" && (
        <ModifyProfile onClose={() => handleSetMenu()} />
      )}
      {showMenu === "password" && (
        <ModifyPassword onClose={() => handleSetMenu()} />
      )}
      {showMenu === "point" && <RPHistory onClose={() => handleSetMenu()} />}
      {showMenu === "notice" && <Notice onClose={() => handleSetMenu()} />}
      {showMenu === "market-notice" && (
        <MarketNotice onClose={() => handleSetMenu()} />
      )}
      {showMenu === "faq" && <FAQ onClose={() => handleSetMenu()} />}
      {showMenu === "referral" && <Referral onClose={() => handleSetMenu()} />}
      {showMenu === "manage-pub" && (
        <ManagePub onClose={() => handleSetMenu()} />
      )}
      {showMenu === "manage-recruit" && (
        <ManageRecruit onClose={() => handleSetMenu()} />
      )}
      {showRegisterPremium && (
        <RegisterPreminumPubInfo
          onClose={() => setShowRegisterPremium(false)}
        />
      )}
      <div className="inner">
        <MyProfileWrapper scrollLock={showMenu !== null}>
          <div className="profile-row">
            <div className="profile" onClick={handleClickImage}>
              {user?.profileImageUrl ? (
                <img src={user.profileImageUrl} />
              ) : (
                <img src="https://dfesoodpx4jgd.cloudfront.net/user/default_profile.png" />
              )}
              <div className="edit" />
            </div>
            <div className="info">
              <div className="tag-row">
                {user?.validate && (
                  <div className="tag">
                    <img src="/image-web/Icon/Certified/small.svg" />
                    <span>본인 인증 완료</span>
                  </div>
                )}
                {user?.role === "ROLE_SELLER" && (
                  <div className="tag">
                    <img src="/image-web/Icon/manager/small.svg" />
                    <span>홀덤펍 관리자</span>
                  </div>
                )}
              </div>
              <div className="nickname">{user?.nickname}</div>
            </div>
          </div>
          <div className="info-row">
            <div className="item">
              <div className="title">리바인권</div>
              <div className="value">
                {userTicketInfo
                  ? userTicketInfo.user.rebuyinTicket.toLocaleString()
                  : "-"}
              </div>
            </div>
            <div className="item">
              <div className="title">GP</div>
              <div className="value">
                {userTicketInfo
                  ? userTicketInfo.user.point.toLocaleString()
                  : "-"}
              </div>
            </div>

            <div className="item">
              <div className="title">친구추천 횟수</div>
              <div className="value">{friendRecommendCount}</div>
            </div>
          </div>
        </MyProfileWrapper>
        <HorizontalBar />
        <MenuWrapper>
          <div
            className="row"
            onClick={() => {
              handleSetMenu("profile");
            }}
          >
            <div className="title">
              <img className="icon" src="/image-web/Icon/Profile.svg" />
              <span>프로필 수정</span>
            </div>
            <img className="arrow" src="/image-web/Icon/Arrow-right.svg" />
          </div>
          <div
            className="row"
            onClick={() => {
              handleSetMenu("password");
            }}
          >
            <div className="title">
              <img className="icon" src="/image-web/Icon/Password.svg" />
              <span>비밀번호 변경</span>
            </div>
            <img className="arrow" src="/image-web/Icon/Arrow-right.svg" />
          </div>
          <div
            className="row"
            onClick={() => {
              copyClipboard(
                window.location.protocol +
                  "//" +
                  window.location.host +
                  "/signup?referral=" +
                  user?.nickname
              );
            }}
          >
            <div className="title">
              <img
                className="icon"
                src="/image-web/Icon/Add_Friends_black.svg"
              />
              <span>초대 링크 복사</span>
            </div>
          </div>

          {user?.role === "ROLE_SELLER" && (
            <>
              <div
                className="row"
                onClick={() => {
                  handleSetMenu("manage-pub");
                }}
              >
                <div className="title">
                  <img className="icon" src="/image-web/Icon/Manage.svg" />
                  <span>내 홀덤펍 관리</span>
                </div>
                <img className="arrow" src="/image-web/Icon/Arrow-right.svg" />
              </div>
              <div
                className="row"
                onClick={() => {
                  handleSetMenu("manage-recruit");
                }}
              >
                <div className="title">
                  <img
                    className="icon"
                    src="/image-web/Icon/Manage%20hiring.svg"
                  />
                  <span>내 채용 관리</span>
                </div>
                <img className="arrow" src="/image-web/Icon/Arrow-right.svg" />
              </div>
            </>
          )}

          <div
            className="row"
            onClick={() => {
              openDialog({
                type: "web",
                title: "로그아웃",
                text: "로그아웃을<br/>진행하시겠습니까?",
                onConfirm: handleLogout,
                confirmText: "로그아웃",
                confirm: true,
                confirmColor: "#D91818",
              });
            }}
          >
            <div className="title">
              <span>로그아웃</span>
            </div>
          </div>
        </MenuWrapper>
        <HorizontalBar />
        <CustomerServiceWrapper>
          <div className="title">고객센터</div>
          <div className="grid-wrapper">
            <div className="item" onClick={() => handleSetMenu("notice")}>
              <div className="icon-wrapper">
                <img
                  className="icon"
                  src="/image-web/customerMenu/Megaphone.svg"
                />
              </div>
              <div className="title">공지사항</div>
            </div>
            <div className="item" onClick={() => handleSetMenu("faq")}>
              <div className="icon-wrapper">
                <img
                  className="icon"
                  src="/image-web/customerMenu/Question.svg"
                />
              </div>
              <div className="title">자주묻는 질문</div>
            </div>
            <div className="item" onClick={() => handleSetMenu("referral")}>
              <div className="icon-wrapper">
                <img
                  className="icon"
                  src="/image-web/customerMenu/Add%20Friends.svg"
                />
              </div>
              <div className="title">친구 추천</div>
            </div>
            <div
              className="item"
              onClick={() => {
                setShowRegisterPremium(true);
              }}
            >
              <div className="icon-wrapper">
                <img className="icon" src="/image-web/store/premium.png" />
              </div>
              <div className="title">
                프리미엄 홀덤펍
                <br />
                등록 문의
              </div>
            </div>
            <div
              className="item"
              onClick={() => {
                openNewWindow("https://pf.kakao.com/_BxbmbG");
              }}
            >
              <div className="icon-wrapper">
                <img className="icon" src="/image-web/customerMenu/Kakao.svg" />
              </div>
              <div className="title">공식채널상담</div>
            </div>
            <div
              className="item"
              onClick={() => {
                openNewWindow("https://t.me/RunnerRunner0");
              }}
            >
              <div className="icon-wrapper">
                <img
                  className="icon"
                  src="/image-web/customerMenu/Telegram.svg"
                />
              </div>
              <div className="title">텔레그램 상담</div>
            </div>
          </div>
        </CustomerServiceWrapper>
      </div>
    </MyPageWrapper>
  );
};

export default Index;
