import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SearchFilter from "./SearchFilter";
import RegisterRecruit from "./RegisterRecruit";
import {
  Job,
  jobList,
  JobSearchParams,
  RecruitType,
  UserJob,
  userJobList,
} from "../../../api/job";
import { enqueueSnackbar } from "notistack";
import {
  getDistanceKm,
  getKoreanNumber,
  parseJSON,
  shortProvinceName,
} from "../../../utils/common";
import useGeoLocation from "../../../hooks/useGeoLocation";
import {
  getGameTypeString,
  getGenderLabel,
  getRecruitSortLabel,
  getRecruitStateLabel,
  getRecruitTypeLabel,
  RecruitSortLabels,
  RecruitTypeLabels,
} from "../../../utils/constants";
import useUserInfo from "../../../hooks/useUserInfo";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import useQueryParams from "../../../hooks/useQueryParams";
import useCities from "../../../hooks/useCities";
import RegisterUserRecruit from "./RegisterUserRecruit";

const RecruitWrapper = styled.div<{
  scrollLock: boolean;
}>`
  position: absolute;
  top: 0;
  left: 50%;
  max-width: 500px;
  transform: translateX(-50%);
  width: 100%;
  height: 100svh;
  overscroll-behavior: none;
  z-index: 10;
  background: white;
  transition: all 0.5s ease-in-out;
  padding-top: 142px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${(p) =>
    p.scrollLock
      ? `
      overflow-y: hidden;
    `
      : `
      overflow-y: scroll;
    `}
  > .header {
    z-index: 101;
    transition: all 0.1s ease-in-out;
    top: 0;
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 8px;
    background: white;

    > .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

    > .title {
      transition: all 0.1s ease-in-out;
      color: ${(p) => p.theme.color.black400};
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .button {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      cursor: pointer;
      color: var(--Black-200, #b7b7b7);
      text-align: right;
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.26px;
    }
  }

  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
  }
`;

const FilterWrapper = styled.div`
  position: fixed;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  top: 48px;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  gap: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  z-index: 11;

  > .type-row {
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    > .type-wrapper-inner {
      flex-grow: 1;
      overflow-x: scroll;

      > .type-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;

        > .type {
          cursor: pointer;
          flex-shrink: 0;
          width: fit-content;
          color: ${(p) => p.theme.color.purple300};
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 7px 12px;
          border-radius: 15px;
          border: 1px solid ${(p) => p.theme.color.purple300};
          background: white;
        }

        > .type.selected {
          color: #fff;
          background: ${(p) => p.theme.color.purple300};
        }
      }
    }

    > .sort-wrapper {
      flex-shrink: 0;
      position: relative;
      padding: 7px 8px 7px 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2px;
      border-radius: 15px;
      border: 1px solid ${(p) => p.theme.color.black200};
      color: ${(p) => p.theme.color.black500};
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      > span {
        cursor: pointer;
      }

      > .dim {
        top: 0;
        left: -12px;
        position: absolute;
        width: 4px;
        height: 30px;
        background: linear-gradient(
          270deg,
          #fff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      > .sort-popup {
        width: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 12px;
        position: absolute;
        right: 0;
        top: 36px;
        z-index: 105;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);

        > .item {
          width: 100%;
          cursor: pointer;
          padding: 8px 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 15px;
          border: 1px solid var(--Black-100, #f0f0f0);
          color: var(--Black-500, #202020);
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        > .item.selected {
          color: #fff;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: var(--Purple-300, #6436e7);
        }
      }
    }
  }

  > .filter-row-inner {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;

    > .filter-row {
      padding-left: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      > .item {
        cursor: pointer;
        white-space: nowrap;
        padding: 7px 12px;
        color: ${(p) => p.theme.color.black500};
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 15px;
        background: ${(p) => p.theme.color.black100};
      }

      > .item.selected {
        color: var(--Purple-300, #6436e7);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > .box {
    width: 100%;
    padding: 16px;
    background: #f0f0f0;
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 18.2px */
    letter-spacing: -0.26px;
  }

  > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 20px 16px;
    > .empty-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      gap: 12px;

      > img {
        margin-top: 10px;
        width: 180px;
        object-fit: contain;
      }

      > .empty-text {
        width: 100%;
        color: var(--Black-300, #808080);
        text-align: center;
        font-family: Pretendard;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
`;
export const ContentItemWrapper = styled.div<{
  selected?: boolean;
}>`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: relative;
  padding: 12px;
  @media ${MEDIA_DESKTOP} {
    box-shadow: none;
    border-radius: 12px;
    border: 1px solid var(--Black-100, #f0f0f0);
    background: #fff;
    ${(p) =>
      p.selected
        ? `
        border: 2px solid var(--Purple-300, #6436E7);
        padding: 11px;
      `
        : `
      
      `}
  }

  > .dim {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.3);
    z-index: 2;
    border-radius: 8px;
  }

  > .top-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    position: relative;

    > .edit {
      right: 0;
      position: absolute;
      cursor: pointer;
      display: flex;
      width: 54px;
      height: 22px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: var(--Black-100, #f0f0f0);
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    > .pub-badge {
      position: relative;
      width: 22px;
      height: 22px;
      border-radius: 39px;
      background: var(--Purple-100, #f0eaff);

      > img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }

    > .badge {
      padding: 4px 6px;
      border-radius: 16.667px;
      border: 1px solid var(--Purple-100, #f0eaff);
      color: var(--Purple-300, #6436e7);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .info-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
    }

    > .info {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.26px;
    }

    > .money {
      width: 100%;
      text-align: right;
      color: var(--Black-400, #444);
      text-align: right;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
    }
  }

  > .edit-button {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 10px;
    background: var(--Black-100, #f0f0f0);
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 54px;
    height: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

interface RecruitPageProps {
  onClose?: () => void;
}

const RecruitPage = ({ onClose }: RecruitPageProps) => {
  const history = useHistory();
  const { user } = useUserInfo();
  const [showFilter, setShowFilter] = useState(false);
  const [showRegisterRecruit, setShowRegisterRecruit] = useState(false);
  const [showRegisterUserRecruit, setShowRegisterUserRecruit] = useState(false);
  const [showRegisterUserEdit, setShowRegisterUserEdit] = useState(-1);
  const [showRegisterEdit, setShowRegisterEdit] = useState(-1);
  const [showSortFilter, setShowSortFilter] = useState(false);
  const [selectedSort, setSelectedSort] = useState("");
  const [recruitType, setSelectedRecruitType] = useState<RecruitType>("");
  const [list, setList] = useState<Job[]>([]);
  const [userList, setUserList] = useState<UserJob[]>([]);
  const [filter, setFilter] = useState<JobSearchParams>({});

  const [stateInited, setStateinited] = useState(false);

  const { latitude, longitude } = useGeoLocation();
  const query = useQueryParams();
  const { provinces } = useCities();

  const updateList = useCallback(() => {
    if (!stateInited) {
      return;
    }

    jobList({
      ...filter,
      recruitType: recruitType ? [recruitType] : undefined,
      lat: latitude,
      lon: longitude,
    })
      .then((list) => {
        setList(list);
      })
      .catch((e: any) => {
        enqueueSnackbar("채용 목록을 가져올 수 없습니다:" + e.message, {
          variant: "error",
        });
      });
    userJobList({
      ...filter,
      recruitType: recruitType ? [recruitType] : undefined,
      lat: latitude,
      lon: longitude,
    })
      .then((list) => {
        setUserList(list);
      })
      .catch((e: any) => {
        enqueueSnackbar("채용 목록을 가져올 수 없습니다:" + e.message, {
          variant: "error",
        });
      });
  }, [stateInited, recruitType, filter]);

  const sortedList = useMemo(() => {
    if (list.length === 0) {
      return [];
    }

    return list.sort((a, b) => {
      if (selectedSort === "oldest") {
        return a.id - b.id;
      } else if (selectedSort === "nearest") {
        const distanceA = Number(
          getDistanceKm(latitude, longitude, a.lat, a.lon)
        );
        const distanceB = Number(
          getDistanceKm(latitude, longitude, b.lat, b.lon)
        );
        return distanceA - distanceB;
      } else if (selectedSort === "highNet") {
        return b.hourlyPay - a.hourlyPay;
      }

      return b.id - a.id;
    });
  }, [list, selectedSort, latitude, longitude]);

  useEffect(() => {
    updateList();
  }, [updateList]);

  // URL 쿼리에서 상태 반영
  useEffect(() => {
    const recruitType = query.get("recruit_type");
    if (recruitType) {
      setSelectedRecruitType(recruitType);
    }

    const sort = query.get("sort");
    if (sort) {
      setSelectedSort(sort);
    }

    const filter = parseJSON(query.get("filter"));
    if (filter) {
      setFilter(filter);
    }

    setStateinited(true);
  }, []);

  // URL 쿼리 수정
  useEffect(() => {
    if (recruitType) query.set("recruit_type", recruitType);
    if (selectedSort) query.set("sort", selectedSort);

    query.set("filter", JSON.stringify(filter));

    history.replace({ search: query.toString() });
  }, [recruitType, selectedSort, filter]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      if (history.length > 1) {
        history.goBack();
      } else {
        history.push("/");
      }
    }
  };
  return (
    <>
      {showFilter && (
        <SearchFilter
          provinces={provinces}
          filter={filter}
          onApplyFilter={setFilter}
          onClose={() => setShowFilter(false)}
        />
      )}
      {showRegisterRecruit && (
        <RegisterRecruit
          mode="write"
          update={updateList}
          onClose={() => setShowRegisterRecruit(false)}
        />
      )}
      {showRegisterUserRecruit && (
        <RegisterUserRecruit
          mode="write"
          update={updateList}
          onClose={() => setShowRegisterUserRecruit(false)}
        />
      )}
      {showRegisterEdit > -1 && (
        <RegisterRecruit
          mode="edit"
          recruitId={showRegisterEdit}
          update={updateList}
          onClose={() => setShowRegisterEdit(-1)}
        />
      )}
      {showRegisterUserEdit > -1 && (
        <RegisterUserRecruit
          mode="edit"
          recruitId={showRegisterUserEdit}
          update={updateList}
          onClose={() => setShowRegisterUserEdit(-1)}
        />
      )}
      <RecruitWrapper scrollLock={false}>
        <div className="header">
          <div className="close" onClick={handleClose}>
            <img src="/image-web/Icon/Back.svg" alt="close" />
          </div>
          <div className="title">실시간 채용</div>
          {user && (
            <div
              className="button"
              onClick={() => {
                if (user?.role === "ROLE_SELLER") setShowRegisterRecruit(true);
                else setShowRegisterUserRecruit(true);
              }}
            >
              채용 등록
            </div>
          )}
        </div>
        <FilterWrapper>
          <div className="type-row">
            <div className="type-wrapper-inner">
              <div className="type-wrapper">
                {RecruitTypeLabels.map(({ type, label }, i) => {
                  return (
                    <div
                      className={
                        "type " + (recruitType === type ? "selected" : "")
                      }
                      key={i}
                      onClick={() => setSelectedRecruitType(type)}
                    >
                      {label}
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className="sort-wrapper"
              onClick={() => setShowSortFilter(!showSortFilter)}
            >
              <div className="dim" />
              <span>{getRecruitSortLabel(selectedSort)}</span>
              <img src="/image-web/Icon/Arrow%20down.svg" />
              {showSortFilter && (
                <div className="sort-popup">
                  {RecruitSortLabels.map(({ type, label }, i) => {
                    return (
                      <div
                        className={
                          "item " + (selectedSort === type ? "selected" : "")
                        }
                        key={i}
                        onClick={() => setSelectedSort(type)}
                      >
                        {label}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="filter-row-inner" onClick={() => setShowFilter(true)}>
            <div className="filter-row">
              {filter.provinces ? (
                <span className="item selected">
                  지역 :{" "}
                  {filter.provinces
                    .map((x) => {
                      const province = provinces.find((p) => p.id === x);
                      return shortProvinceName(province?.name ?? "");
                    })
                    .join(",")}
                </span>
              ) : (
                <span className="item">지역</span>
              )}
              {filter.stateType ? (
                <span className="item selected">
                  구인상태 :{" "}
                  {filter.stateType
                    .map((x) => {
                      return getRecruitStateLabel(x);
                    })
                    .join(",")}
                </span>
              ) : (
                <span className="item">구인상태</span>
              )}
              {filter.maxHourlyPay || filter.minHourlyPay ? (
                <span className="item selected">
                  시급 :{" "}
                  {filter.minHourlyPay
                    ? filter.minHourlyPay.toLocaleString() + "원"
                    : ""}{" "}
                  ~{" "}
                  {filter.maxHourlyPay
                    ? filter.maxHourlyPay.toLocaleString() + "원"
                    : ""}
                </span>
              ) : (
                <span className="item">시급</span>
              )}
              {filter.fromDistance || filter.toDistance ? (
                <span className="item selected">
                  거리 : {filter.fromDistance ? filter.fromDistance + "km" : ""}{" "}
                  ~ {filter.toDistance ? filter.toDistance + "km" : ""}
                </span>
              ) : (
                <span className="item">거리</span>
              )}
            </div>
          </div>
        </FilterWrapper>
        <div className="inner">
          <ContentWrapper>
            <div className="box">
              <li>러너러너 회원이면 누구나 채용등록 가능합니다.</li>
              <li>
                각 채용 항목들에 대해서 러너 소프트에 책임과 권한이 없음을
                알려드립니다.
              </li>
            </div>
            <div className="content">
              {sortedList.length === 0 && userList.length === 0 && (
                <div className="empty-wrapper">
                  <img src="/image-web/None.png" />
                  <div className="empty-text">채용 정보가 없습니다.</div>
                </div>
              )}
              {sortedList.map((item, index) => {
                return (
                  <ContentItemWrapper
                    key={item.id}
                    onClick={() => {
                      history.push("/recruit/detail/" + item.id + "/pub");
                    }}
                  >
                    {item.state === "DONE" && <div className="dim" />}
                    <div className="top-row">
                      <div className="pub-badge">
                        {item.pubType === "PREMIUM" && (
                          <img src="/image-web/store/premium.png" />
                        )}
                        {item.pubType === "AFFILIATE" && (
                          <img src="/image-web/store/store_check.svg" />
                        )}
                        {item.pubType === "NORMAL" && (
                          <img src="/image-web/store/store_normal.png" />
                        )}
                      </div>
                      <div className="badge">
                        {getRecruitStateLabel(item.state)}
                      </div>
                      <div className="badge">
                        {getRecruitTypeLabel(
                          item.recruitType,
                          item.recruitTypeEtc
                        )}
                      </div>
                      {item.owner && (
                        <div
                          className="edit"
                          onClick={(e) => {
                            setShowRegisterEdit(item.id);
                            e.stopPropagation();
                          }}
                        >
                          수정
                        </div>
                      )}
                    </div>
                    <div className="info-wrapper">
                      <div className="title">{item.cafeName}</div>
                      <div className="info">{item.place}</div>
                      <div className="info">
                        성별: {getGenderLabel(item.gender)}
                      </div>
                      <div className="money">
                        시급: {item.hourlyPay.toLocaleString()}원
                      </div>
                    </div>
                  </ContentItemWrapper>
                );
              })}
              {userList.map((item, index) => {
                return (
                  <ContentItemWrapper
                    key={item.id}
                    onClick={() => {
                      history.push("/recruit/detail/" + item.id + "/user");
                    }}
                  >
                    {item.state === "DONE" && <div className="dim" />}
                    <div className="top-row">
                      <div className="badge">
                        {getRecruitStateLabel(item.state)}
                      </div>
                      <div className="badge">
                        {getRecruitTypeLabel(
                          item.recruitType,
                          item.recruitTypeEtc
                        )}
                      </div>
                      {item.owner && (
                        <div
                          className="edit"
                          onClick={(e) => {
                            setShowRegisterUserEdit(item.id);
                            e.stopPropagation();
                          }}
                        >
                          수정
                        </div>
                      )}
                    </div>
                    <div className="info-wrapper">
                      <div className="title">{item.cafeName}</div>
                      <div className="info">{item.place}</div>
                      <div className="info">
                        성별: {getGenderLabel(item.gender)}
                      </div>
                      <div className="money">
                        시급: {item.hourlyPay.toLocaleString()}원
                      </div>
                    </div>
                  </ContentItemWrapper>
                );
              })}
            </div>
          </ContentWrapper>
        </div>
      </RecruitWrapper>
    </>
  );
};
export default RecruitPage;
