import styled from "styled-components";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {StoreContext} from "../StoreDetail";
import useUserInfo from "../../../hooks/useUserInfo";
import Write from "../StoreDetail/Write";
import {cafeDeleteMenu} from "../../../api/cafe";
import {enqueueSnackbar} from "notistack";
import {FoodMenuItem} from "../../../api/types";

const MenuInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  >.title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    >.title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.36px;
    }
    >.button {
      cursor: pointer;
      color: var(--Black-200, #B7B7B7);
      text-align: right;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
  
  >.menu-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    > .no-menu {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      font-size: 16px;
      color: var(--Black-400, #444);
      grid-column: 1 / -1;
    }
    >.item {
      padding: 8px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      > img {
        flex-shrink: 0;
        width: 92px;
        height: 92px;
        object-fit: contain;
        border-radius: 4px;
        border: 1px solid var(--Black-100, #F0F0F0);
      }
      >.info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        >.name {
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.32px;
        }      
        >.description {
          margin-top: 4px;
          color: var(--Black-500, #202020);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          letter-spacing: -0.28px;
          height: 41px;
        }
        >.price {
          margin-top: 8px;
          color: var(--Purple-300, #6436E7);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.32px;
        }
        > .admin-menu-row {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;

          > .button {
            padding: 6px 16px;
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid var(--Black-200, #B7B7B7);
            color: var(--Black-500, #202020);
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }
`

interface MenuData {
  id: number,
  orderIndex: number,
  title: string,
  description: string,
  price: number,
  image: string | null,
}

const MenuInfo = () => {
  const [menus, setMenus] = useState<MenuData[]>([])
  const [showRegisterMenu, setShowRegisterMenu] = useState<{
    show: boolean,
    mode: 'edit' | 'write'
    menu?: FoodMenuItem
  }>({
    show: false,
    mode: 'write',
  });
  const {user} = useUserInfo();
  const {
    data,
    update
  } = useContext(StoreContext);
  const openWritePopup = (mode: 'write' | 'edit', menu?: FoodMenuItem) => {
    setShowRegisterMenu({
      show: true,
      mode,
      menu
    })
  }

  useEffect(() => {
    setMenus(data.menuItems.map((item, i) => {
      return {
        id: item.id,
        orderIndex: i,
        title: item.itemName,
        description: item.description,
        price: item.price,
        image: item.imageUrl
      }
    }))
  }, [data]);
  const handleEditItem = useCallback((menuId: number) => {
    const menu = menus.find(x => x.id === menuId);
    if (menu) {
      openWritePopup('edit', {
        description: menu.description,
        id: menu.id,
        imageUrl: menu.image,
        itemName: menu.title,
        price: menu.price
      })
    }
  }, [menus]);

  const handleDeleteItem = useCallback((menuId: number) => {
    if (!data) {
      return;
    }

    if (confirm('삭제하시겠습니까?')) {
      cafeDeleteMenu({
        cafeId: data.id,
        menuId: menuId
      }).then(() => {
        enqueueSnackbar("메뉴가 삭제되었습니다.", {variant: 'success'});
        update();
      });
    }
  }, [data]);
  return <>
    {
      showRegisterMenu.show &&
      <Write
        onClose={
          () => setShowRegisterMenu({
            show: false,
            mode: 'write',
          })
        }
        mode={showRegisterMenu.mode}
        menu={showRegisterMenu.menu}/>
    }
  <MenuInfoWrapper>
    <div className='title-row'>
      <div className="title">메뉴</div>
      {
        (user?.role === 'ROLE_SELLER' && data.myCafe) && <div className='button' onClick={()=>setShowRegisterMenu({
          show: true,
          mode: 'write'
        })}>메뉴 등록</div>
      }
    </div>

    <div className='menu-list'>
      {
        data.menuItems.length === 0 && <div className='no-menu'>등록된 메뉴가 없습니다.</div>
      }
      {data.menuItems.map((menu, index) => {
        return  <div className='item' key={index}>
          <img src={menu.imageUrl ?? ''} alt={menu.itemName}/>
          <div className='info'>
            <div className='name'>{menu.itemName}</div>
            <div className='description'>{menu.description}</div>
            <div className='price'>{menu.price.toLocaleString()}원</div>
            {
              (user?.role === 'ROLE_SELLER' && data.myCafe) && (
                <div className='admin-menu-row'>
                  <div className='button' onClick={() => handleEditItem(menu.id)}>수정</div>
                  <div className='button' onClick={() => handleDeleteItem(menu.id)}>삭제</div>
                </div>
              )
            }
          </div>
        </div>
      })}
    </div>
  </MenuInfoWrapper>
  </>
}
export default MenuInfo;
