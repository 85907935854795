import instance from "../utils/network";
export interface IOpenChat {
  id: number;
  url: string;
  ord: number;
  title: string;
  image_url: string;
  createdAt: string;
  updatedAt: string;
}
export function getOpenChatList(): Promise<IOpenChat[]> {
  return instance.get(`/community/open-chat`);
}
