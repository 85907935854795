export const ACCOUNT_EXIST = 4000
export const ACCOUNT_SUSPENDED = 4001
export const ACCOUNT_NOT_FOUND = 4011
export const ACCOUNT_EXIT = 4444
export const CAFE_EXIT = 4445
export const USERNAME_EXISTS = 4002
export const MAIL_EXISTS = 4003
export const NICKNAME_EXISTS = 4004
export const PHONE_NUMBER_EXISTS = 4005
export const ROLE_NOT_FOUND = 4006
export const USER_NOT_FOUND = 4007
export const USER_PASSWORD_NOT_MATCH = 4008
export const TOKEN_EXPIRED = 4100
export const CAFE_NOT_FOUND = 4008
export const DUPLICATED_APPLY_JOB = 4020
export const JOB_OPENING_NOT_FOUND = 4009
export const CAN_NOT_DELETE_OWN_ACCOUNT = 4010
export const CAN_NOT_REVOKE_OWN_AUTHORITY = 4011
export const TOURNAMENT_NOT_FOUND = 4020

export const ACTIVATE_PHONE_NUMBER_VALIDATED = 4015
export const ACTIVATE_PHONE_NUMBER_NOT_VALIDATED = 4025
export const NOT_ACTIVATE_PHONE_NUMBER_VALIDATED = 4035
export const NOT_ACTIVATE_PHONE_NUMBER_NOT_VALIDATED = 4045

export const S3_FILE_UPLOAD_FAILURE = 5001
export const S3_FILE_DELETE_FAILURE = 5002
export const SEARCH_TEXT_IS_WRONG = 5101
export const RESERVATION_IS_DUPLICATE = 5201

export const KAKAO_USER_ACCOUNT = 4300
export const NAVER_USER_ACCOUNT = 4301
export const GOOGLE_USER_ACCOUNT = 4302
export const APPLE_USER_ACCOUNT = 4303

export const ALREADY_RECOMMENDED_FRIEND = 6001
export const NOT_ENOUGH_POINTS = 7001

export const DATA_NOT_FOUND = 9001
export const NO_AVAILABLE_TICKET = 9002
export const REWARD_NOT_FOUND = 9003
export const NOT_VALIDATED = 9004
export const OK = 200
