import styled from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";

import { PubSearchParams, searchAreaCafe, searchCafe } from "../../../api/cafe";
import { Cafe } from "../../../api/types";
import FilterPopup from "./FilterPopup";
import useGeoLocation from "../../../hooks/useGeoLocation";
import LocationFilter from "./LocationFilter";
import LocationSheet from "../../../components/web/LocationSheet";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { geoCoordState } from "../../../recoil/geo";
import {
  getDistanceKm,
  getKoreanNumber,
  getOpStatusText,
  getOpTimeText,
  parseJSON,
} from "../../../utils/common";
import useQuickButtons from "../../../hooks/useQuickButtons";
import { Area } from "../../../api/area";
import useCities from "../../../hooks/useCities";
import { debounce } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import {
  getGameTypeString,
  getPubSortLabel,
  PubSortTypeLabels,
  PubTypeLabels,
} from "../../../utils/constants";
import { navigationTargetState } from "../../../recoil/store";
import usePubSearchFilter from "../../../hooks/usePubSearchFilter";
import SearchPopup from "./SearchPopup";
import useQueryParams from "../../../hooks/useQueryParams";
import StoreMarker from "../../../components/StoreMarker";
import { renderToString } from "react-dom/server";
import { loadingState } from "../../../recoil/app";
import EmptyView from "../../../components/common/EmptyView";
import RegisterPreminumPubInfo from "./RegisterPreminumPubInfo";
import { makeMarkerClustering } from "../../../utils/MarkerClustering";
import useDialog from "../../../hooks/useDialog";

const SearchWrapper = styled.div<{
  scrollLock: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: white;
  position: relative;
  padding-top: 143px;

  ${(p) =>
    p.scrollLock
      ? `
    overflow-y: hidden;
  `
      : `
    overflow-y: scroll;
  `}
  > #map {
    flex-grow: 1;
    width: 100%;

    > #city {
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 20px;
      background: var(--Purple-100, #f0eaff);
      color: var(--Purple-300, #6436e7);
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      z-index: 10;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 8px;
    }

    &[data-hide="true"] {
      display: none;
    }
  }

  > .list {
    width: 100%;
    padding: 0 16px;
    flex-grow: 1;

    > .inner {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      width: 100%;
      padding-bottom: 70px;
      > .premium {
        cursor: pointer;
        width: 100%;
        border-radius: 8px;
        background: var(--Purple-100, #f0eaff);
        height: 43px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.32px;
        > img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;
const AddressBar = styled.div`
  position: fixed;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  background: white;
  z-index: 2;
  width: 100%;
  padding: 7px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  > .box {
    cursor: pointer;
    flex-shrink: 0;
    width: 100px;
    padding: 10px 12px;
    border-radius: 8px;
    background: var(--Black-100, #f0f0f0);
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    outline: none;
    border: none;
    text-align: left;

    &::placeholder {
      color: var(--Black-200, #b7b7b7);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .box.flex {
    flex-grow: 1;
  }
`;
const FilterWrapper = styled.div`
  z-index: 106;
  position: fixed;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  top: 48px;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  gap: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);

  > .type-row {
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    > .type-wrapper-inner {
      flex-grow: 1;
      overflow-x: scroll;

      > .type-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;

        > .type {
          cursor: pointer;
          flex-shrink: 0;
          width: fit-content;
          color: ${(p) => p.theme.color.purple300};
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 7px 12px;
          border-radius: 15px;
          border: 1px solid ${(p) => p.theme.color.purple300};
          background: white;
        }

        > .type.selected {
          color: #fff;
          background: ${(p) => p.theme.color.purple300};
        }
      }
    }

    > .sort-wrapper {
      flex-shrink: 0;
      position: relative;
      padding: 7px 8px 7px 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2px;
      border-radius: 15px;
      border: 1px solid ${(p) => p.theme.color.black200};
      color: ${(p) => p.theme.color.black500};
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      > span {
        cursor: pointer;
      }

      > .dim {
        top: 0;
        left: -12px;
        position: absolute;
        width: 4px;
        height: 30px;
        background: linear-gradient(
          270deg,
          #fff 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      > .sort-popup {
        width: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 12px;
        position: absolute;
        right: 0;
        top: 36px;
        z-index: 105;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);

        > .item {
          width: 100%;
          cursor: pointer;
          padding: 8px 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 15px;
          border: 1px solid var(--Black-100, #f0f0f0);
          color: var(--Black-500, #202020);
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        > .item.selected {
          color: #fff;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: var(--Purple-300, #6436e7);
        }
      }
    }
  }

  > .filter-row-inner {
    width: 100%;
    max-width: 100%;
    overflow-x: scroll;

    > .filter-row {
      padding-left: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      > .item {
        cursor: pointer;
        white-space: nowrap;
        padding: 7px 12px;
        color: ${(p) => p.theme.color.black500};
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 15px;
        background: ${(p) => p.theme.color.black100};
      }

      > .item.selected {
        color: var(--Purple-300, #6436e7);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;
const MapInfoWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  position: fixed;
  bottom: 96px;
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px 8px;
  gap: 12px;

  > .button-row {
    width: 100%;
    height: 36px;
    position: relative;

    > .button {
      position: absolute;
      top: 0;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
      background: #fff;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
    }

    > .button.left {
      left: 0;
      width: 36px;
      height: 36px;
      border-radius: 50%;

      > .icon {
        width: 24px;
        height: 24px;
      }
    }

    > .button.center {
      left: 50%;
      transform: translateX(-50%);
      color: ${(p) => p.theme.color.black500};
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 10px 12px;
      border-radius: 24px;

      > .icon {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
const StoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);

  > .top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    > .thumbnail {
      width: 80px;
      height: 114px;
      background: gray;
      border-radius: 8px;
      overflow: hidden;
      position: relative;

      > .cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > .premium {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 8px;
        left: 8px;
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 1;

      > .tag-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        > .tag {
          padding: 4px 6px;
          color: ${(p) => p.theme.color.purple300};
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border-radius: 16.667px;
          border: 1px solid ${(p) => p.theme.color.purple100};
          white-space: nowrap;
        }
      }
      > .title-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        text-align: center;
        > .name {
          margin-top: 8px;
          color: ${(p) => p.theme.color.black400};
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.32px;
        }
        > .review-box {
          margin-right: 13px;
          display: flex;
          flex-direction: row;
          > .star {
            font-family: Pretendard;
            margin-left: 5px;
            color: gold;
            font-size: 12px;
            margin-bottom: 2px;
            font-style: normal;
          }
          > .review-count {
            font-family: Pretendard;
            margin-left: 1px;
            font-size: 12px;
            margin-bottom: 2px;
            font-style: normal;
          }
        }
      }

      > .address {
        margin-top: 6px;
        color: ${(p) => p.theme.color.black300};
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.26px;
      }

      > .info-row {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        > .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 2px;

          > .icon {
            width: 16px;
            height: 16px;
          }

          > .text {
            color: ${(p) => p.theme.color.black400};
            font-family: Pretendard;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.26px;
          }
        }
      }
    }
  }

  > .bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .status-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 2px;

      > span {
        color: ${(p) => p.theme.color.black300};
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
      }

      > span.bold {
        color: ${(p) => p.theme.color.black400};
      }
    }

    > .button-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;

      > img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
`;
const NaverMaps = window.naver.maps;

const Search = () => {
  const history = useHistory();
  const location = useLocation<{
    mode?: "query" | "area" | "premium";
  }>();
  const mapRef = useRef<any>();
  const [showRegisterPremium, setShowRegisterPremium] = useState(false);
  const myLocMarkerRef = useRef<any>();
  const [storeList, setStoreList] = useState<Cafe[]>([]);
  const [markerList, setMarkerList] = useState<any>([]);
  const [selectedStore, setSelectedStore] = useState<Cafe | null>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showLocationFilter, setShowLocationFilter] = useState(false);
  const [showSortFilter, setShowSortFilter] = useState(false);
  const [showStoreList, setShowStoreList] = useState(false);
  const [mapCenter, setMapCenter] = useState<any>();
  const [cityText, setCityText] = useState("서울특별시 강남구");
  const [hoverStoreId, setHoverStoreId] = useState<number>(-1);

  const setNavigationTarget = useSetRecoilState(navigationTargetState);
  const setLoading = useSetRecoilState(loadingState);

  const { latitude, longitude, accuracy } = useRecoilValue(geoCoordState);
  const [isArea, setIsArea] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const query = useQueryParams();
  const { updateLocation } = useGeoLocation();
  const { provinces, cities, getAddressByCoord } = useCities();
  const { openPhoneCall, toggleLike, shareLink } = useQuickButtons();
  const { openDialog } = useDialog();
  const {
    filter,
    setFilter,
    togglePubType,
    setSortType,
    isSelectedPubType,
    isSelectedSortType,
  } = usePubSearchFilter();

  const handleSearch = useCallback(
    async (params: PubSearchParams): Promise<Cafe[]> => {
      const noPubTypes =
        params.pubTypes?.length === 1 && params.pubTypes[0] === "";
      const noSortType = !Boolean(params.sort);

      return await searchCafe({
        ...params,
        lat: params.lat || 35,
        lon: params.lon || 127,
        myLat: latitude,
        myLon: longitude,
        pubTypes: !noPubTypes ? params.pubTypes : undefined,
        sort: !noSortType ? params.sort : undefined,
      });
    },
    [latitude, longitude]
  );

  const handleSearchAreaCafe = async (cafeName: string) => {
    return await searchAreaCafe(cafeName);
  };

  // 홈 바로가기 기능 실행
  useEffect(() => {
    if (!location.state) {
      return;
    }

    if (location.state.mode === "query") {
      setShowSearch(true);
    } else if (location.state.mode === "area") {
      query.set("view", "list");
      setShowLocationFilter(true);
    } else if (location.state.mode === "premium") {
      toggleShowList();
      setFilter((filter) => ({
        ...filter,
        pubTypes: ["PREMIUM"],
      }));
    }

    history.replace({
      search: location.search,
      state: {
        ...location.state,
        mode: undefined,
      },
    });
  }, []);

  // 네이버 맵 초기화 및 기본 위치 표시
  useEffect(() => {
    let center;

    const qLat = query.get("lat");
    const qLng = query.get("lng");
    if (qLat && qLng) {
      center = new NaverMaps.LatLng(qLat, qLng);
    } else {
      center = new NaverMaps.LatLng(latitude, longitude);
    }

    const mapDiv = document.getElementById("map") as HTMLDivElement;
    const naverMap = new NaverMaps.Map(mapDiv, {
      tileSpare: 3,
      center: center,
    });

    const qZoom = query.get("z");
    if (qZoom) {
      naverMap.setZoom(qZoom);
    }

    new NaverMaps.Event.addListener(
      naverMap,
      "bounds_changed",
      debounce(() => {
        setMapCenter(naverMap.getCenter());
      }, 300)
    );
    new NaverMaps.Event.addListener(naverMap, "click", () =>
      setSelectedStore(null)
    );
    setMapCenter(center);

    mapRef.current = naverMap;
    // @ts-ignore
    window.map = naverMap;
  }, []);

  // 지도 이동 시 주변 카페 조회
  useEffect(() => {
    if (!mapCenter || !mapRef.current) {
      return;
    }

    if (isArea) {
      setIsArea(false);
      return;
    }

    const { y: lat, x: lng } = mapCenter;

    query.set("lat", lat.toFixed(8));
    query.set("lng", lng.toFixed(8));
    query.set("z", mapRef.current.getZoom());
    query.set("filter", JSON.stringify(filter));

    history.replace({
      search: query.toString(),
      state: location.state,
    });

    // 주소 업데이트
    setCityText(getAddressByCoord(lat, lng));

    const mapBounds = mapRef.current.getBounds();
    const km = Number(
      getDistanceKm(
        mapBounds._min.y,
        mapBounds._min.x,
        mapBounds._max.y,
        mapBounds._max.x
      )
    );

    const searchFilter = { ...filter };
    if (searchFilter.buyInFrom) searchFilter.buyInFrom *= 10000;
    if (searchFilter.buyInTo) searchFilter.buyInTo *= 10000;

    handleSearch({
      ...searchFilter,
      lat: lat,
      lon: lng,
      km: km,
    })
      .then((list) => {
        const updatedList = list.map((item) => {
          if (item.reviewData && item.reviewData.length > 0) {
            const totalScore = item.reviewData.reduce(
              (sum, review) => sum + review.score,
              0
            );
            const averageScore = totalScore / item.reviewData.length;
            return {
              ...item,
              score: averageScore,
            };
          } else {
            return {
              ...item,
              score: 0, // 리뷰가 없는 경우 점수를 0으로 설정
            };
          }
        });
        console.log(updatedList);
        const sortedList = updatedList.sort((a, b) => {
          if (a.pubType === "PREMIUM" && b.pubType !== "PREMIUM") {
            return -1;
          }
          if (a.pubType !== "PREMIUM" && b.pubType === "PREMIUM") {
            return 1;
          }
          return 0;
        });
        setStoreList(sortedList);
      })
      .catch((e) => {});
  }, [mapCenter, getAddressByCoord, filter]);

  // 내 위치 마커 표시
  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    if (myLocMarkerRef.current) {
      myLocMarkerRef.current.setMap(null);
    }

    if (accuracy !== -1) {
      const markerContent = `
        <div class="marker" style="width: 50px; height: 50px; filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2))"> 
         <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
             <circle cx="9" cy="9" r="9" fill="white"/>
             <circle cx="9" cy="9" r="5" fill="#6436E7"/>
         </svg>
        </div>
      `;

      myLocMarkerRef.current = new NaverMaps.Marker({
        position: new NaverMaps.LatLng(latitude, longitude),
        map: mapRef.current,
        icon: {
          content: markerContent,
          size: new NaverMaps.Size(22, 35),
          anchor: new NaverMaps.Point(11, 35),
        },
      });
    }
  }, [latitude, longitude, accuracy]);

  // 마커 그리기
  useEffect(() => {
    //마커를 그리기 전, 기존 마커를 모두 지워준다.
    markerList.forEach((marker: any) => {
      marker.setMap(null);
    });

    const markers = storeList.map((store) => {
      if (!mapRef.current) return;

      const isSelected =
        selectedStore?.id === store.id || hoverStoreId === store.id;
      const marker = new NaverMaps.Marker({
        position: new NaverMaps.LatLng(store.lat, store.lon),
        map: mapRef.current,
        title: store.cafeName,
        icon: {
          content: renderToString(
            <StoreMarker
              selected={isSelected}
              pubType={store.pubType}
              buyInFrom={store.buyIn}
              buyInTo={store.buyInMax}
              name={store.cafeName}
            />
          ),
          anchor: new NaverMaps.Point(0, 30),
        },
        zIndex: isSelected ? 1 : undefined,
      });

      new NaverMaps.Event.addListener(marker, "click", function (e: any) {
        if (!mapRef.current) return;
        setSelectedStore(store);
        /*mapRef.current.setCenter(new NaverMaps.LatLng(store.lat, store.lon));
        mapRef.current.setZoom(17);*/
      });

      new NaverMaps.Event.addListener(marker, "mouseover", function (e: any) {
        setHoverStoreId(store.id);
      });

      new NaverMaps.Event.addListener(marker, "mouseout", function (e: any) {
        setHoverStoreId(-1);
      });

      return marker;
    });
    const htmlMarker1 = {
      content:
        '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(https://navermaps.github.io/maps.js.ncp/docs/img/cluster-marker-1.png);background-size:contain;"></div>',
      size: new NaverMaps.Size(40, 40),
      anchor: new NaverMaps.Point(20, 20),
    };
    const htmlMarker2 = {
      content:
        '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(https://navermaps.github.io/maps.js.ncp/docs/img/cluster-marker-2.png);background-size:contain;"></div>',
      size: new NaverMaps.Size(40, 40),
      anchor: new NaverMaps.Point(20, 20),
    };
    const htmlMarker3 = {
      content:
        '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(https://navermaps.github.io/maps.js.ncp/docs/img/cluster-marker-3.png);background-size:contain;"></div>',
      size: new NaverMaps.Size(40, 40),
      anchor: new NaverMaps.Point(20, 20),
    };
    const htmlMarker4 = {
      content:
        '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(https://navermaps.github.io/maps.js.ncp/docs/img/cluster-marker-4.png);background-size:contain;"></div>',
      size: new NaverMaps.Size(40, 40),
      anchor: new NaverMaps.Point(20, 20),
    };
    const htmlMarker5 = {
      content:
        '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(https://navermaps.github.io/maps.js.ncp/docs/img/cluster-marker-5.png);background-size:contain;"></div>',
      size: new NaverMaps.Size(40, 40),
      anchor: new NaverMaps.Point(20, 20),
    };
    setMarkerList(markers);
    const MarkerClustering = makeMarkerClustering(window.naver);
    let clusterer: any = new MarkerClustering({
      minClusterSize: 1,
      maxZoom: 12,
      disableClickZoom: false,
      map: mapRef.current,
      markers: markers,
      gridSize: 130,
      icons: [htmlMarker1, htmlMarker2, htmlMarker3, htmlMarker4, htmlMarker5],
      indexGenerator: [10, 100, 200, 500, 1000],
      stylingFunction: function (clusterMarker: any, count: number) {
        clusterMarker.getElement().querySelector("div:first-child").innerText =
          count;
      },
    });

    return () => {
      markers.forEach((marker: any) => {
        marker.setMap(null);
      });
      clusterer.setMap(null);
    };
  }, [storeList, selectedStore, hoverStoreId]);

  // URL Query 상태 반영
  useEffect(() => {
    // 보기 모드 변경
    setShowStoreList(query.get("view") === "list");

    // 필터 세팅
    const filter = parseJSON(query.get("filter"));
    if (filter) {
      setFilter(filter);
    }
  }, []);

  // 내 위치 업데이트
  const updateMyLocation = useCallback(async () => {
    const result = await updateLocation();
    if (mapRef.current) {
      mapRef.current.setCenter(
        new NaverMaps.LatLng(result.latitude, result.longitude)
      );
    }
  }, [latitude, longitude]);

  const toggleShowList = useCallback(() => {
    let show = !showStoreList;
    setShowStoreList(show);

    if (show) {
      query.set("view", "list");
    } else {
      query.set("view", "map");
    }

    history.replace({
      search: query.toString(),
      state: location.state,
    });
  }, [showStoreList]);

  //선택후
  const handleSelectCity = useCallback((province: Area, city: Area) => {
    setIsArea(true);
    handleSearchAreaCafe(city.name).then((list) => {
      if (list.length > 0) {
        const sortedList = list.sort((a, b) => {
          if (a.pubType === "PREMIUM" && b.pubType !== "PREMIUM") {
            return -1;
          }
          if (a.pubType !== "PREMIUM" && b.pubType === "PREMIUM") {
            return 1;
          }
          return 0;
        });
        if (mapRef.current) {
          const fist_cafe = list[0];
          mapRef.current.setCenter(
            new NaverMaps.LatLng(fist_cafe.lat, fist_cafe.lon)
          );
        }
        setStoreList(sortedList);
      } else {
        if (mapRef.current) {
          mapRef.current.setCenter(
            new NaverMaps.LatLng(city.centerLat, city.centerLon)
          );
        }
      }
      setShowLocationFilter(false);
      setCityText(province.name + " " + city.name);
    });
  }, []);

  const handleClickResult = useCallback((cafe: Cafe) => {
    setShowStoreList(false);
    if (mapRef.current) {
      mapRef.current.setCenter(new NaverMaps.LatLng(cafe.lat, cafe.lon));
    }
    setSelectedStore(cafe);
    setShowFilter(false);
  }, []);

  const handleLikeItem = useCallback(
    async (id: number, isList: boolean) => {
      setLoading(true);
      const liked = await toggleLike(id, "CAFE");
      if (typeof liked === "boolean") {
        if (isList) {
          const idx = storeList.findIndex((item) => item.id === id);
          if (idx !== -1) {
            const newList = storeList.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  like: liked,
                };
              } else {
                return item;
              }
            });

            setStoreList(newList);
          }
        } else {
          setSelectedStore((v) => {
            if (v) {
              return {
                ...v,
                like: liked,
              };
            }
            return v;
          });
        }
      }
      setLoading(false);
    },
    [storeList, toggleLike]
  );

  useEffect(() => {
    updateMyLocation();
    if (latitude === 37.493743896484375 && longitude === 127.0637597) {
      openDialog({
        title: "정확한 위치(ios)",
        type: "web",
        text: "더욱 정확한 위치 정보를 얻기 위해<br>아래와 같이 설정하세요.<br><br>설정 -> 러너러너 -> 위치 -> 정확한 위치 허용",
        style: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        },
      });
    }
  }, []);

  return (
    <>
      {showRegisterPremium && (
        <RegisterPreminumPubInfo
          onClose={() => setShowRegisterPremium(false)}
        />
      )}
      <LocationSheet />
      {showSearch && (
        <SearchPopup
          onSearchText={handleSearch}
          onClickResult={handleClickResult}
          onClose={() => setShowSearch(false)}
        />
      )}
      {showFilter && (
        <FilterPopup
          currentFilter={filter}
          onApplyFilter={setFilter}
          onClose={() => setShowFilter(false)}
        />
      )}
      {showLocationFilter && (
        <LocationFilter
          provinces={provinces}
          cities={cities}
          onSelected={handleSelectCity}
          onClose={() => setShowLocationFilter(false)}
        />
      )}
      <SearchWrapper scrollLock={showFilter || showLocationFilter}>
        <AddressBar>
          <input
            className="box flex"
            readOnly={true}
            placeholder="홀덤 펍 검색"
            onClick={() => {
              setShowSearch(true);
            }}
          />
        </AddressBar>
        <FilterWrapper>
          <div className="type-row">
            <div className="type-wrapper-inner">
              <div className="type-wrapper">
                {PubTypeLabels.map(({ type, label }, i) => {
                  return (
                    <div
                      className={
                        "type " + (isSelectedPubType(type) ? "selected" : "")
                      }
                      key={i}
                      onClick={() => togglePubType(type)}
                    >
                      {label}
                    </div>
                  );
                })}
              </div>
            </div>
            {showStoreList && (
              <div
                className="sort-wrapper"
                onClick={() => setShowSortFilter(!showSortFilter)}
              >
                <div className="dim" />
                <span>{getPubSortLabel(filter.sort)}</span>
                <img src="/image-web/Icon/Arrow%20down.svg" />
                {showSortFilter && (
                  <div className="sort-popup">
                    {PubSortTypeLabels.map(({ type, label }, i) => {
                      return (
                        <div
                          className={
                            "item " +
                            (isSelectedSortType(type) ? "selected" : "")
                          }
                          key={i}
                          onClick={() => setSortType(type)}
                        >
                          {label}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="filter-row-inner" onClick={() => setShowFilter(true)}>
            <div className="filter-row">
              {filter.gameTypes ? (
                <span className="item selected">
                  게임종류 :{" "}
                  {filter.gameTypes
                    .map((x) => {
                      return getGameTypeString([x]);
                    })
                    .join(",")}
                </span>
              ) : (
                <span className="item">게임종류</span>
              )}
              {filter.buyInTo || filter.buyInFrom ? (
                <span className="item selected">
                  바이인 :{" "}
                  {filter.buyInFrom
                    ? getKoreanNumber(filter.buyInFrom) + "만"
                    : ""}{" "}
                  ~{" "}
                  {filter.buyInTo ? getKoreanNumber(filter.buyInTo) + "만" : ""}
                </span>
              ) : (
                <span className="item">바이인</span>
              )}
              {filter.blindUpTo || filter.blindUpFrom ? (
                <span className="item selected">
                  블라인드 :{" "}
                  {filter.blindUpFrom
                    ? getKoreanNumber(filter.blindUpFrom) + "분"
                    : ""}{" "}
                  ~{" "}
                  {filter.blindUpTo
                    ? getKoreanNumber(filter.blindUpTo) + "분"
                    : ""}
                </span>
              ) : (
                <span className="item">블라인드</span>
              )}
              {filter.prizeFrom || filter.prizeTo ? (
                <span className="item selected">
                  프라이즈 :{" "}
                  {filter.prizeFrom
                    ? getKoreanNumber(filter.prizeFrom) + "%"
                    : ""}{" "}
                  ~{" "}
                  {filter.prizeTo ? getKoreanNumber(filter.prizeTo) + "%" : ""}
                </span>
              ) : (
                <span className="item">프라이즈</span>
              )}
            </div>
          </div>
        </FilterWrapper>
        <div id="map" data-hide={showStoreList}>
          <div
            className="location"
            id="city"
            onClick={() => setShowLocationFilter(true)}
          >
            {cityText}
          </div>
        </div>
        {!(showFilter || showLocationFilter) && (
          <>
            {showStoreList && (
              <div className="list">
                {storeList.length === 0 ? (
                  <EmptyView>
                    조건에 해당하는 펍이 지도 주변에 없습니다.
                  </EmptyView>
                ) : (
                  <div className="inner">
                    {isSelectedPubType("PREMIUM") && (
                      <div
                        className="premium"
                        onClick={() => {
                          setShowRegisterPremium(true);
                        }}
                      >
                        <img src="/image-web/store/premium.png" /> 프리미엄
                        홀덤펍 등록하기
                      </div>
                    )}
                    {storeList.map((item, index) => {
                      return (
                        <StoreInfoWrapper
                          key={index}
                          onClick={() => history.push(`/store/${item.id}`)}
                        >
                          <div className="top">
                            <div className="thumbnail">
                              {item.images[0] ? (
                                <img
                                  className="cover"
                                  src={item.images[0].imageUrl}
                                />
                              ) : (
                                <img
                                  className="cover"
                                  src="https://dfesoodpx4jgd.cloudfront.net/cafe/default.png"
                                />
                              )}
                              {item.pubType === "PREMIUM" && (
                                <img
                                  className="premium"
                                  src="/image-web/store/premium.png"
                                />
                              )}
                            </div>
                            <div className="info">
                              <div className="tag-row">
                                <span className="tag">
                                  {getGameTypeString(item.gameTypes)}
                                </span>
                                {item.buyIn && (
                                  <span className="tag">
                                    바이인 : {getKoreanNumber(item.buyIn)} ~{" "}
                                    {item.buyInMax
                                      ? getKoreanNumber(item.buyInMax)
                                      : ""}
                                  </span>
                                )}
                              </div>
                              <div className="title-row">
                                <div className="name">{item.cafeName}</div>
                                <div className="review-box">
                                  <div className="star">★</div>
                                  <div className="review-count">
                                    {item.score &&
                                      (item.score === 0
                                        ? 0
                                        : item.score.toFixed(1))}
                                  </div>
                                </div>
                              </div>

                              <div className="address">
                                {item.newAddress} {item.detailAddress}
                              </div>
                              <div className="info-row">
                                <div className="item">
                                  <img
                                    className="icon"
                                    src="/image-web/Icon/Heart/small.svg"
                                  />
                                  <span className="text">{item.likeCount}</span>
                                </div>
                                <div className="item">
                                  <img
                                    className="icon"
                                    src="/image-web/Icon/Map%20pin/small.svg"
                                  />
                                  <span className="text">
                                    {getDistanceKm(
                                      latitude,
                                      longitude,
                                      item.lat,
                                      item.lon
                                    )}{" "}
                                    km
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bottom">
                            <div className="status-row">
                              {item.operatingDays &&
                                item.operatingStartTime && (
                                  <span className="bold">
                                    {getOpStatusText(
                                      item.operatingDays,
                                      item.operatingStartTime,
                                      item.operatingEndTime
                                    )}
                                  </span>
                                )}
                              <span>
                                {getOpTimeText(
                                  item.operatingDays,
                                  item.operatingStartTime,
                                  item.operatingEndTime
                                )}
                              </span>
                            </div>
                            <div
                              className="button-row"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src="/image-web/Icon/Phone.svg"
                                onClick={() =>
                                  openPhoneCall(
                                    item.cafeName,
                                    item.vcn ? item.vcn : item.phoneNumber
                                  )
                                }
                              />
                              <img
                                src={`/image-web/Icon/${
                                  item.like ? "Heart_on" : "Heart"
                                }.svg`}
                                onClick={() => handleLikeItem(item.id, true)}
                              />
                              <img
                                src="/image-web/Icon/Navigation.svg"
                                onClick={() => setNavigationTarget(item)}
                              />
                              <img
                                src="/image-web/Icon/Share.svg"
                                onClick={() => shareLink()}
                              />
                            </div>
                          </div>
                        </StoreInfoWrapper>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            <MapInfoWrapper>
              <div className="button-row">
                {!showStoreList && (
                  <div className="button left" onClick={updateMyLocation}>
                    <img src="/image-web/Icon/My%20Location.svg" />
                  </div>
                )}
                <div className="button center">
                  <img src="/image-web/Icon/List.svg" />
                  <span onClick={toggleShowList}>
                    {showStoreList ? "지도 보기" : "리스트 보기"}
                  </span>
                </div>
              </div>
              {!showStoreList && selectedStore && (
                <StoreInfoWrapper
                  onClick={() => history.push(`/store/${selectedStore.id}`)}
                >
                  <div className="top">
                    <div className="thumbnail">
                      {selectedStore.images[0] ? (
                        <img
                          className="cover"
                          src={selectedStore.images[0].imageUrl}
                        />
                      ) : (
                        <img
                          className="cover"
                          src="https://dfesoodpx4jgd.cloudfront.net/cafe/default.png"
                        />
                      )}
                    </div>
                    <div className="info">
                      <div className="tag-row">
                        <span className="tag">
                          {getGameTypeString(selectedStore.gameTypes)}
                        </span>
                        {selectedStore.buyIn && (
                          <span className="tag">
                            바이인 : {getKoreanNumber(selectedStore.buyIn)} ~{" "}
                            {selectedStore.buyInMax
                              ? getKoreanNumber(selectedStore.buyInMax)
                              : ""}
                          </span>
                        )}
                      </div>
                      <div className="name">{selectedStore.cafeName}</div>
                      <div className="address">
                        {selectedStore.newAddress} {selectedStore.detailAddress}
                      </div>
                      <div className="info-row">
                        <div className="item">
                          <img
                            className="icon"
                            src="/image-web/Icon/Heart/small.svg"
                          />
                          <span className="text">
                            {selectedStore.likeCount.toLocaleString()}
                          </span>
                        </div>
                        <div className="item">
                          <img
                            className="icon"
                            src="/image-web/Icon/Map%20pin/small.svg"
                          />
                          <span className="text">
                            {getDistanceKm(
                              latitude,
                              longitude,
                              selectedStore.lat,
                              selectedStore.lon
                            )}{" "}
                            km
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="status-row">
                      {selectedStore.operatingDays &&
                        selectedStore.operatingStartTime && (
                          <span className="bold">
                            {getOpStatusText(
                              selectedStore.operatingDays,
                              selectedStore.operatingStartTime,
                              selectedStore.operatingEndTime
                            )}
                          </span>
                        )}
                      <span>
                        {getOpTimeText(
                          selectedStore.operatingDays,
                          selectedStore.operatingStartTime,
                          selectedStore.operatingEndTime
                        )}
                      </span>
                    </div>
                    <div
                      className="button-row"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img
                        src="/image-web/Icon/Phone.svg"
                        onClick={() =>
                          openPhoneCall(
                            selectedStore.cafeName,
                            selectedStore.vcn
                              ? selectedStore.vcn
                              : selectedStore.phoneNumber
                          )
                        }
                      />
                      <img
                        src={`/image-web/Icon/${
                          selectedStore.like ? "Heart_on" : "Heart"
                        }.svg`}
                        onClick={() => handleLikeItem(selectedStore.id, false)}
                      />
                      <img
                        src="/image-web/Icon/Navigation.svg"
                        onClick={() => setNavigationTarget(selectedStore)}
                      />
                      <img
                        src="/image-web/Icon/Share.svg"
                        onClick={() => shareLink()}
                      />
                    </div>
                  </div>
                </StoreInfoWrapper>
              )}
            </MapInfoWrapper>
          </>
        )}
      </SearchWrapper>
    </>
  );
};
export default Search;
