import styled from "styled-components";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/app";
import useScreenOrientation, {
  MEDIA_DESKTOP,
} from "../../hooks/useScreenOrientation";
import {
  authFindByNickname,
  authSignUp,
  authVerifiedNickname,
  authVerifiedTextId,
} from "../../api/auth";
import { UserData } from "./SignUp";
import { certification } from "../../utils/iamport";
import { referralLogRegister } from "../../api/referral";
import Adjust from "@adjustcom/adjust-web-sdk";
import useNativeApp, { isApp } from "../../hooks/useNativeApp";
import { debounce } from "lodash";
import { USER_NOT_FOUND } from "../../api/code";

const PCSignUpWrapper = styled.div`
  width: 680px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 80px 0 100px;
  gap: 30px;

  > .title {
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  > .horizontal-line {
    width: 100%;
    height: 2px;
    background: #444;
  }
  > .horizontal-line-light {
    width: 100%;
    height: 1px;
    background: #b7b7b7;
  }
`;

interface BoxProps {
  idCheck: boolean;
  nickCheck: boolean;
}
const Box = styled.div<BoxProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 40px;
  gap: 12px;

  > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > .label-wrapper {
      width: 156px;
      height: 47px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 2px;

      > .label {
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
      }

      > .star {
        color: #d91818;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }

    > .input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;

      > .input-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        width: 312px;

        > .button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 312px;
          height: 47px;
          border-radius: 8px;
          border: 1px solid var(--Purple-300, #6436e7);
          color: var(--Purple-300, #6436e7);
          text-align: center;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
        }

        > .button.disabled {
          color: var(--Black-300, #808080);
          text-align: center;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.28px;
          border-radius: 8px;
          border: 1px solid var(--Black-200, #b7b7b7);
          background: var(--Black-100, #f0f0f0);
          cursor: not-allowed;
        }

        > input {
          width: 312px;
          padding: 15px 12px;
          border-radius: 8px;
          border: 1px solid var(--Black-100, #f0f0f0);
          color: var(--Black-500, #444);
          text-align: left;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.28px;
          outline: none;

          &::placeholder {
            color: var(--Black-300, #808080);
          }
          &:focus {
            border-radius: 8px;
            border: 1px solid var(--Black-500, #202020);
            background: #fff;
          }
        }

        > .desc {
          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: left;
        }

        > .error {
          color: #d91818;
          text-align: center;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: left;
        }
      }

      > .id-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 47px;
        color: ${(props) => (props.idCheck ? "white" : "#6436e7")};
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        border-radius: 8px;
        border: 1px solid var(--Purple-300, #6436e7);
        background-color: ${(props) => props.idCheck && "#6436e7"};
      }
      > .nick-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 47px;
        color: ${(props) => (props.nickCheck ? "white" : "#6436e7")};
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        border-radius: 8px;
        border: 1px solid var(--Purple-300, #6436e7);
        background-color: ${(props) => props.nickCheck && "#6436e7"};
      }
    }

    > .button:active {
      background: #502bb5;
    }

    > .agreement-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      width: 360px;

      > .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;

        > .checkbox {
          cursor: pointer;
          width: 20px;
          height: 20px;
          flex-shrink: 0;

          > circle {
            transition: all 0.3s;
            fill: ${(p) => p.theme.color.black200};
          }
        }

        > .checkbox.checked {
          > circle {
            fill: ${(p) => p.theme.color.purple300};
          }
        }

        > .title {
          flex-grow: 1;
          color: var(--Black-500, #202020);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        > .title.bold {
          color: var(--Black-500, #202020);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        > .button {
          flex-shrink: 0;
          cursor: pointer;
          color: var(--Black-200, #b7b7b7);
          text-align: right;
          font-family: Pretendard;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.26px;
        }
      }
    }
  }
`;

const InputBoxWrapper = styled.div<{ success: boolean }>`
  > .label {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    color: ${(p) => p.theme.color.purple200};
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    > svg {
      > circle {
        transition: all 0.3s;
        fill: ${(p) =>
          p.success ? p.theme.color.purple200 : p.theme.color.black200};
      }
    }
  }
`;

const SignUpButton = styled.div`
  cursor: pointer;
  width: 312px;
  height: 56px;
  color: #f0f0f0;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background: var(--Purple-300, #6436e7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const agreementList = [
  "(필수) 이용약관 동의",
  "(필수) 개인정보 처리방침 약관 동의",
  "(필수) 위치정보 약관 동의",
  "(필수) 청소년 보호 약관 동의",
  "(선택) 마케팅에 관한 개인정보 수집 및 이용 동의",
];

const PCSignUp = () => {
  //query에 referral이 있으면 자동으로 입력되게끔
  const query = new URLSearchParams(window.location.search);
  const referral = query.get("referral");
  const query_id = sessionStorage.getItem("text_id");
  const signUpType = sessionStorage.getItem("type");
  const token = sessionStorage.getItem("token");
  const [textId, setTextId] = useState("");
  const [nickName, setNickName] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [verifiedId, setVerfiedId] = useState(false);
  const [verifiedNickName, setVerfiedNickName] = useState(false);
  const { sendMessageToNative } = useNativeApp();
  useEffect(() => {
    if (query_id && signUpType) {
      setTextId(query_id);
      setVerfiedId(true);
      setPassword("asdfasdf!@12");
      setRePassword("asdfasdf!@12");
    }
    if (referral) {
      authFindByNickname({ nickname: referral })
        .then((res) => {
          const referralInput = document.getElementById(
            "referral"
          ) as HTMLInputElement;
          if (referralInput) {
            referralInput.value = referral;
          }
        })
        .catch((e) => {
          enqueueSnackbar("레퍼럴 코드가 올바르지 않습니다.", {
            variant: "error",
          });
        });
    }
  }, [referral]);
  const orientation = useScreenOrientation();
  const setLoading = useSetRecoilState(loadingState);
  const history = useHistory();
  const [checkedAgreement, setCheckedAgreement] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [isValidation, setIsValidation] = useState(false);
  const [userData, setUserData] = useState<UserData>({});
  const verifyReferrer = async (nickname: string): Promise<boolean> => {
    try {
      const referrer = await authFindByNickname({ nickname });
      if (!referrer.validate) {
        enqueueSnackbar("본인인증이 되어 있지 않은 추천인입니다.", {
          variant: "error",
        });
        return false;
      }
      return true;
    } catch (e: any) {
      if (e.code === USER_NOT_FOUND) {
        enqueueSnackbar("존재하지 않는 추천인 입니다.", { variant: "error" });
      }
      return false;
    }
  };
  const handleClickSignup = async () => {
    const referral = (document.getElementById("referral") as HTMLInputElement)
      .value;
    const verfityReferrerCheck = await verifyReferrer(referral);
    if (referral !== "" && !verfityReferrerCheck) {
      return;
    }

    if (!checkedAgreement.slice(0, -1).every((v) => v)) {
      alert("필수 약관에 동의해주세요.");
      return;
    }
    const userId = (document.getElementById("user-id") as HTMLInputElement)
      .value;
    const nickname = (document.getElementById("nickname") as HTMLInputElement)
      .value;

    if (!userId || !password || !rePassword || !nickname) {
      alert("모든 항목을 입력해주세요.");
      return;
    }
    if (!verifiedId) {
      alert("아이디 중복 확인을 해주세요.");
      return;
    }
    if (!verifiedNickName) {
      alert("닉네임 중복 확인을 해주세요.");
      return;
    }
    if (password !== rePassword) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    setLoading(true);
    //회원가입 요청
    authSignUp({
      textId: userId,
      password: password,
      nickname: nickname,
      username: isValidation
        ? userData.username
          ? userData.username
          : "UNKNOWN"
        : "UNKNOWN", //본인인증 체크
      phoneNumber: userData.phoneNumber || "",
      birthYear: userData.birthYear || "",
      birthMonth: userData.birthMonth || "",
      birthDate: userData.birthDate || "",
      gender: userData.gender || null,
      expireType: "ONE_YEAR",
      isAgree1: checkedAgreement![0],
      isAgree2: checkedAgreement![1],
      isAgree3: checkedAgreement![2],
      isAgree4: checkedAgreement![3],
      isAgree5: checkedAgreement![4],
      isAllAgree: checkedAgreement!.find((x) => !x) === undefined,
      isValidate: !!userData.certificated,
      recommendNickname: referral || "",
      signupType: signUpType || "",
      accessToken: token || "",
    })
      .then(async (res) => {
        if (res.payload.id) {
          if (process.env.REACT_APP_ENV === "production") {
            if (!isApp) {
              if (userData.referral) {
                Adjust.trackEvent({
                  eventToken: "dm6m4f",
                  callbackParams: [
                    { key: "user_id", value: "" + res.payload.id },
                    { key: "event_value", value: "" + userData.id },
                  ],
                });
              } else {
                Adjust.trackEvent({
                  eventToken: "f0hhdw",
                  callbackParams: [
                    { key: "user_id", value: "" + res.payload.id },
                    { key: "event_value", value: "Signup" },
                  ],
                });
              }
            } else {
              if (userData.referral) {
                sendMessageToNative("adjustEvent", "", {
                  eventToken: "dm6m4f",
                  eventName: "Register_recommend",
                  data: userData.id!,
                  callbackParams: {
                    user_id: "" + res.payload.id,
                    event_value: "" + userData.id,
                  },
                });
              } else {
                sendMessageToNative("adjustEvent", "", {
                  eventToken: "f0hhdw",
                  eventName: "Signup",
                  data: userData.id!,
                  callbackParams: {
                    user_id: "" + res.payload.id,
                    event_value: "Signup",
                  },
                });
              }
            }
          }
          if (referral !== "") {
            referralLogRegister({
              referralName: referral,
              newUserName: nickname!,
              textId: userId!,
            }).then(() => {
              enqueueSnackbar("회원가입 성공, 추천인 등록이 완료되었습니다.", {
                variant: "success",
              });
            });
          } else {
            enqueueSnackbar("회원가입 성공", { variant: "success" });
          }
          if (query_id && signUpType) {
            sessionStorage.removeItem("text_id");
            sessionStorage.removeItem("type");
            sessionStorage.removeItem("token");
          }
          history.replace("/login");
        }
      })
      .catch((e: any) => {
        if (e.statusCode === 400) {
          enqueueSnackbar("회원가입 실패: " + e.message, { variant: "error" });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const certificateUser = () => {
    setLoading(true);
    certification()
      .then((res) => {
        if (res.existed) {
          enqueueSnackbar("이미 가입된 회원입니다.", { variant: "error" });
        } else {
          const newUserData: UserData = {
            ...userData,
            username: res.username,
            phoneNumber: res.phoneNumber,
            birthYear: res.birth[0],
            birthMonth: res.birth[1],
            birthDate: res.birth[2],
            gender: res.gender,
            certificated: true,
          };
          setUserData(newUserData);
          enqueueSnackbar("본인인증 되었습니다.", { variant: "success" });
          setIsValidation(true);
        }
      })
      .catch((e: any) => {
        if (e.message === "failed to init") {
          enqueueSnackbar("현재 본인인증을 할 수 없습니다", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("본인인증에 실패했습니다: " + e.message, {
            variant: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyId = async (id: string): Promise<boolean> => {
    try {
      const { code } = await authVerifiedTextId({ textId: id });
      setVerfiedId(true);
      enqueueSnackbar("사용가능한 아이디입니다.", {
        variant: "success",
      });
      return code === 200;
    } catch (e: any) {
      setVerfiedId(false);
      enqueueSnackbar(e.message, { variant: "error" });
      return false;
    }
  };

  const verifyNickname = async (nickname: string) => {
    try {
      const { verified } = await authVerifiedNickname({ nickname });
      if (!verified) {
        enqueueSnackbar("이미 사용중인 닉네임입니다.", { variant: "error" });
        setVerfiedNickName(false);
      } else {
        enqueueSnackbar("사용가능한 닉네임입니다.", {
          variant: "success",
        });
        setVerfiedNickName(true);
      }
      return verified;
    } catch (e: any) {
      setVerfiedNickName(false);
      enqueueSnackbar(e.message, { variant: "error" });
      return false;
    }
  };

  useEffect(() => {
    if (orientation === "portrait") {
      history.replace("/");
    }
  }, [orientation]);
  return (
    <PCSignUpWrapper>
      <div className="title">회원가입</div>
      <div className="horizontal-line" />
      <Box idCheck={verifiedId} nickCheck={verifiedNickName}>
        <div className="row">
          <span className="label-wrapper">
            <span className="label">아이디</span>
            <span className="star">*</span>
          </span>
          <div className="input-wrapper">
            <div className="input-box">
              <input
                value={textId}
                onChange={(e) => {
                  const value = e.target.value;
                  const filteredValue = value.replace(/[^a-zA-Z0-9]/g, "");
                  setTextId(filteredValue);
                }}
                placeholder="아이디를 입력해주세요"
                id="user-id"
                disabled={verifiedId}
              />
              <div className="error">영문, 숫자 4~15자리</div>
            </div>
            <div
              className="id-button"
              onClick={() => {
                if (!verifiedId) verifyId(textId);
              }}
            >
              중복확인
            </div>
          </div>
        </div>
        <div className="row">
          <span className="label-wrapper">
            <span className="label">비밀번호</span>
            <span className="star">*</span>
          </span>
          <div className="input-wrapper">
            <div className="input-box">
              <input
                placeholder="비밀번호를 입력해주세요"
                id="password"
                type="password"
                value={password}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length > 15) {
                    return;
                  }
                  setPassword(value);
                }}
                disabled={query_id !== null}
              />
              <InputBoxWrapper success={password !== ""}>
                <div className="label">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <circle cx="6" cy="6" r="6" fill="#B7B7B7" />
                    <path
                      d="M4 6L5.77778 7.5L8 4.5"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>영문, 숫자, 특수문자 조합 8~15자리</span>
                </div>
              </InputBoxWrapper>
            </div>
          </div>
        </div>
        <div className="row">
          <span className="label-wrapper">
            <span className="label">비밀번호확인</span>
            <span className="star">*</span>
          </span>
          <div className="input-wrapper">
            <div className="input-box">
              <input
                disabled={query_id !== null}
                placeholder="비밀번호를 입력해주세요"
                id="re-password"
                type="password"
                value={query_id ? rePassword : undefined}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value !== password) {
                    debounce(() => setRePassword(""), 100)();
                    return;
                  }
                  debounce(() => setRePassword(value), 100)();
                }}
              />
              <InputBoxWrapper
                success={password !== "" && password === rePassword}
              >
                <div className="label">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <circle cx="6" cy="6" r="6" fill="#B7B7B7" />
                    <path
                      d="M4 6L5.77778 7.5L8 4.5"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>비밀번호를 한번 더 입력해주세요.</span>
                </div>
              </InputBoxWrapper>
            </div>
          </div>
        </div>
        <div className="row">
          <span className="label-wrapper">
            <span className="label">닉네임</span>
            <span className="star">*</span>
          </span>
          <div className="input-wrapper">
            <div className="input-box">
              <input
                value={nickName}
                onChange={(e) => setNickName(e.target.value)}
                placeholder="닉네임을 입력해주세요"
                id="nickname"
                disabled={verifiedNickName}
              />
              <div className="error">한 번 정한 뒤에는 수정이 불가합니다.</div>
            </div>
            <div
              className="nick-button"
              onClick={() => {
                if (!verifiedNickName) verifyNickname(nickName);
              }}
            >
              중복확인
            </div>
          </div>
        </div>
        <div className="row">
          <span className="label-wrapper">
            <span className="label">본인 인증</span>
            <span className="star">*</span>
          </span>
          <div className="input-wrapper">
            <div className="input-box">
              {isValidation ? (
                <div className="button disabled">본인인증완료</div>
              ) : (
                <div className="button" onClick={() => certificateUser()}>
                  본인인증 하러가기
                </div>
              )}
              <div className="error">
                본인인증을 하시면 이벤트 참여를 비롯한 앱의 여러 기능을 이용할
                수 있습니다.
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <span className="label-wrapper">
            <span className="label">추천인 입력</span>
            <span className="star">*</span>
          </span>
          <div className="input-wrapper">
            <div className="input-box">
              <input
                id="referral"
                placeholder="추천인 닉네임을 입력해주세요"
                disabled={!isValidation}
              />
              <div className="error">본인인증 후 이용 가능합니다.</div>
            </div>
          </div>
        </div>
      </Box>
      <div className="horizontal-line" />
      <Box idCheck={verifiedId} nickCheck={true}>
        <div className="row">
          <span className="label-wrapper">
            <span className="label">이용약관동의</span>
            <span className="star">*</span>
          </span>
          <div className="agreement-list">
            <div className="row">
              <svg
                onClick={() => {
                  setCheckedAgreement(
                    checkedAgreement.map(
                      () => !checkedAgreement.every((v) => v)
                    )
                  );
                }}
                className={
                  "checkbox " +
                  (checkedAgreement.every((v) => v) ? "checked" : "")
                }
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle cx="10" cy="10" r="10" fill="#8359F7" />
                <path
                  d="M6 10L8.96296 12.5L13.6667 7.5"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="title bold">전부 동의</div>
            </div>
            {agreementList.map((agreement, idx) => {
              return (
                <div className="row" key={idx}>
                  <svg
                    onClick={() => {
                      const newChecked = [...checkedAgreement];
                      newChecked[idx] = !newChecked[idx];
                      setCheckedAgreement(newChecked);
                    }}
                    className={
                      "checkbox " + (checkedAgreement[idx] ? "checked" : "")
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle cx="10" cy="10" r="10" fill="#8359F7" />
                    <path
                      d="M6 10L8.96296 12.5L13.6667 7.5"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="title">{agreement}</div>
                  <div className="button">보기</div>
                </div>
              );
            })}
          </div>
        </div>
      </Box>
      <div className="horizontal-line-light" />
      <SignUpButton onClick={handleClickSignup}>회원가입</SignUpButton>
    </PCSignUpWrapper>
  );
};

export default PCSignUp;
