import instance from "../utils/network";

export interface UserRank {
  nickname: string;
  userId: number;
  point: number;
  rank: number;
}

export interface TournamentScheduleItemInterface {
  id: number;
  image: string;
  title: string;
  description: string | null;
  lat: number | null;
  lon: number | null;
  prize: number;
  place: string;
  phone: string | null;
  webUrl: string;
  startAt: string;
  endAt: string;
  buyIn: number | null;
  blindLevelUpTime: null;
  registerLimit: null;
  currentBlindLevel: null;
  starting: null;
  reEntry: null;
  player: null;
  entry: null;
  totalStack: null;
  averageStack: null;
}

export function runnerRankingList(params: {
  startDate: string;
  endDate: string;
}): Promise<UserRank[]> {
  return instance.get("/tournament/ranking/runnerRankingList", { params });
}

export function totalRunnerRankingList(): Promise<UserRank[]> {
  return instance.get("/tournament/ranking/totalRunnerRankingList");
}

export function getMajorTournamentList(params: {
  year: number;
  month: number;
}): Promise<TournamentScheduleItemInterface[]> {
  return instance.get("/brand-competition/list", { params });
}

export function getDayMajorTournamentList(params: {
  year: number;
  month: number;
  day: number;
}): Promise<TournamentScheduleItemInterface[]> {
  return instance.get("/brand-competition/day-list", { params });
}

export function getMajorTournamentDetail(params: {
  tournamentId: number;
}): Promise<TournamentScheduleItemInterface> {
  return instance.get(`/brand-competition/${params.tournamentId}`);
}

export function getMinorTournamentList(params: {
  year: number;
  month: number;
}): Promise<TournamentScheduleItemInterface[]> {
  return instance.get("/minor-competition/list", { params });
}

export function getMinorTournamentDetail(params: {
  tournamentId: number;
}): Promise<TournamentScheduleItemInterface> {
  return instance.get(`/minor-competition/${params.tournamentId}`);
}
