import styled from "styled-components";
import {useContext} from "react";
import {StoreContext} from "../StoreDetail";

const HoldemNowInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  >.title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    >.title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.36px;
    }
    >.more-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;
      cursor: pointer;
      color: var(--Black-200, #B7B7B7);
      text-align: right;
      font-family: Pretendard;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.26px;
      >img {
        width: 16px;
        height: 16px;
      }
    }
  }
  >.content {
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background: var(--Black-100, #F0F0F0);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    >.empty {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.32px;
    }
    >.info-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
      >.description {
        flex-grow: 1;
        color: var(--Black-500, #202020);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        letter-spacing: -0.32px;
      }
      >.created-at {
        color: var(--Black-200, #B7B7B7);
        text-align: right;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
    >.thumbnail {
      width: 160px;
      height: 160px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #E5E5E5;
    }
  }
`
const HoldemNowInfo = () => {
  const {
    data
  } = useContext(StoreContext);

  return <HoldemNowInfoWrapper>
    <div className='title-row'>
      <div className='title'>홀덤 NOW</div>
      <div className='more-wrapper'>
        <span>더보기</span>
        <img src='/image-web/store/more.svg'/>
      </div>
    </div>
    {
      data.holdemNowList.length > 0 ?
        <div className='content'>
          <div className='info-wrapper'>
            <div className='description'>{data.holdemNowList[0].message}</div>
            <div className='created-at'>{data.holdemNowList[0].createdAt}</div>
          </div>
          {
            data.holdemNowList[0].imageList.length > 0 &&
            <img className='thumbnail' src={data.holdemNowList[0].imageList[0]}/>
          }
        </div> :
        <div className='content'>
          <div className='empty'>
            등록된 홀덤 NOW가 없습니다.
          </div>
        </div>
    }

  </HoldemNowInfoWrapper>
}

export default HoldemNowInfo;
