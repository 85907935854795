import styled from "styled-components";
import useDialog from "../../../hooks/useDialog";
import { useHistory, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Cafe } from "../../../api/types";
import {
  cafeDeleteReviewPhoto,
  cafeDetail,
  cafeRegistReviewPhoto,
} from "../../../api/cafe";
import { enqueueSnackbar } from "notistack";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../../recoil/auth";
import Loading from "../../../components/Loading";
import { getGameTypeString } from "../../../utils/constants";
import HoldemNowInfo from "./HoldemNowInfo";
import StoreInfo from "./StoreInfo";
import DetailInfo from "./DetailInfo";
import { StoreContext } from "../StoreDetail";
import MenuInfo from "./MenuInfo";
import LocationInfo from "./LocationInfo";
import { loadingState } from "../../../recoil/app";
import ImageViewer from "../../../components/ImageViewer";
import { EMPTY_IMAGE, shareURL } from "../../../utils/common";
import useQuickButtons from "../../../hooks/useQuickButtons";
import PCReview from "./PcReview";

const PCStoreDetailWrapper = styled.div`
  width: 100%;
  max-width: 1060px;
  padding-top: 50px;
  position: relative;
  padding-bottom: 140px;

  > .thumbnail-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 20px;
    overflow: hidden;

    > .button-wrapper {
      position: absolute;
      bottom: 16px;
      right: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 6px;

      > .item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: center;
        padding: 4px 12px;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.24px;
        border-radius: 12px;
        background: #fff;
      }
    }

    img {
      background: #d9d9d9;
    }

    > .main {
      width: 584px;
      height: 468px;
      flex-shrink: 0;
      object-fit: cover;
    }

    > .sub-list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      > .sub {
        width: 468px;
        height: 230px;
        flex-shrink: 0;
        object-fit: cover;
      }

      > .sub-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        > .item {
          width: 230px;
          height: 230px;
          flex-shrink: 0;
          object-fit: cover;
        }
      }
    }
  }

  > .content {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    > .main {
      width: 680px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 40px;

      > .report-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > .title {
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.36px;
        }

        > .button {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          width: 150px;
          padding: 9px 0;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border-radius: 8px;
          background: rgba(217, 24, 24, 0.1);
          color: #d91818;
          text-align: center;
          font-family: Pretendard;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.165px;

          > img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    > .sub {
      width: 338px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;
    }
  }
`;
const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Black-100, #f0f0f0);
`;
const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > .title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    > .title-box {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      text-align: center;
      > .title {
        margin-top: 8px;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.64px;
      }
      > .star {
        margin-left: 15px;
        color: gold;
        font-size: 16px;
        margin-bottom: 2px;
      }
      > .review-count {
        margin-left: 3px;
        font-size: 16px;
        margin-bottom: 2px;
      }
    }

    > .address {
      margin-top: 6px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
    }

    > .tag-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      > .tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2px;
        padding: 4px 8px;
        color: var(--Purple-300, #6436e7);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 16.667px;
        border: 1px solid var(--Purple-100, #f0eaff);

        > img {
          width: 16px;
          height: 16px;
        }
      }

      > .tag.premium {
        border: 1.5px solid #6c36d6;
        background: var(--Purple-100, #f0eaff);
      }

      > .tag.affiliate {
        color: #176ced;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      > .tag.normal {
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  > .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    height: 44px;

    > .item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border-radius: 22px;
      background: var(--Black-100, #f0f0f0);

      > img {
        width: 24px;
        object-fit: contain;
      }
    }
  }
`;

const CurrentTournamentWrapper = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid var(--Black-100, #f0f0f0);

  > .title {
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
  }

  > .content {
    padding: 16px;
    word-break: keep-all;
    width: 100%;
    color: var(--Black-500, #202020);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 18.2px */
    letter-spacing: -0.26px;
    border-radius: 8px;
    background: var(--Black-100, #f0f0f0);
  }
`;
const Index = () => {
  const { openDialog } = useDialog();
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);

  const setLoading = useSetRecoilState(loadingState);
  const user = useRecoilValue(userState);
  const history = useHistory();
  const { toggleLike } = useQuickButtons();
  const params = useParams<{
    id: string;
  }>();
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState<Cafe>();
  const [rate, setRate] = useState(0);
  const handleClickAddPhoto = () => {
    //파일 선택
    const file = document.createElement("input");
    file.type = "file";
    file.accept = "image/*";
    file.click();
    file.onchange = (e) => {
      if (file.files && file.files[0]) {
        handleRegister(file.files[0]);
      }
    };
  };

  const handleRegister = useCallback(
    (file: File) => {
      if (!data?.id) {
        return;
      }

      setLoading(true);

      cafeRegistReviewPhoto(data.id, file)
        .then(() => {
          handleUpdate();
          enqueueSnackbar("사진이 등록되었습니다.", { variant: "success" });
        })
        .catch((e: any) => {
          enqueueSnackbar(e.message, { variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [data?.id]
  );

  const handleUpdate = useCallback(() => {
    if (refresh) {
      setRefresh(false);
    }
    if (params.id) {
      const cafeId = Number(params.id);
      cafeDetail(cafeId)
        .then((res) => {
          if (res.reviewData.length > 0) {
            const totalScore = res.reviewData.reduce(
              (sum, review) => sum + review.score,
              0
            );
            setRate(totalScore / res.reviewData.length);
          }
          res.reviewData.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          setData(res);
        })
        .catch((e: any) => {
          enqueueSnackbar("펍 정보를 가져올 수 없습니다: " + e.message, {
            variant: "error",
          });
          history.goBack();
        });
    } else {
      history.goBack();
    }
  }, [params.id, user?.id, refresh]);

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate]);

  const handleDeleteImage = useCallback(
    (index: number) => {
      if (data) {
        const dialogText = `
      <img src='${data?.reviewImages[index].imageUrl}' style="width: 60px; height: 60px"/><br/>
      <span style="margin-bottom: 20px;">해당 사진을 삭제하시겠습니까?</span>
    `;
        openDialog({
          text: dialogText,
          confirm: true,
          confirmText: "삭제하기",
          type: "web",
          onConfirm: () => {
            setLoading(true);
            cafeDeleteReviewPhoto({
              cafeId: data.id,
              photoId: data?.reviewImages[index].id,
            })
              .then(() => {
                handleUpdate();
                enqueueSnackbar("사진이 삭제되었습니다.", {
                  variant: "success",
                });
              })
              .catch((e) => {
                enqueueSnackbar(e.message, { variant: "error" });
              })
              .finally(() => {
                setLoading(false);
              });
          },
        });
      }
    },
    [data]
  );

  if (!data) {
    return <Loading full />;
  }

  return (
    <>
      {selectedImageIndex !== -1 && (
        <ImageViewer
          canEdit={data.myCafe && user?.role === "ROLE_SELLER"}
          images={data?.images.map((x) => x.imageUrl) ?? []}
          defaultIndex={selectedImageIndex}
          onDeleteImage={(index) => handleDeleteImage(index)}
          reviewImages={data.reviewImages}
          onClose={() => setSelectedImageIndex(-1)}
        />
      )}
      <StoreContext.Provider
        value={{
          data: data,
          update: handleUpdate,
        }}
      >
        <PCStoreDetailWrapper>
          <div className="thumbnail-wrapper">
            <img
              className="main"
              src={
                data.images.length > 0
                  ? data.images[0].imageUrl
                  : "https://dfesoodpx4jgd.cloudfront.net/cafe/default.png"
              }
              alt={data.images.length > 0 ? "Main image" : "Default image"}
            />
            {(data.images ?? []).length > 1 && (
              <div className="sub-list">
                <img
                  className="sub"
                  src={data.images[1].imageUrl}
                  alt="보조 이미지 1"
                />
                <div className="sub-row">
                  <img
                    className="item"
                    src={data.images[2] ? data.images[2].imageUrl : EMPTY_IMAGE}
                    alt="보조 이미지 2"
                  />
                  <img
                    className="item"
                    src={data.images[3] ? data.images[3].imageUrl : EMPTY_IMAGE}
                    alt="보조 이미지 3"
                  />
                </div>
              </div>
            )}
            <div className="button-wrapper">
              {user?.role === "ROLE_SELLER" && data.myCafe && (
                <div className="item" onClick={handleClickAddPhoto}>
                  사진 추가
                </div>
              )}
              {data.images.length > 0 && (
                <div
                  className="item"
                  onClick={() => {
                    setSelectedImageIndex(0);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14 10L11.9427 7.94267C11.6926 7.69271 11.3536 7.55229 11 7.55229C10.6464 7.55229 10.3074 7.69271 10.0573 7.94267L4 14M3.33333 2H12.6667C13.403 2 14 2.59695 14 3.33333V12.6667C14 13.403 13.403 14 12.6667 14H3.33333C2.59695 14 2 13.403 2 12.6667V3.33333C2 2.59695 2.59695 2 3.33333 2ZM7.33333 6C7.33333 6.73638 6.73638 7.33333 6 7.33333C5.26362 7.33333 4.66667 6.73638 4.66667 6C4.66667 5.26362 5.26362 4.66667 6 4.66667C6.73638 4.66667 7.33333 5.26362 7.33333 6Z"
                      stroke="#444444"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {data.images.length}
                </div>
              )}
            </div>
          </div>
          <div className="content">
            <div className="main">
              <TitleWrapper>
                <div className="title-wrapper">
                  <div className="tag-row">
                    {data.pubType === "PREMIUM" && (
                      <div className="tag premium">
                        <img src="/image-web/store/premium.png" />
                        프리미엄
                      </div>
                    )}
                    {data.pubType === "AFFILIATE" && (
                      <div className="tag affiliate">
                        <img src="/image-web/store/store_check.svg" />
                        제휴
                      </div>
                    )}
                    {data.pubType === "NORMAL" && (
                      <div className="tag normal">일반</div>
                    )}
                    <div className="tag">
                      {getGameTypeString(data.gameTypes)}
                    </div>
                    {data.buyIn && (
                      <div className="tag">
                        바이인 : {data.buyIn.toLocaleString()} ~{" "}
                        {data.buyInMax ? data.buyInMax.toLocaleString() : ""}
                      </div>
                    )}
                  </div>
                  <div className="title-box">
                    <div className="title">{data.cafeName}</div>
                    <div className="star">★</div>
                    <div className="review-count">{rate.toFixed(1)}</div>
                  </div>
                  <div className="address">{data.newAddress}</div>
                </div>
                <div className="button-wrapper">
                  <div
                    className="item"
                    onClick={async () => {
                      await toggleLike(data?.id!, "CAFE");
                      handleUpdate();
                    }}
                  >
                    <img
                      src={`/image-web/store/${
                        data.like ? "Heart_on" : "Heart"
                      }.svg`}
                    />
                  </div>
                  {data.openChatURL !== null && (
                    <div
                      className="item"
                      onClick={() => {
                        if (data.openChatURL)
                          window.open(data.openChatURL, "_blank");
                      }}
                    >
                      <img src="/image-web/store/Btn/Kakao.png" />
                    </div>
                  )}
                  <div
                    className="item"
                    onClick={() => shareURL(window.location.href)}
                  >
                    <img src="/image-web/store/Share.svg" />
                  </div>
                </div>
              </TitleWrapper>
              <HoldemNowInfo />
              <HorizontalDivider />
              <StoreInfo />
              <HorizontalDivider />
              <DetailInfo />
              <HorizontalDivider />
              <MenuInfo />
              <HorizontalDivider />
              <PCReview
                reviewData={data.reviewData}
                pubId={data.id}
                todayReviewPhotoAdd={data.todayReviewPhotoAdd}
                setRefresh={setRefresh}
              />
              <HorizontalDivider />
              <div className="report-wrapper">
                <div className="title">
                  실제 방문해보니 달랐던 정보가 있나요 ?
                </div>
                <div className="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M17 17.4V12C17 10.8065 16.5259 9.66193 15.682 8.81802C14.8381 7.97411 13.6935 7.5 12.5 7.5C11.3065 7.5 10.1619 7.97411 9.31802 8.81802C8.47411 9.66193 8 10.8065 8 12V17.4M17 17.4H8M17 17.4C17.4774 17.4 17.9352 17.5896 18.2728 17.9272C18.6104 18.2648 18.8 18.7226 18.8 19.2V21H6.2V19.2C6.2 18.7226 6.38964 18.2648 6.72721 17.9272C7.06477 17.5896 7.52261 17.4 8 17.4M20.6 12H21.5M18.35 5.25L17.9 5.7M3.5 12H4.4M12.5 3V3.9M6.13628 5.6361L6.77258 6.2724M12.5 12V17.4"
                      stroke="#D91818"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>허위정보 신고</span>
                </div>
              </div>
            </div>
            <div className="sub">
              <LocationInfo />
              {data.pubTournamentList.length > 0 && (
                <CurrentTournamentWrapper>
                  <div className="title">진행중 토너먼트</div>
                  {data.pubTournamentList.map((tournament, index) => {
                    return (
                      <div key={index} className="content">
                        {tournament.title}
                      </div>
                    );
                  })}
                </CurrentTournamentWrapper>
              )}
            </div>
          </div>
        </PCStoreDetailWrapper>
      </StoreContext.Provider>
    </>
  );
};
export default Index;
