import styled from "styled-components";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  IYoutube,
  IYoutubeChannel,
  getYoutubeChannelList,
  getYoutubeList,
} from "../../../api/youtube";
import YoutubeList from "./YoutubeList";
import RecommendYoutubeList from "./RecommendYoutubeList";
import YoutubeChannelList from "./YoutubeChannelList";

const EventWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: white;

  @media ${MEDIA_DESKTOP} {
    position: static;
    height: auto;
    max-width: 1060px;
    margin: 0 auto;
    padding: 40px 0;
  }
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background: white;
  z-index: 11;

  @media ${MEDIA_DESKTOP} {
    position: static;
    padding: 0;
  }

  .close {
    cursor: pointer;
    width: 24px;
    height: 24px;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  .title {
    color: #444;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;

    @media ${MEDIA_DESKTOP} {
      font-size: 24px;
      font-weight: 700;
    }
  }
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 12px 56px;

  @media ${MEDIA_DESKTOP} {
    padding: 0 16px 56px;
  }
`;

const YoutubePage = () => {
  const history = useHistory();
  const [youtubeList, setYoutubeList] = useState<IYoutube[]>([]);
  const [recommendYoutubeList, setRecommendYoutubeList] = useState<IYoutube[]>(
    []
  );
  const [youtubeChannelList, setYoutubeChannelList] = useState<
    IYoutubeChannel[]
  >([]);

  const handleClose = () => {
    history.push("/");
  };

  const getList = async () => {
    const youtube = await getYoutubeList("YOUTUBE");
    const recommended = youtube.filter((item) => item.recommend);
    const channels = await getYoutubeChannelList();
    setYoutubeChannelList(channels);
    setRecommendYoutubeList(recommended);
    setYoutubeList(youtube);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <EventWrapper>
      <Header>
        <div className="close" onClick={handleClose}>
          <img src="/image-web/Icon/Back.svg" alt="close" />
        </div>
        <div className="title">러튜브</div>
      </Header>
      <Content>
        <YoutubeChannelList list={youtubeChannelList} />
        <RecommendYoutubeList list={recommendYoutubeList} />
        <YoutubeList list={youtubeList} />
      </Content>
    </EventWrapper>
  );
};

export default YoutubePage;
