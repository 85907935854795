import styled from "styled-components";
import {useEffect, useState} from "react";
import {
  ChargeHistoryInterface,
  ChargeHistoryResultInterface,
  getChargeHistory,
  getUseRPHistory,
  UseRPHistoryInterface
} from "../../../../api/auth";
import moment from "moment";
import {MEDIA_DESKTOP} from "../../../../hooks/useScreenOrientation";

const HistoryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`

const ListWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > .header {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media ${MEDIA_DESKTOP} {
      padding-bottom: 12px;
      border-bottom: 1px solid #B7B7B7;
    }
    
    
    > .item {
      color: var(--Black-200, #B7B7B7);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media ${MEDIA_DESKTOP} {
        font-size: 14px;
      }
    }

    > .item.rp {
      text-align: center;
      width: 72px;
      flex-shrink: 0;
    }
  }

  > .inner {
    flex-grow: 1;
    margin-top: 10px;
    width: 100%;
    max-height: calc(100% - 1px);
    overflow-y: scroll;

    > .list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 7px;
    }
  }

  > .pagination-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding-top: 25px;
    padding-bottom: 10px;
    @media ${MEDIA_DESKTOP} {
      width: 100%;
      border-top: 1px solid #B7B7B7;
    }

    > .button {
      cursor: pointer;
      width: 32px;
      height: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid var(--Purple-100, #F0EAFF);
      background: #FFF;
      color: var(--Black-400, #444);
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */

      > svg {
        width: 20px;
        height: 20px;
      }
    }
    > .button.selected {
      border: 1px solid var(--Purple-300, #6436E7);
      color: var(--Purple-300, #6436E7);
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }

    > .button.disabled {
      opacity: 0.2;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
`

const HistoryItemWrapper = styled.div`
  padding: 4px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media ${MEDIA_DESKTOP} {
    padding-bottom: 12px;
    border-bottom: 1px solid #F0F0F0;
    &:last-child {
      border-bottom: none;
    }
  }

  > .info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;

    > .content {
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media ${MEDIA_DESKTOP} {
        font-size: 14px;
      }
    }

    > .date {
      color: var(--Black-200, #B7B7B7);
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media ${MEDIA_DESKTOP} {
        font-size: 14px;
      }
    }
  }

  > .point {
    width: 72px;
    flex-shrink: 0;
    color: var(--Black-300, #808080);
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media ${MEDIA_DESKTOP} {
      font-size: 14px;
    }
  }
`
const LeftArrow = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M12.5 15L7.5 10L12.5 5" stroke="#444444" strokeWidth="1.25" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>
}
const RightArrow = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M7.5 15L12.5 10L7.5 5" stroke="#444444" strokeWidth="1.25" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>
}

const HISTORY_ITEM_PER_PAGE = 10;
const History = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageArray, setPageArray] = useState<number[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [startPage, setStartPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>(10);
  const [chargeHistory, setChargeHistory] = useState<ChargeHistoryResultInterface | null>(null);


  useEffect(() => {
    const innerTotalCount = chargeHistory?.totalCount ?? 0;
    const arr = [];
    const maxPage = Math.ceil(innerTotalCount / HISTORY_ITEM_PER_PAGE);
    const innerStartPage = Math.floor((currentPage - 1) / 5) * 5 + 1;
    const innerEndPage = Math.min(innerStartPage + 4, maxPage);
    setStartPage(innerStartPage);
    setEndPage(innerEndPage);
    //페이지는 최대 10개까지
    for (let i = innerStartPage; i <= innerEndPage; i++) {
      arr.push(i);
    }
    setPageArray(arr);
    setHasNextPage(innerEndPage < maxPage);
    setHasPrevPage(innerStartPage > 1);
  }, [currentPage, chargeHistory]);

  useEffect(() => {
    getChargeHistory().then((res) => {
      setChargeHistory(res);
    })
  }, []);

  return <HistoryWrapper>
    <ListWrapper>
      <div className='header'>
        <div className='item'>날짜 및 내용</div>
        <div className='item rp'>충전 RP</div>
      </div>
      <div className='inner'>
        {
          chargeHistory ? <div className='list'>
            {
              chargeHistory.list.map((item, i) => {
                return <HistoryItemWrapper key={i}>
                  <div className='info'>
                    <div className='content'>
                      {item.chargeProduct === 'ANY' ? '포인트 충전 테스트' : item.chargeProduct}
                    </div>
                    <div className='date'>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                  </div>
                  <div className='point'>
                    +{item.points.toLocaleString()}
                  </div>
                </HistoryItemWrapper>
              })
            }
          </div> : <></>
        }
      </div>
      <div className='pagination-row'>
        <div className={'button ' + (!hasPrevPage ? 'disabled' : '')}
             onClick={() => setCurrentPage(startPage - 1)}>
          <LeftArrow/>
        </div>
        {
          //5개씩 페이징
          pageArray.map((_, i) => {
            return <div key={i} className={'button ' + (
              currentPage === _ ? 'selected' : ''
            )}
                        onClick={() => setCurrentPage(_)}
            >
              {_}
            </div>
          })
        }
        <div
          className={'button ' + (!hasNextPage ? 'disabled' : '')}
          onClick={() => setCurrentPage(endPage + 1)}>
          <RightArrow/>
        </div>
      </div>
    </ListWrapper>
  </HistoryWrapper>
}

export default History;
