import styled from "styled-components";
import {InfoBoxWrapper} from "../../../components/common/InfoBoxWrapper";
import {RefObject, useContext, useEffect, useState} from "react";
import {useSetRecoilState} from "recoil";
import {navigationTargetState} from "../../../recoil/store";
import {renderToString} from "react-dom/server";
import StoreMarker from "../../../components/StoreMarker";
import {StoreContext} from "../StoreDetail";
import {copyClipboard} from "../../../utils/common";

const LocationInfoBox = styled(InfoBoxWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border-radius: 12px;
  padding: 0;
  border: 1px solid var(--Black-100, #F0F0F0);
  #map {
    width: 338px;
    height: 240px;
    border-radius: 8px;
    overflow: hidden;
  }
  >.address-row {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    >.address {
      flex-grow: 1;
      word-break: break-all;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.32px;
    }
    >.button {
      flex-shrink: 0;
      cursor: pointer;
      width: 80px;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: var(--Purple-100, #F0EAFF);
      color: var(--Purple-300, #6436E7);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.28px;
    }
    
  }
`


const LocationInfo = () => {
  const {data} = useContext(StoreContext);
  const setNavigationTarget = useSetRecoilState(navigationTargetState);

  const [map, setMap] = useState<any>(null)
  useEffect(() => {
    const mapDiv = document.getElementById("map") as HTMLDivElement;

    const NaverMaps = window.naver.maps;
    const map = new NaverMaps.Map(mapDiv, {
      center: new NaverMaps.LatLng(data.lat, data.lon),
      tileSpare: 3,
    });
    //유저 조작 불가능하도록 설정
    map.setOptions({
      draggable: false,
      pinchZoom: false,
      scrollWheel: false,
      keyboardShortcuts: false,
      disableDoubleTapZoom: true,
      disableDoubleClickZoom: true,
      disableTwoFingerTapZoom: true,
    })
    setMap(map)

    new NaverMaps.Marker({
      position: new NaverMaps.LatLng(data.lat, data.lon),
      map: map,
      title: data.cafeName,
      icon: {
        content: renderToString(
          <StoreMarker
            selected
            pubType={data.pubType}
            buyInFrom={data.buyIn}
            buyInTo={data.buyInMax}
            name={data.cafeName}/>
        ),
        anchor: new NaverMaps.Point(0, 30)
      }
    });
  }, [])

  return <LocationInfoBox>
    <div id='map'/>
    <div className='address-row'>
      <div className='address'>
        {data.newAddress} {data.detailAddress}
      </div>
      <div className='button' onClick={() => copyClipboard(data.newAddress)}>
        주소 복사
      </div>
    </div>
  </LocationInfoBox>
}
export default LocationInfo;
