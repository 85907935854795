import {useCallback, useEffect, useState} from "react";
import {Area, areaCities, areaCitiesWithCafeCount, areaProvinces} from "../api/area";

function useCities() {
  const [provinces, setProvinces] = useState<Area[]>([]);
  const [cities, setCities] = useState<Area[]>([]);

  useEffect(() => {
    Promise.all([areaProvinces(), areaCities()]).then(([provinces, cities]) => {
      setProvinces(provinces || []);
      setCities(cities || []);
    })
  }, []);

  const getAddressByCoord = useCallback((lat1: number, lng1: number) => {
    let min = Number.MAX_VALUE;
    let closetCity = cities.find(x => x.name === '강남구');

    for (let i = 0; i < cities.length; i++) {
      const lat2 = cities[i].centerLat;
      const lng2 = cities[i].centerLon;
      const distance = Math.sqrt(Math.pow((lat1 - lat2), 2) + Math.pow((lng1 - lng2), 2));
      if (distance < min) {
        min = distance;
        closetCity = cities[i];
      }
    }

    if (closetCity) {
      const province = provinces.find(x => x.id === closetCity?.provinceId);
      if (province) {
        return `${province.name} ${closetCity.name}`;
      }
    }

    return '로드 중...';
  }, [provinces, cities]);

  return {
    provinces: provinces,
    cities: cities,
    getAddressByCoord: getAddressByCoord
  }
}

export default useCities;
