import styled from "styled-components";
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useDialog from "../../../hooks/useDialog";
import { useHistory, useParams } from "react-router-dom";
import Write from "../HoldemNow/Write";
import { shareURL, timeAgo } from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import Loading from "../../../components/Loading";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/app";
import useScreenOrientation, {
  MEDIA_DESKTOP,
} from "../../../hooks/useScreenOrientation";
import useUserInfo from "../../../hooks/useUserInfo";
import moment from "moment";
import ImageViewer from "../../../components/ImageViewer";
import { LOCAL_STORAGE_ACCESS_KEY } from "../../../utils/network";
import {
  addMarketComment,
  cancelLikeMarket,
  exitMarketComment,
  getMarketComment,
  getMarketDetail,
  likeMarket,
  SecondHandMarketCommentResponse,
  SecondHandMarketResponse,
  updateMarketComment,
} from "../../../api/second-hand-market";
import { Swiper, SwiperSlide } from "swiper/react";
import WriteMarket from "../SecondHandMarket/WriteBox";

const DetailWrapper = styled.div<{
  scrollLock: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 500px;
  height: 100svh;
  overscroll-behavior: none;
  z-index: 10;
  background: white;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${(p) =>
    p.scrollLock
      ? `
      overflow-y: hidden;
  `
      : `
  
  `} @media ${MEDIA_DESKTOP} {
    right: 0;
    padding: 0;
    height: 100%;
  }

  > .floating-button-wrapper {
    position: fixed;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    padding: 12px 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-top: 1px solid var(--Black-100, #f0f0f0);
    background: #fff;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15);
    @media ${MEDIA_DESKTOP} {
      display: none;
    }

    > textarea {
      outline: none;
      border: none;
      resize: none;
      flex-grow: 1;
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      height: 20px;
      max-height: 100px;

      &::placeholder {
        color: var(--Black-300, #808080);
      }
    }

    > .button {
      cursor: pointer;
      width: 52px;
      height: 32px;
      color: var(--Purple-300, #6436e7);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.28px;
      border-radius: 6px;
      background: var(--Purple-100, #f0eaff);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    > .button:active {
      background: #502bb5;
    }
    .button.disable {
      cursor: not-allowed;
      color: var(--Gray-300, #b7b7b7);
      background: var(--Gray-100, #e0e0e0); /* 비활성 버튼의 배경색 설정 */
    }
  }

  > .inner {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      border-radius: 12px;
      border: 1px solid var(--Black-100, #f0f0f0);
      background: #fff;
    }
  }
`;

const HorizontalBar = styled.div`
  width: 100%;
  height: 8px;
  background: var(--Black-100, #f0f0f0);
  @media ${MEDIA_DESKTOP} {
    height: 1px;
    width: calc(100% - 40px);
    margin: 0 20px;
  }
`;
const CommentWrapper = styled.div`
  margin-top: 26px;
  padding: 16px;
  padding-bottom: 60px;
  > .title {
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  > list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;
    background: white;
    @media ${MEDIA_DESKTOP} {
      padding-bottom: 0;
    }
  }
`;
const CommentItemWrapper = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 20px 16px;
  border-bottom: 1px solid var(--Black-100, #f0f0f0);

  &:last-child {
    border-bottom: none;
  }

  > .profile-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    > .profile {
      width: 34px;
      height: 34px;
      > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    > .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;

      > .author-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: flex-start;
        gap: 4px;

        > .author {
          color: var(--Black-400, #444);
          font-family: Pretendard;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }

      .createdAt {
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }
  }

  > .content {
    margin-top: 10px;
    width: 100%;
    text-align: left;
    color: #000;

    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.26px;

    > .edited {
      color: var(--Black-200, #b7b7b7);
    }
  }

  > .fix-row {
    margin-top: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;

    > .button {
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      width: 53px;
      height: 26px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid var(--Black-200, #b7b7b7);
    }
  }
`;

const EmptyWrapper = styled.div`
  padding: 60px 0 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  @media ${MEDIA_DESKTOP} {
    display: none;
  }

  > .empty-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;

    > img {
      width: 180px;
      object-fit: contain;
      filter: grayscale(10%) brightness(95%);
    }

    > .empty-text {
      width: 100%;
      color: var(--Black-300, #808080);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

const PCCommentWriteWrapper = styled.div`
  display: none;
  @media ${MEDIA_DESKTOP} {
    width: calc(100% - 40px);
    padding: 16px;
    margin: 20px;
    border-radius: 12px;
    background: var(--Black-100, #f0f0f0);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 8px;
    > .edit-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: 16px;
      width: 100%;

      > .profile {
        width: 42px;
        height: 42px;
        object-fit: cover;
        border-radius: 50%;
        flex-shrink: 0;
      }

      > textarea {
        margin-top: 10px;
        height: 20px;
        resize: none;
        background: transparent;
        border: none;
        outline: none;
        flex-grow: 1;
        color: var(--Black-500, #202020);
        text-align: justify;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.28px;

        &::placeholder {
          color: var(--Black-300, #808080);
        }
      }
    }

    > .button {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 32px;
      color: var(--Black-200, #b7b7b7);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.28px;
    }
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding 20px 16px;
  
  > .title {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #444444;
  }

  .middle {
    margin-top :6px;
    display: flex;
    flex-direction: row;
    gap: 6px;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #808080;
  }

  .bottom {
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > .price {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
    }
    > .icon-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        > .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          color: var(--Black-300, #808080);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          letter-spacing: -0.28px;

          > img {
            width: 16px;
            height: 16px;
            object-fit: contain;
          }
        }
    }
  }
`;

const HoldemItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  background: white;
  @media ${MEDIA_DESKTOP} {
    padding: 24px 20px;
  }

  > .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    > .images {
      width: 100%;
      position: relative;
      background: #e5e5e5;
      padding-top: 100%;
      overflow: hidden;

      .swiper-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .close {
        z-index: 999;
        position: absolute;
        top: 15px;
        left: 20px;

        img {
          width: 24px;
          height: 24px;
          filter: brightness(0) invert(1);
        }
      }
      .progress-wrapper {
        z-index: 999;
        position: absolute;
        top: 15px;
        right: 20px;
        display: flex;
        gap: 9px;

        img {
          width: 24px;
          height: 24px;
        }
      }
      .like-wrapper {
        z-index: 999;
        position: absolute;
        bottom: 20px;
        right: 20px;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .progress-dots {
        z-index: 999;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 9px;
      }

      .dot {
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 10.5px;
        transition: all 0.4s ease;
      }

      .dot.active {
        border-radius: 10.5px;
        width: 31px;
        background: var(--Purple-300, #6436e7);
        transition: all 0.4s ease;
      }
    }
  }
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 28px 16px;
  gap: 10px;
  > .cover_image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  > .profile {
    display: flex;
    flex-direction: column;

    > .tag-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      > .tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2px;
        border-radius: 2px;
        padding: 4px;
        border: 1px solid ${(p) => p.theme.color.purple100};

        > img {
          width: 16px;
        }

        color: ${(p) => p.theme.color.purple300};
        text-align: right;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
      }
    }
    > .user_name {
      flex: 1;
      display: flex;
      align-items: center;
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.36px;
    }
  }
`;

const InfoWrapper = styled.div`
  margin-top: 24px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  > .title {
    color: var(--Black-400, #444);
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  > .info_box {
    padding: 16px;
    color: #000;

    font-family: Pretendard;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.26px;
  }
`;

interface DetailPageProps {
  onClose?: () => void;
}

const SecondHandMarketDetail = ({ onClose }: DetailPageProps) => {
  const orientation = useScreenOrientation();
  const { user } = useUserInfo();
  const { openDialog } = useDialog();
  const setLoading = useSetRecoilState(loadingState);
  const history = useHistory();
  const inputRefMobile = useRef<HTMLTextAreaElement | null>(null);
  const inputRefPC = useRef<HTMLTextAreaElement | null>(null);
  const [showEdit, setShowEdit] = useState(false);
  const param = useParams();
  const detailId = (param as any).id;
  const [commentList, setCommentList] = useState<
    SecondHandMarketCommentResponse[]
  >([]);
  const [data, setData] = useState<SecondHandMarketResponse | null>(null);
  const [refreshCommentList, setRefreshCommentList] = useState(
    new Date().getTime()
  );
  const [selectedEditCommentId, setSelectedEditCommentID] = useState<
    number | null
  >(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_KEY);
  const [comment, setComment] = useState<string>("");

  const [recommendation, setRecommendation] = useState(false);
  const [recommendationCount, setRecommendationCount] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (detailId) {
      setLoading(true);
      getMarketDetail(detailId)
        .then((res) => {
          setData(res);
          setRecommendation(res.like);
          setRecommendationCount(res.likeCount);
        })
        .catch((err) => {
          if (err.code === 9001) {
            enqueueSnackbar("해당 게시글이 존재하지 않습니다.", {
              variant: "error",
            });
            history.replace("/second-hand-market");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [detailId, showEdit]);

  useEffect(() => {
    if (!data) return;
    getMarketComment(detailId).then((res) => {
      setCommentList(res);
    });
  }, [data, refreshCommentList]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      if (history.action === "PUSH") {
        history.goBack();
      } else {
        history.replace("/second-hand-market");
      }
    }
  };
  const handleEditContent = () => {
    setShowEdit(true);
  };
  const handleAddComment = useCallback(() => {
    let inputRef: RefObject<HTMLTextAreaElement>;
    if (orientation === "landscape") {
      inputRef = inputRefPC;
    } else {
      inputRef = inputRefMobile;
    }
    if (inputRef && inputRef.current && inputRef.current.value === "") return;
    if (data) {
      if (selectedEditCommentId) {
        openDialog({
          text: "해당 댓글을 수정하시겠습니까?",
          confirm: true,
          confirmText: "수정하기",
          type: "web",
          onConfirm: () => {
            if (inputRef && inputRef.current) {
              setLoading(true);
              const message = inputRef.current.value;
              updateMarketComment({
                message,
                id: selectedEditCommentId,
              })
                .then(() => {
                  enqueueSnackbar("댓글이 수정되었습니다.", {
                    variant: "success",
                  });
                  setComment("");
                  setRefreshCommentList(new Date().getTime());
                  setSelectedEditCommentID(null);

                  if (inputRef.current) {
                    inputRef.current.value = "";
                  }
                })
                .catch((e: any) => {
                  enqueueSnackbar(e.message, { variant: "error" });
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          },
        });
      } else {
        if (!accessToken) {
          enqueueSnackbar("로그인이 필요한 서비스입니다.", {
            variant: "error",
          });
          history.push("/login");
          return;
        }
        openDialog({
          text: "댓글을 등록하시겠습니까?",
          confirm: true,
          confirmText: "등록하기",
          type: "web",
          onConfirm: () => {
            if (inputRef && inputRef.current) {
              setLoading(true);
              const message = inputRef.current.value;
              addMarketComment({
                message,
                marketId: detailId,
              })
                .then(() => {
                  enqueueSnackbar("댓글이 등록되었습니다.", {
                    variant: "success",
                  });
                  setRefreshCommentList(new Date().getTime());
                  setComment("");
                })
                .finally(() => {
                  setLoading(false);
                });
            }
          },
        });
      }
    }
  }, [data, inputRefPC, inputRefMobile, selectedEditCommentId, orientation]);

  const recommendPost = async () => {
    if (!accessToken) {
      history.push("/login");
      enqueueSnackbar("로그인이 필요한 서비스입니다.", {
        variant: "error",
      });
      return;
    }

    if (recommendation) {
      try {
        const result = await cancelLikeMarket(detailId);
        if (result.code === 200) {
          setRecommendation(false);
          setRecommendationCount(recommendationCount - 1);
        }
      } catch (e) {}
    } else {
      try {
        const result = await likeMarket(detailId);
        if (result.code === 200) {
          setRecommendation(true);
          setRecommendationCount(recommendationCount + 1);
        }
      } catch (e) {}
    }
  };

  return (
    <>
      {showEdit && (
        <WriteMarket
          onClose={() => setShowEdit(false)}
          mode={"edit"}
          marketData={data ? data : undefined}
        />
      )}
      {selectedImageIndex !== -1 && (
        <ImageViewer
          images={data?.imageList || []}
          defaultIndex={selectedImageIndex}
          onClose={() => setSelectedImageIndex(-1)}
        />
      )}
      {data ? (
        <DetailWrapper scrollLock={showEdit}>
          <div className="inner">
            <HoldemItem>
              <div className="content-wrapper">
                <div className="images">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    className="swiper-container"
                    onSlideChange={(swiper: any) =>
                      setCurrentIndex(swiper.realIndex)
                    }
                  >
                    {data.imageList.map((url, i) => (
                      <SwiperSlide key={i} className="swiper-slide">
                        <img
                          src={url}
                          alt={`Slide ${i}`}
                          onClick={() => setSelectedImageIndex(i)}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="progress-dots">
                    {data.imageList.map((_, i) => (
                      <div
                        key={i}
                        className={`dot ${currentIndex === i ? "active" : ""}`}
                      />
                    ))}
                  </div>
                  <div className="close" onClick={() => history.goBack()}>
                    <img src="/image-web/Icon/Back.svg" alt="close" />
                  </div>
                  <div
                    className="like-wrapper"
                    onClick={() => {
                      recommendPost();
                    }}
                  >
                    {recommendation ? (
                      <img src="/image-web/Icon/like.png" />
                    ) : (
                      <img src="/image-web/Icon/unlike.png" />
                    )}
                  </div>
                  <div className="progress-wrapper">
                    {data.mine && (
                      <img
                        src="/image-web/Icon/edit.png"
                        onClick={() => setShowEdit(true)}
                      />
                    )}
                    <img
                      src="/image-web/Icon/share.png"
                      onClick={() => shareURL(window.location.href)}
                    />
                  </div>
                </div>
              </div>
            </HoldemItem>
            <InfoBox>
              <span className="title">{data.title}</span>
              <div className="middle">
                <span className="area">
                  {data.areaProvinceName + " " + data.areaCityName}
                </span>
                ·
                <span className="date">{moment(data.createdAt).fromNow()}</span>
              </div>
              <div className="bottom">
                <span className="price">{data.price.toLocaleString()} 원</span>
                <div className="icon-wrapper">
                  <div className="item">
                    <img
                      src="/image-web/Icon/Heart/small-gray.svg"
                      alt="좋아요"
                    />
                    <span>{data.likeCount.toLocaleString()}</span>
                  </div>
                  <div className="item">
                    <img src="/image-web/Holdem%20Now/Icon/comment/small-line.svg" />
                    <span>{data.commentCount.toLocaleString()}</span>
                  </div>
                </div>
              </div>
            </InfoBox>
            <ProfileWrapper>
              <img
                className="cover_image"
                src={
                  data.userProfile
                    ? data.userProfile
                    : "https://dfesoodpx4jgd.cloudfront.net/user/default_profile.png"
                }
              />
              <div className="profile">
                <div className="tag-row">
                  {data?.validate && (
                    <div className="tag">
                      <img src="/image-web/Icon/Certified/small.svg" />
                      <span>본인 인증 완료</span>
                    </div>
                  )}
                </div>

                <div className="user_name">{data.username}</div>
              </div>
            </ProfileWrapper>
            <InfoWrapper>
              <div className="title">상품 정보</div>
              <div
                className="info_box"
                dangerouslySetInnerHTML={{
                  __html: data.message.replace(/\n/g, "<br />"),
                }}
              ></div>
            </InfoWrapper>
            {commentList.length === 0 ? (
              <EmptyWrapper>
                <div className="empty-wrapper">
                  <img src="/image-web/None.png" />
                  <div className="empty-text">
                    아직 댓글이 없습니다.
                    <br />첫 댓글을 남겨보세요!
                  </div>
                </div>
              </EmptyWrapper>
            ) : (
              <CommentWrapper>
                <div className="title">댓글</div>
                <div className="list">
                  {commentList.map((commentItem, index) => {
                    const handleDelete = () => {
                      openDialog({
                        type: "web",
                        text: "해당 댓글을 삭제하시겠습니까?",
                        onConfirm: () => {
                          setLoading(true);
                          exitMarketComment(commentItem.id)
                            .then(() => {
                              enqueueSnackbar("댓글이 삭제되었습니다.", {
                                variant: "success",
                              });
                              getMarketComment(detailId).then((res) => {
                                setCommentList(res);
                              });
                            })
                            .finally(() => {
                              setLoading(false);
                            });
                        },
                        confirm: true,
                        confirmColor: "#D91818",
                      });
                    };

                    const handleChange = () => {
                      let inputRef;
                      if (orientation === "landscape") {
                        inputRef = inputRefPC;
                      } else {
                        inputRef = inputRefMobile;
                      }
                      if (inputRef && inputRef.current) {
                        if (selectedEditCommentId === commentItem.id) {
                          enqueueSnackbar("댓글 등록 모드로 전환합니다.", {
                            variant: "info",
                          });
                          setSelectedEditCommentID(null);
                          setComment("");
                        } else {
                          enqueueSnackbar("댓글 수정 모드로 전환합니다.", {
                            variant: "info",
                          });
                          inputRef.current.focus();
                          setComment(commentItem.message);
                          setSelectedEditCommentID(commentItem.id);
                        }
                      }
                    };

                    return (
                      <CommentItemWrapper key={index}>
                        <div className="profile-wrapper">
                          <div className="profile">
                            <img
                              src={
                                commentItem.userProfile
                                  ? commentItem.userProfile
                                  : "https://dfesoodpx4jgd.cloudfront.net/user/default_profile.png"
                              }
                            />
                          </div>
                          <div className="info">
                            <div className="author-row">
                              <span className="author">
                                {commentItem.userNickName}
                              </span>
                            </div>
                            <div className="createdAt">
                              {timeAgo(commentItem.createdAt)}
                            </div>
                          </div>
                        </div>
                        <div className="content">
                          {commentItem.exit
                            ? "삭제된 메시지입니다."
                            : commentItem.message}{" "}
                        </div>
                        {commentItem.mine && !commentItem.exit && (
                          <div className="fix-row">
                            <div className="button" onClick={handleChange}>
                              {selectedEditCommentId !== null &&
                              selectedEditCommentId === commentItem.id
                                ? "취소"
                                : "수정"}
                            </div>
                            <div className="button" onClick={handleDelete}>
                              삭제
                            </div>
                          </div>
                        )}
                      </CommentItemWrapper>
                    );
                  })}
                </div>
              </CommentWrapper>
            )}
            <PCCommentWriteWrapper>
              <div className="edit-wrapper">
                <img
                  className="profile"
                  src={
                    user?.profileImageUrl || "/image-web/default_profile.png"
                  }
                  alt="profile"
                />
                <textarea
                  ref={inputRefPC}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="댓글을 남겨주세요"
                  onInput={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    target.style.height = "1px";
                    target.style.height = target.scrollHeight + "px";
                  }}
                />
              </div>
              <div className="button" onClick={handleAddComment}>
                {selectedEditCommentId ? "수정" : "등록"}
              </div>
            </PCCommentWriteWrapper>
          </div>
          <div className="floating-button-wrapper">
            <textarea
              ref={inputRefMobile}
              placeholder="댓글을 남겨주세요"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = "1px";
                target.style.height = target.scrollHeight + "px";
              }}
            />
            <div
              className={`button ${comment === "" ? "disable" : ""}`}
              onClick={handleAddComment}
            >
              {selectedEditCommentId ? "수정" : "등록"}
            </div>
          </div>
        </DetailWrapper>
      ) : (
        <Loading full />
      )}
    </>
  );
};

export default SecondHandMarketDetail;
