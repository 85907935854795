import Sheet from "react-modal-sheet";
import styled from "styled-components";
import useQuickButtons from "../../hooks/useQuickButtons";
import {isMobile} from "react-device-detect";
import {useRecoilState} from "recoil";
import {navigationTargetState} from "../../recoil/store";
import {useCallback, useMemo} from "react";

const MapModal = styled.div`
  width: 100vw;
  max-width: 480px;
  display: flex;
  padding: 30px 24px 60px 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 12px 12px 0px 0px;
  background: #FFF;

  > .close {
    cursor: pointer;
    position: absolute;
    object-fit: contain;
    width: 24px;
    height: 24px;
    top: 20px;
    right: 20px;
  }

  > .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    > .title {
      color: var(--Black-500, #202020);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
    }

    > .map-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      > .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: var(--Pro2, 72px);
        height: var(--Pro2, 72px);
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid var(--Black-100, #F0F0F0);

        > img {
          width: 24px;
          height: 24px;
        }

        > .title {
          color: var(--Black-500, #202020);
          text-align: center;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.24px;
        }
      }
    }

    > .address {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;

      > img {
        width: 16px;
        height: 16px;
      }
    }
  }
`

const LocationSheet = () => {
  const [navigationTarget, setNavigationTarget] = useRecoilState(navigationTargetState);
  const {openMap} = useQuickButtons();

  const show = useMemo(() => {
    return navigationTarget !== undefined;
  }, [navigationTarget]);

  const address = useMemo(() => {
    return navigationTarget?.newAddress + ' ' + navigationTarget?.detailAddress;
  }, [navigationTarget]);

  const handleClose = useCallback(() => {
    setNavigationTarget(undefined);
  }, []);

  const handleClick = useCallback((app: string) => {
    if (navigationTarget) {
      openMap(app, navigationTarget);
    }
    handleClose();
  }, [navigationTarget]);

  return show ? <Sheet
    style={{
      width: '100%',
      maxWidth: 500,
      left: '50%',
      transform: 'translateX(-50%)'
    }}
    isOpen={show}
    onClose={() => {
    }}
    disableDrag={true}
    detent={'content-height'}
  >
    <Sheet.Container style={{
      maxWidth: 500,
    }}>
      <Sheet.Content>
        <MapModal>
          <img className='close' onClick={handleClose}
               src='/image-web/Holdem%20Now/Icon/Close.svg'/>
          <div className='info'>
            <div className='title'>길 안내 앱 선택</div>
            <div className='map-row'>
              <div className='item' onClick={() => handleClick('naver')}>
                <img src='/image-web/store/Location/Navermap.png'/>
                <div className='title'>네이버 지도</div>
              </div>
              <div className='item' onClick={() => handleClick('kakao')}>
                <img src='/image-web/store/Location/Kakaomap.png'/>
                <div className='title'>카카오맵</div>
              </div>
              {
                isMobile && (
                  <div className='item' onClick={() => handleClick('tmap')}>
                    <img src='/image-web/store/Location/Tmap.png'/>
                    <div className='title'>티맵</div>
                  </div>
                )
              }
              <div className='item' onClick={() => handleClick('copy')}>
                <img src='/image-web/store/Location/Copy.png'/>
                <div className='title'>주소복사</div>
              </div>
            </div>
            <div className='address'>
              <img src='/image-web/store/Map%20pin/small.png'/>
              {address}
            </div>
          </div>
        </MapModal>
      </Sheet.Content>
    </Sheet.Container>
    <Sheet.Backdrop onTap={handleClose}/>
  </Sheet> : <></>
}

export default LocationSheet;
