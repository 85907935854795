import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { useHistory } from "react-router-dom";
import { DashBoardYoutube } from "../../../api/dashboard";
import { MEDIA_DESKTOP } from "../../../hooks/useScreenOrientation";
import { openNewWindow } from "../../../utils/common";

const YoutubeWrapper = styled.div`
  width: 100%;
  margin-top: 64px;
  display: flex;
  background: white;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  @media ${MEDIA_DESKTOP} {
    gap: 20px;
  }
  > .title-row {
    padding: 0 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .title {
      color: var(--Black-400, #444);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    > .more {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      cursor: pointer;

      > img {
        width: 16px;
        height: 16px;
      }

      > span {
        color: var(--Black-200, #b7b7b7);
        text-align: right;
        font-family: Pretendard;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.26px;
      }
    }
  }

  .swiper {
    width: 100%;
    padding-left: 16px;
  }

  .swiper-slide {
    width: 264px;
  }
`;
const CardItemWrapper = styled.div`
  display: flex;
  width: 264px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;

  > .thumbnail {
    width: 264px;
    height: 150px;
    border-radius: 8px;
    position: relative;
    background: gray;
    overflow: hidden;

    > iframe {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  > .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    > .title-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 4px;

      > .title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--Black-400, #444);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.28px;
      }

      > .count {
        color: var(--Black-300, #808080);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }

    > .info {
      margin-top: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
    }
    > .info-bottom {
      width: 100%;
      position: absolute;
      bottom: 0;
      margin-top: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      color: var(--Black-300, #808080);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.28px;
      > .prize {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
const YoutubeList = ({ list }: { list: DashBoardYoutube[] }) => {
  const history = useHistory();

  if (list.length === 0) {
    return null;
  }

  return (
    <YoutubeWrapper>
      <div className="title-row">
        <div className="title">러튜브</div>
      </div>
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={false}
        spaceBetween={16}
        className="mySwiper"
      >
        {list.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <CardItemWrapper onClick={() => openNewWindow(item.url)}>
                <div className="thumbnail">
                  <img src={item.thumbnailUrl} />
                </div>
                <div className="info">
                  <div className="title-row">
                    <div className="title">{item.title}</div>
                  </div>
                </div>
              </CardItemWrapper>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </YoutubeWrapper>
  );
};

export default YoutubeList;
